import { useDispatch, useSelector } from "react-redux"
import {
    changeInputValue,
    toggleModifyAddress,
    deleteAddress,
    loadingDeleteAddress,
    loadingAddAddress,
    defaultAddress,
} from "../../../../actions/userAction"
import "./AddressCard.scss"

const AddressCard = ({
    id,
    title,
    lastName,
    firstName,
    society,
    street,
    postCode,
    city,
    country,
    phoneNumber,
}) => {
    const dispatch = useDispatch()
    const customer = useSelector((state) => state.user.customerId)
    return (
        <div className="adress-card-wrapper">
            <div className="adress-card-title">{title}</div>
            <div className="adress-card-information">
                <span className="last-name">{lastName} </span>
                <span>{firstName}</span>
            </div>
            <div className="adress-card-information">
                <span>{society}</span>
            </div>
            <div className="adress-card-information">
                <span>{street}</span>
            </div>
            <div className="adress-card-information">
                <span>{postCode} </span>
                <span>{city}</span>
            </div>
            <div className="adress-card-information">
                <span>{country}</span>
            </div>
            <div className="adress-card-information">
                <span>{phoneNumber}</span>
            </div>
            <div className="adress-card-button-wrapper">
                <span
                    onClick={() => {
                        dispatch(changeInputValue(id, "adressIdModify"))
                        dispatch(
                            changeInputValue(lastName, "lastNameAddressModify")
                        )
                        dispatch(
                            changeInputValue(
                                firstName,
                                "firstNameAddressModify"
                            )
                        )
                        dispatch(
                            changeInputValue(society, "societyAddressModify")
                        )
                        dispatch(
                            changeInputValue(street, "streetAddressModify")
                        )
                        dispatch(
                            changeInputValue(postCode, "postCodeAddressModify")
                        )
                        dispatch(changeInputValue(city, "cityAddressModify"))
                        dispatch(
                            changeInputValue(country, "countryAddressModify")
                        )
                        dispatch(
                            changeInputValue(
                                phoneNumber,
                                "telephoneAddressModify"
                            )
                        )

                        dispatch(toggleModifyAddress(true))
                    }}>
                    Modifier {">"}
                </span>

                {title !== "Adresse par défaut" && (
                    <>
                        <span
                            onClick={() => {
                                dispatch(loadingDeleteAddress(true))
                                dispatch(deleteAddress(id))
                            }}>
                            Supprimer {">"}
                        </span>
                        <span
                            onClick={() => {
                                dispatch(loadingAddAddress(true))
                                dispatch(defaultAddress(id, customer))
                            }}>
                            Définir par défaut {">"}
                        </span>
                    </>
                )}
            </div>
        </div>
    )
}

export default AddressCard
