import Header from "../Prepage/Header/Header"
import "./Version.scss"

const Version = () => {
    return (
        <div>
            <Header />
            <div className="version-wrapper">
                <div>
                    Version {process.env.REACT_APP_VERSION}
                </div>
                <div>
                    Commit {process.env.REACT_APP_COMMIT_REF}
                </div>
            </div>
        </div>
    )
}

export default Version
