//Components
import { useSelector } from "react-redux"
import TitleCard from "../../Commons/Cards/TitleCard/TitleCard"

//Styles
import "./NavCard.scss"

const NavCard = () => {

    const artsDuFil  = useSelector((state) => state.common.allUniverses).find((item) => item.code === "arts-du-fil");
    const artsCulinaires  = useSelector((state) => state.common.allUniverses).find((item) => item.code === "arts-culinaires");
    const artsPlastiques  = useSelector((state) => state.common.allUniverses).find((item) => item.code === "arts-plastiques");


    
    return (
    <div className="NavCard-wrapper">

        <TitleCard
            title={"culinaires"}
            image={
                artsCulinaires.images !== undefined ? (artsCulinaires.images.length > 0 ?  artsCulinaires.images[0].url : "https://static.artesane.com/images_react/arts_culinaires2.jpg") : "https://static.artesane.com/images_react/arts_culinaires2.jpg"

            }
            path={"/arts-culinaires"}
        />
        <TitleCard
            title={"du fil"}
            image={
                artsDuFil.images !== undefined ? (artsDuFil.images.length > 0 ?  artsDuFil.images[0].url : "https://static.artesane.com/images_react/test_arts_du_fil.jpg") : "https://static.artesane.com/images_react/test_arts_du_fil.jpg"
            }
            path={"/arts-du-fil"}
        />
        <TitleCard
            title={"plastiques"}
            image={
                artsPlastiques.images !== undefined ? (artsPlastiques.images.length > 0 ?  artsPlastiques.images[0].url : "https://static.artesane.com/images_react/arts_plastiques.jpg") : "https://static.artesane.com/images_react/arts_plastiques.jpg"

            }
            path={"/arts-plastiques"}
        />
    </div>
)}

export default NavCard
