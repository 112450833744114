//Assets
import { NavLink } from "react-router-dom"

//Styles
import "./Footer.scss"

const Footer = () => {
    let date = new Date()
    return (
        <div className="footer-wrapper">
            <div className="footer-top-wrapper">
                <div className="footer-univers">
                    <div className="footer-logo-wrapper">
                        <img
                            className="footer-logo-img"
                            src={
                                "https://static.artesane.com/images_react/logo.png"
                            }
                            alt="logo d'Artesane"
                        />
                    </div>
                    <div className="footer-univers-link">
                        {" "}
                        <NavLink to="/arts-culinaires">
                            Arts culinaires
                        </NavLink>{" "}
                    </div>
                    <div className="footer-univers-link">
                        {" "}
                        <NavLink to="/arts-du-fil">Arts du fil</NavLink>
                    </div>
                    <div className="footer-univers-link">
                        {" "}
                        <NavLink to="/arts-plastiques">Arts plastiques</NavLink>
                    </div>
                    <div className="footer-univers-link">
                        {" "}
                        <a
                            target="_blank"
                            href="https://www.artesane.com/le-journal/artesane-formation-pro/"
                            rel="noreferrer">
                            Espace formation pro
                        </a>{" "}
                    </div>
                    <div className="footer-univers-link">
                        {" "}
                        <a
                            target="_blank"
                            href="https://www.artesane.com/le-journal/"
                            rel="noreferrer">
                            Le journal
                        </a>{" "}
                    </div>
                </div>
                <div className="footer-items-wrapper">
                    <div className="footer-items-colonne-wrapper">
                        <div className="footer-items-colonne">
                            <span className="footer-items-title">aide</span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/livraison-et-retours">
                                    Livraison et retours
                                </NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/faq">FAQ</NavLink>
                            </span>
                            {/* <span className='footer-items'> <NavLink to="#">Presse et papier</NavLink></span> */}
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/arts-du-fil/nos-partenaires">
                                    Nos partenaires Arts du fil
                                </NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/arts-plastiques/nos-partenaires">
                                    Nos partenaires Arts plastiques
                                </NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/arts-culinaires/nos-partenaires">
                                    Nos partenaires Arts culinaires
                                </NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/contact">Nous contacter</NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/paiement-securise">
                                    Paiement sécurisé
                                </NavLink>
                            </span>
                        </div>
                        <div className="footer-items-colonne">
                            <span className="footer-items-title">a propos</span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/qui-sommes-nous">
                                    Qui sommes-nous ?
                                </NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/les-professeurs">
                                    Les professeurs
                                </NavLink>
                            </span>
                        </div>
                        <div className="footer-items-colonne">
                            <span className="footer-items-title">
                                infos légales
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/cgv">CGV</NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/mentions-legales">
                                    Mentions légales
                                </NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/politique-de-confidentialite">
                                    Politique de confidentialité
                                </NavLink>
                            </span>
                            <span className="footer-items">
                                {" "}
                                <NavLink to="/conditions-offres">
                                    Conditions des offres en cours
                                </NavLink>
                            </span>
                        </div>
                        <div className="footer-items-colonne">
                            <div className="footer-items-title">
                                Suivez-nous
                            </div>
                            <div className="footer-item-icon">
                                <a
                                    target="_blank"
                                    href="https://www.instagram.com/artesane_france/?hl=fr"
                                    rel="noreferrer">
                                    <img
                                        className="footer-logo"
                                        src={
                                            "https://static.artesane.com/images_react/footer-insta.png"
                                        }
                                        alt="instagram"
                                    />
                                </a>
                            </div>
                            <div className="footer-item-icon">
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/artesanefrance/"
                                    rel="noreferrer">
                                    <img
                                        className="footer-logo"
                                        src={
                                            "https://static.artesane.com/images_react/footer-facebook.png"
                                        }
                                        alt="facebook"
                                    />
                                </a>
                            </div>
                            <div className="footer-item-icon">
                                <a
                                    target="_blank"
                                    href="https://www.youtube.com/c/Artesane_lesvideos/videos"
                                    rel="noreferrer">
                                    <img
                                        id="youtube"
                                        className="footer-logo"
                                        src={
                                            "https://static.artesane.com/images_react/footer-youtube.png"
                                        }
                                        alt="youtube"
                                    />
                                </a>
                            </div>
                            <div className="footer-item-icon">
                                <a
                                    target="_blank"
                                    href="https://www.pinterest.fr/ArtesaneFrance/"
                                    rel="noreferrer">
                                    <img
                                        className="footer-logo"
                                        src={
                                            "https://static.artesane.com/images_react/footer-pinterest.png"
                                        }
                                        alt="pinterest logo"
                                    />
                                </a>
                            </div>

                            <div className="footer-item-icon">
                                <a
                                    target="_blank"
                                    href="https://www.twitch.tv/artesane?lang=fr"
                                    rel="noreferrer">
                                    <img
                                        className="footer-logo"
                                        src={
                                            "https://static.artesane.com/images_react/footer-twitch.png"
                                        }
                                        alt="twitch"
                                    />
                                </a>
                            </div>

                            <div className="footer-item-icon qualiopi">
                                <a
                                    target="_blank"
                                    href="https://www.artesane.com/le-journal/artesane-formation-pro/"
                                    rel="noreferrer">
                                    <img
                                        className="footer-logo"
                                        src={
                                            "https://static.artesane.com/images_react/AF.png"
                                        }
                                        alt="logo de qualiopi"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-items-under">
                        <div className="footer-item-under-follow-wrapper">
                            <div className="footer-item-under-follow-title">
                                Abonnez-vous à la newsletter
                            </div>
                            <form
                                name="mc-embedded-subscibe-form"
                                rel={"noopener"}
                                // noValidate
                                action="https://artesane.us13.list-manage.com/subscribe/post?u=fed3adae0edf9e9d89854e25d&amp;id=698c1acbba&amp;f_id=00f3c2e1f0"
                                target="_blank"
                                method="post"
                                className="newsletter-footer-form"
                                id="mc-embedded-subscribe-form">
                                <div className="input-top-wrapper">
                                    <input
                                        className="newsletter-footer-input"
                                        placeholder="Mon e-mail"
                                        type="email"
                                        id="mce-EMAIL"
                                        size="30"
                                        name={"EMAIL"}
                                        required
                                    />

                                    <button
                                        className="newsletter-footer-button"
                                        type="submit">
                                        je m'inscris
                                    </button>
                                </div>

                                <div>
                                    <ul>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="2"
                                                name="group[15][2]"
                                                id="mce-group[15]-15-0"
                                            />
                                            <label htmlFor="mce-group[15]-15-0">
                                                Arts du fil
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="4"
                                                name="group[15][4]"
                                                id="mce-group[15]-15-1"
                                            />
                                            <label htmlFor="mce-group[15]-15-1">
                                                Arts plastiques
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="8"
                                                name="group[15][8]"
                                                id="mce-group[15]-15-2"
                                            />
                                            <label htmlFor="mce-group[15]-15-2">
                                                Arts culinaires
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                © Artesane {date.getFullYear()}
            </div>
        </div>
    )
}

export default Footer
