import "./AccountAccess.scss"
import { NavLink } from "react-router-dom"

const AccountAccess = ({ logo, slug }) => (
    <div className="accountAccess">
        <NavLink to={slug}>
            <img className="accountAccess-image" src={logo} alt="" />
            {/* si user connecté : assets utilisateur.png */}
        </NavLink>
    </div>
)

export default AccountAccess
