import { NavLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "./AccountMenu.scss"
import { changeInputValue, saveWishlist } from "../../../actions/userAction"
import {
    changeInputAuthValue,
    userConnexion,
    visibilityAuthPassword,
} from "../../../actions/authAction"
import { saveUserMedia, loadingMedia } from "../../../actions/mediaAction"
import {
    countCart,
    saveCartInState,
    changeInputOrder,
} from "../../../actions/cartAction"
const AccountMenu = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const homeUniversActif = useSelector((state) => state.home.homeUniversActif)

    return (
        <>
            {/* <div className={ 
              isOpenBurger ? "account-menu-responsive-burger-open":  
         "account-menu-responsive-burger"}> 
            <div
            onClick={() =>  {
                dispatch(toggleBurger(!isOpenBurger));
            }}
            >
                <div className={isOpenBurger ? "burger-item-one" : "burger-menu-cross-item"}></div>
                <div className={isOpenBurger ? "burger-item-two" : "burger-menu-cross-item"}></div>
                <div className={isOpenBurger ? "burger-item-three" : "burger-menu-cross-item"}></div>
            </div>  
        </div> */}
            <div
                className={
                    // isOpenBurger ? "account-menu-wrapper" :
                    "account-menu-wrapper"
                }>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"/mes-cours-et-documents"}>
                        Mes cours et documents
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"mes-commandes"}>
                        Mes commandes
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"mes-infos-personnelles"}>
                        Mes infos personnelles
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"mon-profil"}>
                        Mon profil
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"mes-adresses"}>
                        Mes adresses
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"mes-cheques-cadeaux"}>
                        Mes cartes cadeaux
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"mes-abonnements"}>
                        Mes abonnements
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? "account-link-active" : undefined
                        }
                        to={"ma-liste-de-souhaits"}>
                        Ma liste de souhaits
                    </NavLink>
                </div>
                <div className="account-menu-item">
                    <div
                        className="deconnect"
                        onClick={() => {
                            dispatch(changeInputValue(0, "userId"))
                            dispatch(changeInputAuthValue("", "email"))
                            dispatch(
                                changeInputAuthValue("", "emailRegistration")
                            )
                            dispatch(
                                changeInputAuthValue("", "emailRegistration")
                            )
                            dispatch(changeInputValue("", "lastNameAddressAdd"))
                            dispatch(changeInputValue("", "lastNameAddressAdd"))
                            dispatch(
                                changeInputValue("", "firstNameAddressAdd")
                            )
                            dispatch(changeInputValue("", "streetAddressAdd"))
                            dispatch(
                                changeInputValue("", "complementAddressAdd")
                            )
                            dispatch(changeInputValue("", "societyAddressAdd"))
                            dispatch(changeInputValue("", "postCodeAddressAdd"))
                            dispatch(changeInputValue("", "cityAddressAdd"))
                            dispatch(
                                changeInputValue("", "telephoneAddressAdd")
                            )
                            dispatch(
                                changeInputValue(false, "defaultAdressAdd")
                            )
                            dispatch(changeInputOrder(false, "addressValidate"))
                            dispatch(
                                changeInputOrder(
                                    false,
                                    "errorTelephoneShipping"
                                )
                            )
                            dispatch(changeInputOrder(0, "addressSelectCarnet"))
                            dispatch(changeInputValue([], "addresses"))
                            dispatch(changeInputValue(0, "wishlistItemNumber"))
                            dispatch(changeInputOrder("", "firstNameShipping"))
                            dispatch(changeInputOrder("", "lastNameShipping"))
                            dispatch(changeInputOrder("", "societyShipping"))
                            dispatch(changeInputOrder("", "cityShipping"))
                            dispatch(changeInputOrder("", "complementShipping"))
                            dispatch(changeInputOrder("", "streetShipping"))
                            dispatch(changeInputOrder("", "postCodeShipping"))
                            dispatch(changeInputOrder("", "countryShipping"))
                            dispatch(changeInputOrder("", "telephoneShipping"))

                            dispatch(changeInputOrder("", "firstNameBilling"))
                            dispatch(changeInputOrder("", "lastNameBilling"))
                            dispatch(changeInputOrder("", "societyBilling"))
                            dispatch(changeInputOrder("", "cityBilling"))
                            dispatch(changeInputOrder("", "complement"))
                            dispatch(changeInputOrder("", "streetBilling"))
                            dispatch(changeInputOrder("", "postCodeBilling"))
                            dispatch(changeInputOrder("", "countryBilling"))
                            dispatch(changeInputOrder("", "telephoneBilling"))
                            dispatch(changeInputOrder("", "adjustmentsPromo"))
                            dispatch(changeInputOrder("", "couponCode"))
                            dispatch(
                                changeInputOrder("", "adjustmentsShipping")
                            )
                            dispatch(visibilityAuthPassword(false))
                            dispatch(saveUserMedia("mediaArtsCulinaires", []))
                            dispatch(saveUserMedia("mediaArtsDuFil", []))
                            dispatch(saveUserMedia("mediaArtsPlastiques", []))
                            dispatch(saveCartInState([]))
                            dispatch(saveWishlist({}))
                            dispatch(countCart(0))
                            dispatch(loadingMedia(true))
                            localStorage.clear()
                            navigate(
                                homeUniversActif !== ""
                                    ? `/${homeUniversActif}`
                                    : "/"
                            )
                            dispatch(userConnexion(false))
                        }}>
                        Se déconnecter{" "}
                        <img
                            src={
                                "https://static.artesane.com/images_react/exit.png"
                            }
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountMenu
