export const dropdownFirstHome = [
    {
        title: "Arts culinaires",
        slug: "/arts-culinaires",
    },
    {
        title: "Arts du fil",
        slug: "/arts-du-fil",
    },
    {
        title: "Arts plastiques",
        slug: "/arts-plastiques",
    },
    // {
    //     title: 'Espace formation Pro',
    //     slug: '/formation-pro',
    // },
]
