//Styles
import "./TitleCard.scss"

//hooks
import { NavLink } from "react-router-dom"

const TitleCard = ({ title, image, path }) => (
    <div className="card-item">
        <NavLink to={path}>
            <img src={image} alt={`photographie illustrant ${title}`} />
        </NavLink>
        <NavLink to={path}>
            <h2 className="title">
                Arts
                <span>{title} </span>
            </h2>
        </NavLink>
        <NavLink className="nav-link" to={path}>
            <div className="nav-link-texte">Découvrez nos cours</div>
        </NavLink>
    </div>
)

export default TitleCard
