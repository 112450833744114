import { useDispatch, useSelector } from "react-redux"
import InputSimple from "../../../UserAccount/InputSimple/inputSimple";
import { changeInputOrder, modifyDefaultAddressOrder, putAddAddressOrder, toggleBillingAddress } from "../../../../actions/cartAction";
import { useRef } from "react";
import TitleCart from "../TittleCart/TitleCart";

const AddressForm = () => {
    const dispatch = useDispatch();
    const countries = useSelector((state) => state.user.countries)
    const isBillingAddressSame = useSelector(
        (state) => state.cart.sameAddressAsShippingAddress
    )

    const lastNameShipping = useSelector((state) => state.cart.lastNameShipping)
    const firstNameShipping = useSelector(
        (state) => state.cart.firstNameShipping
    )
    const societyShipping = useSelector((state) => state.cart.societyShipping)
    const streetShipping = useSelector((state) => state.cart.streetShipping)
    const postCodeShipping = useSelector((state) => state.cart.postCodeShipping)
    const cityShipping = useSelector((state) => state.cart.cityShipping)
    const countryShipping = useSelector((state) => state.cart.countryShipping)
    const telephoneShipping = useSelector(
        (state) => state.cart.telephoneShipping
    )
    const errorTelephoneShipping = useSelector(
        (state) => state.cart.errorTelephoneShipping
    )

    const lastNameBilling = useSelector((state) => state.cart.lastNameBilling)
    const firstNameBilling = useSelector((state) => state.cart.firstNameBilling)
    const societyBilling = useSelector((state) => state.cart.societyBilling)
    const streetBilling = useSelector((state) => state.cart.streetBilling)
    const postCodeBilling = useSelector((state) => state.cart.postCodeBilling)
    const cityBilling = useSelector((state) => state.cart.cityBilling)
    const countryBilling = useSelector((state) => state.cart.countryBilling)
    const telephoneBilling = useSelector((state) => state.cart.telephoneBilling)
    const errorTelephoneBilling = useSelector(
        (state) => state.cart.errorTelephoneBilling
    )
    const cartData = useSelector((state) => state.cart.cartData)
    const ancreRef = useRef(null);
    const addressValidate = useSelector((state) => state.cart.addressValidate)
    const defaultAddress = useSelector((state) => state.user.defaultAddress)

    return (
        <div>
            <TitleCart title={"Mon adresse"}/>
            <div className="order-address-wrapper">
            
            <form
                action="#"
                onSubmit={(event) => {
                    event.preventDefault()
                    window.scrollTo({ top: 0 })
                    if (
                        telephoneShipping.match(
                        /^[0-9]+$/g
                        ) && (telephoneShipping.trim().length <= 15)
                    ) {
                        dispatch(
                            changeInputOrder(
                                false,
                                "errorTelephoneShipping"
                            )
                        )
                        dispatch(
                            putAddAddressOrder(
                                cartData.tokenValue
                            )
                        )
                        dispatch(
                            changeInputOrder(
                                true,
                                "loadingCart"
                            )
                        )
                        dispatch(
                            changeInputOrder(
                                true,
                                "loadingValidateAddress"
                            )
                        )

                        if(defaultAddress !== "") {
                            dispatch(modifyDefaultAddressOrder(defaultAddress));
                        }
                    } else {
                        dispatch(
                            changeInputOrder(
                                true,
                                "errorTelephoneShipping"
                            )
                        )
                    }
                }}>
                <div className="adresse">
                    <div className="name">
                        Adresse de livraison :
                    </div>

                    {/* {addresses.length > 0 && (
                        <div className="chose-address-select">
                            <label>
                                Choissisez une adresse dans
                                votre carnet d'adresses :
                            </label>
                            <select
                                value={addressSelectCarnet}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "addressValidate"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "errorTelephoneShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            Number(
                                                event.target
                                                    .value
                                            ),
                                            "addressSelectCarnet"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].firstName,
                                            "firstNameShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].lastName,
                                            "lastNameShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].company,
                                            "societyShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].city,
                                            "cityShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].street,
                                            "streetShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].postcode,
                                            "postCodeShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].countryCode,
                                            "countryShipping"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            addresses[
                                                Number(
                                                    event
                                                        .target
                                                        .value
                                                ) - 1
                                            ].phoneNumber,
                                            "telephoneShipping"
                                        )
                                    )
                                }}>
                                <option value={0}>
                                    -----------------------------------
                                </option>
                                {addresses.map(
                                    (
                                        {
                                            id,
                                            firstName,
                                            lastName,
                                            street,
                                            postcode,
                                            city,
                                        },
                                        index
                                    ) => (
                                        <option
                                            key={id}
                                            value={
                                                index + 1
                                            }>
                                            {firstName}{" "}
                                            {lastName},{" "}
                                            {street} ,{" "}
                                            {postcode}{" "}
                                            {city}{" "}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    )} */}

                    <div className="adress-formulaire">
                        <div className="form-address-left" ref={ancreRef}>
                            <div className="flex">
                                <InputSimple
                                    inputName={
                                        "lastNameShipping"
                                    }
                                    label={"Nom *"}
                                    value={lastNameShipping}
                                    type={"text"}
                                    required={true}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "lastNameShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "addressValidate"
                                            )
                                        )
                                    }}
                                />

                                <InputSimple
                                    inputName={
                                        "firstNameShipping"
                                    }
                                    label={"Prénom *"}
                                    value={firstNameShipping}
                                    type={"text"}
                                    required={true}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "firstNameShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "addressValidate"
                                            )
                                        )
                                    }}
                                />
                            </div>
                            <div className="flex">
                                <InputSimple
                                    inputName={
                                        "societyShipping"
                                    }
                                    label={"Société"}
                                    value={
                                        societyShipping !== null
                                            ? societyShipping
                                            : ""
                                    }
                                    type={"text"}
                                    required={false}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "societyShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "addressValidate"
                                            )
                                        )
                                    }}
                                />

                                <InputSimple
                                    inputName={"streetShipping"}
                                    label={"Voie et numéro *"}
                                    maxLength={35}
                                    value={streetShipping}
                                    type={"text"}
                                    required={true}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "streetShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "addressValidate"
                                            )
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-address-right">
                            <div className="flex">
                                <InputSimple
                                    inputName={
                                        "postCodeShipping"
                                    }
                                    label={"Code postal *"}
                                    value={postCodeShipping}
                                    type={
                                        countryShipping === "FR"
                                            ? "number"
                                            : "text"
                                    }
                                    required={true}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "postCodeShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "addressValidate"
                                            )
                                        )
                                    }}
                                />

                                <InputSimple
                                    inputName={"cityShipping"}
                                    label={"Ville *"}
                                    value={cityShipping}
                                    type={"text"}
                                    required={true}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "cityShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "addressValidate"
                                            )
                                        )
                                    }}
                                />
                            </div>
                            <div className="flex">
                                <div className="input-wrapper">
                                    <label
                                        className="personal-label"
                                        htmlFor="first-name">
                                        Pays *
                                    </label>
                                    <select
                                        required
                                        value={countryShipping}
                                        name="country"
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputOrder(
                                                    event.target
                                                        .value,
                                                    "countryShipping"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "addressValidate"
                                                )
                                            )
                                        }}>
                                        {countries.map(
                                            ({
                                                name,
                                                code,
                                            }) => {
                                                if (
                                                    code !==
                                                        "EZ" &&
                                                    code !==
                                                        "AC"
                                                ) {
                                                    return (
                                                        <option
                                                            key={
                                                                code
                                                            }
                                                            value={
                                                                code
                                                            }>
                                                            {
                                                                name
                                                            }
                                                        </option>
                                                    )
                                                }
                                                return null
                                            }
                                        )}
                                    </select>
                                </div>
                                <InputSimple
                                    maxLength={15}
                                    error={
                                        errorTelephoneShipping
                                    }
                                    inputName={
                                        "telephoneShipping"
                                    }
                                    label={"Téléphone *"}
                                    value={
                                        telephoneShipping !==
                                        null
                                            ? telephoneShipping
                                            : ""
                                    }
                                    type={"tel"}
                                    required={true}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "errorTelephoneShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "telephoneShipping"
                                            )
                                        )
                                        dispatch(
                                            changeInputOrder(
                                                false,
                                                "addressValidate"
                                            )
                                        )
                                    }}
                                />

                            {/* <div
                                className={`input-information ${errorTelephoneShipping ? "error" : ""}`}>
                                Les lettres et les symboles ne sont pas
                                autorisées.
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="adresse">
                    <div className="flex billing">
                        <div className="name">
                            Adresse de facturation :
                        </div>
                        <InputSimple
                            className={"checkbox"}
                            // inputName={"defaultAddressAdd"}
                            label={
                                "Utiliser la même adresse que l'adresse de livraison"
                            }
                            value={isBillingAddressSame}
                            checked={isBillingAddressSame}
                            type={"checkbox"}
                            required={false}
                            onChange={(event) => {
                                dispatch(
                                    toggleBillingAddress(
                                        !isBillingAddressSame
                                    )
                                )
                                dispatch(
                                    changeInputOrder(
                                        false,
                                        "addressValidate"
                                    )
                                )
                                if (!event.target.checked) {
                                    // Scroll vers l'ancre si le checkbox est décoché
                                    ancreRef.current?.scrollIntoView({ behavior: "smooth" });
                                }
                            }}
                        />
                    </div>

                    {!isBillingAddressSame && (
                        <div className="adress-formulaire">
                            <div className="form-address-left">
                                <div className="flex">
                                    <InputSimple
                                        inputName={
                                            "lastNameBilling"
                                        }
                                        label={"Nom *"}
                                        value={lastNameBilling}
                                        type={"text"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputOrder(
                                                    event.target
                                                        .value,
                                                    "lastNameBilling"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "addressValidate"
                                                )
                                            )
                                        }}
                                    />

                                    <InputSimple
                                        inputName={
                                            "firstNameBilling"
                                        }
                                        label={"Prénom *"}
                                        value={firstNameBilling}
                                        type={"text"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputOrder(
                                                    event.target
                                                        .value,
                                                    "firstNameBilling"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "addressValidate"
                                                )
                                            )
                                        }}
                                    />
                                    </div>
                                    <div className="flex">
                                        <InputSimple
                                            inputName={
                                                "societyBilling"
                                            }
                                            label={"Société"}
                                            value={societyBilling}
                                            type={"text"}
                                            required={false}
                                            onChange={(event) => {
                                                dispatch(
                                                    changeInputOrder(
                                                        event.target
                                                            .value,
                                                        "societyBilling"
                                                    )
                                                )
                                                dispatch(
                                                    changeInputOrder(
                                                        false,
                                                        "addressValidate"
                                                    )
                                                )
                                            }}
                                        />

                                        <InputSimple
                                            inputName={
                                                "streetShipping"
                                            }
                                            label={
                                                "Voie et numéro *"
                                            }
                                            maxLength={35}
                                            value={streetBilling}
                                            type={"text"}
                                            required={true}
                                            onChange={(event) => {
                                                dispatch(
                                                    changeInputOrder(
                                                        event.target
                                                            .value,
                                                        "streetBilling"
                                                    )
                                                )
                                                dispatch(
                                                    changeInputOrder(
                                                        false,
                                                        "addressValidate"
                                                    )
                                                )
                                            }}
                                        />
                                </div>
                            </div>
                            <div className="form-address-right">
                                <div className="flex">
                                    <InputSimple
                                        inputName={
                                            "postCodeBilling"
                                        }
                                        label={"Code postal *"}
                                        value={postCodeBilling}
                                        type={
                                            countryShipping ===
                                            "FR"
                                                ? "number"
                                                : "text"
                                        }
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputOrder(
                                                    event.target
                                                        .value,
                                                    "postCodeBilling"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "addressValidate"
                                                )
                                            )
                                        }}
                                    />

                                    <InputSimple
                                        inputName={
                                            "cityBilling"
                                        }
                                        label={"Ville *"}
                                        value={cityBilling}
                                        type={"text"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputOrder(
                                                    event.target
                                                        .value,
                                                    "cityBilling"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "addressValidate"
                                                )
                                            )
                                        }}
                                    />
                                </div>
                                <div className="flex">
                                    <div className="input-wrapper">
                                        <label
                                            className="personal-label"
                                            htmlFor="first-name">
                                            Pays *
                                        </label>
                                        <select
                                            required
                                            value={
                                                countryBilling
                                            }
                                            name="country"
                                            onChange={(
                                                event
                                            ) => {
                                                dispatch(
                                                    changeInputOrder(
                                                        event
                                                            .target
                                                            .value,
                                                        "countryBilling"
                                                    )
                                                )
                                                dispatch(
                                                    changeInputOrder(
                                                        false,
                                                        "addressValidate"
                                                    )
                                                )
                                            }}>
                                            {countries.map(
                                                ({
                                                    name,
                                                    code,
                                                }) => {
                                                    if (
                                                        code !==
                                                            "EZ" &&
                                                        code !==
                                                            "AC"
                                                    ) {
                                                        return (
                                                            <option
                                                                key={
                                                                    code
                                                                }
                                                                value={
                                                                    code
                                                                }>
                                                                {
                                                                    name
                                                                }
                                                            </option>
                                                        )
                                                    }
                                                    return null
                                                }
                                            )}
                                        </select>
                                    </div>
                                    <InputSimple
                                        error={
                                            errorTelephoneBilling
                                        }
                                        inputName={
                                            "telephoneBilling"
                                        }
                                        label={"Téléphone *"}
                                        value={telephoneBilling}
                                        type={"tel"}
                                        required={true}
                                        onChange={(event) => {
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "errorTelephoneBilling"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    event.target
                                                        .value,
                                                    "telephoneBilling"
                                                )
                                            )
                                            dispatch(
                                                changeInputOrder(
                                                    false,
                                                    "addressValidate"
                                                )
                                            )
                                        }}
                                    />
                                </div>
                                {/* <div
                                    className={`input-information ${errorTelephoneBilling ? "error" : ""}`}>
                                    Les lettres ne sont pas
                                    autorisées.
                                </div> */}
                            </div>
                        </div>
                    )}
                </div>

                {!addressValidate && (
                    <button
                        className="submit-button"
                        type="submit">
                        Continuer {">"}
                    </button>
                )}
            </form>
            </div>
        </div>
    )
}

export default AddressForm;