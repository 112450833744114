//Styles
import "./PrivacyPolicy.scss"

//Components
import Header from "../../Prepage/Header/Header"
import { useEffect } from "react"
import Banner from "../../Banner/Banner"

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | Politique de confidentialité"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Politique de confidentialité "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://artefact.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="privacy-policy-wrapper">
                <h1>AVIS DE CONFIDENTIALIT&Eacute; &ndash; RGPD</h1>
                <p>&nbsp;</p>
                <p>
                    Artesane respecte vos donn&eacute;es personnelles.
                    D&eacute;couvrez ici la charte de confidentialit&eacute; et
                    de traitement de vos donn&eacute;es que nous nous imposons.
                </p>
                <p>&nbsp;</p>
                <p>
                    Vous disposez d'un abonnement &agrave; la newsletter ou d'un
                    compte sur le site&nbsp;
                    <a href="https://www.artesane.com/">
                        https://www.artesane.com
                    </a>
                    .
                </p>
                <p>
                    Notre politique de confidentialit&eacute; a
                    &eacute;t&eacute; mise &agrave; jour afin de nous conformer
                    au R&Egrave;GLEMENT G&Eacute;N&Eacute;RAL SUR LA PROTECTION
                    DES DONN&Eacute;ES DE L'UNION EUROP&Eacute;ENNE.
                </p>
                <p>
                    Dans le cadre de la mise en conformit&eacute; des
                    traitements de donn&eacute;es &agrave; caract&egrave;re
                    personnel mis en &oelig;uvre par ARTESANE au RGPD, entrant
                    en vigueur le 25 mai 2018, nous vous informons que nous
                    mettons en &oelig;uvre des traitements de donn&eacute;es
                    &agrave; caract&egrave;re personnel afin de nous permettre
                    d&rsquo;assurer, entre autres, la gestion de vos commandes,
                    sur une base contractuelle.
                </p>
                <p>
                    Ainsi, vos donn&eacute;es &agrave; caract&egrave;re
                    personnel sont conserv&eacute;es par nos soins pendant toute
                    la dur&eacute;e de la relation contractuelle, puis
                    archiv&eacute;es pour la p&eacute;riode li&eacute;e &agrave;
                    notre responsabilit&eacute;́, avant suppression
                    d&eacute;finitive.
                </p>
                <p>
                    La liste des donn&eacute;es personnelles trait&eacute;es par
                    Artesane est la suivante. Il s'agit d'une liste exhaustive
                    et nous ne disposons pas de ces informations concernant
                    votre &eacute;tat-civil, vos adresses et
                    t&eacute;l&eacute;phones,&nbsp;si vous avez choisi de ne pas
                    nous les communiquer :
                </p>
                <ul>
                    <li>Nom</li>
                    <li>Pr&eacute;nom</li>
                    <li>Genre</li>
                    <li>Pseudonyme</li>
                    <li>Site personnel</li>
                    <li>Date de naissance</li>
                    <li>Adresse postale</li>
                    <li>Adresse &eacute;lectronique</li>
                    <li>T&eacute;l&eacute;phone</li>
                    <li>R&eacute;seaux sociaux</li>
                    <li>
                        Historique des achats et commandes gratuites sur le site
                    </li>
                    <li>Historique de navigation sur le site</li>
                    <li>Historique de suivi des vid&eacute;os</li>
                </ul>
                <p>
                    Nous vous rappelons que vous disposez d&rsquo;un droit
                    d&rsquo;acc&egrave;s, de rectification, d&rsquo;effacement,
                    d&rsquo;opposition, de limitation du traitement de vos
                    donn&eacute;es &agrave; caract&egrave;re personnel.
                </p>
                <p>
                    Vous pouvez exercer ces droits par toute demande
                    &eacute;crite et sign&eacute;e pr&eacute;cisant
                    l&rsquo;adresse &agrave; laquelle doit parvenir la
                    r&eacute;ponse et accompagn&eacute;e d&rsquo;une copie de
                    votre pi&egrave;ce d&rsquo;identit&eacute;, le tout
                    adress&eacute; &agrave; :&nbsp;
                </p>
                <p>ARTESANE, 33 rue du Hameau - 75015 Paris,</p>
                <p>ou par courriel &agrave;&nbsp;</p>
                <p>
                    <a href="mailto:contact@artesane.com">
                        contact@artesane.com
                    </a>
                    .&nbsp;
                </p>
                <p>
                    Une r&eacute;ponse vous sera adress&eacute;e dans un
                    d&eacute;lai d&rsquo;un mois suivant la r&eacute;ception de
                    la demande, d&eacute;lai pouvant &ecirc;tre
                    prolong&eacute;&nbsp;si n&eacute;cessaire.
                </p>
                <p>
                    Attention cependant, pour rappel, l&rsquo;acc&egrave;s
                    &agrave; vos cours et aux diff&eacute;rents documents
                    li&eacute;s est directement li&eacute; &agrave; votre compte
                    client conform&eacute;ment &agrave; nos CGV. La suppression
                    de votre compte entra&icirc;nera la suppression de
                    l&rsquo;acc&egrave;s aux cours achet&eacute;s.
                </p>

                <h2>
                    Finalit&eacute;s, base juridique du traitement et
                    dur&eacute;e de conservation de vos donn&eacute;es
                </h2>

                <p>
                    Voici les grands principes sur lesquels nous nous appuyons
                    dans le traitement de vos donn&eacute;es&nbsp;:
                </p>
                <p>
                    <strong>Transparence&nbsp;: </strong>nous vous fournissons
                    toutes les informations utiles sur le traitement de vos
                    donn&eacute;es, notamment les finalit&eacute;s et les
                    destinataires&nbsp;;
                </p>
                <p>
                    <strong>
                        L&eacute;gitimit&eacute; et pertinence&nbsp;:{" "}
                    </strong>
                    &nbsp;nous collectons et traitons uniquement les
                    donn&eacute;es n&eacute;cessaires aux finalit&eacute;s
                    indiqu&eacute;es&nbsp;;
                </p>
                <p>
                    <strong>
                        Confidentialit&eacute; et
                        int&eacute;gralit&eacute;&nbsp;:{" "}
                    </strong>
                    nous mettons en place toutes les mesures techniques et
                    organisationnelles raisonnables pour prot&eacute;ger vos
                    donn&eacute;es personnelles contre la divulgation, la perte,
                    l&rsquo;alt&eacute;ration ou l&rsquo;acc&egrave;s par un
                    tiers non autoris&eacute;&nbsp;;
                </p>
                <p>
                    <strong>Conservation&nbsp;: </strong>nous ne conservons vos
                    donn&eacute;es personnelles uniquement le temps
                    n&eacute;cessaire aux fins du traitement ou du service
                    d&eacute;termin&eacute;&nbsp;;&nbsp;
                </p>
                <p>
                    <strong>Respect de vos droits&nbsp;: </strong>nous vous
                    offrons la possibilit&eacute; d&rsquo;acc&eacute;der, de
                    modifie et de vous opposer aux traitements de vos
                    donn&eacute;es, soit directement via votre compte, soit via
                    notre service client.
                </p>
                <p>
                    Le tableau ci-dessous expose, pour chaque traitement, les
                    finalit&eacute;s, la base juridique et la dur&eacute;e de
                    conservation de vos donn&eacute;es.
                </p>
                <table>
                    <thead>
                        <tr>
                            <td>
                                <p>
                                    <strong>
                                        OBJET ET FINALITES DU TRAITEMENT
                                    </strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>
                                        BASE JURIDIQUE DU TRAITEMENT
                                    </strong>
                                </p>
                            </td>
                            <td>
                                <p>
                                    <strong>DUREE DE CONSERVATION</strong>
                                </p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p>Ouverture et gestion du compte</p>
                            </td>
                            <td>
                                <p>
                                    Ex&eacute;cution d&rsquo;un contrat auquel
                                    vous &ecirc;tes partie
                                </p>
                            </td>
                            <td>
                                <p>
                                    Pendant la dur&eacute;e pendant laquelle
                                    votre compte est actif puis archivage
                                    &agrave; des fins de preuve l&eacute;gale
                                    pour la dur&eacute;e strictement
                                    n&eacute;cessaire &agrave; ces fins
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    Gestion des commandes de services Artesane
                                </p>
                            </td>
                            <td>
                                <p>
                                    Ex&eacute;cution d&rsquo;un contrat auquel
                                    vous &ecirc;tes partie
                                </p>
                            </td>
                            <td>
                                <p>
                                    Pendant la dur&eacute;e pendant laquelle
                                    votre compte est actif puis archivage
                                    &agrave; des fins de preuve l&eacute;gale
                                    pour la dur&eacute;e strictement
                                    n&eacute;cessaire &agrave; ces fins
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Gestion du contentieux</p>
                            </td>
                            <td>
                                <p>
                                    Int&eacute;r&ecirc;t l&eacute;gitime
                                    poursuivi par ARTESANE s&rsquo;assurer la
                                    protection de ses droits et de se
                                    d&eacute;fendre
                                </p>
                            </td>
                            <td>
                                <p>
                                    Pendant toute la dur&eacute;e de la
                                    proc&eacute;dure, des voix de recours et
                                    pour la dur&eacute;e n&eacute;cessaire
                                    &agrave; l&rsquo;ex&eacute;cution des
                                    d&eacute;cisions
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Gestion de l&rsquo;envoi de newsletters</p>
                            </td>
                            <td>
                                <p>Consentement</p>
                            </td>
                            <td>
                                <p>
                                    Pendant une dur&eacute;e de 3 ans &agrave;
                                    compter de leur collecte ou de votre dernier
                                    contact
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>R&eacute;ponse aux demandes de contact</p>
                            </td>
                            <td>
                                <p>
                                    Int&eacute;r&ecirc;t l&eacute;gitime
                                    poursuivi par ARTESANE de donner suite
                                    &agrave; votre demande de contact
                                </p>
                            </td>
                            <td>
                                <p>
                                    Pendant la dur&eacute;e n&eacute;cessaire
                                    &agrave; donner suite &agrave; votre demande
                                    de contact et pendant une dur&eacute;e de 6
                                    mois suivant le dernier contact.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    Gestion des questionnaires ou enqu&ecirc;tes
                                    de satisfaction
                                </p>
                            </td>
                            <td>
                                <p>
                                    Int&eacute;r&ecirc;t l&eacute;gitime
                                    poursuivi par ARTESANE de connaitre les
                                    pr&eacute;f&eacute;rences et attentes de ses
                                    clients et am&eacute;liorer son service
                                </p>
                            </td>
                            <td>
                                <p>18 mois</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>
                                    Gestion des demandes d&rsquo;exercice de
                                    droits (droit d&rsquo;acc&egrave;s, de
                                    rectification, etc&hellip;)
                                </p>
                            </td>
                            <td>
                                <p>Obligation l&eacute;gale</p>
                            </td>
                            <td>
                                <p>
                                    Pendant la dur&eacute;e n&eacute;cessaire
                                    &agrave; donner suite &agrave; votre demande
                                    et conserv&eacute;e en fonction de la
                                    n&eacute;cessit&eacute; de la traiter
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    ARTESANE ne recourt pas &agrave; des moyens de prise de
                    d&eacute;cision fond&eacute;e exclusivement sur un
                    traitement automatis&eacute;.
                </p>

                <h2>
                    Donn&eacute;es &agrave; caract&egrave;re personnel
                    trait&eacute;es par ARTESANE
                </h2>

                <p>
                    Les donn&eacute;es &agrave; caract&egrave;re personnel
                    trait&eacute;es par ARTESANE sont ad&eacute;quates,
                    pertinentes et limit&eacute;es &agrave; ce qui est
                    n&eacute;cessaire au regard des finalit&eacute;s pour
                    lesquelles elles sont trait&eacute;es.
                </p>
                <p>
                    Votre attention est notamment attir&eacute;e sur le fait que
                    ARTESANE ne traite en aucun cas vos donn&eacute;es bancaires
                    qui ne sont trait&eacute;es que par notre prestataire de
                    paiement, la soci&eacute;t&eacute; Payplug, selon les
                    conditions que cette derni&egrave;re d&eacute;termine.
                </p>
                <p>
                    La fourniture de donn&eacute;es &agrave; caract&egrave;re
                    personnel est parfois exig&eacute;e, cette exigence pouvant
                    avoir un caract&egrave;re contractuel ou conditionnant la
                    conclusion d'un contrat. ARTESANE vous informera lorsque
                    vous &ecirc;tes tenu de fournir des donn&eacute;es &agrave;
                    caract&egrave;re personnel. Si tel est le cas, la
                    non-fourniture de ces donn&eacute;es peut placer ARTESANE
                    dans l&rsquo;impossibilit&eacute; de respecter les
                    obligations r&eacute;glementaires, de r&eacute;pondre
                    &agrave; vos demandes ou d&rsquo;ex&eacute;cuter un contrat.
                </p>

                <h2>
                    Destinataires des donn&eacute;es &agrave; caract&egrave;re
                    personnel
                </h2>

                <p>
                    Vos donn&eacute;es &agrave; caract&egrave;re personnel sont
                    destin&eacute;es aux seules personnes ayant besoin
                    d&rsquo;en conna&icirc;tre au sein de ARTESANE, dans la
                    stricte limite de leurs attributions.
                </p>
                <p>
                    Par ailleurs, vos donn&eacute;es &agrave; caract&egrave;re
                    personnel pourront &ecirc;tre
                    accessibles/communiqu&eacute;es &agrave; des prestataires de
                    services agissant en qualit&eacute; de sous-traitant au sens
                    de la r&egrave;glementation applicable, auxquels ARTESANE
                    peut recourir aux fins de traitement. ARTESANE fait
                    uniquement appel &agrave; des sous-traitants qui
                    pr&eacute;sentent des garanties suffisantes quant &agrave;
                    la mise en &oelig;uvre de mesures techniques et
                    organisationnelles appropri&eacute;es de mani&egrave;re
                    &agrave; ce que le traitement r&eacute;ponde aux exigences
                    de la r&egrave;glementation applicable et garantisse la
                    protection de vos droits. ARTESANE fera souscrire &agrave;
                    ces sous-traitants les obligations contractuelles requises
                    par la r&egrave;glementation applicable.
                </p>
                <p>
                    Enfin, ARTESANE peut transmettre vos donn&eacute;es &agrave;
                    caract&egrave;re personnel &agrave; une juridiction ou une
                    autorit&eacute; administrative ou &agrave; toute autre
                    personne que ladite juridiction ou l&rsquo;autorit&eacute;
                    administrative d&eacute;signerait suite &agrave; une demande
                    en ce sens formul&eacute;e par la juridiction ou
                    l&rsquo;autorit&eacute; administrative ou lorsque ARTESANE
                    estime, de bonne foi, qu&rsquo;un tel transfert est
                    n&eacute;cessaire pour satisfaire les besoins d&rsquo;une
                    proc&eacute;dure judiciaire, r&eacute;pondre &agrave; une
                    plainte ou autre action l&eacute;gale ou prot&eacute;ger les
                    int&eacute;r&ecirc;ts de ARTESANE.
                </p>

                <h2>
                    Transfert en dehors de l&rsquo;Union
                    europ&eacute;enne/Espace &eacute;conomique europ&eacute;en
                </h2>

                <p>
                    ARTESANE ne recourt qu&rsquo;&agrave; des moyens de
                    traitement situ&eacute;s sur le territoire de l&rsquo;Union
                    europ&eacute;enne/Espace &eacute;conomique europ&eacute;en
                    et ne proc&egrave;de donc &agrave; aucun transfert de vos
                    donn&eacute;es &agrave; caract&egrave;re personnel en dehors
                    de cette zone.
                </p>

                <h2>Droits</h2>

                <p>Vous disposez des droits suivants :</p>
                <strong>Droit d&rsquo;acc&egrave;s :</strong>
                <p>
                    vous avez le droit d'obtenir la confirmation que des
                    donn&eacute;es &agrave; caract&egrave;re personnel vous
                    concernant sont ou ne sont pas trait&eacute;es par ARTESANE
                    et, lorsqu'elles le sont, d&rsquo;obtenir l'acc&egrave;s
                    auxdites donn&eacute;es &agrave; caract&egrave;re personnel
                    ainsi qu&rsquo;&agrave; un certain nombre
                    d&rsquo;informations.
                </p>
                <strong>Droit de rectification :</strong>
                <p>
                    vous avez le droit d&rsquo;obtenir la rectification des
                    donn&eacute;es &agrave; caract&egrave;re personnel vous
                    concernant qui seraient inexactes, ainsi que d'obtenir que
                    les donn&eacute;es &agrave; caract&egrave;re personnel vous
                    concernant qui seraient incompl&egrave;tes soient
                    compl&eacute;t&eacute;es.
                </p>
                <strong>
                    Droit &agrave; l&rsquo;effacement (&laquo; droit &agrave;
                    l&rsquo;oubli &raquo;) :
                </strong>
                <p>
                    dans les conditions et limites pr&eacute;vues par la
                    r&egrave;glementation applicable, vous avez le droit
                    d&rsquo;obtenir l&rsquo;effacement de donn&eacute;es
                    &agrave; caract&egrave;re personnel vous concernant.
                </p>
                <strong>Droit &agrave; la limitation :</strong>
                <p>
                    dans les conditions et limites pr&eacute;vues par la
                    r&egrave;glementation applicable, vous avez le droit
                    d&rsquo;obtenir la limitation du traitement dont vous faites
                    l&rsquo;objet.
                </p>
                <strong>Droit &agrave; la portabilit&eacute; :</strong>
                <p>
                    Dans les conditions et limites pr&eacute;vues par la
                    r&egrave;glementation applicable, vous avez le droit de
                    recevoir les donn&eacute;es &agrave; caract&egrave;re
                    personnel vous concernant et que vous avez fournies &agrave;
                    ARTESANE, dans un format structur&eacute;, couramment
                    utilis&eacute; et lisible par machine, et avez le droit de
                    transmettre ces donn&eacute;es &agrave; un autre responsable
                    de traitement.
                </p>
                <strong>Droit d&rsquo;opposition :</strong>
                <p>
                    lorsque le traitement de vos donn&eacute;es &agrave;
                    caract&egrave;re personnel est n&eacute;cessaire aux fins
                    des int&eacute;r&ecirc;ts l&eacute;gitimes poursuivis par
                    ARTESANE, vous disposez, dans les limites pr&eacute;vues par
                    la r&egrave;glementation applicable, du droit de vous
                    opposer &agrave; tout moment &agrave; ce traitement. Vous
                    pouvez &eacute;galement vous opposer &agrave; tout moment au
                    traitement de vos donn&eacute;es &agrave; caract&egrave;re
                    personnel &agrave; des fins de prospection.
                </p>
                <strong>Droit de retirer votre consentement :</strong>
                <p>
                    lorsque le traitement de vos donn&eacute;es &agrave;
                    caract&egrave;re personnel repose sur votre consentement,
                    vous avez le droit de retirer celui-ci &agrave; tout moment.
                </p>
                <strong>
                    Droit de d&eacute;finir des directives post mortem :
                </strong>
                <p>
                    vous pouvez d&eacute;finir et communiquer &agrave; ARTESANE
                    des directives particuli&egrave;res relatives &agrave; la
                    conservation, &agrave; l'effacement et &agrave; la
                    communication de vos donn&eacute;es &agrave;
                    caract&egrave;re personnel apr&egrave;s votre
                    d&eacute;c&egrave;s.
                </p>
                <p>
                    Pour toute question relative &agrave; la pr&eacute;sente
                    Politique ou pour exercer vos droits, vous &ecirc;tes
                    invit&eacute;s &agrave; contacter ARTESANE en
                    pr&eacute;cisant votre demande, nom, pr&eacute;nom et
                    courriel, par courriel &agrave; l&rsquo;adresse suivante :{" "}
                    <a href="mailto:contact@tchac.co">contact@Artesane.co</a>.
                </p>
                <p>
                    Nous attirons votre attention sur le fait qu&rsquo;un
                    certain nombre de droits, notamment le droit de retirer
                    votre consentement &agrave; recevoir notre newsletter peut
                    &ecirc;tre exerc&eacute; directement sur votre compte,
                    rubrique &laquo; mon profil &raquo;.
                </p>
                <p>
                    Dans le cadre de l&rsquo;exercice de vos droits, vous devez
                    justifier de votre identit&eacute; par tout moyen. En cas de
                    doute raisonnable quant &agrave; l&rsquo;identit&eacute; de
                    la personne pr&eacute;sentant la demande, ARTESANE peut
                    demander que lui soient fournies des informations
                    suppl&eacute;mentaires n&eacute;cessaires pour confirmer
                    l&rsquo;identit&eacute; de la personne concern&eacute;e.
                </p>
                <p>
                    Si apr&egrave;s avoir contact&eacute; ARTESANE vous estimez
                    que vos droits ne sont pas respect&eacute;s ou que les
                    traitements que ARTESANE op&egrave;re ne sont pas conformes
                    &agrave; la r&egrave;glementation applicable, vous pouvez
                    adresser une r&eacute;clamation aupr&egrave;s de la
                    Commission nationale de l&rsquo;informatique et des
                    libert&eacute;s (CNIL), 3 place de Fontenoy TSA 80715 75334
                    Paris cedex 07, T&eacute;l. +33 1 53 73 22 22.
                </p>

                <h2>S&eacute;curit&eacute;</h2>

                <p>
                    ARTESANE met en &oelig;uvre les mesures techniques et
                    organisationnelles appropri&eacute;es afin de garantir un
                    niveau de s&eacute;curit&eacute; adapt&eacute; au risque, en
                    tenant compte de l&rsquo;&eacute;tat des connaissances, des
                    co&ucirc;ts de mise en &oelig;uvre et de la nature, de la
                    port&eacute;e, du contexte et des finalit&eacute;s du
                    traitement ainsi que de la probabilit&eacute; et de la
                    gravit&eacute; des risques pour vos droits et
                    libert&eacute;s.
                </p>

                <h2>Cookies</h2>

                <p>
                    Les cookies sont des petits fichiers texte implant&eacute;s
                    sur votre ordinateur, lorsque vous visitez un site internet.
                    Ils sont notamment utilis&eacute;s pour permettre aux sites
                    internet de fonctionner ou de fonctionner plus efficacement,
                    ainsi que de fournir des informations aux &eacute;diteurs du
                    site visit&eacute;.
                </p>
                <p>
                    A l&rsquo;exception des &eacute;ventuels cookies strictement
                    n&eacute;cessaires au fonctionnement du site, nous
                    n&rsquo;utilisons pas de cookies sans avoir au
                    pr&eacute;alable recueilli votre consentement.
                </p>
                <p>
                    Lors de votre premi&egrave;re visite, il vous est
                    propos&eacute; d&rsquo;accepter ou de refuser
                    l&rsquo;utilisation de certains cookies.
                </p>
                <p>
                    Les cookies utilis&eacute;s sont stock&eacute;s sur votre
                    terminal pendant une p&eacute;riode maximum de treize (13)
                    mois &agrave; compter de leur d&eacute;p&ocirc;t. &Agrave;
                    l&rsquo;expiration de ce d&eacute;lai, votre consentement
                    sera &agrave; nouveau requis.
                </p>
                <p>
                    En ce qui concerne les autres cookies, votre choix sera
                    mat&eacute;rialis&eacute; par le placement d&rsquo;un cookie
                    de consentement ou de refus.
                </p>

                <h3>Modification des param&egrave;tres</h3>

                <h4>Module de gestion des cookies</h4>

                <p>
                    ARTESANE vous permet de d&eacute;finir les cookies que vous
                    souhaitez accepter et ceux que vous souhaitez refuser sur ce
                    site.
                </p>
                <p>
                    &Agrave; tout moment, en cliquant sur l'onglet "
                    <strong>Gestion des cookies et RGPD</strong>" pr&eacute;sent
                    en bas du site, vous pourrez acc&eacute;der au module et
                    modifier vos pr&eacute;f&eacute;rences en mati&egrave;re de
                    cookies.
                </p>
                <p>
                    Si vous d&eacute;sactivez les cookies vous ne pourrez plus
                    b&eacute;n&eacute;ficier de certaines fonctionnalit&eacute;s
                    dont la proposition de service d&eacute;pend de
                    l'utilisation de ces m&ecirc;mes cookies.
                </p>

                <h4>D&eacute;sactiver les cookies</h4>

                <p>
                    Il vous est possible de param&eacute;trer les
                    r&eacute;glages de votre navigateur selon les
                    proc&eacute;dures suivantes :
                </p>
                <p>
                    <u>Firefox</u>
                </p>
                <ul>
                    <li>
                        Dans Firefox, cliquez sur le bouton repr&eacute;sentant
                        trois traits
                    </li>
                    <li>S&eacute;lectionnez le menu &laquo; Options &raquo;</li>
                    <li>
                        Cliquez sur l&rsquo;onglet &laquo; Vie priv&eacute;e
                        &raquo;
                    </li>
                    <li>
                        Dans &laquo; Historique &raquo;, modifiez les &laquo;
                        R&egrave;gles de conservation &raquo; et choisissez
                        l&rsquo;option &laquo; utiliser les param&egrave;tres
                        personnalis&eacute;s pour l&rsquo;historique &raquo;
                    </li>
                    <li>
                        D&eacute;cochez la case &laquo; Accepter les cookies
                        &raquo;
                    </li>
                </ul>
                <p>
                    <u>Internet Explorer </u>
                </p>
                <ul>
                    <li>
                        Dans Internet Explorer, acc&eacute;dez au menu &laquo;
                        Outils &raquo; symbolis&eacute; par une roue
                    </li>
                    <li>Cliquez sur &laquo; Options Internet &raquo;</li>
                    <li>Cliquez sur l&rsquo;onglet "Confidentialit&eacute;"</li>
                    <li>Cliquez sur "Avanc&eacute;"</li>
                    <li>
                        Cochez la case &laquo; Ignorer la gestion automatique
                        des cookies &raquo;
                    </li>
                    <li>
                        Vous pouvez ensuite s&eacute;lectionner Vos
                        pr&eacute;f&eacute;rences et &laquo; refuser &raquo; les
                        cookies internes et/ou tierce partie
                    </li>
                    <li>
                        Sauvegardez vos pr&eacute;f&eacute;rences en cliquant
                        sur &laquo; OK &raquo;
                    </li>
                </ul>
                <p>
                    <u>Google Chrome</u>
                </p>
                <ul>
                    <li>
                        Dans Google Chrome, cliquez sur l&rsquo;ic&ocirc;ne
                        &laquo; Menu &raquo; symbolis&eacute; par trois points
                    </li>
                    <li>
                        Cliquez sur l&rsquo;onglet &laquo; Param&egrave;tres
                        &raquo;
                    </li>
                    <li>
                        Cliquez sur l&rsquo;onglet "Param&egrave;tres
                        avanc&eacute;s &raquo; et d&eacute;roulez la liste des
                        param&egrave;tres
                    </li>
                    <li>
                        Cliquez sur &laquo; Param&egrave;tres du contenu &raquo;
                    </li>
                    <li>
                        Cliquez sur &laquo; Cookies &raquo; &bull; Cochez la
                        case &laquo; Bloquer les cookies tiers &raquo;
                    </li>
                </ul>
                <p>
                    <u>Safari</u>
                </p>
                <ul>
                    <li>
                        Dans Safari, cliquez sur &laquo; Safari &raquo;, puis
                        &laquo; Pr&eacute;f&eacute;rences &raquo;
                    </li>
                    <li>
                        Cliquez sur l&rsquo;ic&ocirc;ne &laquo;
                        Confidentialit&eacute; &raquo;
                    </li>
                    <li>
                        &Agrave; c&ocirc;t&eacute; de &laquo; Bloquer les
                        cookies et autres donn&eacute;es de sites web &raquo;,
                        vous pouvez s&eacute;lectionner vos
                        pr&eacute;f&eacute;rences. Ainsi, vous pouvez bloquer
                        tous les cookies en cochant l&rsquo;option &laquo;
                        Toujours &raquo; ou bloquer les cookies des tierces
                        parties et des annonceurs en cochant la case
                        correspondante.
                    </li>
                </ul>

                <h2>Modification de la Politique de Confidentialit&eacute;</h2>

                <p>
                    La pr&eacute;sente Politique de Confidentialit&eacute;
                    pourra faire l&rsquo;objet de modifications &agrave; tout
                    moment &agrave; l&rsquo;initiative de ARTESANE.
                </p>

                <h2>Loi applicable</h2>

                <p>
                    La pr&eacute;sente Politique de Confidentialit&eacute; est
                    r&eacute;gie par le droit fran&ccedil;ais.
                </p>
                <p>
                    La pr&eacute;sente version de la politique de
                    confidentialit&eacute; est &agrave; jour au 1<sup>er</sup>{" "}
                    octobre 2022.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy
