//Components
import Header from "../Home/Header/Header"

//Selectors
import { dePaginator, filterProduct } from "../../selectors/selectors"

//Style
import "./Catalogue.scss"

//Hooks
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

//Components
import FiltersAndSort from "./FiltersAndSort/FiltersAndSort"
import Button from "./Button/Button"
import PaginationContent from "./PaginationContent/PaginationContent"
import SelectFilters from "./FiltersAndSort/SelectFilters/SelectFilters"

//Actions
import {
    removeAllFilters,
    saveCurrentPageCatalogue,
    toggleLoading,
    toggleIsSortActif,
    toggleIsFilterActif,
    saveCatalogueLessonsInState,
    fetchLessons,
} from "../../actions/catalogueAction"
import Products from "./Products/Products"
import Spinner from "../Commons/Spinner/Spinner"
import NotFound from "../Commons/NotFound/NotFound"
import Banner from "../Banner/Banner"

const Catalogue = () => {
    const dispatch = useDispatch()
    const { univers } = useParams()
    const { currentFilters } = useParams()
    const { currentPage } = useParams()
    const isFilterActif = useSelector((state) => state.catalogue.isFilterActif)
    const lessonsData = useSelector((state) => state.catalogue.catalogueData)
    const sortActifValue = useSelector(
        (state) => state.catalogue.sortActifValue
    )
    const allProduct = useSelector((state) => state.catalogue.allProduct)
    const priceValuesSlider = useSelector((state) => state.catalogue.priceValue)
    const filtersChecked = useSelector(
        (state) => state.catalogue.filtersCheckboxInput
    )
    const isLoading = useSelector((state) => state.catalogue.loaderLessons)
    const loadingAllUniverses = useSelector(
        (state) => state.common.loadingAllUniverses
    )
    const allUniverses = useSelector((state) => state.common.allUniverses)
    const catalogueDescription = useSelector(
        (state) => state.catalogue.catalogueDescription
    )
    const catalogueTitle = useSelector(
        (state) => state.catalogue.catalogueTitle
    )
    const catalogueName = useSelector((state) => state.catalogue.catalogueName)

    function createMarkup(catalogueDescription) {
        return {
            __html: catalogueDescription,
        }
    }

    useEffect(() => {
        if (currentPage === "0") {
            window.location.replace(
                `${window.location.origin}/${univers}/catalogue/${currentFilters}/1`
            )
        }

        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute("content", "https://artesane.com" + window.location.pathname)
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute("href", "https://www.artesane.com" + window.location.pathname)

    }, [currentPage, currentFilters, univers])

    useEffect(() => {
        dispatch(toggleLoading(true))
        if (!loadingAllUniverses) {
            dispatch(fetchLessons(univers, currentFilters))
        }
    }, [loadingAllUniverses, currentFilters, univers, dispatch])

    return (
        <div className="catalogue-wrapper">
            <Banner univers={univers} />
            <Header />
            {!loadingAllUniverses ? (
                <>
                    {allUniverses.find((item) => item.code === univers) ? (
                        <>
                            {!isLoading ? (
                                <>
                                   {currentPage >
                                        (lessonsData.length === 0
                                            ? 1
                                            : lessonsData.length) ? (
                                        <>
                                        <Spinner/>
                                            {window.location.replace(
                                                `${window.location.origin}/${univers}/catalogue/${currentFilters}/1`
                                            )}
                                        </>
                                    ) : (
                                        <>
                                        <div className="filters-sort-button-wrapper">
                                        <FiltersAndSort />
                                        <SelectFilters />

                                        {filtersChecked.length >= 1 && (
                                            <div className="filters-buttons-wrapper">
                                                <Button
                                                    onClick={() => {
                                                        dispatch(
                                                            saveCatalogueLessonsInState(
                                                                filterProduct(
                                                                    [],
                                                                    allProduct,
                                                                    priceValuesSlider,
                                                                    sortActifValue,
                                                                    []
                                                                ),
                                                                filterProduct(
                                                                    [],
                                                                    allProduct,
                                                                    priceValuesSlider,
                                                                    sortActifValue,
                                                                    []
                                                                ).length,
                                                                allProduct
                                                            )
                                                        )
                                                        dispatch(
                                                            saveCurrentPageCatalogue(
                                                                1
                                                            )
                                                        )
                                                        dispatch(
                                                            removeAllFilters()
                                                        )
                                                    }}
                                                    title={
                                                        "Tout désélectionner"
                                                    }
                                                />
                                                <Button
                                                    onClick={() => {
                                                        dispatch(
                                                            toggleIsSortActif(
                                                                false
                                                            )
                                                        )
                                                        dispatch(
                                                            toggleIsFilterActif(
                                                                !isFilterActif
                                                            )
                                                        )
                                                    }}
                                                    classname={"style"}
                                                    title={"Enregistrer"}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {filtersChecked.length > 0 && (
                                        <div className="product-found">
                                            {dePaginator(lessonsData).length}{" "}
                                            produits trouvés
                                        </div>
                                    )}
                                    <Products />
                                    <div className="catalogue-products-pagination-wrapper">
                                        <PaginationContent
                                            currentPage={currentPage}
                                            count={lessonsData.length}
                                            url={`/${univers}/catalogue/${currentFilters}`}
                                            actionToDispatch={
                                                saveCurrentPageCatalogue
                                            }
                                        />
                                    </div>
                                    </>
                                    )}
                                </>
                            ) : (
                                <Spinner />
                            )}
                            {univers === "arts-du-fil" &&
                                currentFilters === "tout" && (
                                    <h1 className="title-seo">
                                     Tous les cours en vidéo d'arts du fil de la couture au tricot sur Artesane 
                                        {currentPage > 1
                                            ? " - Page " + currentPage
                                            : ""}
                                    </h1>
                                )}
                            {univers === "arts-plastiques" &&
                                currentFilters === "tout" && (
                                    <h1 className="title-seo">
                                    Apprendre à peindre, dessiner et faire de la céramique avec Artesane
                                        {currentPage > 1
                                            ? " - Page " + currentPage
                                            : ""}
                                    </h1>
                                )}
                            {univers === "arts-culinaires" &&
                                currentFilters === "tout" && (
                                    <h1 className="title-seo">
                                       Cours vidéo de cuisine et pâtisserie - Arts culinaires - Artesane{" "}
                                        {currentPage > 1
                                            ? " - Page " + currentPage
                                            : ""}
                                    </h1>
                                )}

                            {univers === "arts-du-fil" &&
                                currentFilters !== "tout" && (
                                    <h1 className="title-seo">
                                        {catalogueTitle !== null && (
                                            <>
                                                {catalogueTitle +
                                                    (currentPage > 1
                                                        ? " - Page " +
                                                          currentPage
                                                        : "")}
                                            </>
                                        )}
                                        {catalogueTitle === null && (
                                            <>
                                                ARTESANE, LES COURS EN LIGNE
                                                D'ARTS DU FIL :{" "}
                                                {catalogueName.toUpperCase() +
                                                    (currentPage > 1
                                                        ? " - Page " +
                                                          currentPage
                                                        : "")}
                                            </>
                                        )}
                                    </h1>
                                )}
                            {univers === "arts-plastiques" &&
                                currentFilters !== "tout" && (
                                    <h1 className="title-seo">
                                        {catalogueTitle !== null && (
                                            <>
                                                {catalogueTitle +
                                                    (currentPage > 1
                                                        ? " - Page " +
                                                          currentPage
                                                        : "")}
                                            </>
                                        )}
                                        {catalogueTitle === null && (
                                            <>
                                                ARTESANE, LES COURS EN LIGNE
                                                D'ARTS PLASTIQUES :{" "}
                                                {catalogueName.toUpperCase() +
                                                    (currentPage > 1
                                                        ? " - Page " +
                                                          currentPage
                                                        : "")}
                                            </>
                                        )}
                                    </h1>
                                )}
                            {univers === "arts-culinaires" &&
                                currentFilters !== "tout" && (
                                    <h1 className="title-seo">
                                        {catalogueTitle !== null && (
                                            <>
                                                {catalogueTitle +
                                                    (currentPage > 1
                                                        ? " - Page " +
                                                          currentPage
                                                        : "")}
                                            </>
                                        )}
                                        {catalogueTitle === null && (
                                            <>
                                                ARTESANE, LES COURS EN LIGNE
                                                D'ARTS CULINAIRES :{" "}
                                                {catalogueName.toUpperCase() +
                                                    (currentPage > 1
                                                        ? " - Page " +
                                                          currentPage
                                                        : "")}
                                            </>
                                        )}
                                    </h1>
                                )}

                            {catalogueDescription !== undefined &&
                                !(currentPage === 1) && (
                                    <div
                                        className="seo-text"
                                        dangerouslySetInnerHTML={createMarkup(
                                            catalogueDescription
                                        )}></div>
                                )}
                        </>
                    ) : (
                        <NotFound />
                    )}
                </>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default Catalogue
