import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate } from "react-router-dom"
import Spinner from "../../Commons/Spinner/Spinner"
import { useEffect } from "react"
import ChoosePayment from "../Livraison/ChoosePayment/ChoosePayment"
import { changeInputOrder } from "../../../actions/cartAction"
import OrderSummary from "../OrderSummary/OrderSummary"

const Paiement = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const redirectPaiement = useSelector((state) => state.cart.redirectPaiement)
    const loadingAddPayment = useSelector(
        (state) => state.cart.loadingAddPayment
    )
    const cartData = useSelector((state) => state.cart.cartData)
    const payplugUrl = useSelector((state) => state.cart.payplugUrl)

    useEffect(() => {
        if (payplugUrl !== "") {
            window.location.replace(payplugUrl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payplugUrl])

    useEffect(() => {
        if (
            cartData.shippingAddress !== null &&
            cartData.shippingAddress !== undefined
        ) {
            dispatch(changeInputOrder(true, "addressValidate"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData])

    useEffect(() => {
        if (redirectPaiement) {
            dispatch(changeInputOrder(false, "redirectPaiement"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, redirectPaiement])

    const loadingCart = useSelector((state) => state.cart.loadingCart)


    return (
        <>
        <div className="livraison-wrapper">
            <div
                className="return"
                onClick={() => {
                    dispatch(
                        changeInputOrder(false, "redirectPaiement")
                    )
                    // dispatch(changeInputOrder(true, "chooseDelivery"))
                    dispatch(changeInputOrder(false, "addressValidate"))
                }}>
                <NavLink to={"/commande/livraison"}>
                    {"< "}Retour à la livraison
                </NavLink>
            </div>
            {(loadingCart || loadingAddPayment || loadingCart) && <Spinner />}
            {!loadingCart && !loadingAddPayment && !loadingCart && (
                <>


                    <ChoosePayment />
                    <OrderSummary/>
                </>
            )}
                  

        </div>
       </>

    )
}

export default Paiement
