import { NavLink } from "react-router-dom"
import "./NotFound.scss"
import { useSelector } from "react-redux"

const NotFound = () => {
    const lastUnivers = useSelector((state) => state.home.homeUniversActif)

    return (
        <div>
            {/* <Header /> */}
            <div className="notfound-wrapper">
                <div className="bloc-wrapper">
                    <img
                        className="reverse"
                        src={
                            "https://static.artesane.com/images_react/favicon.png"
                        }
                        alt="artesane"
                    />{" "}
                    <div className="text-wrapper">
                        <div className="not-found-title">Erreur 404</div>
                        <div className="not-found">
                            <div>La page recherchée n'existe pas</div>
                            <div>ou une autre erreur s'est produite.</div>
                            <div>
                                <NavLink
                                    to={
                                        lastUnivers !== "" &&
                                        (lastUnivers === "arts-du-fil" ||
                                            lastUnivers === "arts-culianires" ||
                                            lastUnivers === "arts-plastiques")
                                            ? `/${lastUnivers}`
                                            : "/"
                                    }>
                                    Revenir à la page d'accueil
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <img
                        className="reverse"
                        src={
                            "https://static.artesane.com/images_react/favicon.png"
                        }
                        alt="artesane"
                    />{" "}
                </div>
            </div>
        </div>
    )
}
export default NotFound
