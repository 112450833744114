//Styles
import "./Price.scss"

const Price = ({ price, originalPrice }) => (
    <div className="lesson-card-selection-price">
        {price !== originalPrice && (
            <>
                <span className="lesson-card-selection-final-price">
                    <span className="num-font">{price} €</span>
                </span>
                <span className="lesson-card-selection-before-price">
                    <span className="num-font">{originalPrice} €</span>
                </span>
            </>
        )}
        {price === originalPrice && (
            <span className="lesson-card-selection-price-without-reduction">
                <span className="num-font">{price}€</span>
            </span>
        )}
    </div>
)

export default Price
