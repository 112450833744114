import "./SeoText.scss"

import { useParams } from "react-router-dom"

const SeoText = () => {
    const { univers } = useParams()

    return (
        <div className="seo-text-wrapper">
            <div className="seo-content-wrapper">
                {univers === "arts-du-fil" && (
                    <>
                        <h1>
                            ARTESANE, LES COURS EN LIGNE DE COUTURE, BRODERIE,
                            TRICOT
                        </h1>
                        <p>
                            Artesane est la première école en France de cours
                            vidéo de couture, broderie, tricot mais aussi de
                            stylisme, modélisme, crochet, tissage ou encore
                            vannerie. Avec Artesane, c'est facile d'apprendre à
                            coudre. Découvrez nos cours vidéo, accessibles à vie
                            une fois acheté, pour apprendre la couture à
                            distance. Si vous souhaitez devenir couturière et
                            passer votre CAP couture ou CAP MMVF, tournez-vous
                            vers nos formations professionnelles : nous sommes à
                            l'origine du livre de référence sur le CAP "Passez
                            votre CAP couture". Testez nos cours vidéos de
                            couture gratuits pour découvrir la qualité de la
                            pédagogie Artesane.
                        </p>
                        <p>
                            Que vous cherchiez à débuter en couture, à faire vos
                            premiers pas en tricot circulaire, à réaliser une
                            poche paysanne ou un col officier, à maîtriser la
                            couture du jersey ou à vous servir de votre
                            surjeteuse, nous avons le cours vidéo qu'il vous
                            faut. Nos professeurs sont des experts et les
                            meilleurs dans leur domaine. Ils vous transmettent
                            avec pédagogie tout leur savoir-faire pour que vous
                            maitrisiez des techniques de couture spécifique. Si
                            vous avez envie de recycler des vieux vêtements ou
                            des tissus pour en faire des pièces uniques adaptées
                            à votre morphologie, vous avez frappé à la bonne
                            porte chez Artesane. Reprisage, raccomodage,
                            adaptation de patron, stylisme, coupe à plat ou
                            moulage : nous faisons de notre mieux au sein de
                            l'école pour répondre à vos besoins exacts et vous
                            permettre d'apprendre sereinement. Bien plus que de
                            simples tutoriels, nos cours vous permettent
                            d'approfondir vos connaissances en couture ou en
                            broderie, grâce aux documents de cours, au pas-à-pas
                            détaillés par vos professeurs, et au patrons au
                            format PDF A0 ou A4 disponibles dans votre compte
                            client Artesane.
                        </p>
                        <p>
                            Très reconnue dans les arts du fil, notre école est
                            partenaire de nombreuses marques du milieu du DIY
                            comme Singer, Pfaff, Rascol, Scavini,
                            Lafayette-Saltiel Drapiers, Motif Personnel, Maison
                            Naïve et bien d'autres.
                        </p>
                    </>
                )}

                {univers === "arts-plastiques" && (
                    <>
                        <h1>
                            ARTESANE, LES COURS EN LIGNE DE DESSIN, AQUARELLE,
                            PEINTURE, CÉRAMIQUE
                        </h1>
                        <p>
                            Qu'on aime l'aquarelle, le dessin aux feutres, le
                            dessins aux crayons, ou même au fusain, la peinture
                            à l'huile ou la gouache, ou l'illustration sur
                            tablette, on cherche parfois des cours vidéos nous
                            permettant d 'apprendre à distance, à son rythme
                            avec des professeurs experts dans leur domaine.
                            C'est avec cette idée qu'Artesane a monté une école
                            entièrement en ligne de cours vidéo en français.
                            Nous vous proposons même des cours de céramique pour
                            maîtriser la poterie grâce à des techniques de
                            tournage, de tournassage, d'émaillage ou de
                            modelage. Débutez l'aquarelle avec Blanche Tristant
                            alias l'eau bleue ou lancez-vous dans l'illustration
                            à l'encre avec l'illustratrice de BD Cy. Sortez vos
                            pinceaux, choisissez vos crayons, créez avec votre
                            cours vidéo et évadez-vous.
                        </p>
                        <p>
                            Comme nos partenaires, Faber Castell, Rougier Plé ou
                            Hahnemühle, faites confiance à Artesane pour
                            apprendre à créer. Aucune technique d'arts
                            graphiques n'est laissée de côté et nous vous
                            proposons aussi des cours avec Procreate
                            d'illustration sur tablettes ou des cours sur les
                            bases de la BD.
                        </p>
                    </>
                )}

                {univers === "arts-culinaires" && (
                    <>
                        <h1>
                            ARTESANE, LES COURS EN LIGNE DE CUISINE ET
                            PÂTISSERIE
                        </h1>
                        <p>
                            Artesane est l'école française de cuisine et de
                            pâtisserie intégralement en ligne. Testez un cours
                            vidéo gratuit de pâtisserie. Les cours vidéo
                            d'Artesane sont accessibles à vie et en illimité :
                            nos cours de cuisine avec des grand chefs sont
                            accessibles 24/7. Ils sont toujours composés d'un
                            cours vidéo de chefs, mixologues, pâtissiers ou
                            oenologue et des fiches recettes associées. Avec
                            plus de 160 000 élèves, Artesane forment des
                            cuisiniers, des chefs, des pâtissiers à distance
                            grâce à des experts en arts culinaires qui
                            transmettent avec passion les meilleures techniques
                            de cuisson, de brioches, d'entremets, de cocktails.
                        </p>
                        <p>
                            Plus encore, Artesane forme au CAP Cuisine à travers
                            un parcours construit avec Bernard Charron,
                            professeur formateur en école hôtelière pendant près
                            de 30 ans. Pour réussir votre CAP cuisine, le
                            programme de la formation comprend la connaissance
                            des produits alimentaires, la gestion de
                            l’approvisionnement et du stockage des marchandises,
                            la préparation de mets et assemblage de produits
                            pré-élaborés, la maîtrise des techniques de cuisson,
                            la réalisation de préparations chaudes ou froides,
                            le dressage des assiettes, l'élaboration d’un menu
                            et l'entretien du poste de travail et respect des
                            règles d’hygiène et de sécurité.
                        </p>
                        <p>
                            Si la pâtisserie vous passionne, nos chefs
                            pâtissiers vous apprendront les techniques pour
                            réaliser les meilleures recettes de choux à la
                            crème, d'entremets, de tartes, de petits gâteaux
                            (madeleine, financiers, muffins ou cookies). Obtenez
                            un glaçage brillant et succulent, maîtrisez la
                            cuisson du chocolat et réussissez votre chantilly à
                            tous les coups.
                        </p>
                    </>
                )}
            </div>
        </div>
    )
}

export default SeoText
