//Styles
import "./PaiementTag.scss"

const PaiementTag = ({ oney }) => {
    return (
        <div className="paiement-tag-wrapper">
            <img
                className="paiement-tag-logo-oney"
                src={
                    oney
                        ? `https://static.artesane.com/images_react/Logo_Oney.png`
                        : `https://static.artesane.com/images_react/3x4x-alt.svg`
                }
                alt="logo de Oney"
            />
            <span
                className={`paiement-tag ${oney ? "oney-active" : "oney-desactive"}`}>
                {" "}
                Paiement en plusieurs fois disponible
            </span>
        </div>
    )
}

export default PaiementTag
