//Styles
import "./FooterResponsiveItems.scss"

//Hooks
import { NavLink } from "react-router-dom"
const FooterResponsiveItems = ({ target, title, url }) => (
    <span className="footer-items">
        {" "}
        <NavLink target={target} to={url}>
            {title}
        </NavLink>
    </span>
)

export default FooterResponsiveItems
