import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { NavLink } from "react-router-dom"
import { fetchFaqs } from "../../../actions/homeActions"
import Faq from "../../Home/Faq/Faq"
import NewsLetter from "../../Home/NewsLetter/NewsLetter"
import "./EmptyPage.scss"

const EmptyPage = ({
    firstPart,
    secondPartOne,
    secondPartTwo,
    urlSecondPart,
    urlThirdPart,
    thirdPartOne,
    thirdPartTwo,
}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchFaqs())
    }, [dispatch])
    return (
        <div className="empty-page-wrapper">
            <div className="first-part">{firstPart}</div>
            <div className="other-part">
                <span>En panne d'inspiration ? </span>{" "}
                <span>Retrouvez nos meilleurs cours ici :</span>
            </div>
            <div className="button-link">
                <NavLink to={"/arts-du-fil/catalogue/tout/1"}>
                    Arts du fil
                </NavLink>
                <NavLink to={"/arts-culinaires/catalogue/tout/1"}>
                    Arts culinaires
                </NavLink>
                <NavLink to={"/arts-plastiques/catalogue/tout/1"}>
                    Arts plastiques
                </NavLink>
            </div>
            <div className="other-part">
                <span>{thirdPartOne}</span>
                <span>
                    {thirdPartOne !== undefined && (
                        <NavLink to={urlThirdPart}>{thirdPartTwo}</NavLink>
                    )}
                </span>
            </div>
            <NewsLetter />
            <Faq />
        </div>
    )
}
export default EmptyPage
