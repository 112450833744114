//API for get country's name from ISO code Alpha2
import countries from "i18n-iso-countries"

//Hooks
import { useDispatch, useSelector } from "react-redux"
//Actions
import {
    changeInputValue,
    putModifyAddress,
    loadingModifyAddress,
} from "../../../../actions/userAction"
//Components
import InputSimple from "../../InputSimple/inputSimple"
import Spinner from "../../../Commons/Spinner/Spinner"
//Style
import "./ModifyAddress.scss"

//API for get country's name from ISO code Alpha2
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"))

const ModifyAddress = ({
        title,
        toggleWindow,
        errorPatternTelephone,
        errorTelephone, 
        error,
        loading,
        put,
        lastName,
        firstName,
        society,
        street,
        postCode,
        city,
        telephone,
        country,
        firstNameChangeInput, 
        lastNameChangeInput,
        societyChangeInput,
        streetChangeInput,
        postCodeChangeInput,
        cityChangeInput,
        telephoneChangeInput,
        countryChangeInput,
        
        }) => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.user.loadingModifyAddress)
    const isError = useSelector((state) => state.user.errorModifyAddress)
    const countries = useSelector((state) => state.user.countries)

    return (
        <div className="modify-address-wrapper">
            {isLoading && (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            )}

            <div className="modify-popup-wrapper">
                <div className="moify-address-title">{title}</div>
                {isError && (
                    <div className="error">
                        Une erreur s'est produite. Votre modification n'a pas pu
                        être enregistrée.
                    </div>
                )}

                <form
                    action="#"
                    onSubmit={(event) => {
                        event.preventDefault()
                        if (
                            (telephone.match(/[0-9]/g) ||
                                telephone.match(/[-+!?*$@%_]/g)) &&
                            !telephone.match(/[A-Z]/g) &&
                            !telephone.match(/[a-z]/g)
                        ) {
                            errorPatternTelephone(false)
                            
                            error(false)
                            loading(true)
                            put()
                        } else {
                            errorPatternTelephone(true)
                            
                        }
                    }}>
                    <span
                        className="cross"
                        onClick={() => {
                            error(false)
                            toggleWindow(false)
                        }}>
                        ╳
                    </span>
                    <InputSimple
                        inputName={"lastName"}
                        label={"Nom *"}
                        value={lastName}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            lastNameChangeInput(event)
                        }}
                    />
                    <InputSimple
                        inputName={"firstName"}
                        label={"Prénom *"}
                        value={firstName}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            firstNameChangeInput(event)
                        }}
                    />
                    <InputSimple
                        inputName={"society"}
                        label={"Société"}
                        value={society}
                        type={"text"}
                        required={false}
                        onChange={(event) => {
                            societyChangeInput(event)
                        }}
                    />

                    <InputSimple
                        inputName={"street"}
                        maxLength={35}
                        label={"Voie et numéro *"}
                        value={street}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            streetChangeInput(event)
                        }}
                    />
                    <InputSimple
                        inputName={"postCode"}
                        label={"Code postal *"}
                        value={postCode}
                        type={"number"}
                        required={true}
                        onChange={(event) => {
                            postCodeChangeInput(event)
                        }}
                    />

                    <InputSimple
                        inputName={"city"}
                        label={"Ville *"}
                        value={city}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            cityChangeInput(event)
                        }}
                    />
                    <InputSimple
                        error={errorTelephone}
                        inputName={"telephone"}
                        label={"Téléphone *"}
                        value={telephone}
                        type={"tel"}
                        required={true}
                        onChange={(event) => {
                            errorPatternTelephone(false)

                            telephoneChangeInput(event)
                        }}
                    />
                    <div
                        className={`input-information ${errorTelephone ? "error" : ""}`}>
                        {" "}
                        Les lettres ne sont pas autorisées
                    </div>
                    <div className="input-wrapper">
                        <label className="personal-label" htmlFor="first-name">
                            Pays *
                        </label>
                        <select
                            required
                            value={country}
                            name="country"
                            onChange={(event) => {
                                countryChangeInput(event)
                            }}>

                            {countries.map(({ name, code }) => {
                                if (code !== "EZ" && code !== "AC") {
                                    return (
                                        <option key={code} value={code}>
                                            {name}
                                        </option>
                                    )
                                }
                                return null
                            })}
                        </select>
                    </div>
                    <button className="submit-button" type="submit">
                        Enregistrer
                    </button>
                </form>
            </div>
        </div>
    )
}
export default ModifyAddress
