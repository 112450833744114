import Banner from "../../Banner/Banner"
import Header from "../../Prepage/Header/Header"

import "./LegalNotices.scss"
import { useEffect } from "react"

const LegalNotices = () => {
    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | Conditions générales d'utilisation du site"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Mentions légales "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="legal-notices-wrapper">
                <h1>
                    CONDITIONS GENERALES D&rsquo;UTILISATION DU SITE
                    WWW.ARTESANE.COM
                </h1>

                <p>
                    <strong>ARTESANE</strong>&nbsp;- Soci&eacute;t&eacute; par
                    Actions simplifi&eacute;e au capital de 100.600 euros
                    <br />
                    immatricul&eacute;e au RCS de Paris, 814 557 575 et dont le
                    si&egrave;ge social est situ&eacute; au 33 rue du Hameau
                    75015 Paris.
                    <br />
                    Tel : 01 45 31 03 89
                    <br />
                    Courriel :&nbsp;
                    <a href="mailto:contact@artesane.com">
                        contact@artesane.com
                    </a>
                    <br />
                    Directrice de publication : Anne-Sarah BALLU-SAMUEL
                    <br />
                    SIREN : 814 557 575
                    <br />
                    N&deg; de TVA intracommunautaire : FR 43 814 557 575
                    <br />
                    <br />
                    <strong>Ce site est h&eacute;berg&eacute; par :</strong>
                    <br />
                    Scaleway - Soci&eacute;t&eacute; par Actions
                    Simplifi&eacute;e au capital de 214 410,50 Euros
                    <br />
                    SIREN : 433 115 904 RCS Paris
                    <br />
                    Si&egrave;ge social : 8 rue de la Ville
                    l&rsquo;Ev&ecirc;que, 75008 Paris
                    <br />
                    N&deg; de TVA intracommunautaire : FR 35 433115904
                    <br />
                    Directeur de publication: Arnaud Brindejonc de Bermingham
                    <br />
                    H&eacute;bergeur : SCALEWAY SAS BP 438 75366 PARIS CEDEX 08
                    FRANCE
                </p>

                <h2>Article 1&nbsp;:&emsp;D&eacute;finition</h2>

                <p>D&rsquo;apr&egrave;s ces conditions d&rsquo;utilisation :</p>
                <ol>
                    <li>La Soci&eacute;t&eacute;</li>
                    <li>
                        "Utilisateur" d&eacute;signe la personne qui visite et
                        utilise le site www.artesane.com
                    </li>
                    <li>
                        "El&egrave;ve" d&eacute;signe un utilisateur
                        enregistr&eacute;,
                    </li>
                    <li>
                        La "Communaut&eacute; Artesane" d&eacute;signe
                        l&rsquo;ensemble des utilisateurs et membres,
                    </li>
                    <li>
                        "Compte" d&eacute;signe le compte d&rsquo;un utilisateur
                        enregistr&eacute;,
                    </li>
                    <li>
                        "Professeur.e" d&eacute;signe la personne transmettant
                        du contenu &eacute;ducatif, en particulier dans les
                        vid&eacute;os,
                    </li>
                </ol>

                <h2>Article 2&nbsp;:&nbsp; Domaine d&lsquo;application</h2>

                <p>
                    Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
                    d&rsquo;utilisation ont pour objet l&rsquo;acc&egrave;s et
                    l&rsquo;utilisation du site&nbsp;
                    <a href="https://www.artesane.com/">www.artesane.com</a>
                    &nbsp;sur tous les terminaux offrant une connexion
                    internet&nbsp;: ordinateurs, tablettes et mobiles&nbsp;.
                    Elles sont accessibles&nbsp;&agrave; tout moment sur notre
                    site et peuvent &ecirc;tre imprim&eacute;es.
                </p>
                <p>
                    L&rsquo;inscription &agrave; notre site et/ou &agrave; notre
                    newsletter par le biais de la
                    rubrique&nbsp;&laquo;&nbsp;Inscription&nbsp;&raquo; emporte
                    votre accord avec les conditions d&rsquo;utilisation
                    pr&eacute;sentement &eacute;nonc&eacute;es. Vous
                    d&eacute;clarez avoir lu les pr&eacute;sentes conditions et
                    acceptez de suivre les r&egrave;gles de fonctionnement et de
                    comportement qu&rsquo;elles &eacute;noncent.
                </p>
                <p>
                    L&rsquo;inscription sur le site&nbsp;
                    <a href="https://www.artesane.com/">www.artesane.com</a>
                    &nbsp;est r&eacute;serv&eacute;e aux personnes physiques
                    majeures et responsables jouissant de leur enti&egrave;re
                    capacit&eacute; juridique. De fait, vous &ecirc;tes
                    responsable des informations que vous jugez bon de diffuser
                    sur votre profil public.
                </p>
                <p>
                    Nous sommes soucieux de constamment am&eacute;liorer notre
                    offre et de nous d&eacute;velopper. Nous nous
                    r&eacute;servons donc le droit de modifier ces conditions
                    d&rsquo;utilisation afin de les faire correspondre aux
                    &eacute;volutions de notre site. Dans le cas d&rsquo;un
                    changement, ceux-ci vous seront envoy&eacute;s sur
                    l&rsquo;adresse email que vous nous avez communiqu&eacute;e
                    lors de votre inscription. Si vous ne contestez pas les
                    changements &eacute;voqu&eacute;s dans les quatre semaines,
                    ils seront alors effectifs dans le contrat nous liant.
                </p>

                <h2>Article 3&nbsp;: &nbsp;offres et services</h2>

                <p>
                    Notre plateforme internet offre l&rsquo;acc&egrave;s
                    &agrave; un contenu &agrave; vis&eacute;e &eacute;ducative.
                    Nos cours vid&eacute;o et les documents de cours
                    aff&eacute;rent permettent de se former en ligne dans le
                    domaine des loisirs cr&eacute;atifs, des travaux manuels,
                    tels que la couture, le tricot, le crochet, le tissage, la
                    cosm&eacute;tique maison, le dessin, etc. Cette liste est
                    non exhaustive et est amen&eacute;e &agrave; &eacute;voluer
                    au fur et &agrave; mesure de notre d&eacute;veloppement.
                </p>
                <p>
                    Les cours vid&eacute;o propos&eacute;s sur la plateforme
                    sont con&ccedil;us&nbsp;et anim&eacute;s&nbsp;par nos
                    professeurs et tourn&eacute;s par notre
                    Soci&eacute;t&eacute;.
                </p>
                <p>
                    L&rsquo;acc&egrave;s &agrave; ces cours peut &ecirc;tre
                    gratuit ou payant selon la tarification affich&eacute;e
                    clairement sur notre site.
                </p>
                <p>
                    Les &eacute;l&egrave;ves du site Artesane ont la
                    possiblit&eacute; de publier leurs r&eacute;alisations par
                    le biais de photos sur leur compte &eacute;l&egrave;ve.
                </p>
                <p>
                    Les cours vid&eacute;o sont accompagn&eacute;s de documents
                    t&eacute;l&eacute;chargeables au format PDF si le/la
                    professeure a jug&eacute; n&eacute;cessaire un support
                    compl&eacute;mentaire.
                </p>
                <p>
                    Outre la vente d&rsquo;un acc&egrave;s illimit&eacute;
                    &agrave; tel ou tel cours vid&eacute;o, nous proposons
                    &agrave; la vente des patrons de couture, de tricot, de
                    broderie ou de crochet &agrave; la vente au format PDF dans
                    l&rsquo;espace Boutique.
                </p>

                <h2>
                    Article 4&nbsp;:&nbsp; Conditions d&rsquo;utilisation du
                    site
                </h2>

                <p>
                    L&rsquo;acc&egrave;s au site ww.artesane.com ne
                    n&eacute;cessite pas d&rsquo;enregistrement
                    pr&eacute;alable. Tout visiteur peut consulter le contenu
                    libre d&rsquo;acc&egrave;s sans enregistrement
                    pr&eacute;alable. Ce contenu libre d&rsquo;acc&egrave;s est
                    constitu&eacute; de&nbsp;:
                </p>
                <ul>
                    <li>notre catalogue de cours (avant achat)</li>
                    <li>
                        les fiches produits de chacun de nos cours
                        (bande-annonces, description du contenu du cours etc)
                    </li>
                    <li>
                        les fiches produits des mod&egrave;les propos&eacute;s
                        en boutique (description, aper&ccedil;u du mod&egrave;le
                        etc.)
                    </li>
                    <li>
                        notre blog intitul&eacute;&nbsp;&laquo;&nbsp;Le
                        journal&nbsp;&raquo;
                    </li>
                    <li>
                        les pr&eacute;sentes conditions g&eacute;n&eacute;rales
                        d&rsquo;utilisation
                    </li>
                    <li>les conditions g&eacute;n&eacute;rales de vente</li>
                    <li>la rubrique contact et les mentions l&eacute;gales</li>
                </ul>
                <p>
                    La cr&eacute;ation d&rsquo;un compte
                    &laquo;&nbsp;&eacute;l&egrave;ve&nbsp;&raquo; pour devenir
                    &eacute;l&egrave;ve de notre communaut&eacute; est gratuite.
                    Cette inscription vous permet d&rsquo;acc&eacute;der aux
                    cours et mod&egrave;les gratuits et de faire
                    l&rsquo;acquisition de cours vid&eacute;o et mod&egrave;les
                    payants sur notre site. Vous aurez acc&egrave;s et pourrez
                    consulter librement vos cours et documents dans la rubrique
                    &laquo;&nbsp;Mes cours&nbsp;&raquo; et la rubrique
                    &laquo;&nbsp;Mes documents&nbsp;&raquo;.
                </p>
                <p>
                    Vous avez la possibilit&eacute; de compl&eacute;ter ou
                    modifier les mentions obligatoires et facultatives de profil
                    utilisateur quand vous le souhaitez. Ces informations sont
                    accessibles dans la rubrique &laquo;&nbsp;Mon
                    compte&nbsp;&raquo;
                </p>
                <p>
                    Les informations que vous communiquez lors de votre
                    inscription, hors informations fantaisie comme votre
                    pseudonyme, doivent&nbsp;&ecirc;tre v&eacute;ridiques et
                    fid&egrave;les &agrave; la r&eacute;alit&eacute;.
                </p>
                <p>
                    Une fois compl&eacute;t&eacute; le formulaire
                    d&rsquo;inscription, &emsp;vous souscrivez sans
                    r&eacute;serve aux pr&eacute;sentes conditions
                    g&eacute;n&eacute;rales d&rsquo;utilisation d&egrave;s lors
                    que vous cliquez sur le
                    bouton&nbsp;&laquo;&nbsp;Cr&eacute;er un
                    compte&nbsp;&raquo;.
                </p>
                <p>
                    La soci&eacute;t&eacute; se r&eacute;serve le droit de
                    refuser la cr&eacute;ation d&rsquo;un compte
                    &eacute;l&egrave;ve ou de supprimer un compte d&egrave;s
                    lors que son titulaire a transmis des informations
                    volontairement inexactes.
                </p>
                <p>
                    La soci&eacute;t&eacute; se r&eacute;serve le droit de
                    verrouiller et de supprimer un compte &eacute;l&egrave;ve
                    d&egrave;s lors que son titulaire adopterait sur le site un
                    comportement r&eacute;pr&eacute;hensible. Sont interdits
                    notamment&nbsp;:
                </p>
                <ul>
                    <li>
                        la publication de contenu &agrave; caract&egrave;re
                        pornographique, injurieux, sexiste, raciste,
                        x&eacute;nophobe etc.
                    </li>
                    <li>
                        la publication de contenu incitant &agrave; la violence
                        physique ou morale
                    </li>
                </ul>
                <p>
                    L&rsquo;&eacute;l&egrave;ve reconna&icirc;t qu&rsquo;il est
                    le seul d&eacute;tenteur de l&rsquo;acc&egrave;s &agrave;
                    son compte &eacute;l&egrave;ve. En cas de partage de ses
                    informations de connexion avec un tiers aux fins de diffuser
                    le contenu payant appartenant &agrave; la
                    Soci&eacute;t&eacute;, cette derni&egrave;re sera libre de
                    supprimer imm&eacute;diatement le compte de
                    l&rsquo;utilisateur et tous ses acc&egrave;s aux achats
                    effectu&eacute;s jusqu&rsquo;alors sans
                    d&eacute;dommagement.
                </p>
                <p>
                    L&rsquo;&eacute;l&egrave;ve est&nbsp;responsable de toutes
                    les activit&eacute;s auxquelles il s&rsquo;adonne en
                    &eacute;tant connect&eacute; &agrave; son compte Artesane.
                    Pour le cas o&ugrave; l&rsquo;&eacute;l&egrave;ve se
                    rendrait compte que des activit&eacute;s dont il n&rsquo;est
                    pas responsable ont lieu sur son compte utilisateur, il a
                    l&rsquo;obligation d&rsquo;en informer la
                    Soci&eacute;t&eacute; &agrave; l&rsquo;adresse&nbsp;
                    <a href="mailto:contact@artesane.com">
                        contact@artesane.com
                    </a>
                    . L&rsquo;acc&egrave;s au compte de l&rsquo;utilisateur sera
                    bloqu&eacute; le temps de permettre la r&eacute;solution des
                    faits.
                </p>
                <p>
                    Une fois inscrit.e, l&rsquo;&eacute;l&egrave;ve
                    re&ccedil;oit sur l&rsquo;adresse courriel qu&rsquo;il a
                    indiqu&eacute;e et v&eacute;rifi&eacute;e l&rsquo;ensemble
                    des documents l&eacute;gaux et transactionnels tels que la
                    confirmation de commande, la facture, les notifications de
                    changement de conditions d&rsquo;utilisation. En souscrivant
                    aux pr&eacute;sentes conditions, vous acceptez de recevoir
                    automatiquement ce type d&rsquo;informations l&eacute;gales.
                </p>
                <p>
                    L&rsquo;&eacute;l&egrave;ve peut &agrave; tout moment
                    demander la suppression de son compte. Celle-ci emportera
                    automatiquement la suppression de ses acc&egrave;s &agrave;
                    tout le contenu vid&eacute;o et les documents qui
                    l&rsquo;accompagne dont il aurait fait l&rsquo;acquisition
                    pr&eacute;alablement.
                </p>

                <h2>
                    Article 5&nbsp;:&nbsp; Propri&eacute;t&eacute;
                    intellectuelle
                </h2>

                <p>
                    L&rsquo;ensemble du contenu du site&nbsp;
                    <a href="https://www.artesane.com/">www.artesane.com</a>
                    &nbsp;(illustrations, textes, libell&eacute;s, marques,
                    images, vid&eacute;os) est la propri&eacute;t&eacute; de
                    Artesane, de ses cocontractants ou partenaires. Toute
                    reproduction partielle ou totale du contenu du site Artesane
                    par quelque proc&eacute;d&eacute; que ce soit et sur
                    n&rsquo;importe quel support est soumise &agrave; une
                    autorisation pr&eacute;alable et expresse d&rsquo;Artesane.
                    <br />
                    Toutes les informations, les contenus, les dossiers et les
                    logiciels fournis sur le Site Artesane sont
                    prot&eacute;g&eacute;s par les lois fran&ccedil;aises et
                    internationales concernant la propri&eacute;t&eacute;
                    intellectuelle et le copyright.
                    <br />
                    Artesane n&rsquo;autorise pas la reproduction, la
                    repr&eacute;sentation, la copie, l&rsquo;affichage ou la
                    distribution de tout contenu pour lequel l&rsquo;utilisateur
                    du site Artesane ne poss&egrave;de pas de droits de
                    propri&eacute;t&eacute; intellectuelle. Toute utilisation
                    sans autorisation de ces contenus en fraude aux droits
                    d&eacute;tenus par Artesane et/ou par des tiers sur ceux-ci
                    est susceptible de constituer le d&eacute;lit de
                    contrefa&ccedil;on, s&eacute;v&egrave;rement
                    sanctionn&eacute; par le Code de la Propri&eacute;t&eacute;
                    Intellectuelle.
                    <br />
                    Artesanene saurait en aucun cas &ecirc;tre tenue pour
                    responsable de la violation par un utilisateur du site
                    Artesane de droits d&eacute;tenus par des tiers et
                    perp&eacute;tr&eacute;e &agrave; raison des activit&eacute;s
                    de l&rsquo;utilisateur sur le Site Artesane.
                </p>

                <h2>
                    Article 6&nbsp;: Publication et mod&eacute;ration des
                    &nbsp;avis et commentaires
                </h2>

                <p>
                    Les avis d&eacute;pos&eacute;s par les utilisateurs et
                    &eacute;l&egrave;ves d&rsquo;Artesane sur les pages qui
                    pr&eacute;sentent nos cours vid&eacute;o ou nos
                    mod&egrave;les pdf ne sont pas relus par Artesane.
                    N&eacute;anmoins, ils seront mod&eacute;r&eacute;s
                    apr&egrave;s publications s&rsquo;ils sont jug&eacute;s hors
                    contexte, injurieux, ou s&rsquo;ils enfreignent loi et
                    &eacute;thique du consommateur.
                </p>
                <p>
                    Les insultes, la publicit&eacute; abusive, les propos
                    diffamatoires, les commentaires hors contexte ne seront pas
                    tol&eacute;r&eacute;s. Tout propos ne respectant pas la
                    politique &eacute;ditoriale g&eacute;n&eacute;rale
                    d&rsquo;Artesane sera modifi&eacute; ou supprim&eacute;.
                </p>

                <h2>
                    Article 7 &ndash; Donn&eacute;es &agrave; caract&egrave;re
                    personnel
                </h2>

                <p>
                    En s&rsquo;inscrivant sur le Site Artesane (notamment pour
                    l&rsquo;ouverture d&rsquo;un compte &eacute;l&egrave;ve ou
                    en s&rsquo;abonnant &agrave; notre newsletter),
                    l&rsquo;utilisateur du site s&rsquo;engage &agrave; fournir
                    des informations sinc&egrave;res et v&eacute;ritables le
                    concernant.&nbsp;La communication de fausses informations
                    est contraire aux pr&eacute;sentes conditions d'utilisation.
                    Conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier
                    1978 &laquo; Informatique et Libert&eacute;s &raquo;, la
                    collecte et le traitement de ces informations ont fait
                    l'objet d'une d&eacute;claration aupr&egrave;s de la
                    Commission Nationale de l'Informatique et des
                    Libert&eacute;s (CNIL) sous le num&eacute;ro de
                    r&eacute;c&eacute;piss&eacute;&nbsp;1905482 V0&nbsp;
                </p>
                <p>
                    La soci&eacute;t&eacute; Artesane est seule
                    d&eacute;tentrice des informations vous concernant.
                    <br />
                    Vous pouvez &agrave; n'importe quel moment faire une demande
                    aupr&egrave;s de Artesane afin :
                </p>
                <ul>
                    <li>
                        de savoir quelles informations vous concernant sont
                        d&eacute;tenues par,
                    </li>
                    <li>
                        de vous opposer &agrave; leur traitement, de les faire
                        modifier ou supprimer et ce, en contactant le service
                        client par email &agrave; l&rsquo;adresse&nbsp;
                        <a href="mailto:contact@lingueo.com">
                            contact@artesane.com
                        </a>
                        .<br />
                        <br />
                    </li>
                </ul>
                <p>
                    Si vous l'avez accept&eacute; lors de votre identification
                    sur le Site, Artesane et ses partenaires commerciaux peuvent
                    vous envoyer par courrier &eacute;lectronique des
                    informations dans le cadre d'op&eacute;rations
                    promotionnelles pr&eacute;cises et ponctuelles. Ces
                    partenaires sont sp&eacute;cialement choisis par Artesane en
                    fonction de la qualit&eacute; de leurs produits et services.
                </p>
                <p>
                    Vous pouvez demander &agrave; ne plus recevoir ces courriers
                    &eacute;lectroniques &agrave; tout moment sur le lien
                    pr&eacute;vu &agrave; cet effet et ins&eacute;r&eacute; en
                    pied de page de chacun des courriers &eacute;lectroniques
                    qui vous seront adress&eacute;s.
                </p>
                <p>
                    L&rsquo;utilisateur inscrit sur Artesane octroie un droit
                    d&rsquo;utilisation non limit&eacute; dans le temps et dans
                    l&rsquo;espace, illimit&eacute;, irr&eacute;vocable et
                    transmissible &agrave; un tiers de publier et de promouvoir
                    le contenu (texte, photos, vid&eacute;os) qu&rsquo;il aura
                    mis &agrave; disposition sur le site&nbsp;
                    <a href="https://www.artesane.com/">www.artesane.com</a>. Il
                    autorise la soci&eacute;t&eacute; &agrave; reproduire,
                    diffuser et communiquer publiquement ce contenu &agrave;
                    tout moment. Ce contenu peut &ecirc;tre utilis&eacute;
                    partiellement ou totalement comme moyen de promotion des
                    services fournis par la soci&eacute;t&eacute; Artesane.
                </p>
                <p>
                    Le contenu fourni par l&rsquo;utilisateur peut &ecirc;tre
                    adapt&eacute; afin de correspondre aux sp&eacute;cifications
                    et contraintes techniques de l&rsquo;exploitation du site
                    internet et de l&rsquo;application web et mobile de la
                    Soci&eacute;t&eacute; sur tous les terminaux.
                </p>
                <p>La cession des droits est toujours gratuite.</p>
                <p>
                    L&rsquo; &eacute;l&egrave;ve utilisateur garantit
                    &nbsp;qu&rsquo;il est le &nbsp;d&eacute;tenteur
                    l&eacute;gitime du droit d&rsquo;utiliser le contenu mis en
                    ligne (texte, photos, vid&eacute;os) et que le contenu
                    publi&eacute; sur Artesane (texte, photos, vid&eacute;os)
                    est libre des droits d&rsquo;un tiers.
                </p>
                <p>
                    L&rsquo;&eacute;l&egrave;ve utilisateur s&rsquo;engage
                    notamment &agrave;&nbsp;ne pas partager du contenu qui
                    enfreindrait les droits d&rsquo;un tiers ou la loi (en
                    particulier droits de la personnalit&eacute;, droit au nom,
                    droit des marques, droit d&rsquo;auteur et de la
                    propri&eacute;t&eacute; intellectuelle).
                </p>

                <h2>
                    Article 8 : &emsp;Responsabilit&eacute; d&rsquo;Artesane
                </h2>

                <p>
                    La responsabilit&eacute; illimit&eacute;e
                    d&rsquo;Artesane&nbsp;ne concerne que les n&eacute;gligences
                    graves ou fautes graves. En cas de l&eacute;g&egrave;re
                    violation d&rsquo;une obligation principale ou accessoire
                    dont la violation compromet le but du contrat, ou dont
                    l&rsquo;ex&eacute;cution permet le respect en bonne et due
                    forme du contrat et au respect duquel vous pouvez vous fier
                    (&laquo; obligation accessoire essentielle &raquo;), la
                    &nbsp;responsabilit&eacute; d&rsquo;Artesane est
                    limit&eacute;e aux dommages contractuels et
                    pr&eacute;visibles.
                </p>
                <p>
                    La soci&eacute;t&eacute; Artesane ne peut &ecirc;tre tenue
                    pour&nbsp;&nbsp;responsable des liens menant &agrave; des
                    sites Internet tiers figurant sur son propre site, ni de
                    leurs contenus. La responsabilit&eacute; relative aux
                    contenus de ces sites tiers sur Internet n&rsquo;incombe
                    qu&rsquo;&agrave; leurs exploitants respectifs. Les contenus
                    et/ou les exploitants de ces sites peuvent en
                    effet&nbsp;&ecirc;tre modifi&eacute;s apr&egrave;s que nous
                    ayons &eacute;tabli un lien avec notre site Internet sans
                    que nous en ayons connaissance. Nous ne pouvons &ecirc;tre
                    tenus de contr&ocirc;ler les contenus des pages tierces, ni
                    leur compatibilit&eacute; juridique.
                </p>

                <h2>
                    Article 9&nbsp;:&emsp;Modification des pages,
                    indisponibilit&eacute;&nbsp;du service,
                    int&eacute;grit&eacute; du syst&egrave;me
                </h2>

                <p>
                    La soci&eacute;t&eacute; Artesane se r&eacute;serve le droit
                    et la possibilit&eacute; de modifier l&rsquo;aspect de ses
                    offres et services, y compris les focntions actuelles et
                    futures de l&rsquo;int&eacute;gralit&eacute; des pages du
                    site, de l&rsquo;application mobile. La
                    soci&eacute;t&eacute; peut suspendre temporairement ou
                    effacer d&eacute;finitivement du contenu pour des raisons
                    l&eacute;gales, techniques ou commerciales. En outre, la
                    soci&eacute;t&eacute; se r&eacute;serve le droit de limiter
                    l&rsquo;utilisation&nbsp;de certaines fonctions de ses
                    services, de r&eacute;duire l&rsquo;accessibilit&eacute;
                    &agrave; certaines prestations du site
                    ou&nbsp;application&nbsp;&agrave; court terme ou
                    temporairement, et ce, sans avoir &agrave; annoncer ces
                    restrictions &agrave; l&rsquo;avance, et sans
                    responsabilit&eacute; aucune.
                </p>
                <p>
                    Dans certaines circonstances, l&rsquo;acc&egrave;s
                    &agrave;&nbsp;
                    <a href="https://www.artesane.com">
                        https://www.artesane.com
                    </a>
                    &nbsp;ou&nbsp;&agrave; l&rsquo;application Artesa,e peut par
                    moment&nbsp;&ecirc;tre interrompu (&agrave; court terme ; de
                    mani&egrave;re impr&eacute;vue), en raison de
                    probl&egrave;mes techniques du c&ocirc;t&eacute;
                    d&rsquo;internet ou du c&ocirc;t&eacute; de la
                    Soci&eacute;t&eacute; Artesane, ou encore venant d&rsquo;un
                    prestataire de service que nous utilisons. Dans ce cas, la
                    soci&eacute;t&eacute; mettra tout en &oelig;uvre pour
                    r&eacute;gler le probl&egrave;me au plus vite dans la mesure
                    o&ugrave; sa solution est de son ressort. N&eacute;anmoins,
                    nous insistons sur le fait qu&rsquo;aucun acc&egrave;s au
                    site ou &agrave; ses fonctions ne peut dans ces conditions
                    &ecirc;tre garanti. Nous nous effor&ccedil;ons cependant
                    d&rsquo;&eacute;viter ces troubles au moyen de
                    mises&nbsp;&agrave; jour r&eacute;guli&egrave;res et de
                    mises en place de nouveaux services et nouvelles fonctions.
                </p>
                <p>
                    Vous&nbsp;&ecirc;tes tenus d&rsquo;installer votre
                    syst&egrave;me et vos programmes en lien avec
                    l&rsquo;utilisation des offres pr&eacute;sentes
                    sur&nbsp;www.artesane.com&nbsp;et l&rsquo;application
                    Artesane de telle sorte que ni la s&eacute;curit&eacute;, ni
                    l&rsquo;int&eacute;grit&eacute; ou
                    l&rsquo;accessibilit&eacute; du syst&egrave;me que la
                    soci&eacute;t&eacute; met &nbsp;&agrave; votre disposition
                    ne soient menac&eacute;es.
                </p>
            </div>
        </div>
    )
}

export default LegalNotices
