//Libraries
import axios from "axios"
import jwt_decode from "jwt-decode"
//Actions
import {
    FETCH_FAQS,
    saveHomeDataInState,
    saveMegaMenu,
    isLoadingMegaMenu,
    saveLessonsSlider,
    saveTopSliderItems,
} from "../actions/homeActions"

import {
    FETCH_LESSONS,
    saveCatalogueLessonsInState,
    toggleLoading,
    saveFilters,
    saveTeachersFilters,
    addFiltersCheckbox,
    saveCategoriesMenu,
    savePagelist,
    saveCatalogueH1,
} from "../actions/catalogueAction"

import {
    GET_GENERAL_SEARCH_BAR,
    saveCommonDataInState,
    FETCH_SEO_ENTRIES,
    FETCH_DATA_BY_UNIVERSE_AND_PAGE,
    FETCH_CHANNEL,
} from "../actions/commonAction"

import {
    FETCH_PRODUCT_DATA,
    saveProductData,
    isLoadingProduct,
    saveProductDataState,
} from "../actions/productsActions"

import {
    FETCH_PROFESSEUR_CATALOGUE_DATA,
    saveProfesseurCatalogueData,
    FETCH_PROFESSEUR_PUBLIC_DATA,
    saveProfesseurPublicData,
    FETCH_ALL_PROFESSORS,
    saveAllProfessors,
    saveProfessorsHighlight,
} from "../actions/professorsAction"

import {
    FETCH_USER_DATA,
    changeInputValue,
    saveOrders,
    FETCH_ORDER_ITEMS,
    saveOrderItems,
    loadingOrderItems,
    saveWishlist,
    PUT_MODIFY_ADDRESS,
    toggleModifyAddress,
    loadingModifyAddress,
    FETCH_COUNTRIES,
    saveCountries,
    errorModifyAddress,
    POST_NEW_ADDRESS,
    loadingAddAddress,
    togglePopupError,
    DELETE_ADDRESS,
    loadingDeleteAddress,
    PATCH_MY_PROFIL_DATA,
    loadingUserRequest,
    checkSave,
    PATCH_MY_PERSONAL_INFOS,
    PATCH_SUBSCRIPTIONS,
    PUT_MODIFY_PASSWORD,
    loadingModifyPassword,
    DEFAULT_ADDRESS,
    PUT_WISHLIST_ITEM,
    wishlistItem,
    FETCH_ADDRESSES,
    fetchAddresses,
    DELETE_WISHLIST_ITEM,
    FETCH_GIFTCARDS,
    FETCH_PDF_GIFTCARD,
    ADD_GIFT_CARD_ACCOUNT,
    fetchGiftcards,
    POST_ACTIVATE_COURSE,
    FETCH_WISHLIST,
    FETCH_INVOICE,
    fetchUserData,
    fetchWishlist,
} from "../actions/userAction"

import {
    POST_CONNEXION,
    userConnexion,
    changeInputAuthValue,
    loadingConnexion,
    errorConnexion,
    POST_REGISTRATION,
    loadingRegistration,
    RESET_PASSWORD,
    MODIFY_RESET_PASSWORD,
    REFRESH_TOKEN,
    visibilityAuthPassword,
    refreshToken,
} from "../actions/authAction"

import {
    FETCH_COURSE_ITEM,
    FETCH_SINGLE_COLLECTION,
    FETCH_USER_MEDIA,
    loadingCollection,
    loadingCourseItem,
    loadingMedia,
    saveCourseItem,
    saveSingleCollection,
    saveUserMedia,
    POST_NEW_TIME_IN_VIDEO,
} from "../actions/mediaAction"
import {
    ADD_ORDER_ITEM_CONNECTED,
    CHANGE_QUANTITY,
    countCart,
    CREATE_CART_DISCONNECTED,
    DELETE_ORDER_ITEM_CART,
    getCartConnected,
    getCartDisconnected,
    GET_CART_CONNECTED,
    GET_CART_DISCONNECTED,
    loadingAddCart,
    loadingDeleteModifyCart,
    saveCartInState,
    toggleVariantsChoice,
    verifyCart,
    VERIFY_CART,
    changeInputOrder,
    PUT_ADD_ADDRESS_ORDER,
    FETCH_PAYMENT_URL,
    PATCH_SHIPPING_METHOD,
    POST_COUPON_CODE,
    POST_GIFT_CARD_CART,
    DELETE_GIFT_CARD_CART,
    FETCH_ONEY_SIMULATION,
    PATCH_PAYMENT_METHOD,
    fetchPaymentUrl,
    FETCH_CART_ASSOCIATION,
    fetchCartAssociation,
    saveCartAssociation,
    patchShippingMethod,
    MODIFY_DEFAULT_ADDRESS_ORDER,
} from "../actions/cartAction"
import {
    SeoCreator,
    addBestSalesBool,
    dePaginator,
    filterGiftcards,
    filterProduct,
    isAddressesSame,
    lastCollectionsWatched,
    menuToCheckFilters,
    productsToTeacher,
    setCookie,
    trierCollectionParUnivers,
    trierCollectionsParMatieres,
} from "../selectors/selectors"

import { cartProcessor, cartAddressProcessor } from "../services/cart"

const apiMiddleware = (store) => (next) => (action) => {
    const apiBaseUrl = process.env.REACT_APP_API_SERVER_URL

    const userToken = localStorage.getItem("token")
    store
        .getState()
        .home.homeUniversActif.replace(new RegExp("-", "g"), "_")
        .toUpperCase()

    //cookie for staging
    axios.defaults.withCredentials = true

    if (action.type === FETCH_INVOICE) {
        axios
            .get(`${apiBaseUrl}shop/orders/${action.token}/invoice`, {
                responseType: "blob",
                headers: {
                    accept: "application/ld+json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: "application/pdf",
                })
                const url = window.URL.createObjectURL(blob)
                store.dispatch(
                    changeInputValue(
                        { orderToken: action.token, url: url },
                        "urlInvoice"
                    )
                )
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(changeInputValue("", "loadingInvoice"))
            })
    }

    if (action.type === REFRESH_TOKEN) {
        axios
            .post(
                `${apiBaseUrl}shop/refresh-token`,
                {
                    refresh_token: localStorage.getItem("refresh_token"),
                }
            )
            .then((response) => {
                const decoded = jwt_decode(response.data.token)
                const email = decoded.username
                localStorage.setItem("token", response.data.token)
                localStorage.setItem(
                    "refresh_token",
                    response.data.refresh_token
                )
                store.dispatch(changeInputAuthValue(email, "email"))
                store.dispatch(fetchUserData(localStorage.getItem("customer"), response.data.token))
                store.dispatch(userConnexion(true))
                store.dispatch(fetchWishlist(response.data.token))
            })
            .catch((error) => {
                console.log(error)
                    store.dispatch(changeInputAuthValue("", "email"))
                    store.dispatch(changeInputAuthValue("", "emailRegistration"))
                    store.dispatch(changeInputAuthValue("", "emailRegistration"))
                    store.dispatch(changeInputValue("", "lastNameAddressAdd"))
                    store.dispatch(changeInputValue("", "lastNameAddressAdd"))
                    store.dispatch(changeInputValue("", "firstNameAddressAdd"))
                    store.dispatch(changeInputValue("", "streetAddressAdd"))
                    store.dispatch(changeInputValue("", "complementAddressAdd"))
                    store.dispatch(changeInputValue("", "societyAddressAdd"))
                    store.dispatch(changeInputValue("", "postCodeAddressAdd"))
                    store.dispatch(changeInputValue("", "cityAddressAdd"))
                    store.dispatch(changeInputValue("", "telephoneAddressAdd"))
                    store.dispatch(changeInputValue(false, "defaultAdressAdd"))
                    store.dispatch(changeInputOrder(false, "addressValidate"))
                    store.dispatch(changeInputOrder(false, "errorTelephoneShipping"))
                    store.dispatch(changeInputOrder(0, "addressSelectCarnet"))
                    store.dispatch(changeInputValue([], "addresses"))
                    store.dispatch(changeInputOrder("", "firstNameShipping"))
                    store.dispatch(changeInputOrder("", "lastNameShipping"))
                    store.dispatch(changeInputOrder("", "societyShipping"))
                    store.dispatch(changeInputOrder("", "cityShipping"))
                    store.dispatch(changeInputOrder("", "complementShipping"))
                    store.dispatch(changeInputOrder("", "streetShipping"))
                    store.dispatch(changeInputOrder("", "postCodeShipping"))
                    store.dispatch(changeInputOrder("", "countryShipping"))
                    store.dispatch(changeInputOrder("", "telephoneShipping"))
                    store.dispatch(changeInputOrder("", "firstNameBilling"))
                    store.dispatch(changeInputOrder("", "lastNameBilling"))
                    store.dispatch(changeInputOrder("", "societyBilling"))
                    store.dispatch(changeInputOrder("", "cityBilling"))
                    store.dispatch(changeInputOrder("", "complement"))
                    store.dispatch(changeInputOrder("", "streetBilling"))
                    store.dispatch(changeInputOrder("", "postCodeBilling"))
                    store.dispatch(changeInputOrder("", "countryBilling"))
                    store.dispatch(changeInputOrder("", "telephoneBilling"))
                    store.dispatch(changeInputOrder("", "adjustmentsPromo"))
                    store.dispatch(changeInputOrder("", "couponCode"))
                    store.dispatch(changeInputOrder("", "adjustmentsShipping"))
                    store.dispatch(visibilityAuthPassword(false))
                    store.dispatch(saveUserMedia("mediaArtsCulinaires", []))
                    store.dispatch(saveUserMedia("mediaArtsDuFil", []))
                    store.dispatch(saveUserMedia("mediaArtsPlastiques", []))
                    store.dispatch(saveCartInState([]))
                    store.dispatch(saveWishlist([]))
                    store.dispatch(countCart(0))
                    store.dispatch(loadingMedia(true))
                    localStorage.clear()
                    store.dispatch(userConnexion(false))
                
            })
            .finally(() => {


            })
    }

    if (action.type === FETCH_WISHLIST) {
        axios
            .get(`${apiBaseUrl}shop/wishlists?page=1&itemsPerPage=300`, {
                headers: {
                    Authorization: "Bearer " + action.token,
                },
            })

            .then((response) => {
                if (response.data["hydra:totalItems"] === 0) {
                    axios
                        .post(
                            `${apiBaseUrl}shop/wishlists`,
                            {
                                token: action.token,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/ld+json",
                                    accept: "application/json, text/plain, */*",
                                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                                },
                            }
                        )
                        .then((response) => {
                            store.dispatch(saveWishlist(response.data))
                            store.dispatch(
                                changeInputValue(0, "wishlistItemNumber")
                            )
                        })
                        .catch((error) => {
                            console.log(error)
                            store.dispatch(refreshToken())
                        })
                        .finally(() => {
                            store.dispatch(
                                changeInputValue(false, "loadingWishlist")
                            )
                        })
                } else {
                    store.dispatch(
                        saveWishlist(response.data["hydra:member"][0])
                    )
                    store.dispatch(
                        changeInputValue(
                            response.data["hydra:member"][0].wishlistProducts
                                .length,
                            "wishlistItemNumber"
                        )
                    )
                    store.dispatch(changeInputValue(false, "loadingWishlist"))
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
                console.log(error)
            })
            .finally(() => {
                store.dispatch(changeInputAuthValue(false, "loadingWishlist"))
            })
    }
    if (action.type === FETCH_CHANNEL) {
        axios
            .get(`${apiBaseUrl}shop/channels/artesane`)
            .then((response) => {
                if (action.infos === "maintenance") {
                    store.dispatch(
                        saveCommonDataInState(
                            "modeMaintenance",
                            response.data.maintenance
                        )
                    )
                } else {
                    store.dispatch(
                        saveCommonDataInState(
                            "modeMaintenance",
                            response.data.maintenance
                        )
                    )
                    store.dispatch(
                        saveCommonDataInState(
                            "allUniverses",
                            response.data.universes
                        )
                    )
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(
                    saveCommonDataInState("loadingAllUniverses", false)
                )
            })
    }

    if (action.type === FETCH_ONEY_SIMULATION) {
        axios
            .get(
                `${apiBaseUrl}shop/orders/${action.cartToken}/oney/simulation`,
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(
                    changeInputOrder(response.data, "oneySimulation")
                )
                store.dispatch(changeInputOrder(false, "oneySimulationLoading"))
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {})
    }

    if (action.type === FETCH_SEO_ENTRIES) {
        axios
            .get(`${apiBaseUrl}seo-entries?page=1&itemsPerPage=300`)
            .then((response) => {
                store.dispatch(
                    saveCommonDataInState(
                        "seoItems",
                        response.data["hydra:member"]
                    )
                )
                store.dispatch(saveCommonDataInState("loadingSeoItem", false))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (action.type === GET_GENERAL_SEARCH_BAR) {
        axios
            .post(
                `${apiBaseUrl}shop/products-search`,
                {
                    search: action.words,
                    page: 1,
                    itemsPerPage: 150,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/ld+json",
                    },
                }
            )
            .then((response) => {
                store.dispatch(
                    saveCommonDataInState(
                        "searchGeneral",
                        response.data.items["hydra:member"]
                    )
                )
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(
                    saveCommonDataInState("loadingGeneralSearchbar", false)
                )
            })
            .finally(() => {
                store.dispatch(
                    saveCommonDataInState("loadingGeneralSearchbar", false)
                )
            })
    }

    if (action.type === MODIFY_RESET_PASSWORD) {
        const errorPassword = store.getState().auth.forgetErrorPatternPassword
        const errorConfirmPassword =
            store.getState().auth.forgetErrorConfirmPassword

        if (!errorConfirmPassword && !errorPassword) {
            axios
                .patch(
                    `${apiBaseUrl}shop/reset-password-requests/${action.value}`,
                    {
                        newPassword: action.newPassword,
                        confirmNewPassword: action.confirmPassword,
                    },
                    {
                        headers: {
                            "Content-Type": "application/merge-patch+json",
                            accept: "application/json",
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(
                        changeInputAuthValue(false, "loadingChangePassword")
                    )
                    store.dispatch(
                        changeInputAuthValue("", "forgetNewPassword")
                    )
                    store.dispatch(
                        changeInputAuthValue("", "forgetConfirmNewPassword")
                    )
                    store.dispatch(
                        changeInputAuthValue(true, "popUpPasswordConfirm")
                    )
                    store.dispatch(
                        changeInputAuthValue(
                            true,
                            "redirectAfterChangePassword"
                        )
                    )
                })
                .catch((error) => {
                    store.dispatch(togglePopupError(true))
                    store.dispatch(
                        changeInputAuthValue(false, "loadingChangePassword")
                    )
                    console.log(error)
                })
        } else {
            store.dispatch(changeInputAuthValue(false, "loadingChangePassword"))
        }
    }

    if (action.type === RESET_PASSWORD) {
        axios
            .post(
                `${apiBaseUrl}shop/reset-password-requests`,
                {
                    email: action.value,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/json, text/plain, */*",
                    },
                }
            )
            .then((response) => {

                    store.dispatch(
                        changeInputAuthValue("", "emailForgottenPassword")
                    )
                    store.dispatch(
                        changeInputAuthValue(true, "popUpPasswordConfirm")
                    )
                    store.dispatch(
                        changeInputAuthValue(false, "loadingChangePassword")
                    )
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(togglePopupError(true))
                store.dispatch(
                    changeInputAuthValue(false, "loadingChangePassword")
                )
            })
    }

    if (action.type === PUT_WISHLIST_ITEM) {
        const wishlistToken = store.getState().user.wishList.token
        axios
            .patch(
                `${apiBaseUrl}shop/wishlists/${wishlistToken}/product`,
                {
                    productId: action.productId,
                },
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(wishlistItem({}))
                axios
                    .get(`${apiBaseUrl}shop/wishlists/${wishlistToken}`, {
                        headers: {
                            "Content-Type": "application/json",
                            accept: "application/json, text/plain, */*",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((response) => {
                        store.dispatch(loadingAddCart(""))
                        store.dispatch(saveWishlist(response.data))

                        store.dispatch(
                            changeInputValue(
                                response.data.wishlistProducts.length,
                                "wishlistItemNumber"
                            )
                        )
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
            })
    }

    if (action.type === DELETE_WISHLIST_ITEM) {
        axios
            .delete(
                `${apiBaseUrl}shop/wishlists/${action.token}/products/${action.productId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                axios
                    .get(`${apiBaseUrl}shop/wishlists/${action.token}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((response) => {
                        store.dispatch(loadingAddCart(""))
                        store.dispatch(saveWishlist(response.data))

                        store.dispatch(
                            changeInputValue(
                                response.data.wishlistProducts.length,
                                "wishlistItemNumber"
                            )
                        )
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {})
    }

    if (action.type === POST_NEW_TIME_IN_VIDEO) {
        axios
            .post(
                `${apiBaseUrl}shop/courses`,
                {
                    id: action.courseId,
                    time_in_video: action.time,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => {})
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {})
    }

    if (action.type === POST_COUPON_CODE) {
        axios
            .put(
                `${apiBaseUrl}shop/orders/${action.token}`,
                {
                    couponCode: action.value,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(getCartConnected("cart"))
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(changeInputOrder(false, "loadingCart"))
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                } else {

                    store.dispatch(
                        changeInputOrder(
                            error.response.data["hydra:description"].includes(
                                "couponCode"
                            )
                                ? error.response.data[
                                      "hydra:description"
                                  ].replace(/couponCode:/, "")
                                : error.response.data["hydra:description"],
                            "messageRetourCoupon"
                        )
                    )

                    store.dispatch(changeInputOrder("", "couponCode"))
                }
            })
    }

    if (action.type === PATCH_SHIPPING_METHOD) {
        const orderToken = store.getState().cart.cartData.tokenValue
        const shipments = store.getState().cart.cartData.shipments
        axios
            .patch(
                `${apiBaseUrl}shop/orders/${orderToken}/shipments/${shipments[0].id}`,
                {
                    "shippingMethod": shipments[0].availableMethods.find(
                        (item) => item.id === Number(action.shippingId)
                    )["@id"],
                    "pickupPostCode": store.getState().cart.mondialRelayPostCode !== null ? store.getState().cart.mondialRelayPostCode.trim() : null,
                    "pickupCountryCode": store.getState().cart.mondialRelayCountryCode !== null ? store.getState().cart.mondialRelayCountryCode.trim() : null,
                    "pickupPointId": store.getState().cart.mondialRelayId !== null ? store.getState().cart.mondialRelayId.trim() : null,
                    "pickupName": store.getState().cart.mondialRelayName !== null ? store.getState().cart.mondialRelayName.trim() : null ,
                    "pickupStreet": store.getState().cart.mondialRelayStreet !== null ? store.getState().cart.mondialRelayStreet.trim() : null,
                    "pickupCity": store.getState().cart.mondialRelayCity !== null ? store.getState().cart.mondialRelayCity.trim() : null
                },
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(getCartConnected())

                store.dispatch(changeInputOrder(true, "addressValidate"))

                // if (response.data.payments.length > 0) {
                //     if (
                //         response.data.payments[response.data.payments.length - 1].availableMethods.length === 1
                //     ) {
                //         store.dispatch(
                //             changeInputOrder(false, "redirectPaiement")
                //         )
                //         // store.dispatch(
                //         //     changeInputOrder(false, "chooseDelivery")
                //         // )
                //         store.dispatch(
                //             changeInputOrder(true, "redirectPaiementWindow")
                //         )
                //     } 
                //     // else {
                //     //     store.dispatch(
                //     //         changeInputOrder(true, "redirectPaiement")
                //     //     )
                //     //     store.dispatch(changeInputOrder(true, "chooseDelivery"))
                //     //     store.dispatch(
                //     //         changeInputOrder(false, "redirectPaiementWindow")
                //     //     )
                //     // }
                // } else {
                //     store.dispatch(changeInputOrder(false, "redirectPaiement"))
                //     store.dispatch(changeInputOrder(false, "chooseDelivery"))
                // }
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(
                    changeInputOrder(false, "loadingValidateAddress")
                )
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
            })
            .finally(() => {
                store.dispatch(
                    changeInputOrder(false, "loadingValidateAddress")
                )
            })
    }

    if (action.type === FETCH_PAYMENT_URL) {
        axios
            .patch(
                `${apiBaseUrl}shop/orders/${action.cartToken}/complete`,
                {},
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                if (
                    response.data.checkoutState === "completed" &&
                    response.data.payments.length === 0
                ) {
                    store.dispatch(
                        changeInputOrder(
                            `/paiement/${action.value}/reussite`,
                            "payplugUrl"
                        )
                    )
                } else {
                    axios
                        .patch(
                            `${apiBaseUrl}shop/payments/${action.value}/token`,
                            {},
                            {
                                headers: {
                                    "Content-Type":
                                        "application/merge-patch+json",
                                    accept: "application/json, text/plain, */*",
                                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                                },
                            }
                        )
                        .then((response) => {
                            store.dispatch(
                                changeInputOrder(
                                    response.data.targetUrl,
                                    "payplugUrl"
                                )
                            )
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    if (action.type === PUT_ADD_ADDRESS_ORDER) {
        const cartToken = store.getState().cart.cartData.tokenValue
        const addresses = store.getState().user.addresses;
        if(isAddressesSame(cartAddressProcessor(store).shippingAddress, cartAddressProcessor(store).billingAddress)) {
            store.dispatch(changeInputOrder(true, "sameAddressAsShippingAddress"))
        }

        axios
            .put(
                `${apiBaseUrl}shop/orders/${cartToken}`,
                cartAddressProcessor(store),
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
               

                store.dispatch(changeInputOrder(true, "addressValidate"))
                store.dispatch(getCartConnected("cart"))
                store.dispatch(
                    changeInputOrder(
                        false, "modifyAddressShipping"
                    )
                )

                store.dispatch(
                    changeInputOrder(
                        false, "modifyAddressBilling"
                    )
                )

            })
            .catch((error) => {
                console.log(error)
                store.dispatch(
                    changeInputOrder(false, "loadingValidateAddress")
                )
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
            })
            .finally(() => {
                store.dispatch(
                    changeInputOrder(false, "loadingValidateAddress")
                )
            })
    }

    if (action.type === POST_ACTIVATE_COURSE) {
        axios
            .post(
                `${apiBaseUrl}shop/associations`,
                {
                    activationToken: action.token,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(changeInputValue(true, "courseActivate"))
                store.dispatch(changeInputValue(false, "loadingActivateCourse"))
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(togglePopupError(true, ""))
                store.dispatch(changeInputValue(false, "loadingActivateCourse"))
            })
    }

    if (action.type === ADD_ORDER_ITEM_CONNECTED) {
        const userCartToken = store.getState().cart.cartTokenUser
        if (userCartToken !== "") {
            axios
                .post(
                    `${apiBaseUrl}shop/orders/${userCartToken}/items`,
                    {
                        gift: action.gift,
                        productVariant:
                            "/api/v2/shop/product-variants/" + action.code,
                        quantity: 1,
                        amount: action.giftcardConfigurable
                            ? Number(action.giftcardAmount) * 100
                            : null,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/json, text/plain, */*",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(getCartConnected())

                    store.dispatch(toggleVariantsChoice(0))
                    store.dispatch(loadingAddCart(""))
                })
                .catch((error) => {
                    store.dispatch(loadingAddCart(""))
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                })
        } else {
            axios
                .post(
                    `${apiBaseUrl}shop/orders`,
                    { localeCode: "fr_FR" },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            accept: "application/json, text/plain, */*",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    const newToken = response.data.tokenValue

                    axios
                        .post(
                            `${apiBaseUrl}shop/orders/${newToken}/items`,
                            {
                                gift: action.gift,
                                productVariant:
                                    "/api/v2/shop/product-variants/" +
                                    action.code,
                                quantity: 1,
                                amount: action.giftcardConfigurable
                                    ? Number(action.giftcardAmount) * 100
                                    : null,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/ld+json",
                                    accept: "application/json, text/plain, */*",
                                    Authorization: `Bearer ${userToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            store.dispatch(getCartConnected())

                            store.dispatch(toggleVariantsChoice(0))
                            store.dispatch(loadingAddCart(""))
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                    store.dispatch(loadingAddCart(""))
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                })
        }
    }
    if (action.type === GET_CART_CONNECTED) {
        store.dispatch(changeInputOrder(true, "loadingCart"))
        axios
            .post(
                `${apiBaseUrl}shop/orders`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => {
                
                if(action.context === "delivery") {
                    store.dispatch(fetchAddresses(action.context))           
                }else {
                    cartProcessor(store, response.data, "cart")
                }

                store.dispatch(fetchGiftcards(response.data.giftCards))
            })
            .catch((error) => {
                console.log(error)

                store.dispatch(changeInputOrder(false, "loadingCart"))
            }).finally(() => {
                store.dispatch(changeInputOrder(false, "loadingCartAfterConnexion"))

            })
    }

    if (action.type === PATCH_PAYMENT_METHOD) {
        axios
            .patch(
                `${apiBaseUrl}shop/orders/${action.cartToken}/payments/${action.paymentId}`,
                {
                    paymentMethod: action.paymentMethodCode,
                    oneyPaymentChoice: action.oneyPaymentChoice,
                },
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(changeInputOrder(false, "choosePayment"))
                store.dispatch(changeInputOrder(true, "validatePayment"))
               

                if (response.data.payments.length > 0) {
                    // var payment = response.data.payments[response.data.payments.length - 1];
                    // if (payment.availableMethods.length === 1) {
                    store.dispatch(
                        fetchPaymentUrl(
                            response.data.payments[0].id,
                            response.data.tokenValue,
                            response.data.checkoutState
                        )
                    )
                    
                    // }
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(changeInputOrder(false, "loadingAddPayment"))
            })
    }

    if (action.type === VERIFY_CART) {
        const processOrderItems = async (orderItems, newCartToken, userToken) => {
            try {
                for (const { variant, quantity, gift, total } of orderItems) {
                    const response = await axios.post(
                        `${apiBaseUrl}shop/orders/${newCartToken}/items`,
                        {
                            gift: gift,
                            productVariant: variant["@id"],
                            quantity: quantity,
                            amount: variant["@id"].includes("gift-card")
                                ? Number(total)
                                : null,
                        },
                        {
                            headers: {
                                "Content-Type": "application/ld+json",
                                accept: "application/json, text/plain, */*",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                }
        
                // Toutes les requêtes sont terminées
                store.dispatch(getCartConnected());
                console.log("je passe là")
                store.dispatch(changeInputOrder(false, "loadingCartAfterConnexion"))

            } catch (error) {
                store.dispatch(changeInputOrder(false, "loadingCartAfterConnexion"))
                console.error("Une erreur s'est produite :", error);
        
                if (error.response?.status === 401) {
                    store.dispatch(refreshToken());
                }
            }
        };
        
        if (action.cartToken !== null) {
            axios
                .get(`${apiBaseUrl}shop/orders/${action.cartToken}/items`)
                .then(async (response) => {
                    const orderItems = response.data["hydra:member"];
        
                    // Supprimez le panier précédent
                    await axios.delete(`${apiBaseUrl}shop/orders/${action.cartToken}`);
        
                    // Créez un nouveau panier
                    const newCartResponse = await axios.post(
                        `${apiBaseUrl}shop/orders`,
                        { localeCode: "fr_FR" },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                accept: "application/json, text/plain, */*",
                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    );
        
                    const newCartToken = newCartResponse.data.tokenValue;
                    store.dispatch(fetchGiftcards(newCartResponse.data.giftCards));
        
                    // Ajoutez les articles au nouveau panier
                    await processOrderItems(orderItems, newCartToken, localStorage.getItem("token"));
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken());
                    }
                });
        } else {
            store.dispatch(getCartConnected());
        }
        
    }
    if (action.type === CHANGE_QUANTITY) {
        const userConnected = store.getState().auth.userConnected

        if (!userConnected) {
            axios
                .patch(
                    `${apiBaseUrl}shop/orders/${action.cartToken}/items/${action.orderItemId}`,
                    {
                        quantity: action.quantity,
                    },
                    {
                        headers: {
                            "Content-Type": "application/merge-patch+json",
                            accept: "application/ld+json, application/json, text/html",
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(getCartDisconnected(action.cartToken))
                    store.dispatch(loadingDeleteModifyCart(0))
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            axios
                .patch(
                    `${apiBaseUrl}shop/orders/${action.cartToken}/items/${action.orderItemId}`,
                    {
                        quantity: action.quantity,
                    },
                    {
                        headers: {
                            "Content-Type": "application/merge-patch+json",
                            accept: "application/ld+json, application/json, text/html",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(getCartConnected(action.cartToken))
                    store.dispatch(loadingDeleteModifyCart(0))
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                })
        }
    }

    if (action.type === DELETE_ORDER_ITEM_CART) {
        if (action.user) {
            axios
                .delete(
                    `${apiBaseUrl}shop/orders/${action.cartToken}/items/${action.orderItemId}`,
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/json, text/plain, */*",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(loadingDeleteModifyCart(0))
                    store.dispatch(getCartConnected(action.cartToken))
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                })
        } else {
            axios
                .delete(
                    `${apiBaseUrl}shop/orders/${action.cartToken}/items/${action.orderItemId}`,
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/json, text/plain, */*",
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(loadingDeleteModifyCart(0))

                    store.dispatch(getCartDisconnected(action.cartToken))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    if (action.type === GET_CART_DISCONNECTED) {
        axios
            .get(`${apiBaseUrl}shop/orders/${action.value}`)
            .then((response) => {
                store.dispatch(saveCartInState(response.data))
                if (response.data.items.length === 0) {
                    store.dispatch(countCart(0))
                }

                if (response.data.items.length === 1) {
                    store.dispatch(fetchCartAssociation(response.data.items))
                    store.dispatch(countCart(response.data.items[0].quantity))
                } else if (response.data.items.length > 1) {
                    store.dispatch(fetchCartAssociation(response.data.items))
                    const totalItemsCart = response.data.items
                        .map((items) => items.quantity)
                        .reduce((previous, current) => previous + current)
                    store.dispatch(countCart(totalItemsCart))
                }


                store.dispatch(changeInputOrder(false, "loadingCart"))
                store.dispatch(changeInputValue(false, "loadingGiftcards"))
            })
            .catch((error) => {
                console.log(error)

                store.dispatch(changeInputOrder(false, "loadingCart"))
                if (error.response.status === 404) {
                    localStorage.clear()
                }
            })
            .finally(() => {})
    }

    if (action.type === CREATE_CART_DISCONNECTED) {
        const cartToken = localStorage.getItem("cart")
        if (cartToken === null) {
            //Si pas de token en localStorage : création nouveau panier
            axios
                .post(
                    `${apiBaseUrl}shop/orders`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/ld+json",
                            "Accept-Language": "fr_FR",
                        },
                    }
                )
                .then((response) => {
                    localStorage.setItem("cart", response.data.tokenValue)

                    axios
                        .post(
                            `${apiBaseUrl}shop/orders/${response.data.tokenValue}/items`,
                            {
                                gift: action.gift,
                                productVariant:
                                    "/api/v2/shop/product-variants/" +
                                    action.variantCode,
                                quantity: 1,
                                amount: action.giftcardConfigurable
                                    ? Number(action.giftcardAmount) * 100
                                    : null,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/ld+json",
                                    accept: "application/json, text/plain, */*",
                                },
                            }
                        )
                        .then((response) => {
                            store.dispatch(
                                getCartDisconnected(
                                    localStorage.getItem("cart")
                                )
                            )
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                        .finally(() => {
                            store.dispatch(toggleVariantsChoice(0))
                            store.dispatch(loadingAddCart(""))
                        })
                })
                .catch((error) => {
                    console.log(error)
                    store.dispatch(toggleVariantsChoice(0))
                    store.dispatch(loadingAddCart(""))
                })
        } else {
            axios
                .post(
                    `${apiBaseUrl}shop/orders/${cartToken}/items`,
                    {
                        gift: action.gift,
                        productVariant:
                            "/api/v2/shop/product-variants/" +
                            action.variantCode,
                        quantity: 1,
                        amount: action.giftcardConfigurable
                            ? Number(action.giftcardAmount) * 100
                            : null,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/json, text/plain, */*",
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(
                        getCartDisconnected(localStorage.getItem("cart"))
                    )
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    store.dispatch(toggleVariantsChoice(0))
                    store.dispatch(loadingAddCart(""))
                })
        }
    }

    if (action.type === FETCH_COURSE_ITEM) {
        axios
            .get(`${apiBaseUrl}shop/course/${action.value}`, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json, text/plain, */*",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                const courseItem = response.data
                if (courseItem.access !== (undefined && null)) {
                    setCookie(
                        "CloudFront-Key-Pair-Id",
                        courseItem.access["Cloudfront-Key-Pair-Id"],
                        7
                    )
                    setCookie(
                        "CloudFront-Policy",
                        courseItem.access["Cloudfront-Policy"],
                        7
                    )
                    setCookie(
                        "CloudFront-Signature",
                        courseItem.access["Cloudfront-Signature"],
                        7
                    )
                }
                store.dispatch(saveCourseItem(courseItem))
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(loadingCourseItem(false))
            })
    }

    if (action.type === FETCH_SINGLE_COLLECTION) {
        axios
            .get(`${apiBaseUrl}shop/association/${action.value}`, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json, text/plain, */*",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                store.dispatch(saveSingleCollection(response.data))
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(loadingCollection(false))
            })
    }

    if (action.type === FETCH_USER_MEDIA) {
        const axiosInstance = axios.create({
            headers: {
                "Content-Type": "application/json",
                accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
        axiosInstance
            .get(`${apiBaseUrl}shop/associations?isActive=true&page=1&itemsPerPage=300`)
            .then((response) => {
                const associations = response.data["hydra:member"]
                // .filter(item => item.isActive === undefined || item.isActive);

                const mediaArtsDuFil = trierCollectionParUnivers(
                    associations,
                    "arts-du-fil"
                )
                const mediaArtsCulinaires = trierCollectionParUnivers(
                    associations,
                    "arts-culinaires"
                )
                const mediaArtsPlastiques = trierCollectionParUnivers(
                    associations,
                    "arts-plastiques"
                )

                const mediaArtsDuFilSort = trierCollectionsParMatieres(
                    mediaArtsDuFil,
                    "arts_du_fil"
                )
                const mediaArtsCulinairesSort = trierCollectionsParMatieres(
                    mediaArtsCulinaires,
                    "arts_culinaires"
                )
                const mediaArtsPlastiquesSort = trierCollectionsParMatieres(
                    mediaArtsPlastiques,
                    "arts_plastiques"
                )

                const lastAssociationmediaArtsCulinaires =
                    mediaArtsCulinaires.length <= 3
                        ? mediaArtsCulinaires
                        : [
                              mediaArtsCulinaires[0],
                              mediaArtsCulinaires[1],
                              mediaArtsCulinaires[2],
                          ]

                const lastAssociationmediaArtsDuFil =
                    mediaArtsDuFil.length <= 3
                        ? mediaArtsDuFil
                        : [
                              mediaArtsDuFil[0],
                              mediaArtsDuFil[1],
                              mediaArtsDuFil[2],
                          ]

                const lastAssociationmediaArtsPlastiques =
                    mediaArtsPlastiques.length <= 3
                        ? mediaArtsPlastiques
                        : [
                              mediaArtsPlastiques[0],
                              mediaArtsPlastiques[1],
                              mediaArtsPlastiques[2],
                          ]

                store.dispatch(
                    saveUserMedia(
                        "lastAssociationWatchedmediaArtsDuFil",
                        lastCollectionsWatched(mediaArtsDuFil)
                    )
                )
                store.dispatch(
                    saveUserMedia(
                        "lastAssociationWatchedmediaArtPlastiques",
                        lastCollectionsWatched(mediaArtsPlastiques)
                    )
                )
                store.dispatch(
                    saveUserMedia(
                        "lastAssociationWatchedmediaArtsCulinaires",
                        lastCollectionsWatched(mediaArtsCulinaires)
                    )
                )

                store.dispatch(
                    saveUserMedia(
                        "lastAssociationmediaArtsDuFil",
                        lastAssociationmediaArtsDuFil
                    )
                )
                store.dispatch(
                    saveUserMedia(
                        "lastAssociationmediaArtPlastiques",
                        lastAssociationmediaArtsPlastiques
                    )
                )
                store.dispatch(
                    saveUserMedia(
                        "lastAssociationmediaArtsCulinaires",
                        lastAssociationmediaArtsCulinaires
                    )
                )

                store.dispatch(
                    saveUserMedia("mediaArtsDuFil", mediaArtsDuFilSort)
                )
                store.dispatch(
                    saveUserMedia(
                        "mediaArtsPlastiques",
                        mediaArtsPlastiquesSort
                    )
                )
                store.dispatch(
                    saveUserMedia(
                        "mediaArtsCulinaires",
                        mediaArtsCulinairesSort
                    )
                )
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
            })
            .finally(() => {
                store.dispatch(loadingMedia(false))
            })
    }
    if (action.type === PUT_MODIFY_PASSWORD) {
        if (
            !store.getState().user.errorPatternPassword &&
            !store.getState().user.errorConfirmPassword
        ) {
            axios
                .put(
                    `${apiBaseUrl}shop/customers/${action.value}/password`,
                    {
                        newPassword: store.getState().user.newPassword,
                        confirmNewPassword:
                            store.getState().user.confirmPassword,
                        currentPassword: store.getState().user.currentPassword,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/json, text/plain, */*",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(loadingModifyPassword(false))
                    store.dispatch(
                        changeInputValue(true, "changePasswordConfirm")
                    )
                    store.dispatch(changeInputValue("", "currentPassword"))
                    store.dispatch(changeInputValue("", "newPassword"))
                    store.dispatch(changeInputValue("", "confirmPassword"))
                })
                .catch((error) => {
                    store.dispatch(loadingModifyPassword(false))
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                    if (
                        error.response.data["hydra:description"].includes(
                            "mot de passe"
                        )
                    ) {
                        store.dispatch(
                            changeInputValue(true, "badCurrentPassword")
                        )
                    } else {
                        store.dispatch(
                            changeInputValue(true, "errorModifyPassword")
                        )
                    }
                })
        } else {
            store.dispatch(loadingModifyPassword(false))
        }
    }

    if (action.type === POST_REGISTRATION) {
        const birthDate = new Date(store.getState().auth.birthDateRegistration)
        let newBirthdateFormat = null
        if (birthDate.length > 1) {
            newBirthdateFormat = birthDate.toISOString()
        }
        if (
            !store.getState().auth.errorPatternPasswordRegistration &&
            !store.getState().auth.errorConfirmPasswordRegistration &&
            !store.getState().auth.errorPatternEmailRegistration &&
            store.getState().auth.capchaRegistrationChecked
        ) {
            axios
                .post(
                    `${apiBaseUrl}shop/customers`,
                    {
                        username: store.getState().auth.pseudoRegistration,
                        phoneNumber:
                            store.getState().auth.telephoneRegistration,
                        birthday: newBirthdateFormat,
                        firstName: store.getState().auth.firstNameRegistration,
                        lastName: store.getState().auth.lastNameRegistration,
                        email: store.getState().auth.emailRegistration,
                        password: store.getState().auth.passwordRegistration,
                        subscribedToNewsletter:
                            store.getState().auth.newsletterEmailRegistration,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "*/*",
                        },
                    }
                )
                .then((response) => {
                    axios
                        .post(
                            `${apiBaseUrl}shop/authentication-token`,
                            {
                                email: store.getState().auth.emailRegistration,
                                password:
                                    store.getState().auth.passwordRegistration,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    accept: "application/json",
                                },
                            }
                        )
                        .then((response) => {
                            store.dispatch(changeInputAuthValue("", "password"))
                            store.dispatch(loadingConnexion(false))

                            //Veirfication panier

                            localStorage.setItem("token", response.data.token)
                            localStorage.setItem(
                                "refresh_token",
                                response.data.refresh_token
                            )
                            localStorage.setItem(
                                "customer",
                                response.data.customer
                            )

                            const customerId = response.data.customer

                            store.dispatch(
                                verifyCart(
                                    localStorage.getItem("cart"),
                                    customerId.substring(
                                        customerId.lastIndexOf("/") + 1,
                                        customerId.length
                                    ),
                                    response.data.token
                                )
                            )
                        })
                        .catch((error) => {
                            store.dispatch(loadingRegistration(false))
                            store.dispatch(togglePopupError(true, ""))
                            console.log(error)
                            if (error.response.status === 401) {
                                store.dispatch(refreshToken())
                            }
                        })
                        .finally(() => {
                            store.dispatch(loadingRegistration(false))
                            store.dispatch(
                                changeInputValue(
                                    store.getState().auth.pseudoRegistration,
                                    "pseudo"
                                )
                            )
                            store.dispatch(
                                changeInputAuthValue(
                                    "",
                                    "firstNameRegistration"
                                )
                            )
                            store.dispatch(
                                changeInputAuthValue("", "lastNameRegistration")
                            )
                            store.dispatch(
                                changeInputAuthValue(
                                    "",
                                    "birthDateRegistration"
                                )
                            )
                            store.dispatch(
                                changeInputAuthValue(
                                    "",
                                    "birthDateRegistration"
                                )
                            )
                            store.dispatch(
                                changeInputAuthValue("", "pseudoRegistration")
                            )
                            store.dispatch(
                                changeInputAuthValue(
                                    "",
                                    "telephoneRegistration"
                                )
                            )
                            store.dispatch(
                                changeInputAuthValue("", "passwordRegistration")
                            )
                            store.dispatch(
                                changeInputAuthValue(
                                    "",
                                    "confirmPasswordRegistration"
                                )
                            )
                            store.dispatch(userConnexion(true))
                        })
                })
                .catch((error) => {
                    console.log(error)
                    store.dispatch(loadingRegistration(false))
                    store.dispatch(
                        togglePopupError(
                            true,
                            error.response.data["hydra:description"]
                        )
                    )
                })
        } else {
            store.dispatch(loadingRegistration(false))
        }
    }

    if (action.type === PATCH_SUBSCRIPTIONS) {
        if (action.attribut === "newsletterEmail") {
            axios
                .put(
                    `${apiBaseUrl}shop/customers/${action.value}`,
                    {
                        subscribedToNewsletter:
                            store.getState().user.newsletterEmail,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/ld+json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(loadingUserRequest(false))
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                    console.log(error)
                    store.dispatch(loadingUserRequest(false))
                    store.dispatch(togglePopupError(true, ""))
                })
        } else if (action.attribut === "newsletterSms") {
            axios
                .put(
                    `${apiBaseUrl}shop/customers/${action.value}`,
                    {
                        subscribedToNewsletterSMS:
                            store.getState().user.newsletterSMS,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/ld+json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(loadingUserRequest(false))
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                    console.log(error)
                    store.dispatch(loadingUserRequest(false))
                    store.dispatch(togglePopupError(true, ""))
                })
        } else if (action.attribut === "newsletterPaper") {
            axios
                .put(
                    `${apiBaseUrl}shop/customers/${action.value}`,
                    {
                        subscribedToNewsletterPaper:
                            store.getState().user.newsletterPaper,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/ld+json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(loadingUserRequest(false))
                    store.dispatch(checkSave(true))
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                    console.log(error)
                    store.dispatch(loadingUserRequest(false))
                    store.dispatch(togglePopupError(true, ""))
                })
        } else if (action.attribut === "cookies") {
            axios
                .put(
                    `${apiBaseUrl}shop/customers/${action.value}`,
                    {
                        cookieAccepted:
                            store.getState().user.cookies.lastIndexOf("true") >
                            0
                                ? true
                                : false,
                    },
                    {
                        headers: {
                            "Content-Type": "application/ld+json",
                            accept: "application/ld+json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(loadingUserRequest(false))
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                    console.log(error)
                    store.dispatch(loadingUserRequest(false))
                    store.dispatch(togglePopupError(true, ""))
                })
        }
    }

    if (action.type === PATCH_MY_PERSONAL_INFOS) {
        const birthDate = new Date(store.getState().user.birthDate)
        let newBirthdateFormat = null
        newBirthdateFormat = birthDate.toISOString()
        axios
            .put(
                `${apiBaseUrl}shop/customers/${action.value}`,
                {
                    email: store.getState().user.email,
                    birthday: newBirthdateFormat,
                    gender: store.getState().user.gender,
                    phoneNumber: store.getState().user.telephone,
                    firstName: store.getState().user.firstName,
                    lastName: store.getState().user.lastName,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/ld+json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(loadingUserRequest(false))
                store.dispatch(checkSave(true))
                store.dispatch(saveCommonDataInState("confirmPopupOpen", true))
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                } else if (error.response.status === 422) {
                    store.dispatch(
                        togglePopupError(
                            true,
                            error.response.data["hydra:description"]
                        )
                    )
                } else {
                    store.dispatch(togglePopupError(true, ""))
                }
                store.dispatch(loadingUserRequest(false))
            })
    }

    if (action.type === PATCH_MY_PROFIL_DATA) {
        axios
            .put(
                `${apiBaseUrl}shop/customers/${action.value}`,
                {
                    username: store.getState().user.pseudo,
                    bio: store.getState().user.description,
                    instagram: store.getState().user.instagram,
                    facebook: store.getState().user.facebook,
                    youtube: store.getState().user.youtube,
                    twitch: store.getState().user.twitch,
                    pinterest: store.getState().user.pinterest,
                    website: store.getState().user.website,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/ld+json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(loadingUserRequest(false))
                store.dispatch(checkSave(true))
                store.dispatch(saveCommonDataInState("confirmPopupOpen", true))
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(loadingUserRequest(false))
                if (error.response.status === 422) {
                    store.dispatch(
                        togglePopupError(
                            true,
                            error.response.data["hydra:description"]
                        )
                    )
                }
            })
    }

    if (action.type === DELETE_ADDRESS) {
        axios
            .delete(`${apiBaseUrl}shop/addresses/${action.value}`, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json, text/plain, */*",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((response) => {
                store.dispatch(fetchAddresses())
                store.dispatch(loadingDeleteAddress(false))
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
                store.dispatch(loadingDeleteAddress(false))
                store.dispatch(togglePopupError(true, ""))
            })
    }

    if (action.type === DEFAULT_ADDRESS) {
        axios
            .put(
                `${apiBaseUrl}shop/customers/${action.customer}`,
                {
                    defaultAddress: "/api/v2/shop/addresses/" + action.value,
                },
                {
                    headers: {
                        "Content-Type": "application/ld+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(changeInputValue(action.value, "defaultAddress"))
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(loadingAddAddress(false))

                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
            })
            .finally(() => {
                store.dispatch(changeInputValue(false, "loadingAddAddress"))
            })
    }

    if (action.type === POST_NEW_ADDRESS) {
        if (!store.getState().user.errorPatternTelephoneAddressAdd) {
            axios
                .post(
                    `${apiBaseUrl}shop/addresses`,
                    {
                        firstName: store.getState().user.firstNameAddressAdd,
                        lastName: store.getState().user.lastNameAddressAdd,
                        phoneNumber: store.getState().user.telephoneAddressAdd,
                        street: store.getState().user.streetAddressAdd,
                        company: store.getState().user.societyAddressAdd,
                        city: store.getState().user.cityAddressAdd,
                        postcode: store.getState().user.postCodeAddressAdd,
                        countryCode: store.getState().user.countryAddressAdd,
                        provinceCode: null,
                        provinceName: "",
                        used: false,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            accept: "application/json, text/plain, */*",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                )
                .then((response) => {
                    store.dispatch(changeInputValue("", "lastNameAddressAdd"))
                    store.dispatch(changeInputValue("", "firstNameAddressAdd"))
                    store.dispatch(changeInputValue("", "streetAddressAdd"))
                    store.dispatch(changeInputValue("", "complementAddressAdd"))
                    store.dispatch(changeInputValue("", "societyAddressAdd"))
                    store.dispatch(changeInputValue("", "postCodeAddressAdd"))
                    store.dispatch(changeInputValue("", "cityAddressAdd"))
                    store.dispatch(changeInputValue("FR", "countryAddressAdd"))
                    store.dispatch(changeInputValue("", "telephoneAddressAdd"))

                    if (store.getState().user.defaultAdressAdd) {
                        axios
                            .put(
                                `${apiBaseUrl}shop/customers/${store.getState().user.customerId}`,
                                {
                                    defaultAddress: response.data["@id"],
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/ld+json",
                                        accept: "application/json, text/plain, */*",
                                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                                    },
                                }
                            )
                            .then((reponse) => {
                                store.dispatch(
                                    changeInputValue(false, "defaultAdressAdd")
                                )
                                store.dispatch(
                                    changeInputValue(
                                        Number(
                                            response.data["@id"].match(
                                                /[^/]+$/
                                            )[0]
                                        ),
                                        "defaultAddress"
                                    )
                                )
                                store.dispatch(fetchAddresses())
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    } else {
                        store.dispatch(fetchAddresses())
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        store.dispatch(refreshToken())
                    }
                    store.dispatch(loadingAddAddress(false))
                    store.dispatch(togglePopupError(true, ""))
                    console.log(error)
                })
        } else {
            store.dispatch(loadingAddAddress(false))
        }
    }

    if (action.type === FETCH_COUNTRIES) {
        axios
            .get(`${apiBaseUrl}shop/countries?page=1&itemsPerPage=600`)
            .then((response) => {
                store.dispatch(saveCountries(response.data["hydra:member"]))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if(action.type === MODIFY_DEFAULT_ADDRESS_ORDER) {
        axios
        .put(
            `${apiBaseUrl}shop/addresses/${action.value}`,
            {
                firstName: store.getState().cart.firstNameShipping,
                lastName: store.getState().cart.lastNameShipping,
                phoneNumber: store.getState().cart.telephoneShipping,
                company: store.getState().cart.societyShipping,
                countryCode: store.getState().cart.countryShipping,
                provinceCode: null,
                provinceName: "",
                street: store.getState().cart.streetShipping,
                city: store.getState().cart.cityShipping,
                postcode: store.getState().cart.postCodeShipping,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json, text/plain, */*",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        )
        .then((response) => {
        })
        .catch((error) => {
            console.log(error)

            if (error.response.status === 401) {
                store.dispatch(refreshToken())
            }
        })
    }

    if (action.type === PUT_MODIFY_ADDRESS) {
        axios
            .put(
                `${apiBaseUrl}shop/addresses/${action.value}`,
                {
                    firstName: store.getState().user.firstNameAddressModify,
                    lastName: store.getState().user.lastNameAddressModify,
                    phoneNumber: store.getState().user.telephoneAddressModify,
                    company: store.getState().user.societyAddressModify,
                    countryCode: store.getState().user.countryAddressModify,
                    provinceCode: null,
                    provinceName: "",
                    street: store.getState().user.streetAddressModify,
                    city: store.getState().user.cityAddressModify,
                    postcode: store.getState().user.postCodeAddressModify,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(loadingModifyAddress(false))
                store.dispatch(toggleModifyAddress(false))
                store.dispatch(fetchAddresses())
            })
            .catch((error) => {
                console.log(error)

                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
                store.dispatch(errorModifyAddress(true))
                store.dispatch(loadingModifyAddress(false))
            })
    }

    if (action.type === POST_CONNEXION) {
        axios
            .post(
                `${apiBaseUrl}shop/authentication-token`,
                {
                    email: action.email,
                    password: action.password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                }
            )
            .then((response) => {
                store.dispatch(changeInputAuthValue("", "password"))

                store.dispatch(loadingConnexion(false))
                if (action.stayConnected) {
                    localStorage.setItem("token", response.data.token)
                    localStorage.setItem("customer", response.data.customer)
                    localStorage.setItem(
                        "refresh_token",
                        response.data.refresh_token
                    )
                }
                const customerId = response.data.customer.match(/[^/]+$/)[0]

                console.log("je lance veiry_cart apres post connexion")
                store.dispatch(
                    verifyCart(
                        localStorage.getItem("cart"),
                        Number(customerId),
                        response.data.token
                    )
                )
                store.dispatch(userConnexion(true))
            })
            .catch((error) => {
                store.dispatch(loadingConnexion(false))
                store.dispatch(errorConnexion(true))
                console.log(error)
            })
    }

    if (action.type === FETCH_GIFTCARDS) {
        axios
            .get(`${apiBaseUrl}shop/gift-cards?page=1&itemsPerPage=30`, {
                headers: {
                    Authorization: "Bearer " + userToken,
                },
            })
            .then((response) => {
                if (action.orderGiftcards !== undefined) {
                    if (
                        action.orderGiftcards.length > 0 &&
                        response.data["hydra:member"].length > 0
                    ) {
                        const giftcardFind = filterGiftcards(
                            response.data["hydra:member"]
                        ).findIndex(
                            (item) =>
                                item.code === action.orderGiftcards[0].code
                        )

                        if (giftcardFind !== -1) {
                            store.dispatch(
                                changeInputOrder(
                                    giftcardFind + 1,
                                    "giftCardSelected"
                                )
                            )
                        } else {
                            store.dispatch(
                                changeInputOrder(0, "giftCardSelected")
                            )
                        }
                    } else {
                        store.dispatch(changeInputOrder(0, "giftCardSelected"))
                    }
                }

                store.dispatch(
                    changeInputValue(response.data["hydra:member"], "giftcards")
                )
            })
            .finally(() => {
                store.dispatch(changeInputValue(false, "loadingGiftcards"))
            })
    }

    if (action.type === DELETE_GIFT_CARD_CART) {
        axios
            .patch(
                `${apiBaseUrl}shop/gift-cards/${action.code}/remove-from-order`,
                {
                    orderTokenValue: action.orderToken,
                },
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(getCartConnected())
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {})
    }

    if (action.type === ADD_GIFT_CARD_ACCOUNT) {
        axios
            .patch(
                `${apiBaseUrl}shop/gift-cards/${action.code}/add-to-account`,
                {},
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(changeInputValue("", "giftcardCode"))
                store.dispatch(fetchGiftcards())
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(changeInputValue(false, "loadingGiftcards"))
                if (error.response.status === 404) {
                    store.dispatch(
                        togglePopupError(true, "Ce code n'existe pas")
                    )
                }
            })
            .finally(() => {
                // store.dispatch(changeInputValue(false, 'loadingGiftcards'));
            })
    }

    if (action.type === POST_GIFT_CARD_CART) {
        axios
            .patch(
                `${apiBaseUrl}shop/gift-cards/${action.code}/add-to-order`,
                {
                    orderTokenValue: action.orderToken,
                },
                {
                    headers: {
                        "Content-Type": "application/merge-patch+json",
                        accept: "application/json, text/plain, */*",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((response) => {
                store.dispatch(getCartConnected())
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {})
    }
    if (action.type === FETCH_PDF_GIFTCARD) {
        axios
            .get(`${apiBaseUrl}shop/gift-cards/${action.code}/pdf`, {
                responseType: "blob",
            })
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: "application/pdf",
                })
                const url = window.URL.createObjectURL(blob)

                // Ouvrir une nouvelle fenêtre avec l'URL du fichier
                window.open(url, "_blank")
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(changeInputValue("", "loadingGiftcardsPdf"))
            })
    }
    if (action.type === FETCH_ORDER_ITEMS) {
        axios
            .get(
                `${apiBaseUrl}shop/orders/${action.value}/items?page=1&itemsPerPage=600`, {
                    headers: {
                        Authorization: "Bearer " + userToken,
            }})
            .then((response) => {
                store.dispatch(saveOrderItems(response.data["hydra:member"]))
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(loadingOrderItems(false))
            })
    }

    if (action.type === FETCH_USER_DATA) {
        axios
            .get(`${apiBaseUrl}${action.value.substring(8)}`, {
                headers: {
                    Authorization: "Bearer " + action.token,
                },
            })
            .then((response) => {
                const userData = response.data
                store.dispatch(changeInputValue(response.data.id, "userId"))
                store.dispatch(
                    changeInputValue(action.value.substring(23), "customerId")
                )
                store.dispatch(
                    changeInputValue(
                        userData.bio === undefined || userData.bio === null
                            ? ""
                            : userData.bio,
                        "description"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.birthday !== null
                            ? userData.birthday.slice(0, 10)
                            : "",
                        "birthDate"
                    )
                )
                store.dispatch(
                    changeInputValue(userData.firstName, "firstName")
                )
                store.dispatch(changeInputValue(userData.lastName, "lastName"))
                store.dispatch(changeInputValue(userData.gender, "gender"))
                store.dispatch(changeInputValue(userData.email, "email"))
                store.dispatch(
                    changeInputValue(
                        userData.phoneNumber === null
                            ? ""
                            : userData.phoneNumber,
                        "telephone"
                    )
                )
                store.dispatch(
                    changeInputValue(userData.url_original, "userImageUrl")
                )
                store.dispatch(changeInputValue(null, "userImageFile"))
                store.dispatch(changeInputValue(userData.username, "pseudo"))
                store.dispatch(
                    changeInputValue(
                        userData.subscribedToNewsletter,
                        "newsletterEmail"
                    )
                )
                store.dispatch(changeInputValue(userData.website, "website"))
                store.dispatch(
                    changeInputValue(
                        userData.subscribedToNewsletterSMS,
                        "newsletterSMS"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.subscribedToNewsletterPaper,
                        "newsletterPaper"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.defaultAddress !== null
                            ? userData.defaultAddress.id
                            : "",
                        "defaultAddress"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.instagram === null ? "" : userData.instagram,
                        "instagram"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.facebook === null ? "" : userData.facebook,
                        "facebook"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.youtube === null ? "" : userData.youtube,
                        "youtube"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.twitch === null ? "" : userData.twitch,
                        "twitch"
                    )
                )
                store.dispatch(
                    changeInputValue(
                        userData.pinterest === null ? "" : userData.pinterest,
                        "pinterest"
                    )
                )

                axios
                    .get(
                        `${apiBaseUrl}shop/orders?order%5BcreatedAt%5D=desc&page=1&itemsPerPage=600`,
                        // &paymentState=paid
                        {
                            headers: {
                                Authorization: "Bearer " + action.token,
                            },
                        }
                    )

                    .then((firstResponse) => {
                        store.dispatch(
                            saveOrders(firstResponse.data["hydra:member"])
                        )
                    })
                    .catch((error) => {
                        console.log(error)
                        if (error.response.status === 401) {
                            store.dispatch(refreshToken())
                        }
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
            })
    }

    if (action.type === FETCH_ADDRESSES) {
        axios
            .get(`${apiBaseUrl}shop/addresses?page=1&itemsPerPage=3000`, {
                headers: {
                    Authorization: "Bearer " + userToken,
                },
            })
            .then((response) => {

                const defaultAddress = store.getState().user.defaultAddress;
                const addresses = response.data["hydra:member"].filter(item => item.createdAt === undefined || item.createdAt > "2024-07-05 00:00:00" || item.id === defaultAddress);
                store.dispatch(
                    changeInputValue(addresses, "addresses")
                )

                if(action.context === "delivery" ) {
                    cartProcessor(store, store.getState().cart.cartData, "delivery", addresses)
                }
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status === 401) {
                    store.dispatch(refreshToken())
                }
            })
            .finally(() => {
                store.dispatch(changeInputValue(false, "loadingAddAddress"))
            })
    }

    if (action.type === FETCH_ALL_PROFESSORS) {
        axios
            .get(
                `${apiBaseUrl}shop/universe?&groups[]=shop:universe:details_all_teachers`
            )
            .then((response) => {
                let teachers = []
                response.data["hydra:member"].forEach((univers) => {
                    univers.teachers.forEach((teacher) => {
                        if (!teachers.find((item) => item.id === teacher.id)) {
                            teacher["univers"] = univers.code
                            teachers.push(teacher)
                        }
                    })
                })

                store.dispatch(saveAllProfessors(teachers))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (action.type === FETCH_PROFESSEUR_PUBLIC_DATA) {
        axios
            .get(`${apiBaseUrl}shop/teachers/${action.value}`)
            .then((response) => {
                const data = response.data
                axios
                    .get(
                        `${apiBaseUrl}shop/products-catalogue?author.code=${action.value}&enabled=true`
                    )
                    .then((response) => {
                        store.dispatch(
                            saveProfesseurPublicData(
                                data,
                                response.data["hydra:member"]
                            )
                        )
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (action.type === FETCH_PROFESSEUR_CATALOGUE_DATA) {
        axios
            .get(`${apiBaseUrl}shop/universe/${action.value}/teachers`)
            .then((response) => {
                store.dispatch(saveProfesseurCatalogueData(response.data))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (action.type === FETCH_CART_ASSOCIATION) {

        let productsAssociations = [];

        for (let item of action.items) {
            // Fusionner les tableaux en utilisant la méthode concat
            productsAssociations = productsAssociations.concat(item.product.associations);
        }

        axios
        .all(
            productsAssociations.map((item) => {
                return axios.get(
                    `${apiBaseUrl}shop/product-associations/${item.match(/[^/]+$/)[0]}`
                )
            })
        )
        .then(
            axios.spread((...responses) => {
                const associations = responses.filter(
                    (item) =>
                        item.data.type ===
                        "/api/v2/shop/product-association-types/vous-aimerez-aussi"
                )


                let associatedProducts = [];
        
                for (let item of responses) {
                    // Fusionner les tableaux en utilisant la méthode concat
                    associatedProducts = associatedProducts.concat(item.data.associatedProducts);
                }
               store.dispatch( 
                saveCartAssociation(
                    associatedProducts
                            ? associatedProducts.filter(item => item.enabled === undefined || item.enabled)
                            : []
                    )
                )

                store.dispatch(changeInputOrder(false, "loadingAssociationProduct"))

            })
        )
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
            store.dispatch(
                saveProductDataState(
                    "loadingAssociationsProduct",
                    false
                )
            )
        })
    }

    if (action.type === FETCH_PRODUCT_DATA) {
        axios
            .get(`${apiBaseUrl}shop/products-by-slug/${action.value}`)
            .then((response) => {
                if (
                    response.data.universes.find(
                        (item) => item.code === action.univers
                    ) 
                ) {
                    store.dispatch(saveProductData(response.data))

                    SeoCreator(
                        response.data.metaTitle,
                        response.data.name,
                        response.data.metaDescription,
                        response.data.metaKeywords,
                        window.location.pathname
                    )
                } else if(action.univers === undefined) {
                    store.dispatch(saveProductData(response.data))

                    SeoCreator(
                        response.data.metaTitle,
                        response.data.name,
                        response.data.metaDescription,
                        response.data.metaKeywords,
                        window.location.pathname
                    )
                } else {
                    store.dispatch(
                        saveProductDataState("notFoundProduct", true)
                    )
                }



                axios
                    .all(
                        response.data.associations.map((item) => {
                            return axios.get(
                                `${apiBaseUrl}shop/product-associations/${item.match(/[^/]+$/)[0]}`
                            )
                        })
                    )
                    .then(
                        axios.spread((...responses) => {
                            const associations = responses.find(
                                (item) =>
                                    item.data.type ===
                                    "/api/v2/shop/product-association-types/vous-aimerez-aussi"
                            )
                            store.dispatch(
                                saveProductDataState(
                                    "associationsProduct",
                                    associations
                                        ? associations.data.associatedProducts.filter(item => item.enabled === undefined || item.enabled)
                                        : []
                                )
                            )
                        })
                    )
                    .catch((error) => {
                        console.error(error)
                    })
                    .finally(() => {
                        store.dispatch(
                            saveProductDataState(
                                "loadingAssociationsProduct",
                                false
                            )
                        )
                    })
            })
            .catch((error) => {
                console.log(error)

                if (error.response.status === 404) {
                    store.dispatch(
                        saveProductDataState("notFoundProduct", true)
                    )
                }
            })
            .finally(() => {
                store.dispatch(isLoadingProduct(false))
            })
    }

    if (action.type === FETCH_DATA_BY_UNIVERSE_AND_PAGE) {
        //const priceValuesSlider = store.getState().catalogue.priceValue
        axios
            .get(`${apiBaseUrl}shop/universe/${action.universe}/${action.page}`)
            .then((response) => {
                if (action.page === "home") {
                    const data = response.data
                    const topslider = data.sliders.find(
                        (element) => element.code === action.universe
                    )

                    const highlightProductsSlider = data.sliders.find(
                        (element) =>
                            element.code ===
                            "slider-produits-mis-en-avant-" + action.universe
                    )
                    const highlightTeachersSlider = data.sliders.find(
                        (element) =>
                            element.code ===
                            "slider-professeurs-mis-en-avant-" + action.universe
                    )
                    store.dispatch(
                        saveTopSliderItems(topslider ? topslider : [])
                    )
                    store.dispatch(
                        saveLessonsSlider(
                            highlightProductsSlider
                                ? highlightProductsSlider
                                : []
                        )
                    )

                    store.dispatch(
                        saveProfessorsHighlight(
                            highlightTeachersSlider !== undefined
                                ? highlightTeachersSlider
                                : []
                        )
                    )
                } else if (action.page === "mega-menu") {
                    const data = response.data
                    store.dispatch(
                        saveMegaMenu(
                            data.mega_menu ? data.mega_menu.children : []
                        )
                    )
                    store.dispatch(
                        saveHomeDataInState(
                            "taxonCatalogue",
                            data.catalogue_taxon
                                ? data.catalogue_taxon.children
                                : []
                        )
                    )
                    store.dispatch(
                        savePagelist(
                            data.pagelist ? data.pagelist.children : []
                        )
                    )
                    store.dispatch(isLoadingMegaMenu(false))
                    store.dispatch(
                        saveHomeDataInState("loadingTaxonCatalogue", false)
                    )
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                store.dispatch(
                    saveCommonDataInState("loadingDataByUniverse", false)
                )
            })
    }

    if (action.type === FETCH_FAQS) {
        axios
            .get(`${apiBaseUrl}shop/faq`)
            .then((response) => {
                const faqsData = response.data["hydra:member"]
                store.dispatch(saveHomeDataInState("faqsData", faqsData))
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (action.type === FETCH_LESSONS) {
        const priceValuesSlider = store.getState().catalogue.priceValue

        const params = new URLSearchParams({
            "order[createdAt]": "desc",
            "universes.code": action.value,
            enabled: true,
            page: 1,
            itemsPerPage: 1000,
        })

        axios
            .get(`${apiBaseUrl}shop/products-catalogue?${params.toString()}`)

            .then((response) => {
                const products = response.data["hydra:member"]
                store.dispatch(saveTeachersFilters(productsToTeacher(products)))

                axios
                    .get(`${apiBaseUrl}shop/universe/${action.value}/filters`)
                    .then((response) => {
                        const taxons = response.data
                        const allFilters =
                            taxons.filters_common !== null ? taxons.filters_common.children.concat(
                                taxons.filters_taxon.children
                            ) : taxons.filters_taxon.children

                        store.dispatch(saveFilters(allFilters))
                        if (action.filters !== "tout") {
                            axios
                                .get(
                                    `${apiBaseUrl}shop/taxons/${action.filters}`
                                )
                                .then((response) => {
                                    const menuToCheckFiltersResult =
                                        menuToCheckFilters(
                                            response.data,
                                            action.filters,
                                            allFilters
                                        )
                                    SeoCreator(
                                        response.data.metaTitle,
                                        "Notre catalogue " + response.data.name,
                                        null,
                                        null,
                                        window.location.pathname
                                    )
                                    store.dispatch(
                                        saveCatalogueH1(
                                            response.data.h1,
                                            response.data.name
                                        )
                                    )

                                    store.dispatch(
                                        saveCatalogueLessonsInState(
                                            filterProduct(
                                                menuToCheckFiltersResult.newfilters,
                                                addBestSalesBool(
                                                    products,
                                                    action.value
                                                ),
                                                priceValuesSlider,
                                                "PlusRecent",
                                                menuToCheckFiltersResult.productTaxons
                                            ),
                                            response.data["hydra:totalItems"],
                                            //Si pas de taxons lié, alors tous les produits = produits appartenant au taxon
                                            response.data.categories.length ===
                                                0
                                                ? dePaginator(
                                                      filterProduct(
                                                          [],
                                                          addBestSalesBool(
                                                              products,
                                                              action.value
                                                          ),
                                                          priceValuesSlider,
                                                          "PlusRecent",
                                                          menuToCheckFiltersResult.productTaxons
                                                      )
                                                  )
                                                : products
                                        )
                                    )
                                    store.dispatch(
                                        saveCategoriesMenu(
                                            menuToCheckFiltersResult.categoriesMenu,
                                            menuToCheckFiltersResult.description,
                                            menuToCheckFiltersResult.title
                                        )
                                    )
                                    store.dispatch(
                                        addFiltersCheckbox(
                                            menuToCheckFiltersResult.newfilters,
                                            menuToCheckFiltersResult.newName
                                        )
                                    )

                                    store.dispatch(toggleLoading(false))
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                                .finally(() => {})
                        } else {
                            store.dispatch(addFiltersCheckbox([], []))
                            SeoCreator(
                                action.value === "arts-du-fil" ? 'Cours vidéo arts du fil - couture - broderie - tricot - tissage - crochet à cours vidéo - Artesane' : action.value === 'arts-culinaires' ? 'Cours vidéo de cuisine et pâtisserie - Arts culinaires - Artesane' : 'Cours vidéo de peinture et dessin- promotions cours vidéo arts plastiques - Artesane',
                                "Notre catalogue " + response.data.name,
                                null,
                                null,
                                window.location.pathname
                            )
                            store.dispatch(
                                saveCatalogueLessonsInState(
                                    filterProduct(
                                        [],
                                        addBestSalesBool(
                                            products,
                                            action.value
                                        ),
                                        priceValuesSlider,
                                        "PlusRecent",
                                        []
                                    ),
                                    response.data["hydra:totalItems"],
                                    products
                                )
                            )

                            store.dispatch(toggleLoading(false))
                        }
                    })

                    .catch((error) => {
                        console.log(error)
                        store.dispatch(toggleLoading(false))
                    })
            })
            .catch((error) => {
                console.log(error)
                store.dispatch(toggleLoading(false))
            })
            .finally(() => {})
    }

    next(action)
}

export default apiMiddleware
