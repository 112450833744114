import {
    saveUserMedia,
    toggleChapterOpen,
} from "../../../../actions/mediaAction"
import { useDispatch, useSelector } from "react-redux"

import "./ChapterMenu.scss"

const ChapterMenu = ({ playerRef, chapters, courseTitle }) => {
    const dispatch = useDispatch()
    const isOpenChapters = useSelector((state) => state.media.chapterOpen)
    const light = useSelector((state) => state.media.light)

    return (
        <div className="chapter-menu">
            <span
                onClick={() => {
                    dispatch(toggleChapterOpen(!isOpenChapters))
                }}
                className="cross">
                ✖
            </span>
            <div className="cours-title">{courseTitle}</div>
            <div className="chapters-items">
                {chapters.map(
                    (
                        { id, title, level, timeInVideo, getSecondsInVideo },
                        index
                    ) => {
                        if (level === 1) {
                            return (
                                <div
                                    onClick={() => {
                                        if (light) {
                                            dispatch(
                                                saveUserMedia("light", false)
                                            )
                                        }
                                        playerRef.current.seekTo(
                                            getSecondsInVideo,
                                            "seconds"
                                        )
                                    }}
                                    key={id}
                                    className="chapter-item-lecon">
                                    <img
                                        className="player-icon"
                                        src={
                                            "https://static.artesane.com/images_react/player.png"
                                        }
                                        alt="player"
                                    />
                                    {title}

                                    <span className="time">
                                        {timeInVideo.slice(2)}
                                    </span>
                                </div>
                            )
                        } else if (level === 2) {
                            return (
                                <div
                                    onClick={() => {
                                        if (light) {
                                            dispatch(
                                                saveUserMedia("light", false)
                                            )
                                        }
                                        playerRef.current.seekTo(
                                            getSecondsInVideo,
                                            "seconds"
                                        )
                                    }}
                                    key={id}
                                    className="chapter-item-section">
                                    <img
                                        className="player-icon"
                                        src={
                                            "https://static.artesane.com/images_react/player.png"
                                        }
                                        alt=""
                                    />
                                    {title}

                                    <span className="time">
                                        {timeInVideo.slice(2)}
                                    </span>
                                </div>
                            )
                        } else {
                            return null
                        }
                    }
                )}
            </div>
        </div>
    )
}

export default ChapterMenu
