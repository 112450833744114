import { useDispatch, useSelector } from "react-redux"
import { countQuantity } from "../../../../../selectors/selectors"
import {
    addOrderItemConnected,
    createCartDisconnected,
    loadingAddCart,
    toggleVariantsChoice,
} from "../../../../../actions/cartAction"

//Styles
import "./AddCartAndWishlist.scss"
import { useNavigate } from "react-router-dom"
import {
    wishlistItem,
    putWishlistItem,
    deleteWishlistItem,
} from "../../../../../actions/userAction"

const AddCartAndWishlist = ({
    productId,
    buyOnce,
    wishlist,
    variants,
    variantsNotInStock,
    giftCardAmountConfigurable,
}) => {
    const dispatch = useDispatch()
    const userConnected = useSelector((state) => state.auth.userConnected)
    const customerId = useSelector((state) => state.user.customerId)
    const userId = useSelector((state) => state.user.userId)
    const wishlistData = useSelector((state) => state.user.wishList)
    const cartData = useSelector((state) => state.cart.cartData)
    const navigate = useNavigate()

    const productStillAdd = () => {
        if (cartData.items !== undefined) {
            if (cartData.items.length > 0) {
                return cartData.items.find(
                    (element) => element.variant === variants[0]["@id"]
                )
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <div className="addCardAndWishlist-selection-icon-wrapper">
            {!userConnected && (
                <img
                    onClick={() => {
                        dispatch(wishlistItem([variants[0].product_id]))
                        return navigate("/connexion")
                    }}
                    className="addCardAndWishlist-selection-icon heart"
                    src={
                        wishlist
                            ? "https://static.artesane.com/images_react/full_heart.png"
                            : "https://static.artesane.com/images_react/empty_heart.png"
                    }
                    alt="coeur"
                />
            )}
            {userConnected && (
                <img
                    onClick={() => {
                        if (wishlist) {
                            if(variants.length> 0 ) {
                                dispatch(loadingAddCart(variants[0].code))

                            }
                            dispatch(
                                deleteWishlistItem(
                                    wishlistData.token,
                                    productId
                                )
                            )
                        } else {
                            dispatch(loadingAddCart(variants[0].code))
                            dispatch(putWishlistItem(userId, productId))
                        }
                    }}
                    className="addCardAndWishlist-selection-icon heart"
                    src={
                        wishlist
                            ? "https://static.artesane.com/images_react/full_heart.png"
                            : "https://static.artesane.com/images_react/empty_heart.png"
                    }
                    alt="coeur"
                />
            )}

            {variants.length > variantsNotInStock.length &&
                !productStillAdd() && (
                    <img
                        onClick={() => {
                            if (
                                variants.length === 1 &&
                                !giftCardAmountConfigurable
                            ) {
                                dispatch(loadingAddCart(variants[0].code))
                                if (userConnected) {
                                    dispatch(
                                        addOrderItemConnected(
                                            false,
                                            giftCardAmountConfigurable,
                                            0,
                                            customerId,
                                            variants[0].id,
                                            variants[0].code,
                                            countQuantity(
                                                buyOnce,
                                                cartData,
                                                variants[0].code
                                            )
                                        )
                                    )
                                } else {
                                    dispatch(
                                        createCartDisconnected(
                                            false,
                                            giftCardAmountConfigurable,
                                            0,
                                            variants[0].product_id,
                                            variants[0].id,
                                            variants[0].code,
                                            1
                                        )
                                    )
                                }
                            } else if (
                                variants.length > 1 ||
                                giftCardAmountConfigurable
                            ) {
                                dispatch(toggleVariantsChoice(productId))
                            }
                        }}
                        className="addCardAndWishlist-selection-icon cart"
                        src={
                            "https://static.artesane.com/images_react/cart.png"
                        }
                        alt="panier"
                    />
                )}

            {variants.length > variantsNotInStock.length &&
                productStillAdd() && (
                    <img
                        className="addCardAndWishlist-selection-icon cart"
                        src={
                            "https://static.artesane.com/images_react/panier_still_add.png"
                        }
                        alt="panier plein"
                    />
                )}
        </div>
    )
}

export default AddCartAndWishlist
