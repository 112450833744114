//Styles
import "./Materiel.scss"

const Materiel = ({ productData }) => {
    function createMarkup() {
        return {
            __html: productData.attributes.find(
                (item) => item.code === "il-faut-que-vous-vous-procuriez"
            ).value,
        }
    }

    if (
        productData.attributes.find(
            (item) => item.code === "il-faut-que-vous-vous-procuriez"
        )
    ) {
        return (
            <div className="materiel-wrapper">
                <div className="accordion-under-title">Matériel</div>
                {/* {materiel.map((item, index) => (
                
            ))} */}
                <div
                    dangerouslySetInnerHTML={createMarkup()}
                    className="materiel-item"></div>
            </div>
        )
    }
}

export default Materiel
