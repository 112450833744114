//Styles
import "./Reinsurance.scss"

const Reinsurance = ({
    logo,
    altImg,
    firstPartOfPhrase,
    secondPartOfPhrase,
    styledColor,
}) => (
    <div className="reinssurance-items">
        <img className="pictogram" src={logo} alt={altImg} />
        <h3>
            <div className={`catchPhrase ${styledColor}`}>
                {" "}
                <div className="catchPhrase-start">
                    {" "}
                    {firstPartOfPhrase}{" "}
                </div>{" "}
                <div>{secondPartOfPhrase} </div>
            </div>
        </h3>
    </div>
)

export default Reinsurance
