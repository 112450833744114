//Styles
import "./Highlights.scss"

const Highlights = ({ productData }) => {
    // const productData = useSelector((state) => state.products.productData);
    return (
        <div className="highlights-wraper">
            {productData.attributes.find(
                (item) => item.code === "highlight1"
            ) !== undefined && (
                <div
                    key={
                        productData.attributes.find(
                            (item) => item.code === "highlight1"
                        ).id
                    }
                    className="highlights-item">
                    <img
                        src={
                            "https://static.artesane.com/images_react/picto-prepage3.png"
                        }
                        alt="pictogramme d'une tablette numérique"
                    />
                    <span className="hightlight-texte">
                        {
                            productData.attributes.find(
                                (item) => item.code === "highlight1"
                            ).value
                        }
                    </span>
                </div>
            )}

            {productData.attributes.find(
                (item) => item.code === "highlight2"
            ) !== undefined && (
                <div
                    key={
                        productData.attributes.find(
                            (item) => item.code === "highlight2"
                        ).id
                    }
                    className="highlights-item">
                    <img
                        src={
                            "https://static.artesane.com/images_react/picto-prepage3.png"
                        }
                        alt="pictogramme d'une tablette numérique"
                    />
                    <h2 className="hightlight-texte">
                        {
                            productData.attributes.find(
                                (item) => item.code === "highlight2"
                            ).value
                        }
                    </h2>
                </div>
            )}

            {/* <div key={"un accès au chat"} className="highlights-item">
                    <img src={Picto} alt="pictogramme d'une tablette numérique" />
                    <span className='hightlight-texte'>Un accès au chat</span>
                </div> */}
        </div>
    )
}

export default Highlights
