import { NavLink } from "react-router-dom"
import "./MediaMenu.scss"

const MediaMenu = () => {
    return (
        <div className="mediamenu-wrapper">
            <NavLink className="mediamenu-item" to={"arts-culinaires"}>
                <img
                    className="mediamenu-img "
                    src={"https://static.artesane.com/images_react/cuisine.png"}
                    alt="arts culinaires"
                />
                <div className="media-menu-title"> Arts culinaires</div>
            </NavLink>
            <NavLink className="mediamenu-item" to={"arts-du-fil"}>
                <img
                    className="mediamenu-img"
                    src={"https://static.artesane.com/images_react/couture.png"}
                    alt="arts du fil"
                />
                <div className="media-menu-title"> Arts du fil</div>
            </NavLink>
            <NavLink className="mediamenu-item" to={"arts-plastiques"}>
                <img
                    className="mediamenu-img"
                    src={
                        "https://static.artesane.com/images_react/peinture.png"
                    }
                    alt="arts plastiques"
                />
                <div className="media-menu-title"> Arts plastiques</div>
            </NavLink>
        </div>
    )
}
export default MediaMenu
