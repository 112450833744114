import "./Associations.scss"
import { useDispatch, useSelector } from "react-redux"
import { selectHomeUniversActifSlug } from "../../../selectors/selectors"
import LessonCardSelection from "../../Commons/Cards/LessonCardSelection/LessonCardSelection"
import AliceCarousel from "react-alice-carousel"
import { useEffect } from "react"
import { changeInputOrder, fetchCartAssociation } from "../../../actions/cartAction"
import Spinner from "../../Commons/Spinner/Spinner"

const Associations = () => {


    const association = useSelector((state) => state.cart.cartAssociation)
    const loadingAssociationProduct = useSelector((state) => state.cart.loadingAssociationProduct)


    return (
        <>
        {!loadingAssociationProduct && association.length > 0 && (
            <div className="association-wrapper">
            <div className="association-title">Ce qui pourrait vous intéresser</div>
            <div
                className={`caroussel-association-wrapper${association.length < 3 ? " flex" : ""}`}>
                {association.length < 3 &&
                        association.map(
                            ({
                                id,
                                mainImage,
                                name,
                                author,
                                duree,
                                niveau,
                                universes,
                                isNew,
                                pack,
                                parcours,
                                coffret,
                                variants,
                                buy_once,
                                slug,
                                giftCard,
                                giftCardAmountConfigurable,
                            }) => (
                                <div className="recommendation-slider-card-wrapper">
                                    <LessonCardSelection
                                        key={id}
                                        productId={id}
                                        variants={variants}
                                        urlImg={
                                            mainImage !== null
                                                ? mainImage.thumbnails.carousel
                                                : ""
                                        }
                                        pack={pack}
                                        coffret={coffret}
                                        parcours={parcours}
                                        newer={isNew}
                                        title={name}
                                        professor={
                                            author !== null ? author.name : ""
                                        }
                                        time={duree}
                                        level={niveau}
                                        price={variants.length > 0 ? variants[0].price / 100 : null}
                                        originalPrice={
                                          variants.length > 0 ?  variants[0].originalPrice / 100 : null
                                        }
                                        reduction={
                                            variants.length > 0 ? 
                                            (variants[0].originalPrice !==
                                            variants[0].price
                                                ? Math.floor(
                                                      ((variants[0]
                                                          .originalPrice -
                                                          variants[0].price) /
                                                          variants[0]
                                                              .originalPrice) *
                                                      100
                                                  )
                                                : 0)
                                                : 
                                                0
                                        }
                                         currentUnivers={
                                                    universes.length > 0 ? universes[0].code : 'undefined'
                                                }
                                        productSlug={slug}
                                        buyOnce={buy_once}
                                        giftCard={giftCard}
                                        giftCardAmountConfigurable={
                                            giftCardAmountConfigurable
                                        }
                                        alt={
                                            mainImage !== null &&
                                            mainImage !== undefined
                                                ? mainImage.alt
                                                : ""
                                        }
                                    />
                                </div>
                            )
                        )}
                {association.length >= 3 && (
                    <AliceCarousel
                        infinite
                        // animationDuration={1000}
                        // autoPlayInterval={1000}
                        items={association.map(
                            ({
                                id,
                                mainImage,
                                name,
                                author,
                                niveau,
                                duree,
                                universes,
                                isNew,
                                pack,
                                parcours,
                                coffret,
                                variants,
                                buy_once,
                                slug,
                                giftCard,
                                giftCardAmountConfigurable,
                            }) => {
                                return (
                                    <div className="recommendation-slider-card-wrapper">
                                        <LessonCardSelection
                                            key={id}
                                            productId={id}
                                            variants={variants}
                                            urlImg={
                                                mainImage !== null &&
                                                mainImage !== undefined
                                                    ? mainImage.url
                                                    : ""
                                            }
                                            pack={pack}
                                            coffret={coffret}
                                            parcours={parcours}
                                            newer={isNew}
                                            title={name}
                                            professor={
                                                author !== null
                                                    ? author.name
                                                    : ""
                                            }
                                            time={duree !== undefined ? duree : ""}
                                            level={niveau !== undefined ? niveau : ""}
                                            price={variants.length > 0 ? variants[0].price / 100 : null}
                                            originalPrice={
                                                variants.length > 0 ? 
                                                (variants[0].originalPrice /
                                                100)
                                                 : null
                                            }
                                            reduction={
                                                variants.length > 0 ? 
                                                (variants[0]
                                                    .originalPrice !==
                                                variants[0].price
                                                    ? Math.floor(
                                                          ((variants[0]
                                                              .originalPrice -
                                                              variants[0]
                                                                  .price) /
                                                              variants[0]
                                                                  .originalPrice) *
                                                          100
                                                      )
                                                    : 0)
                                                    : 0
                                            }
                                            currentUnivers={
                                                universes.length > 0 ? universes[0].code : 'undefined'
                                            }
                                            productSlug={slug}
                                            buyOnce={buy_once}
                                            giftCard={giftCard}
                                            giftCardAmountConfigurable={
                                                giftCardAmountConfigurable
                                            }
                                            alt={
                                                mainImage !== null &&
                                                mainImage !== undefined
                                                    ? mainImage.alt
                                                    : ""
                                            }
                                        />
                                    </div>
                                )
                            }
                        )}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 2,
                            },
                            1024: {
                                items: 3,
                            },
                        }}
                    />
                )}
            </div>
            </div>
        )}

    </>
    )

}

export default Associations
