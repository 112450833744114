import { useEffect } from "react"
import "./GiftCard.scss"
import { useDispatch, useSelector } from "react-redux"
import {
    addGiftCardAccount,
    changeInputValue,
    fetchGiftcards,
    fetchPdfGiftcard,
} from "../../../actions/userAction"
import Spinner from "../../Commons/Spinner/Spinner"
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined"
import InputSimple from "../InputSimple/inputSimple"
import ErrorPopup from "../ErrorPopup/ErrorPopup"

const GiftCard = () => {
    const dispatch = useDispatch()
    const loadingGiftCards = useSelector((state) => state.user.loadingGiftcards)
    const giftcards = useSelector((state) => state.user.giftcards)
    const loadingGiftcardsPdf = useSelector(
        (state) => state.user.loadingGiftcardsPdf
    )
    const giftcardCode = useSelector((state) => state.user.giftcardCode)

    const errorGiftcard = useSelector((state) => state.user.errorRequest)

    useEffect(() => {
        dispatch(fetchGiftcards())
    }, [dispatch])

    return (
        <div className="gift-card-wrapper">
            {errorGiftcard && <ErrorPopup />}
            {loadingGiftCards && <Spinner />}
            {!loadingGiftCards && giftcards.length === 0 && (
                <div className="empty-gift-cards">
                    <div>
                        Vous n'avez pas encore de cartes cadeaux enregistrées{" "}
                    </div>
                    <div>
                        Pour profiter d'une carte cadeau et pouvoir l'utiliser,
                        il vous suffit de renseigner le code de votre carte
                        cadeau dans le formulaire ci-dessous. Une fois
                        enregistrée sur votre compte, vous pourrez la
                        sélectionner à l'aide d'un menu-déroulant directement
                        dans votre panier et ainsi bénéficier du montant de
                        votre carte cadeau. Si le montant de votre carte cadeau
                        est supérieur au montant de votre commande, vous pourrez
                        utiliser la différence quand vous le souhaitez pour un
                        prochain achat.
                    </div>
                </div>
            )}
            {!loadingGiftCards && (
                <>
                    <div className="gift-card-input-wrapper">
                        <div className="giftcard-input-title">
                            Ajouter une carte cadeau à mon espace
                        </div>
                        <div>
                            <form
                                className="gift-add-form"
                                onSubmit={(event) => {
                                    event.preventDefault()
                                    dispatch(
                                        changeInputValue(
                                            true,
                                            "loadingGiftcards"
                                        )
                                    )
                                    dispatch(addGiftCardAccount(giftcardCode))
                                }}>
                                <InputSimple
                                    inputName={"code-gift-card"}
                                    label={"Code de votre carte-cadeau"}
                                    value={giftcardCode}
                                    type={"text"}
                                    required={true}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputValue(
                                                event.target.value,
                                                "giftcardCode"
                                            )
                                        )
                                    }}
                                />
                                <button type="submit">Ajouter</button>
                            </form>
                        </div>
                    </div>
                    {giftcards.map(
                        ({
                            code,
                            initialAmount,
                            amount,
                            enabled,
                            expiresAt,
                        }) => (
                            <div className="gift-cards-element-wrapper">
                                {loadingGiftcardsPdf === code && <Spinner />}
                                <div className="gift-cards-element code">
                                    <div className="gift-cards-element-title">
                                        Code :{" "}
                                    </div>
                                    <div>{code}</div>
                                </div>
                                <div className="gift-cards-element">
                                    <div className="gift-cards-element-title">
                                        Montant initial :{" "}
                                    </div>
                                    <div>
                                        {Number.isInteger(initialAmount / 100)
                                            ? initialAmount / 100
                                            : (initialAmount / 100)
                                                  .toFixed(2)
                                                  .replace(".", ",")}{" "}
                                        €
                                    </div>
                                </div>
                                <div className="gift-cards-element">
                                    <div className="gift-cards-element-title">
                                        Montant restant :{" "}
                                    </div>
                                    <div>
                                        {Number.isInteger(amount / 100)
                                            ? amount / 100
                                            : (amount / 100)
                                                  .toFixed(2)
                                                  .replace(".", ",")}{" "}
                                        €
                                    </div>
                                </div>
                                <div className="gift-cards-element">
                                    <div className={`gift-cards-element-title`}>
                                        État :{" "}
                                    </div>
                                    <div
                                        className={`${enabled ? "enabled" : "disabled"}`}>
                                        {enabled ? "Activée ✓" : "Désactivée X"}
                                    </div>
                                </div>
                                <div className="gift-cards-element">
                                    <div className={`gift-cards-element-title`}>
                                        Date d'expiration :{" "}
                                    </div>
                                    <div>
                                        {expiresAt !== undefined
                                            ? new Date(
                                                  expiresAt
                                              ).toLocaleDateString("fr-FR", {
                                                  day: "2-digit",
                                                  month: "2-digit",
                                                  year: "numeric",
                                              })
                                            : "N'expire pas"}
                                    </div>
                                </div>
                                <div className="gift-cards-element">
                                    <div className="gift-cards-element-title">
                                        Télécharger :{" "}
                                    </div>
                                    <div
                                        className="icon"
                                        onClick={() => {
                                            dispatch(
                                                changeInputValue(
                                                    code,
                                                    "loadingGiftcardsPdf"
                                                )
                                            )
                                            dispatch(fetchPdfGiftcard(code))
                                        }}>
                                        {
                                            <PictureAsPdfOutlinedIcon fontSize="medium" />
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </>
            )}
        </div>
    )
}

export default GiftCard
