//Styles
import "./ExtraitCours.scss"

//Libraries
import ReactPlayer from "react-player/youtube"

const ExtraitCours = ({ extrait }) => {
    return (
        <div className="extrait-cours-wrapper">
            <div className="extrait-cours-title">
                <div> Découvrez</div>
                <div>un extrait du cours</div>
            </div>
            <div className="extrait-cours-player-wrapper">
                <ReactPlayer
                    url={extrait}
                    muted={false}
                    loop={false}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                    playIcon={
                        <img
                            className="player-logo"
                            src={
                                "https://static.artesane.com/images_react/white_player.png"
                            }
                            alt="player"
                        />
                    }
                />
            </div>
        </div>
    )
}

export default ExtraitCours
