//Styles
import "./Reduction.scss"

const Reduction = ({ originalPrice, price }) => {
    const reduction = Math.floor(((originalPrice - price) / originalPrice) * 100)

    return (
        <div className="reduction-wrapper">
            {reduction.toString().replace(".", ",") < 0
                ? reduction.toString().replace(".", ",")
                : "- " + reduction.toString().replace(".", ",")}{" "}
            %
        </div>
    )
}

export default Reduction
