//Components
import TaxonsItems from "./TaxonsItems/TaxonsItems"
import SearchBarMutual from "./SearchBarMutual/SearchBarMutual"
//Actions
import { changeSearchbar } from "../../../../actions/prepageAction"
//Libraries
import MediaQuery from "react-responsive"

//Hooks
import { useSelector, useDispatch } from "react-redux"

//Style
import "./UnderNavBar.scss"

const UnderNavbar = () => {
    const isScrolling = useSelector((state) => state.home.scroll)
    const dispatch = useDispatch()
    const inputValue = useSelector((state) => state.prepage.inputSearchbar)
    const isLoadingMegamenu = useSelector(
        (state) => state.home.isLoadingMegaMenu
    )
    const isStickiheader = useSelector((state) => state.common.isStickiheader)

    return (
        <div
            className="under-navbar"
            style={{
                transform: isStickiheader ? "translateY(-205px)" : "none",
                position: isStickiheader ? "fixed" : "relative",
                transition: "transform 0.3s ease-in-out",
                opacity: isStickiheader ? "0" : "1",
                WebkitTransition: "transform 0.3s ease-in-out",
                WebkitTransform: isStickiheader ? "translateY(-205px)" : "none",
                willChange: "transform, opacity",
            }}>
            {!isLoadingMegamenu && (
                <MediaQuery minWidth={1000}>
                    <TaxonsItems isScrolling={isScrolling} />
                </MediaQuery>
            )}
            <div className="under-navbar-searchBar">
                <SearchBarMutual
                    onSubmit={(event) => {
                        event.preventDefault()
                    }}
                    placeHolder={"RECHERCHER..."}
                    inputValue={inputValue}
                    onChange={(event) => {
                        dispatch(changeSearchbar(event.target.value))
                    }}
                />
            </div>
        </div>
    )
}

export default UnderNavbar
