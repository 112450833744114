import { useDispatch, useSelector } from "react-redux";
import OneyChoose from "../OneyChoose/OneyChoose"
import "./PaymentAccordion.scss";
import { changeInputOrder } from "../../../../../actions/cartAction";

const PaymentAccordion = ({code, name, id, description}) => {
    
    const dispatch = useDispatch();
    const selectPaymentCode = useSelector(
        (state) => state.cart.selectPaymentCode
    )
    const validatePayment = useSelector((state) => state.cart.validatePayment)
    return (
        <div className="payment-accordion-wrapper">
            <div key={id} className="payment-item-wrapper">
                <input
                className="radio-input"
                    value={code}
                    checked={
                        selectPaymentCode === code ? true : false
                    }
                    name={id}
                    type={"radio"}
                />

                {code === "oney-by-payplug" && (
                    <>
                        <label
                            className="payment-item oney"
                            onClick={() => {
                                dispatch(
                                    changeInputOrder(
                                        code,
                                        "selectPaymentCode"
                                    )
                                )
                                dispatch(
                                    changeInputOrder(
                                        false,
                                        "validateChoosePayment"
                                    )
                                )
                            }}>
                                {name}
                            <img
                                className="oney-logo"
                                src="https://static.artesane.com/images_react/3x4x.svg"
                                alt="payer avec Oney"
                                onClick={() => {
                                    dispatch(
                                        changeInputOrder(
                                            "oney",
                                            "selectPaymentCode"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "validatePayment"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            true,
                                            "oneyChoosePayment"
                                        )
                                    )
                                }}
                            />
                        </label>
                    </>
                )}
                {code !== "oney-by-payplug" && (
                    
                        <label
                            className="payment-item"
                            htmlFor={id}
                            onClick={() => {
                                dispatch(
                                    changeInputOrder(
                                        code,
                                        "selectPaymentCode"
                                    )
                                )
                                dispatch(
                                    changeInputOrder(
                                        false,
                                        "validatePayment"
                                    )
                                )

                                
                            }}>
                                {name}
                            <img
                                className="cb"
                                src="https://static.artesane.com/images_react/cb.jpeg"
                                alt="carte bancaire"
                            />
                            
                        </label>
                    
                )} 
                {  selectPaymentCode === code && (
                    
                    <div className="payment-item-content">
                        {selectPaymentCode === "oney-by-payplug" && (
                            <OneyChoose/>
                        )}
                        <div className="payment-item-content-info">
                            <div className="legals-info">
                            Par votre commande, vous acceptez nos 
                            <a 
                                href={"/cgv"}
                                onClick={ (event) => {
                                    event.preventDefault();
                                    window.open('/cgv', 'popupWindow', 'width=800,height=600');
                                }}
                            > conditions générales de vente</a> et notre 
                            <a
                                href={"/politique-de-confidentialite"}
                                onClick={ (event) => {
                                    event.preventDefault();
                                    window.open('/politique-de-confidentialite', 'popupWindow', 'width=800,height=600');
                                }}
                            > politique de protection des données personnelles</a>. Vous confirmez également que cet achat est destiné à un usage personnel uniquement. Nous pouvons occasionnellement vous envoyer des recommandations de produits par e-mail. Ne vous inquiétez pas, vous pouvez vous désinscrire à tout moment en cliquant simplement sur le lien figurant en bas de ces e-mails.
                            </div>
                            <button type="submit" className="payment-button">
                                payer avec {name}
                            </button>
                        </div>

                    </div>
                )}
            </div>
        </div>
    )
}

export default PaymentAccordion;