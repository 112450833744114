//Components
import ClientsAdvicesCard from "../../Commons/Cards/ClientsAdvicesCard/ClientsAdvicesCard"

//Libraries
import AliceCarousel from "react-alice-carousel"

//Hooks
import { useSelector } from "react-redux"

//Styles
import "./ClientsAdvices.scss"

const ClientsAdvices = () => {
    const clientsAdvices = useSelector((state) => state.home.clientsAdvices)
    return (
        <div className="home-clients-advices-wrapper">
            <div className="home-clients-advices-title">
                Ce que nos élèves disent de nous
            </div>
            <AliceCarousel
                infinite
                autoPlay={true}
                animationDuration={1000}
                autoPlayInterval={5000}
                items={clientsAdvices.map(({ text, author }, index) => (
                    <ClientsAdvicesCard
                        text={text}
                        id={index}
                        author={author}
                    />
                ))}
                responsive={{
                    0: {
                        items: 1,
                    },
                    1024: {
                        items: 3,
                    },
                    1600: {
                        items: 4,
                    },
                }}
            />
        </div>
    )
}

export default ClientsAdvices
