//Hooks
import { useSelector } from "react-redux"
import { NavLink, useParams } from "react-router-dom"

//Styles
import "./CatalogueItems.scss"

const CatalogueItems = () => {
    const { univers } = useParams()
    const navDataCatalogue = useSelector((state) => state.home.taxonCatalogue)

    if (navDataCatalogue.length > 0) {
        if (navDataCatalogue.length < 9) {
            return (
                <div className="home-catalogue-taxons-wrapper">
                    {navDataCatalogue.map((item) => (
                        <div key={item.slug} className="home-catalogue-taxons">
                            <NavLink
                                className="home-catalogue-taxon-navlink"
                                to={`/${univers}/catalogue/${item.slug}/1`}>
                                {item.name}
                            </NavLink>
                        </div>
                    ))}
                </div>
            )
        } else {
            return (
                <>
                    <div className="home-catalogue-taxons-wrapper">
                        {navDataCatalogue.slice(0, 8).map((item, index) => (
                            <div
                                key={item.slug}
                                className="home-catalogue-taxons">
                                <NavLink
                                    className="home-catalogue-taxon-navlink"
                                    to={`/${univers}/catalogue/${item.code.toLowerCase()}/1`}>
                                    {item.name}
                                </NavLink>
                            </div>
                        ))}
                    </div>
                    <div className="home-catalogue-taxons-wrapper">
                        {navDataCatalogue
                            .slice(8, navDataCatalogue.length)
                            .map((item, index) => (
                                <div
                                    key={item.slug}
                                    className="home-catalogue-taxons">
                                    <NavLink
                                        className="home-catalogue-taxon-navlink"
                                        to={`/${univers}/catalogue/${item.code.toLowerCase()}/1`}>
                                        {item.name}
                                    </NavLink>
                                </div>
                            ))}
                    </div>
                </>
            )
        }
    }
}

export default CatalogueItems
