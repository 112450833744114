export const dropdownMutualHomeUserConnected = [
    {
        title: "Mes cours et documents",
        slug: "/mes-cours-et-documents/arts-du-fil",
    },
    {
        title: "Mes commandes",
        slug: "/mon-compte/mes-commandes",
    },
    {
        title: "Mes infos personnelles",
        slug: "/mon-compte/mes-infos-personnelles",
    },
    {
        title: "Mon profil",
        slug: "/mon-compte/mon-profil",
    },
    {
        title: "Mes adresses",
        slug: "/mon-compte/mes-adresses",
    },
    {
        title: "Mes chèques-cadeaux",
        slug: "/mon-compte/mes-cheques-cadeaux",
    },

    {
        title: "Mes abonnements",
        slug: "/mon-compte/mes-abonnements",
    },

    {
        title: "Ma liste de souhaits",
        slug: "/mon-compte/ma-liste-de-souhaits",
    },
]

export const dropdownMutualHomeUserNoConnected = [
    {
        title: "Me connecter",
        slug: "/connexion",
    },
    {
        title: "Créer un compte",
        slug: "/inscription",
    },
]
