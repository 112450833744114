//Styles
import "./ProfesseurCatalogue.scss"
//Components
import Header from "../Home/Header/Header"
import Title from "./Title/Title"
import ProfesseurCard from "./ProfesseurCard/ProfesseurCard"
import Spinner from "../Commons/Spinner/Spinner"
import Banner from "../Banner/Banner"

//Actions
import { fetchProfesseurCatalogueData } from "../../actions/professorsAction"

//hooks
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect } from "react"

const ProfesseurCatalogue = () => {
    const { univers } = useParams()
    const isLoading = useSelector(
        (state) => state.professors.isLoadingProfesseurCatalogue
    )
    const professorsData = useSelector(
        (state) => state.professors.professeurCatalogueData
    )
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProfesseurCatalogueData(univers))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, univers])

    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | Les professeurs  " +
            (univers === "arts-du-fil"
                ? "Arts du fil "
                : univers === "arts-culinaires"
                  ? "Arts culinaires"
                  : "Arts plastiques")
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="professeurs-catalogue-wrapper">
                <Title upperText={"Nos professeurs"} />
                <div className="professeur-catalogue-cards-wrapper">
                    {isLoading && <Spinner />}
                    {!isLoading &&
                        professorsData.teachers &&
                        professorsData.teachers.length > 0 &&
                        professorsData.teachers.map(
                            ({ code, name, teacherTaxons, images, bio }) => {
                                return (
                                    <ProfesseurCard
                                        key={code}
                                        name={name}
                                        subjects={teacherTaxons}
                                        urlImage={
                                            images.length > 0
                                                ? images[0].thumbnails.carousel
                                                : ""
                                        }
                                        bio={
                                            bio !== null &&
                                            typeof bio === "string"
                                                ? `${bio.slice(0, 220)}...`
                                                : ""
                                        }
                                        url={`/${univers}/professeur/${code}`}
                                    />
                                )
                            }
                        )}
                </div>
            </div>
        </div>
    )
}

export default ProfesseurCatalogue
