import "./TopArrow.scss"

const TopArrow = () => {
    return (
        <div
            onClick={() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                })
            }}
            className={"top-arrow-wrapper"}>
            <div>
                <span>{">"}</span>
            </div>
        </div>
    )
}

export default TopArrow
