//Components
import LessonCard from "../../Commons/Cards/LessonsCard/LessonCard"

// //Assets
// import photo_test from '../../../assets/photo4.jpg';
// import video from "../../../assets//video-prepage.mp4";

//Libraries
import AliceCarousel from "react-alice-carousel"

//Styles
import "./TopSlider.scss"

//Hooks
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

const TopSlider = () => {
    const { univers } = useParams()

    useEffect(() => {
        // dispatch(fetchTopSliderItems(univers));
    }, [univers])

    const sliderItems = useSelector((state) => state.home.topSlider)

    return (
        <div className="homeSlider-wrapper">
            {Object.keys(sliderItems).length > 0 && (
                <AliceCarousel
                    // activeIndex={activeIndex}
                    autoPlay={true}
                    animationDuration={1000}
                    autoPlayInterval={3000}
                    infinite
                    items={sliderItems.images.map(
                        ({ title, subtitle, content, link, url, linktext }) => (
                            <LessonCard
                                // fileType={artesane_slider_entry_image_type}
                                url={link}
                                buttonText={linktext}
                                urlImg={url ? url : ""}
                                // urlVideo={""}
                                title={title}
                                subtitle={subtitle}
                                description={content}
                            />
                        )
                    )}
                    responsive={{
                        0: {
                            items: 1,
                        },
                        600: {
                            items: 1,
                        },
                        1024: {
                            items: 1,
                        },
                    }}
                />
            )}
        </div>
    )
}

export default TopSlider
