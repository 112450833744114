//Style
import "./InputSimple.scss"

const InputSimple = ({
    onClick,
    className,
    minLength,
    pattern,
    iconVisibility,
    error,
    checked,
    errorMessage,
    maxLength,
    inputName,
    placeholder,
    label,
    value,
    type,
    required,
    onChange,
    onBlur,
    min,
    dataPlaceholder,
}) => {
    return (
        <div className={`input-wrapper ${className}`}>
            <label
                onClick={onClick}
                className="personal-label"
                htmlFor={inputName}>
                {label}
            </label>
            <input
                checked={checked}
                maxLength={maxLength}
                pattern={pattern}
                minLength={minLength}
                placeholder={placeholder}
                data-placeholder={dataPlaceholder}
                min={min}
                className={`personal-input ${error ? `error` : ""}`}
                required={required}
                name={inputName}
                type={type}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
            <div className={`error-message ${error ? `error` : ""}`}>
                {errorMessage}
            </div>
            <div>{iconVisibility}</div>
        </div>
    )
}

export default InputSimple
