import InputSimple from "../../../UserAccount/InputSimple/inputSimple"
import "./MediaFilter.scss"
import { useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
    changeFilters,
    toggleFiltersOpen,
} from "../../../../actions/mediaAction"
import { useEffect } from "react"

const MediaFilter = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const isOpenFilters = useSelector((state) => state.media.filtersOpen)
    const packsEtParcours = useSelector((state) => state.media.packsEtParcours)
    const documentsCours = useSelector((state) => state.media.documentsCours)
    const videosCours = useSelector((state) => state.media.videosCours)
    const nbFilter = useSelector((state) => state.media.nbFilterActif)

    useEffect(() => {
        dispatch(changeFilters("packsEtParcours", false, 0))
        dispatch(changeFilters("documentsCours", false, 0))
        dispatch(changeFilters("videosCours", false, 0))
        dispatch(toggleFiltersOpen(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div className="media-filter-wrapper">
            <div
                className="filter-media-select-title"
                onClick={() => {
                    dispatch(toggleFiltersOpen(!isOpenFilters))
                }}>
                <span>Filtrer par</span>
                {nbFilter > 0 && (
                    <span className="filter-number">{nbFilter}</span>
                )}

                <span className={isOpenFilters ? "open" : "closed"}> ▼ </span>
            </div>

            <div
                className={`filter-items${isOpenFilters ? " open" : " closed"}`}>
                <InputSimple
                    onChange={() => {
                        dispatch(
                            changeFilters(
                                "packsEtParcours",
                                !packsEtParcours,
                                packsEtParcours ? nbFilter - 1 : nbFilter + 1
                            )
                        )
                    }}
                    onClick={() => {
                        dispatch(
                            changeFilters(
                                "packsEtParcours",
                                !packsEtParcours,
                                packsEtParcours ? nbFilter - 1 : nbFilter + 1
                            )
                        )
                    }}
                    checked={packsEtParcours}
                    className={"checkbox"}
                    type={"checkbox"}
                    label={"Packs et parcours"}
                />
                <InputSimple
                    onChange={() => {
                        dispatch(
                            changeFilters(
                                "documentsCours",
                                !documentsCours,
                                documentsCours ? nbFilter - 1 : nbFilter + 1
                            )
                        )
                    }}
                    onClick={() => {
                        dispatch(
                            changeFilters(
                                "documentsCours",
                                !documentsCours,
                                documentsCours ? nbFilter - 1 : nbFilter + 1
                            )
                        )
                    }}
                    checked={documentsCours}
                    className={"checkbox"}
                    type={"checkbox"}
                    label={"PDF hors cours"}
                />
                <InputSimple
                    onChange={() => {
                        dispatch(
                            changeFilters(
                                "videosCours",
                                !videosCours,
                                videosCours ? nbFilter - 1 : nbFilter + 1
                            )
                        )
                    }}
                    onClick={() => {
                        dispatch(
                            changeFilters(
                                "videosCours",
                                !videosCours,
                                videosCours ? nbFilter - 1 : nbFilter + 1
                            )
                        )
                    }}
                    checked={videosCours}
                    className={"checkbox"}
                    type={"checkbox"}
                    label={"Cours vidéo à l'unité"}
                />
            </div>
        </div>
    )
}

export default MediaFilter
