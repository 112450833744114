//Styles
import { useEffect, useRef } from "react"
import "./Programme.scss"

const Programme = ({ productData }) => {
    const contentRef = useRef(null)

    useEffect(() => {
        if (contentRef.current) {
            const links = contentRef.current.querySelectorAll("a")
            links.forEach((link) => {
                link.setAttribute("target", "_blank")
                link.setAttribute("rel", "noopener noreferrer")
            })
        }
    }, [])

    function createMarkup() {
        return {
            __html: productData.attributes.find(
                (item) => item.code === "votre-programme-d-apprentissage"
            ).value,
        }
    }

    if (
        productData.attributes.find(
            (item) => item.code === "votre-programme-d-apprentissage"
        ) !== undefined
    ) {
        return (
            <div
                className="programme-wrapper"
                ref={contentRef}
                dangerouslySetInnerHTML={createMarkup()}></div>
        )
    }
}
export default Programme
