//Styles
import "./Professor.scss"

//Hooks
import { NavLink, useParams } from "react-router-dom"

const Professor = ({ productData }) => {
    const { univers } = useParams()

    function createMarkup() {
        return {
            __html: productData.author.bio,
        }
    }

    return (
        <div className="product-professor-wrapper">
            <div className="product-professor-img-name-wrapper">
                <div className="professor-image-wrapper">
                    <NavLink
                        to={`/${productData.author.universes.length > 0 ? productData.author.universes[0].code : univers}/professeur/${productData.author.code}`}>
                        <img
                            src={
                                productData.author.images[0].url === undefined
                                    ? "https://static.artesane.com/default/0001/12/thumb_11364_default_big.png"
                                    : productData.author.images[0].thumbnails
                                          .carousel
                            }
                            alt={productData.author.name}
                        />
                    </NavLink>
                </div>
                <div className="professor-name">
                    <div>Votre professeur</div>
                    <div>{productData.author.name}</div>
                </div>
            </div>

            <div
                dangerouslySetInnerHTML={createMarkup()}
                className="product-professor-description"></div>

            <div className="product-professor-button">
                <NavLink
                    to={`/${productData.author.universes.length > 0 ? productData.author.universes[0].code : univers}/professeur/${productData.author.code}`}>
                    Voir tous ses cours
                </NavLink>
            </div>
        </div>
    )
}

export default Professor

// "https://static.artesane.com/default/0001/12/thumb_11156_default_big.png" alt="photo du professeur"
