//Styles
import "./Prerequis.scss"

const Prerequis = ({ productData }) => {
    function createMarkup() {
        return {
            __html: productData.attributes.find(
                (item) => item.code === "ce-cours-s-adresse-a-moi-si"
            ).value,
        }
    }
    if (
        productData.attributes.find(
            (item) => item.code === "ce-cours-s-adresse-a-moi-si"
        ) !== undefined
    ) {
        return (
            <div className="prerequis-wrapper">
                <div className="accordion-under-title">Prérequis</div>
                <div
                    dangerouslySetInnerHTML={createMarkup()}
                    className="prerequis-item"></div>
            </div>
        )
    }
}

export default Prerequis
