import { useDispatch, useSelector } from "react-redux"
import "./VariantsChoiceCart.scss"
import {
    toggleVariantsChoice,
    validateVariantsAdd,
    createCartDisconnected,
    loadingAddCart,
    addOrderItemConnected,
} from "../../../../../../actions/cartAction"
import { countQuantity } from "../../../../../../selectors/selectors"
import { selectVariantsCatalogue } from "../../../../../../actions/cartAction"
import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import InputSimple from "../../../../../UserAccount/InputSimple/inputSimple"
import { saveProductDataState } from "../../../../../../actions/productsActions"

const VariantsChoiceCart = ({
    variants,
    openOrClosed,
    variantsNotInStock,
    giftCard,
    giftCardAmountConfigurable,
}) => {
    const dispatch = useDispatch()
    const nameVariantSelect = useSelector(
        (state) => state.cart.variantsSelectedCatalogue
    )
    const isVariantAdd = useSelector(
        (state) => state.cart.addCarVariantsValidate
    )
    const isUserConnected = useSelector((state) => state.auth.userConnected)
    const customerId = useSelector((state) => state.user.customerId)
    const cartData = useSelector((state) => state.cart.cartData)
    const giftcardAmount = useSelector((state) => state.products.giftcardAmount)
    const giftcardAmountError = useSelector(
        (state) => state.products.giftcardAmountError
    )
    const userConnected = useSelector((state) => state.auth.userConnected)

    useEffect(() => {
        dispatch(selectVariantsCatalogue(""))
        dispatch(validateVariantsAdd(false))
        dispatch(saveProductDataState("giftcardAmount", ""))

        dispatch(saveProductDataState("giftcardAmountError", false))
    }, [dispatch])
    return (
        <div>
            {giftCard && giftCardAmountConfigurable ? (
                <div
                    className={`variants-choice-cart-popup-wrapper ${openOrClosed}`}>
                    <span
                        className="variants-choice-cart-toclose"
                        onClick={() => {
                            dispatch(selectVariantsCatalogue(""))
                            dispatch(validateVariantsAdd(false))
                            dispatch(toggleVariantsChoice(0))
                        }}>
                        {" "}
                        ✕
                    </span>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault()
                            if (giftcardAmount <= 0) {
                                dispatch(
                                    saveProductDataState(
                                        "giftcardAmountError",
                                        true
                                    )
                                )
                            } else {
                                dispatch(loadingAddCart(variants[0].code))

                                if (userConnected) {
                                    dispatch(
                                        addOrderItemConnected(
                                            false,
                                            giftCardAmountConfigurable,
                                            giftcardAmount,
                                            customerId,
                                            variants[0].id,
                                            variants[0].code,
                                            countQuantity(
                                                false,
                                                cartData,
                                                variants[0].code
                                            )
                                        )
                                    )
                                } else {
                                    dispatch(
                                        createCartDisconnected(
                                            false,
                                            giftCardAmountConfigurable,
                                            giftcardAmount,
                                            variants[0].product_id,
                                            variants[0].id,
                                            variants[0].code,
                                            countQuantity(
                                                false,
                                                cartData,
                                                variants[0].code
                                            )
                                        )
                                    )
                                }
                            }
                        }}>
                        <InputSimple
                            errorMessage={"Montant insuffisant"}
                            error={giftcardAmountError}
                            inputName={"giftamount"}
                            min={1}
                            placeholder={"Votre montant"}
                            value={giftcardAmount}
                            type={"number"}
                            required={true}
                            label={
                                "Saisissez le montant que vous souhaitez offrir :"
                            }
                            onChange={(event) => {
                                dispatch(
                                    saveProductDataState(
                                        "giftcardAmountError",
                                        false
                                    )
                                )
                                dispatch(
                                    saveProductDataState(
                                        "giftcardAmount",
                                        event.target.value
                                    )
                                )
                            }}
                        />

                        <button type="submit" className="validate-button">
                            Ajouter{">"}
                        </button>
                    </form>
                </div>
            ) : (
                <div
                    className={`variants-choice-cart-popup-wrapper ${openOrClosed}`}>
                    <span
                        className="variants-choice-cart-toclose"
                        onClick={() => {
                            dispatch(selectVariantsCatalogue(""))
                            dispatch(validateVariantsAdd(false))
                            dispatch(toggleVariantsChoice(0))
                        }}>
                        {" "}
                        ✕
                    </span>

                    <div className="variants-choice-cart-popup-choose">
                        Veuillez choisir une option :{" "}
                    </div>

                    {variants.map(({ name, id, product_id, code }) => {
                        if (
                            !variantsNotInStock.find(
                                (element) => element.id === id
                            )
                        ) {
                            return (
                                <div
                                    key={id}
                                    className={`variants-choices-cart-item ${name === nameVariantSelect.name ? "select" : ""}`}
                                    onClick={() => {
                                        dispatch(
                                            selectVariantsCatalogue({
                                                name: name,
                                                id: id,
                                                productId: product_id,
                                                code: code,
                                            })
                                        )
                                    }}>
                                    {name}
                                    {!isVariantAdd &&
                                        nameVariantSelect.id !== id && (
                                            <div
                                                className="validate-button"
                                                onClick={() => {
                                                    dispatch(
                                                        loadingAddCart(code)
                                                    )

                                                    if (isUserConnected) {
                                                        dispatch(
                                                            validateVariantsAdd(
                                                                true
                                                            )
                                                        )
                                                        dispatch(
                                                            addOrderItemConnected(
                                                                false,
                                                                giftCardAmountConfigurable,
                                                                giftcardAmount,
                                                                customerId,
                                                                id,
                                                                code,
                                                                countQuantity(
                                                                    false,
                                                                    cartData,
                                                                    code
                                                                )
                                                            )
                                                        )
                                                    } else {
                                                        dispatch(
                                                            validateVariantsAdd(
                                                                true
                                                            )
                                                        )
                                                        dispatch(
                                                            createCartDisconnected(
                                                                false,
                                                                giftCardAmountConfigurable,
                                                                giftcardAmount,
                                                                product_id,
                                                                id,
                                                                code,
                                                                countQuantity(
                                                                    false,
                                                                    cartData,
                                                                    code
                                                                )
                                                            )
                                                        )
                                                    }
                                                }}>
                                                Ajouter{">"}
                                            </div>
                                        )}
                                    {isVariantAdd &&
                                        nameVariantSelect.id === id && (
                                            <div className="cart-add-button">
                                                <NavLink to={"/mon-panier"}>
                                                    ✓ Ajouté au panier
                                                </NavLink>
                                            </div>
                                        )}
                                </div>
                            )
                        }
                        return <></>
                    })}
                    {/* {!isVariantAdd && (
                        <div
                            className="validate-button" 
                            onClick={() => {
                              
                                if(nameVariantSelect !== ""){

                                    dispatch(loadingAddCart(nameVariantSelect.code));

                                    if(isUserConnected) {
                                        dispatch(validateVariantsAdd(true));
                                        dispatch(addOrderItemConnected(customerId, nameVariantSelect.id, nameVariantSelect.productId, countQuantity(false, cartData, nameVariantSelect.code)));
                                    }else {
                                        dispatch(createCartDisconnected( nameVariantSelect.productId, nameVariantSelect.id, countQuantity(false, cartData, nameVariantSelect.code)));
                                    }
                               
                                }
                            }}
                        >
                            Ajouter au panier {">"}
                        </div>
                    )} */}
                    {/* {isVariantAdd && (
                        <div className="cart-add-button"> 
                            <NavLink to={"/mon-panier"}>
                            ✓ Ajouté au panier
                            </NavLink>
                        </div>
                    )}
                    */}
                </div>
            )}
        </div>
    )
}

export default VariantsChoiceCart
