//Styles
import { NavLink, useParams } from "react-router-dom"
import "./ProfessorName.scss"

const ProfessorName = ({ professorName, productData }) => {
    const { univers } = useParams()
    if (professorName !== undefined) {
        return (
            <div className="professor-name">
                <NavLink
                    to={
                        productData.author !== null
                            ? `/${productData.author.universes.length > 0 ? productData.author.universes[0].code : univers}/professeur/${productData.author.code}`
                            : ""
                    }>
                    {professorName}
                </NavLink>
            </div>
        )
    }
}

export default ProfessorName
