//Actions type
import {
    TOGGLE_DROPDOWN,
    TOGGLE_SEARCHBAR,
    CHANGE_SEARCH_BAR,
    TOGGLE_BURGER,
} from "../actions/prepageAction"

const initialState = {
    dropDownMenuFirstHome: false,
    toggleSearchbar: false,
    inputSearchbar: "",
    toggleBurger: false,
}

const prepageReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case TOGGLE_BURGER:
            return {
                ...state,
                toggleBurger: action.value,
            }

        case TOGGLE_DROPDOWN:
            return {
                ...state,
                dropDownMenuFirstHome: action.value,
            }
        case TOGGLE_SEARCHBAR:
            return {
                ...state,
                toggleSearchbar: action.value,
            }

        case CHANGE_SEARCH_BAR:
            return {
                ...state,
                inputSearchbar: action.value,
            }

        default:
            return state
    }
}
export default prepageReducer
