//Components
import CatalogueItems from "./CatalogueItems/CatalogueItems"
import CatalogueImage from "./CatalogueImage/CatalogueImage"

//Styles
import "./Catalogue.scss"
import { useSelector } from "react-redux"

const Catalogue = () => {
    const loadingTaxonCatalogue = useSelector(
        (state) => state.home.loadingTaxonCatalogue
    )

    return (
        <>
            {!loadingTaxonCatalogue && (
                <div
                    className="home-catalogue-wrapper"
                    id="home-catalogue-wrapper">
                    <div className="home-catalogue-image-wrapper">
                        <CatalogueImage />
                    </div>
                    <div className="home-catalogue-text-wrapper">
                        <div className="home-catalogue-title">
                            <div className="home-catalogue-title-firstpart">
                                Explorez
                            </div>
                            <div className="home-catalogue-title-secondpard">
                                notre catalogue
                            </div>
                        </div>
                        <CatalogueItems />
                    </div>
                </div>
            )}
        </>
    )
}

export default Catalogue
