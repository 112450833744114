//Styles
import "./TopInformations.scss"
//Components
import Reduction from "./Reduction/Reduction"
import Title from "./Title/Title"
import ProfessorName from "./ProfessorName/ProfessorName"
import LevelAndTime from "./LevelAndTime/LevelAndTime"
import Price from "../../Commons/Cards/LessonCardSelection/Price/Price"
import VariantsChoice from "./VariantsChoice/VariantsChoice"
import PaiementTag from "./PaimentTag/PaiementTag"
import { useSelector } from "react-redux"
import GiftcardAmount from "./GiftcardAmount/GiftcardAmount"

const TopInformations = ({ productData }) => {
    const isVariantSelected = useSelector(
        (state) => state.products.variantSelected
    )

    //Tous les variants sont-il en stocks ?
    let allVariantStock = false

    productData.variants.forEach((element) => {
        if (element.inStock === true) {
            allVariantStock = true
        }
    })

    return (
        <div className="top-informations-wrapper">
            <Title title={productData.name} />

            {!productData.giftCard && (
                <>
                    <ProfessorName
                        productData={productData}
                        professorName={
                            productData.author !== null
                                ? productData.author.name
                                : ""
                        }
                    />
                    <div className="product-level-and-price-wrapper">
                        <div className="product-level-wrapper">
                            <LevelAndTime
                                texte={
                                    productData.attributes.find(
                                        (item) => item.code === "niveau"
                                    )
                                        ? productData.attributes.find(
                                              (item) => item.code === "niveau"
                                          ).value
                                        : ""
                                }
                                logo={
                                    "https://static.artesane.com/images_react/level.png"
                                }
                            />
                            <LevelAndTime
                                texte={
                                    productData.attributes.find(
                                        (item) => item.code === "duree"
                                    )
                                        ? productData.attributes.find(
                                              (item) => item.code === "duree"
                                          ).value
                                        : ""
                                }
                                logo={
                                    "https://static.artesane.com/images_react/time.png"
                                }
                            />
                        </div>
                        <div className="top-informations-price-wrapper">
                            {productData.variants[
                                isVariantSelected !== ""
                                    ? isVariantSelected.index
                                    : 0
                            ].price !==
                                productData.variants[
                                    isVariantSelected !== ""
                                        ? isVariantSelected.index
                                        : 0
                                ].originalPrice && (
                                <Reduction
                                    originalPrice={
                                        productData.variants[
                                            isVariantSelected !== ""
                                                ? isVariantSelected.index
                                                : 0
                                        ].originalPrice
                                    }
                                    price={
                                        productData.variants[
                                            isVariantSelected !== ""
                                                ? isVariantSelected.index
                                                : 0
                                        ].price
                                    }
                                />
                            )}

                            <Price
                                price={
                                    productData.variants[
                                        isVariantSelected !== ""
                                            ? isVariantSelected.index
                                            : 0
                                    ].price / 100
                                }
                                originalPrice={
                                    productData.variants[
                                        [
                                            isVariantSelected !== ""
                                                ? isVariantSelected.index
                                                : 0,
                                        ]
                                    ].originalPrice / 100
                                }
                            />
                        </div>
                    </div>
                </>
            )}
            {productData.buy_once === false && allVariantStock === false && (
                <div className="out-stocks">
                    Ce produit n'est malheureusement plus disponible{" "}
                </div>
            )}
            {productData.variants.length > 1 &&
                allVariantStock === true &&
                !productData.giftCard && (
                    <VariantsChoice
                        variantNotInStock={[]}
                        productData={productData}
                        variants={productData.variants}
                    />
                )}

            {productData.giftCard && productData.giftCardAmountConfigurable && (
                <GiftcardAmount />
            )}
            {productData.variants[
                isVariantSelected !== "" ? isVariantSelected.index : 0
            ].price /
                100 >
                100 && <PaiementTag oney={true} />}
        </div>
    )
}

export default TopInformations
