//Styles
import "./NavLogo.scss"

//Hooks
import { NavLink } from "react-router-dom"

const NavLogo = ({
    logo,
    altImg,
    title,
    currentUnivers,
    filterAffectation,
}) => {
    return (
        <NavLink
            className="navlogo-items"
            to={`/${currentUnivers}/catalogue/${filterAffectation}/1`}>
            <img className="pictogram" src={logo()} alt={altImg} />
            <div className="navlogo-title-wrapper">
                <div className="navlogo-title-actif">
                    {" "}
                    {title} {">"}
                </div>
            </div>
        </NavLink>
    )
}

export default NavLogo
