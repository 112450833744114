//Styles
import "./NouveauteEtPack.scss"

const NouveauteEtPack = ({ productData }) => {
    return (
        <div className="nouveaute-et-pack-wrapper">
            {productData.isNew && (
                <span className="nouveaute-tag">Nouveauté</span>
            )}

            {productData.pack && <span className="pack-tag">Pack</span>}

            {productData.parcours && <span className="pack-tag">Parcours</span>}

            {productData.coffret && <span className="pack-tag">Coffret</span>}
        </div>
    )
}

export default NouveauteEtPack
