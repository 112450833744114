//Hooks
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

//Libraries
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

//Actions
import { fetchFaqs } from "../../../actions/homeActions"
import Header from "../../Prepage/Header/Header"

//Styles
import "./Faq.scss"
import Banner from "../../Banner/Banner"

const Faq = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchFaqs())
    }, [dispatch])

    function createMarkup(item) {
        return {
            __html: item,
        }
    }

    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | Foire aux questions , FAQ"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | La foire aux questions - FAQ"
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])

    const faqsData = useSelector((state) => state.home.faqsData)

    return (
        <div>
            <Banner />
            <Header />
            <div className="common-faqs-wrapper">
                <div className="common-faqs-title">FAQ</div>
                <div className="home-faq-accordion-wrapper">
                    {/* Afficher un nombre d'items variable en fonction de l'accordéon ouvert ou non, action dispatchée au click sur le bouton  */}
                    {faqsData.map(({ question, answer }) => (
                        <Accordion
                            sx={{
                                boxShadow: "none",
                                marginBottom: "1%",
                                backgroundColor: "#FFFCF8",
                                "& .MuiAccordionSummary-root": {
                                    padding: "0 5%",
                                    fontWeight: "600",
                                    "& .MuiAccordionSummary-content": {
                                        paddingRight: "5%",
                                    },
                                },
                                "&::before": {
                                    height: "0px",
                                },
                            }}
                            key={question}>
                            <AccordionSummary
                                sx={{
                                    fontSize: "16px",
                                    padding: "0 20%",
                                    fontWeight: 600,
                                    "& .MuiAccordionDetails-root": {
                                        padding: "0 5%",
                                        fontWeight: "600",
                                    },
                                }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <span
                                    dangerouslySetInnerHTML={createMarkup(
                                        question
                                    )}></span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <span
                                    dangerouslySetInnerHTML={createMarkup(
                                        answer
                                    )}></span>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Faq
