import { useDispatch } from "react-redux"
import { NavLink } from "react-router-dom"
import { changeInputAuthValue } from "../../../actions/authAction"
import "./DeconnectPopin.scss"

const DeconnectPopin = () => {
    const dispatch = useDispatch()

    return (
        <div className="deconnect-popin-wrapper">
            <div className="wrapper">
                <div> Vous avez été déconnecté.</div>
                <div
                    onClick={() => {
                        dispatch(changeInputAuthValue(false, "isDeconnect"))
                    }}>
                    <NavLink to={"/connexion"}>Me reconnecter {">"}</NavLink>
                </div>
            </div>
        </div>
    )
}

export default DeconnectPopin
