// Components
import AccountAccess from "./AccountAcccess/AccountAccess"
import NavIcons from "../../../Commons/NavIcons/NavIcons"

//Hooks
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

//Actions
import { countCart } from "../../../../actions/cartAction"

//Styles
import "./NavBar.scss"
import SearchBar from "./SearchBar/SearchBar"
import { NavLink } from "react-router-dom"
import OrderPopup from "../../../Cart/OrderPopup/OrderPopup"

const NavBar = () => {
    const dispatch = useDispatch()
    const isSearchBarOpen = useSelector(
        (state) => state.prepage.toggleSearchbar
    )
    const isConnected = useSelector((state) => state.auth.userConnected)
    const numberProductCart = useSelector((state) => state.cart.cartDataNumber)
    const wishListNum = useSelector((state) => state.user.wishlistItemNumber)

    const cartItems = JSON.parse(sessionStorage.getItem("cart"))
    const orderPopup = useSelector((state) => state.common.orderPopup)

    useEffect(() => {
        if (cartItems !== null) {
            dispatch(countCart(cartItems.length))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems])

    return (
        <div className="navBar">
            {!isSearchBarOpen && (
                <>
                    {orderPopup && <OrderPopup />}
                    {isConnected && (
                        <AccountAccess
                            logo={
                                "https://static.artesane.com/images_react/player.png"
                            }
                            slug={"/mes-cours-et-documents"}
                        />
                    )}
                    <AccountAccess
                        logo={
                            "https://static.artesane.com/images_react/user.png"
                        }
                        slug={"/mon-compte/mes-commandes"}
                    />
                    <NavLink
                        className={"navlink-wrapper"}
                        to={"/mon-compte/ma-liste-de-souhaits"}>
                        <NavIcons
                            logo={
                                "https://static.artesane.com/images_react/empty_heart.png"
                            }
                            numberOfProduct={wishListNum}
                            styleColor={"wishList"}
                            altPicture={"Logo liste d'envie"}
                        />
                    </NavLink>
                    <NavLink className={"navlink-wrapper"} to={"/mon-panier"}>
                        <NavIcons
                            logo={
                                "https://static.artesane.com/images_react/cart.png"
                            }
                            numberOfProduct={numberProductCart}
                            styleColor={"cart"}
                            altPicture={"Logo liste d'un pannier"}
                        />
                    </NavLink>
                </>
            )}
            <SearchBar
                logo={"https://static.artesane.com/images_react/loupe.png"}
                path={"/"}
                altPicture={"Logo d'une loupe"}
            />
        </div>
    )
}
export default NavBar
