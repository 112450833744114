import OrdersCard from "./OrdersCard/OrdersCard"
import "./Orders.scss"

import { useSelector } from "react-redux"
import Spinner from "../../Commons/Spinner/Spinner"
import EmptyPage from "../../Commons/EmptyPage/EmptyPage"

const Orders = () => {
    const orders = useSelector((state) => state.user.orders)
    const isLoading = useSelector((state) => state.user.loadingOrders)

    return (
        <div className="user-orders-wrapper">
            {!isLoading &&
                orders.filter((item) =>( item.paymentState !== "cart" && item.paymentState !== "awaiting_payment" && item.paymentState !== "cancelled") || item.paymentState === 'authorized').map(
                    ({
                        id,
                        number,
                        itemsTotal,
                        total,
                        created_at,
                        checkoutCompletedAt,
                        shippingState,
                        paymentState,
                        shippingAddress,
                        billingAddress,
                        tokenValue,
                        shipments,
                        state,
                    }) => {
                            return (
                                <OrdersCard
                                    createdAt={created_at}
                                    key={id}
                                    id={id}
                                    orderNumber={number !== undefined? number : ""}
                                    totalPrice={total !== undefined ? total : itemsTotal}
                                    date={checkoutCompletedAt}
                                    orderState={shippingState}
                                    paymentState={paymentState}
                                    shippingFirstName={
                                        shippingAddress
                                            ? shippingAddress.firstName
                                            : ""
                                    }
                                    shippingLastName={
                                        shippingAddress
                                            ? shippingAddress.lastName
                                            : ""
                                    }
                                    shippingStreet={
                                        shippingAddress
                                            ? shippingAddress.street
                                            : ""
                                    }
                                    shippingCity={
                                        shippingAddress
                                            ? shippingAddress.city
                                            : ""
                                    }
                                    shippingPostcode={
                                        shippingAddress
                                            ? shippingAddress.postcode
                                            : ""
                                    }
                                    shippingCountrycode={
                                        shippingAddress
                                            ? shippingAddress.countryCode
                                            : ""
                                    }
                                    shippingCompany={
                                        shippingAddress
                                            ? shippingAddress.company
                                            : ""
                                    }
                                    billingFirstName={
                                        billingAddress
                                            ? billingAddress.firstName
                                            : ""
                                    }
                                    billingLastName={
                                        billingAddress
                                            ? billingAddress.lastName
                                            : ""
                                    }
                                    billingStreet={
                                        billingAddress
                                            ? billingAddress.street
                                            : ""
                                    }
                                    billingCity={
                                        billingAddress
                                            ? billingAddress.city
                                            : ""
                                    }
                                    billingPostcode={
                                        billingAddress
                                            ? billingAddress.postcode
                                            : ""
                                    }
                                    billingCountrycode={
                                        billingAddress
                                            ? billingAddress.countryCode
                                            : ""
                                    }
                                    billingCompany={
                                        billingAddress
                                            ? billingAddress.company
                                            : ""
                                    }
                                    tokenValue={tokenValue}
                                    shipments={shipments}
                                />
                            )
                    }
                )}

            {isLoading && <Spinner />}
            {!isLoading && orders.length === 1 && (
                <>
                    {orders[0].state === "cart" && (
                        <EmptyPage
                            firstPart={
                                "Vous n'avez pas encore passé de commandes."
                            }
                            secondPartOne={"En panne d’inspiration ?"}
                            secondPartTwo={
                                " Retrouvez nos meilleurs cours ici ! >"
                            }
                            urlSecondPart={"/arts-du-fil/catalogue/tout/1"}
                            // thirdPartOne={" Vous avez déjà ajouté des articles à votre panier ?"}
                            // thirdPartTwo={"Connectez-vous pour les retrouver ! >"}
                            // urlThirdPart={"/connexion"}
                        />
                    )}
                </>
            )}
            {orders.length < 1 && (
                <EmptyPage
                    firstPart={"Vous n'avez pas encore passé de commandes."}
                    secondPartOne={"En panne d’inspiration ?"}
                    secondPartTwo={" Retrouvez nos meilleurs cours ici ! >"}
                    urlSecondPart={"/arts-du-fil/catalogue/tout/1"}
                    // thirdPartOne={" Vous avez déjà ajouté des articles à votre panier ?"}
                    // thirdPartTwo={"Connectez-vous pour les retrouver ! >"}
                    // urlThirdPart={"/connexion"}
                />
            )}

            {/* <OrdersCard /> */}
        </div>
    )
}

export default Orders
