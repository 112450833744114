export const SAVE_COMMON_DATA_IN_STATE = "SAVE_COMMON_DATA_IN_STATE"
export const GET_GENERAL_SEARCH_BAR = "GET_GENERAL_SEARCH_BAR"
export const FETCH_COURS_IDEAL = "FETCH_COURS_IDEAL"
export const FETCH_PRODUCTS_COURS_IDEAL = "FETCH_PRODUCTS_COURS_IDEAL"
export const FETCH_SEO_ENTRIES = "FETCH_SEO_ENTRIES"
export const FETCH_DATA_BY_UNIVERSE_AND_PAGE = "FETCH_DATA_BY_UNIVERSE_AND_PAGE"
export const FETCH_CHANNEL = "FETCH_CHANNEL"

export const fetchChannel = (infos) => ({
    type: FETCH_CHANNEL,
    infos: infos,
})

export const fetchDataByUniverseAndPage = (universe, page, filters) => ({
    type: FETCH_DATA_BY_UNIVERSE_AND_PAGE,
    universe: universe,
    page: page,
    filters: filters,
})

export const fetchSeoEntries = () => ({
    type: FETCH_SEO_ENTRIES,
})

export const fetchProductsCoursIdeal = (products) => ({
    type: FETCH_PRODUCTS_COURS_IDEAL,
    products: products,
})

export const fetchCoursIdeal = () => ({
    type: FETCH_COURS_IDEAL,
})

export const getGeneralSearchbar = (words) => ({
    type: GET_GENERAL_SEARCH_BAR,
    words: words,
})

export const saveCommonDataInState = (stateProperty, newvalue) => ({
    type: SAVE_COMMON_DATA_IN_STATE,
    value: newvalue,
    property: stateProperty,
})
