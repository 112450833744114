//Styles
import "./Objectifs.scss"

const Objectifs = ({ productData }) => {
    function createMarkup() {
        return {
            __html: productData.attributes.find(
                (item) => item.code === "a-la-fin-du-cours-je-serai-capable-de"
            ).value,
        }
    }

    if (
        productData.attributes.find(
            (item) => item.code === "a-la-fin-du-cours-je-serai-capable-de"
        ) !== undefined
    ) {
        return (
            <div className="objectifs-wrapper">
                <div className="accordion-under-title">Objectifs</div>
                {/* <div >A la fin de ce cours vous saurez faire: </div> */}
                <div dangerouslySetInnerHTML={createMarkup()}></div>
                {/* <ul>
            {objectifs.map((item, index) => (
                <li key={index}> - {item}</li>
            ))}
         </ul> */}
            </div>
        )
    }
}

export default Objectifs
