import './TitleCart.scss';

const TitleCart = ({title}) => {
    return (
        <div className='title-wrapper'>
               <div className='title-text'>{title}</div>
        </div>
     
    )
}

export default TitleCart