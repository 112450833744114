import "./MediaFilterDesktop.scss"
import { useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { changeFilters } from "../../../../actions/mediaAction"
import { useEffect } from "react"

const MediaFilterDesktop = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const packsEtParcours = useSelector((state) => state.media.packsEtParcours)
    const documentsCours = useSelector((state) => state.media.documentsCours)
    const videosCours = useSelector((state) => state.media.videosCours)
    const nbFilter = useSelector((state) => state.media.nbFilterActif)

    useEffect(() => {
        dispatch(changeFilters("packsEtParcours", false, 0))
        dispatch(changeFilters("documentsCours", false, 0))
        dispatch(changeFilters("videosCours", false, 0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div className="media-filter-desktop-wrapper">
            <div className="filter-media-select-title">Filtrer par :</div>

            <div
                onClick={() => {
                    dispatch(
                        changeFilters(
                            "packsEtParcours",
                            !packsEtParcours,
                            packsEtParcours ? nbFilter - 1 : nbFilter + 1
                        )
                    )
                }}
                className={`mediafilter-desktop-item ${packsEtParcours ? "actif" : ""}`}>
                Packs et parcours
            </div>
            <div
                onClick={() => {
                    dispatch(
                        changeFilters(
                            "documentsCours",
                            !documentsCours,
                            documentsCours ? nbFilter - 1 : nbFilter + 1
                        )
                    )
                }}
                className={`mediafilter-desktop-item ${documentsCours ? "actif" : ""}`}>
                PDF hors cours
            </div>
            <div
                onClick={() => {
                    dispatch(
                        changeFilters(
                            "videosCours",
                            !videosCours,
                            videosCours ? nbFilter - 1 : nbFilter + 1
                        )
                    )
                }}
                className={`mediafilter-desktop-item ${videosCours ? "actif" : ""}`}>
                Cours vidéo à l'unité
            </div>
        </div>
    )
}

export default MediaFilterDesktop
