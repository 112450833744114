//Styles
import Banner from "../../Banner/Banner"
import Header from "../../Prepage/Header/Header"
import "./SecurePayment.scss"

import { useEffect } from "react"

const SecurePayment = () => {
    useEffect(() => {
        document.title = "Artesane - Apprenez à créer | Paiement sécurisé"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Paiement sécurisé "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="secure-payment-wrapper">
                <h1>Paiement sécurisé</h1>
                <p>
                    <strong>Les solutions propos&eacute;es : </strong>
                </p>
                <ul>
                    <li>
                        Nous proposons deux modes paiement 100%
                        s&eacute;curis&eacute;s :&nbsp;&nbsp;Paiement par carte
                        bancaire via Payplug
                    </li>
                    <li>
                        Paiement en 3 fois ou 4 fois via Oney (partenaire
                        Payplug) pour les commandes dont le montant est
                        sup&eacute;rieur &agrave; 100&euro;
                    </li>
                </ul>
                <div className="secure-payment-images">
                    <img
                        src={
                            "https://static.artesane.com/images_react/Logo_Oney.png"
                        }
                        alt="Logo d'Oney"
                    />
                    <img
                        src={
                            "https://static.artesane.com/images_react/logo_payplug.png"
                        }
                        alt="Logo de Payplug"
                    />
                </div>
                <p>
                    <strong>Nos garanties s&eacute;curit&eacute; : </strong>
                </p>
                <p>
                    Le paiement sur notre site est enti&egrave;rement
                    s&eacute;curis&eacute; gr&acirc;ce au syst&egrave;me de
                    paiement en ligne de notre partenaire{" "}
                    <strong>Payplug</strong>.
                </p>
                <p>
                    Le processus de paiement est directement effectu&eacute; sur
                    le <strong>serveur s&eacute;curis&eacute;</strong> PayPlug.
                    Vos donn&eacute;es bancaires sont enti&egrave;rement
                    s&eacute;curis&eacute;es gr&acirc;ce au
                    <strong>&nbsp;protocole HTTPS</strong>. Les donn&eacute;es
                    sensibles, telles que le num&eacute;ro de carte bancaire et
                    sa date d&rsquo;expiration, sont enti&egrave;rement
                    <strong>
                        &nbsp;chiffr&eacute;es et prot&eacute;g&eacute;es
                    </strong>{" "}
                    gr&acirc;ce &agrave; un protocole TLS afin
                    d&rsquo;emp&ecirc;cher que les informations
                    &eacute;chang&eacute;es puissent &ecirc;tre
                    intercept&eacute;es en clair par un tiers au cours de la
                    transaction. Les num&eacute;ros de carte sont{" "}
                    <strong>chiffr&eacute;s instantan&eacute;ment</strong> et ne
                    sont pas accessibles par Artesane.&nbsp;
                </p>
                <p>
                    De plus, PayPlug ne conserve pas les num&eacute;ros de carte
                    et s&rsquo;appuie sur une infrastructure
                    s&eacute;curis&eacute;e qui{" "}
                    <strong>respecte la norme internationale PCI-DSS</strong>.
                </p>
                <p>
                    Votre paiement est ainsi{" "}
                    <strong>100% s&eacute;curis&eacute;</strong>.
                </p>
            </div>
        </div>
    )
}
export default SecurePayment
