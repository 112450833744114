//Components
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import MegaMenu from "../MegaMenu/MegaMenu"
import LinkButton from "../../../../Commons/Buttons/LinkButton/LinkButton"

//Actions
import { toggleMegaMenuActif } from "../../../../../actions/homeActions"

//Hooks
import { useParams } from "react-router-dom"

//Styles
import "./TaxonsItems.scss"

const TaxonsItems = ({ isScrolling }) => {
    const { univers } = useParams()
    const dispatch = useDispatch()
    const navData = useSelector((state) => state.home.navData)
    const megaMenuActif = useSelector((state) => state.home.megaMenuActif)

    return (
        <div
            className={
                isScrolling
                    ? "under-navbar-taxons-scroll"
                    : "under-navbar-taxons"
            }>
            {navData.map(({ name, children, code, id }, index) => {
                if (
                    name !== "Explorez notre catalogue" &&
                    name !== "explorez notre catalogue" &&
                    name !== "Notre sélection"
                ) {
                    return (
                        <section
                            key={id}
                            onMouseEnter={() => {
                                dispatch(toggleMegaMenuActif(name))
                            }}
                            onMouseLeave={() => {
                                dispatch(toggleMegaMenuActif(""))
                            }}>
                            <div key={name} className="taxons-items">
                                <NavLink
                                    className="taxons-items-navlinks"
                                    to={`/${univers}/catalogue/${code.toLowerCase()}/1`}>
                                    {" "}
                                    {name}
                                </NavLink>
                            </div>
                            <div
                                key={id}
                                className={
                                    megaMenuActif === name
                                        ? "taxons-items-wrapper-active"
                                        : "taxons-items-wrapper"
                                }>
                                <MegaMenu key={name} navItems={children} />
                            </div>
                            <div
                                className={
                                    megaMenuActif === name
                                        ? "taxons-items-button-active"
                                        : "taxons-items-button"
                                }
                                onClick={() => {
                                    // dispatch(removeAllFilters());
                                }}>
                                <LinkButton
                                    texte={"Voir tous les cours"}
                                    url={`/${univers}/catalogue/${code.toLowerCase()}/1`}
                                />
                            </div>
                        </section>
                    )
                }
                return null
            })}
        </div>
    )
}

export default TaxonsItems
