//Hooks

import { useSelector } from "react-redux/es/exports"
import { useParams } from "react-router-dom"
//Components
import LessonCardSelection from "../../Commons/Cards/LessonCardSelection/LessonCardSelection"

const Products = () => {
    const lessonsData = useSelector((state) => state.catalogue.catalogueData)
    const { currentPage } = useParams()

    return (
        <div className="catalogue-products-wrapper">
            {lessonsData.length > 0 &&
                lessonsData[currentPage - 1].map((item, index) => {
                    return (
                        <div
                            key={item.id}
                            className="catalogue-product-card-wrapper">
                            <LessonCardSelection
                                productId={item.id}
                                urlImg={
                                    item.mainImage !== null &&
                                    item.mainImage !== undefined
                                        ? item.mainImage.thumbnails.carousel
                                        : ""
                                }
                                title={item.name}
                                professor={item.author ? item.author.name : ""}
                                originalPrice={(
                                    item.variants[0].originalPrice / 100
                                )
                                    .toFixed(2)
                                    .toString()
                                    .replace(".", ",")}
                                price={(item.variants[0].price / 100)
                                    .toFixed(2)
                                    .toString()
                                    .replace(".", ",")}
                                pack={item.pack}
                                parcours={item.parcours}
                                coffret={item.coffret}
                                reduction={
                                    item.variants[0].originalPrice !==
                                    item.variants[0].price
                                        ? Math.floor(
                                              ((item.variants[0].originalPrice -
                                                  item.variants[0].price) /
                                                  item.variants[0]
                                                      .originalPrice) *
                                              100
                                          )
                                        : 0
                                }
                                newer={item.isNew}
                                time={
                                    item.duree !== undefined ? item.duree : ""
                                }
                                level={
                                    item.niveau !== undefined ? item.niveau : ""
                                }
                                key={item.id}
                                currentUnivers={item.universes[0].code}
                                productSlug={item.slug}
                                // shipedRequired={item.variants[0].shipping_required}
                                variants={item.variants}
                                buyOnce={item.buy_once}
                                giftCard={item.giftCard}
                                giftCardAmountConfigurable={
                                    item.giftCardAmountConfigurable
                                }
                                alt={
                                    item.mainImage !== null &&
                                    item.mainImage !== undefined
                                        ? item.mainImage.alt
                                        : ""
                                }
                            />
                        </div>
                    )
                })}

            {lessonsData.length === 0 && (
                <div> Aucun produit ne correspond à votre recherche</div>
            )}
        </div>
    )
}

export default Products
