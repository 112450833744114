//Styles
import "./Title.scss"

const Title = ({ title }) => {
    if (title !== undefined) {
        return <h1 className="title-wrapper">{title}</h1>
    }
}

export default Title
