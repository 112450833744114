import "./GiftcardAmount.scss"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux/es/exports"

//Action
import {
    selectVariants,
    saveProductDataState,
} from "../../../../actions/productsActions"
import InputSimple from "../../../UserAccount/InputSimple/inputSimple"

const GiftcardAmount = () => {
    const dispatch = useDispatch()
    const giftcardAmount = useSelector((state) => state.products.giftcardAmount)
    const giftcardAmountError = useSelector(
        (state) => state.products.giftcardAmountError
    )

    useEffect(() => {
        dispatch(selectVariants(""))
        dispatch(saveProductDataState("giftcardAmount", ""))
        dispatch(saveProductDataState("giftcardAmountError", false))
    }, [dispatch])
    return (
        <div className="giftcard-amount-wrapper">
            <InputSimple
                errorMessage={"Montant insuffisant"}
                error={giftcardAmountError}
                inputName={"giftamount"}
                placeholder={"Votre montant"}
                value={giftcardAmount}
                type={"number"}
                required={true}
                min={1}
                label={"Saisissez le montant que vous souhaitez offrir :"}
                onChange={(event) => {
                    dispatch(saveProductDataState("giftcardAmountError", false))
                    dispatch(
                        saveProductDataState(
                            "giftcardAmount",
                            event.target.value
                        )
                    )
                }}
            />
        </div>
    )
}

export default GiftcardAmount
