export const clientsAdives = [
    {
        text: "Artesane, c'est le paradis de la création ! Il y en a pour tous les goûts. Une équipe dynamique et bienveillante, avec qui on a envie d'apprendre une multitude de techniques !",
        author: "Isabelle G.",
    },
    {
        text: "Artesane c'est du partage, des rencontres, une transmission de savoirs, de la beauté, un souffle de créativité, des étincelles de gentillesse, de bienveillance et de générosité et des talents qui nous apprennent, montrent et œuvrent pour nous.",
        author: "Séverine P.",
    },
    {
        text: "Artesane est pour moi le meilleur moyen pour apprendre ; que ce soit pour commencer une nouvelle passion, pour se perfectionner, ou pour se professionnaliser. Des cours d'une qualité exceptionnelle, des professeurs dont on voit la passion, et une équipe hyper réactive. Artesane est la meilleure solution pour se former avec des professionnel(le)s depuis chez soi et à son rythme !",
        author: "Layana P.",
    },
    {
        text: "Les cours proposés sont d'un grand professionnalisme, on y apprend plein d'astuces et tout y est expliqué dans le moindre détail. Il faut aussi souligner que les vidéos sont filmées au top : on s'y croirait, et c'est super important de voir la technique et pas seulement les mains. Je recommande les yeux fermés",
        author: "Carole T.",
    },
    {
        text: "Une plateforme de cours très riche et diversifiée pour grand débutant à expert, des échanges chaleureux et généreux , une grande disponibilité de toute l’équipe toujours à l´ écoute de sa communauté. On se sent bien chez Artesane !",
        author: "Anne D.",
    },
    {
        text: "Artesane est une expérience unique en son genre : les sujets de cours sont variés, pertinents et traités à la perfection. Artesane remet au goût du jour, de façon moderne, claire et accessible, des métiers et des savoir-faire du patrimoine. Quant au support vidéo, il dépasse clairement tout ce que j'ai pu tester ailleurs, ce qui est idéal pour bien comprendre le geste",
        author: "Magali V.",
    },
    {
        text: "Quel que soit votre niveau, Artesane peut vous permettre de vous améliorer. Même pour une professionnelle, l'inspiration peut être enrichie par des pratiques complémentaires et il est même bien utile de réveiller parfois des savoir-faire endormis. L'atmosphère et la bienveillance sont très appréciables. Je recommande chaudement.",
        author: "Anne L.",
    },
    {
        text: "J'ai suivi quelques cours sur Artesane. Tous ont été très instructifs, faciles à suivre et à comprendre. Les étapes sont bien filmées, on voit ce qui est fait. C'est détaillé mais très clair. J'ai beaucoup appris même si je ne suis pas débutante.",
        author: "Elisabeth",
    },
    {
        text: "La qualité des cours gratuits (dans le fond et dans la forme) m'ont très vite convaincue d'acheter des cours car je savais que je n'allais pas être déçue. J'ai maintenant une belle bibliothèque de cours que je consulte facilement : grâce aux chapitres et à la possibilité de laisser des notes pendant les cours, je sais revenir rapidement à un moment précis pour me rappeler des points importants",
        author: "Mailys",
    },
]
