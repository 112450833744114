import "./TotalOrder.scss"
import Spinner from "../../Commons/Spinner/Spinner"
import { NavLink, redirect, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { redirectToOrder } from "../../../actions/authAction"
import OutsideClickHandler from "react-outside-click-handler"
import {
    fetchPaymentUrl,
    changeInputOrder,
    postCouponCode,
    postGiftCardCart,
    deleteGiftCardCart,
    fetchOneySimulation,
    patchPaymentMethod,
} from "../../../actions/cartAction"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import OneySimulation from "./OneySimulation/OneySimulation"
import { filterGiftcards } from "../../../selectors/selectors"
import MediaQuery from "react-responsive"

const TotalOrder = ({ link, total }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const cartData = useSelector((state) => state.cart.cartData)
    const addressValidate = useSelector((state) => state.cart.addressValidate)
    const couponCode = useSelector((state) => state.cart.couponCode)
    const userConnected = useSelector((state) => state.auth.userConnected)

    const orderAdjustments = useSelector((state) => state.cart.orderAdjustments)
    const shipping = orderAdjustments.find(
        (element) => element.type === "shipping"
    )
    const loadingTotalOrder = useSelector(
        (state) => state.cart.loadingTotalOrder
    )
    const messageRetourCoupon = useSelector(
        (state) => state.cart.messageRetourCoupon
    )
    const giftcards = useSelector((state) => state.user.giftcards)
    const giftCardSelected = useSelector((state) => state.cart.giftCardSelected)
    const loadingGiftcards = useSelector((state) => state.user.loadingGiftcards)
    const oneySimulationLoading = useSelector(
        (state) => state.cart.oneySimulationLoading
    )
    const cartDataNumber = useSelector(
        (state) => state.cart.cartDataNumber
    )

    const redirectPaiementWindow = useSelector(
        (state) => state.cart.redirectPaiementWindow
    )
    const chooseDelivery = useSelector((state) => state.cart.chooseDelivery)


    useEffect(() => {
        dispatch(fetchOneySimulation(cartData.tokenValue))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])



    return (
        <div className="total-order-wrapper">
            {(loadingTotalOrder || loadingGiftcards) && (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            )}
            <div className="total-order-title"> {pathname.includes("panier") ? "Récapitulatif : " : "Ma commande : "}{cartDataNumber === 1 ? cartDataNumber + " article" : cartDataNumber + " articles"} </div>
            <MediaQuery minWidth={1000}>
                <div className="price">
                    <div>
                    {" Sous-total "}
                    </div>
                    <span className="num-font">
                        {(
                            (cartData.items.length > 1
                                ? cartData.items.reduce((a, b) => a + b.subtotal, 0)
                                : cartData.items[0].subtotal) / 100
                        )
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")}
                        {" "}€
                    </span>
                
                </div>
            </MediaQuery>
        {pathname.includes("panier") && (
            <MediaQuery maxWidth={1000}>
                <div className="price">
                    <div>
                    {" Sous-total "}
                    </div>
                    <span className="num-font">
                        {(
                            (cartData.items.length > 1
                                ? cartData.items.reduce((a, b) => a + b.subtotal, 0)
                                : cartData.items[0].subtotal) / 100
                        )
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")}
                        {" "}€
                    </span>
                
                </div>
            </MediaQuery>
        )}


            {cartData.orderPromotionTotal !== 0 && (

            <MediaQuery minWidth={1000}>
                <div className="price">
                    <div>
                        Réduction{" "}
                    </div>
                    <span className="num-font">
                        {(cartData.orderPromotionTotal / 100)
                            .toFixed(2)
                            .replace(".", ",")}
                            {" "}
                            €
                    </span>
                </div>
            </MediaQuery>
            )}
            {cartData.orderPromotionTotal !== 0 && pathname.includes('panier') && (
                <MediaQuery maxWidth={1000}>

                    <div className="price">
                        <div>
                            Réduction{" "}
                        </div>
                        <span className="num-font">
                            {(cartData.orderPromotionTotal / 100)
                                .toFixed(2)
                                .replace(".", ",")}
                                {" "}
                                €
                        </span>
                    </div>
                </MediaQuery>
                )}

            {cartData.giftCards.length > 0 && (
                <div className="delivery">
                    Cartes cadeaux :
                    {cartData.adjustments.filter((item) =>
                        item.type.includes("gift_card")
                    ).length > 0 &&
                        cartData.adjustments
                            .filter((item) => item.type.includes("gift_card"))
                            .map((item) => (
                                <span className="num-font">
                                    {" - "}
                                    {Math.abs(item.amount / 100)
                                        .toFixed(2)
                                        .toString()
                                        .replace(".", ",")}{" "}
                                    €
                                </span>
                            ))}
                </div>
            )}
            {cartData.shippingTotal !== 0  && (
                <MediaQuery minWidth={1000}>
                  <div className="price">
                    <div>
                        Frais de livraison {" "}
                    </div>
                  
                    <span className="num-font">
                        {(cartData.shippingTotal / 100)
                            .toFixed(2)
                            .replace(".", ",")}
                            {" "}
                            €
                    </span>
                </div>
                </MediaQuery>

            )}

        {cartData.shippingTotal !== 0 && (
                <MediaQuery maxWidth={1000}>
                  <div className="price">
                    <div>
                        Frais de livraison {" "}
                    </div>
                  
                    <span className="num-font">
                        {(cartData.shippingTotal / 100)
                            .toFixed(2)
                            .replace(".", ",")}
                            {" "}
                            €
                    </span>
                </div>
                </MediaQuery>

            )}
            <div className="code-gift-wrapper">
                {userConnected && (
                    <div className="code-promo">
                        Mon code promo
                        <form
                            onSubmit={(event) => {
                                event.preventDefault()
                                dispatch(changeInputOrder(true, "loadingCart"))
                                dispatch(
                                    postCouponCode(
                                        couponCode,
                                        cartData.tokenValue
                                    )
                                )
                            }}
                            className="form-code">
                            <input
                                required
                                value={couponCode}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputOrder(
                                            "",
                                            "messageRetourCoupon"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            event.target.value,
                                            "couponCode"
                                        )
                                    )
                                }}
                                type="text"
                            />
                            <button type="submit">Valider {">"}</button>
                        </form>
                        {messageRetourCoupon.length > 0 &&(
                            <div className="code-result">
                                {" "}
                                {messageRetourCoupon}
                            </div>
                        )}
                    </div>
                )}
                {userConnected && filterGiftcards(giftcards).length > 0 && (
                    <div className="code-promo gift">
                        <label>J'utilise une carte cadeau</label>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault()

                                if (giftCardSelected > 0) {
                                    dispatch(
                                        changeInputOrder(true, "loadingCart")
                                    )
                                    dispatch(
                                        postGiftCardCart(
                                            filterGiftcards(giftcards)[
                                                giftCardSelected - 1
                                            ].code,
                                            cartData.tokenValue
                                        )
                                    )
                                }
                            }}
                            className="form-code">
                            <div className="chose-giftcard-select">
                                {cartData.giftCards.length > 0 && (
                                    <span
                                        className="cross-delete"
                                        onClick={() => {
                                            if (giftCardSelected > 0) {
                                                dispatch(
                                                    changeInputOrder(
                                                        true,
                                                        "loadingCart"
                                                    )
                                                )
                                                dispatch(
                                                    deleteGiftCardCart(
                                                        filterGiftcards(
                                                            giftcards
                                                        )[giftCardSelected - 1]
                                                            .code,
                                                        cartData.tokenValue
                                                    )
                                                )
                                            }
                                        }}>
                                        {"✖"}
                                    </span>
                                )}

                                <select
                                    value={giftCardSelected}
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputOrder(
                                                Number(event.target.value),
                                                "giftCardSelected"
                                            )
                                        )
                                    }}>
                                    <option value={0} key={0}>
                                        --------------------------------------------
                                    </option>
                                    {filterGiftcards(giftcards).map(
                                        ({ id, amount, code }, index) => (
                                            <option key={id} value={index + 1}>
                                                {(amount / 100)
                                                    .toString()
                                                    .replace(".", ",") + " €"}
                                                {",  "}
                                                {code},{" "}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <button type="submit">Ajouter {">"}</button>
                        </form>
                    </div>
                )}

            </div>

            <div className="total-price-order">
                <div>
                    Total commande {" "}
                </div>
                <span className="num-font">
                    {(cartData.total / 100).toFixed(2).replace(".", ",")}
                    {" "}€
                </span>
            </div>
            {(pathname.includes("livraison")) && (
                <NavLink to={link}>
                    <div
                        className="order-button"
                        disabled={pathname.includes("livraison") && cartData.shippingAddress === undefined ? true: false}
                        onClick={() => {
                            dispatch(redirectToOrder(true))
                            if((cartData.checkoutState === "payment_skipped" || cartData.payments.length === 0)&& cartData.shippingAddress !== undefined ) {

                                dispatch(
                                    changeInputOrder(
                                        true,
                                        "loadingValidateAddress"
                                    )
                                )
                                   dispatch(
                                    fetchPaymentUrl(
                                        cartData.payments.length > 0
                                            ? cartData.payments[cartData.payments.length - 1].id
                                            : 0,
                                        cartData.tokenValue,
                                        cartData.checkoutState
                                    )
                                )
                            }
                            if ( cartData.shippingAddress !== undefined &&
                                (cartData.checkoutState !== "payment_skipped" && cartData.payments.length > 0 && cartData.checkoutState !== "addressed" &&( (cartData.shipments.length === 0 && !chooseDelivery) || (cartData.shipments.length >0 && chooseDelivery)))
                            ) {

                                    if(cartData.payments[0].availableMethods.length > 0 ) {
                                        //When Only one payment method is available
                                        if(cartData.payments[0].availableMethods.length === 1) {
                                            dispatch(patchPaymentMethod(
                                                cartData.tokenValue,
                                                cartData.payments[cartData.payments.length - 1].id,
                                                cartData.payments[cartData.payments.length - 1].availableMethods[0]
                                                    .code,
                                                ""
                                            ))
                                        }
                                   

                                        if(cartData.payments[0].availableMethods.length > 1 ) {
                                            dispatch(changeInputOrder(false, "loadingAddPayment"))
                                            dispatch(
                                                changeInputOrder(
                                                    true,
                                                    "redirectPaiement"
                                                )
                                            )
                                        }
                                    
                                

                                // dispatch(
                                //     changeInputOrder(
                                //         true,
                                //         "loadingValidateAddress"
                                //     )
                                // )

                            }
                             else if (
                                cartData.shippingAddress !== undefined &&
                                redirectPaiementWindow &&
                                (cartData.checkoutState ===
                                    "shipping_selected" ||
                                    cartData.checkoutState ===
                                        "shipping_skipped")
                            ) {

                                console.log("else if")
                                dispatch(
                                    changeInputOrder(
                                        true,
                                        "loadingValidateAddress"
                                    )
                                )

                            } 
                        } 
                        if ((cartData.checkoutState === "addressed" || cartData.checkoutState === "shipping_selected" || cartData.checkoutState === "shipping_skipped") && cartData.shipments.length > 0 && !chooseDelivery ){

                            dispatch(changeInputOrder(true, "chooseDelivery"))
                        }
                        }}>
                            Payer {">"}
                    </div>
                </NavLink>
            )}

            

            {/* accès vue après panier */}
            {(pathname.includes("panier")) && (
                                <NavLink to={link}>
                                <div
                                    className="order-button"
                                    onClick={() => {

                                    }}>
                                        Commander {">"}
                                </div>
                            </NavLink>
            )}

            <MediaQuery minWidth={1000}>
                <div className="logos-wrapper">
                        <img
                            src={"https://static.artesane.com/images_react/cb.svg"}
                            alt="cb"
                        />
                        <img
                            src={"https://static.artesane.com/images_react/visa.svg"}
                            alt="visa"
                        />
                        <img
                            src={
                                "https://static.artesane.com/images_react/mastercard.svg"
                            }
                            alt="mastercard"
                        />
                    </div>
                {!oneySimulationLoading && cartData.total / 100 > 100 && (
                <OutsideClickHandler
                onOutsideClick={() => {
                    dispatch(
                        changeInputOrder(false, "oneySimulationPopup")
                    )
                }}>
                    <OneySimulation />
                    </OutsideClickHandler>
                )}
           
            </MediaQuery>

            {pathname.includes("panier") && (
                <MediaQuery maxWidth={1000}>
                    <div className="logos-wrapper">
                        <img
                            src={"https://static.artesane.com/images_react/cb.svg"}
                            alt="cb"
                        />
                        <img
                            src={"https://static.artesane.com/images_react/visa.svg"}
                            alt="visa"
                        />
                        <img
                            src={
                                "https://static.artesane.com/images_react/mastercard.svg"
                            }
                            alt="mastercard"
                        />
                    </div>
                    {!oneySimulationLoading && cartData.total / 100 > 100 && (
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            dispatch(
                                changeInputOrder(false, "oneySimulationPopup")
                            )
                        }}>
                        <OneySimulation />
                        </OutsideClickHandler>
                    )}
            
                </MediaQuery>
            )}
            {pathname.includes("livraison") && cartData.payments.length > 0 && (
                <>
                {cartData.payments[0].availableMethods.length === 1 && (
                    <div className="legals-info">
                    Par votre commande, vous acceptez nos 
                    <a 
                        href={"/cgv"}
                        onClick={ (event) => {
                            event.preventDefault();
                            window.open('/cgv', 'popupWindow', 'width=800,height=600');
                        }}
                    > conditions générales de vente</a> et notre 
                     <a
                        href={"/politique-de-confidentialite"}
                        onClick={ (event) => {
                            event.preventDefault();
                            window.open('/politique-de-confidentialite', 'popupWindow', 'width=800,height=600');
                        }}
                    > politique de protection des données personnelles</a>. Vous confirmez également que cet achat est destiné à un usage personnel uniquement. Nous pouvons occasionnellement vous envoyer des recommandations de produits par e-mail. Ne vous inquiétez pas, vous pouvez vous désinscrire à tout moment en cliquant simplement sur le lien figurant en bas de ces e-mails.
                    </div>
                )}
                
                </>
                
            )}
            

        </div>
    )
}

export default TotalOrder
