import "./ZoomCarousel.scss"
//Hooks
import { useSelector, useDispatch } from "react-redux"
import ReactPlayer from "react-player"

//Action
import {
    toggleSliderZoomProduct,
    sliderZoomIndexToSHow,
} from "../../../actions/productsActions"
import { sortMediaProduct } from "../../../selectors/selectors"

export const ZoomCarousel = ({ productData }) => {
    const carousselIndexToShow = useSelector(
        (state) => state.products.zoomCarouselIndex
    )
    const dispatch = useDispatch()

    const productMedia = sortMediaProduct(
        productData.images,
        productData.trailers,
        "big"
    )

    return (
        <div className="zoom-carousel-wrapper">
            <div
                onClick={() => {
                    dispatch(toggleSliderZoomProduct(false))
                }}
                className="zoom-carousel-close-buttn">
                Fermer x
            </div>
            <div className="zoom-carousel-image-wrapper">
                {productMedia[carousselIndexToShow]["@type"].includes(
                    "Image"
                ) && (
                    <img
                        src={productMedia[carousselIndexToShow].url}
                        alt={productMedia[carousselIndexToShow].alt}
                    />
                )}

                {productMedia[carousselIndexToShow]["@type"].includes(
                    "Trailer"
                ) && (
                    <ReactPlayer
                        url={productMedia[carousselIndexToShow].url}
                        muted={false}
                        loop={false}
                        controls={true}
                        width={"100%"}
                        height={"100%"}
                    />
                )}
            </div>
            <div className="zoom-carousel-video-wrapper"></div>
            <div
                onClick={() => {
                    if (carousselIndexToShow === 0) {
                        dispatch(sliderZoomIndexToSHow(productMedia.length - 1))
                    } else {
                        dispatch(
                            sliderZoomIndexToSHow(carousselIndexToShow - 1)
                        )
                    }
                }}
                className="zoom-carousel-prev-btn">
                {"<"}
            </div>

            <div
                onClick={() => {
                    if (carousselIndexToShow === productMedia.length - 1) {
                        dispatch(sliderZoomIndexToSHow(0))
                    } else {
                        dispatch(
                            sliderZoomIndexToSHow(carousselIndexToShow + 1)
                        )
                    }
                }}
                className="zoom-carousel-next-btn">
                {">"}
            </div>
        </div>
    )
}

export default ZoomCarousel
