//Styles
import "./QuiSommesNous.scss"

//Components
import Header from "../../Prepage/Header/Header"

import { useEffect } from "react"
import Banner from "../../Banner/Banner"

const QuiSommesNous = () => {
    useEffect(() => {
        document.title = "Artesane - Apprenez à créer | Qui sommes-nous ?"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Qui sommes-nous ? "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="qui-sommes-nous-wrapper">
                <h1>Qui sommes-nous ?</h1>
                <div className="qui-sommes-nous-top-description">
                    <p>
                        Bienvenue chez Artesane, &eacute;cole en ligne
                        d&rsquo;arts et d&rsquo;artisanat&nbsp;!
                        Sp&eacute;cialistes de la{" "}
                        <strong>
                            formation en ligne des techniques d&rsquo;art et
                            d&rsquo;artisanat
                        </strong>
                        , nous travaillons tous les jours &agrave; la
                        r&eacute;alisation de{" "}
                        <strong>cours vid&eacute;o</strong> qui r&eacute;pondent
                        &agrave; nos exigences et celles de nos
                        &eacute;l&egrave;ves.
                    </p>
                </div>

                <div className="qui-sommes-nous-informations-wrapper">
                    <div className="qui-sommes-nous-image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/photo2.jpg"
                            }
                            alt="Anne-Sarah et Marie-Gabrielle"
                        />
                    </div>
                    <div className="qui-sommes-nous-text-information">
                        <h2>
                            Artesane, notre &eacute;cole en ligne d'arts et
                            d'artisanat
                        </h2>
                        <div className="qui-sommes-nous-description">
                            <p>
                                En tant qu&rsquo;&eacute;cole, Artesane existe
                                avant tout par et gr&acirc;ce &agrave; ses
                                &eacute;l&egrave;ves, qui ont su au fil des
                                ann&eacute;es construire autour de passions
                                communes &ndash; le geste, le savoir-faire et le
                                travail bien fait &ndash;, une{" "}
                                <strong>communaut&eacute;</strong> anim&eacute;e
                                et chaleureuse. Chez nos &eacute;l&egrave;ves,
                                nous d&eacute;celons une envie d&rsquo;apprendre
                                d&eacute;vorante et le d&eacute;sir de
                                progresser sans cesse, mais aussi un besoin de
                                souplesse et de libert&eacute; dans la
                                fa&ccedil;on d&rsquo;apprendre et les
                                modalit&eacute;s d&rsquo;enseignement&nbsp;:
                                c&rsquo;est pour r&eacute;pondre &agrave; cette
                                demande que nous avons b&acirc;ti cette{" "}
                                <strong>
                                    &eacute;cole d&rsquo;arts et
                                    d&rsquo;artisanat
                                </strong>{" "}
                                <strong>virtuelle</strong> et pourtant si
                                vivante, si tangible, qu&rsquo;est devenue
                                Artesane.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="qui-sommes-nous-informations-wrapper">
                    <div className="qui-sommes-nous-image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/photo4.png"
                            }
                            alt="équipe de tournage"
                        />{" "}
                    </div>
                    <div className="qui-sommes-nous-text-information">
                        <h2>
                            Des cours vid&eacute;o en ligne dans tous les
                            domaines artistiques
                        </h2>
                        <div className="qui-sommes-nous-description">
                            <p>
                                {" "}
                                Chaque jour, nous travaillons au
                                d&eacute;veloppement de notre catalogue de cours
                                pour r&eacute;pondre &agrave; toutes les
                                demandes d&rsquo;&eacute;l&egrave;ves et tous
                                les besoins cr&eacute;atifs. De la
                                <strong> broderie traditionnelle</strong>&nbsp;
                                <strong>au patchwork,</strong>&nbsp;
                                <strong>
                                    du CAP couture flou&nbsp;aux techniques de
                                    couture tailleur,
                                </strong>
                                &nbsp;
                                <strong>
                                    du paysage &agrave; l'aquarelle&nbsp;aux
                                    secrets de la c&eacute;ramique,
                                </strong>
                                &nbsp;en passant par l'art de&nbsp;
                                <strong>faire des macarons</strong>&nbsp;
                                <strong>
                                    ou de r&eacute;ussir des p&acirc;tes,
                                </strong>
                                &nbsp;nous abordons en profondeur chaque sujet
                                et vous accompagnons dans votre apprentissage.
                            </p>
                            <p>
                                {" "}
                                Notre catalogue de <strong>300</strong>{" "}
                                <strong>cours vid&eacute;o </strong>est
                                accessible &agrave; tous et &agrave;
                                toutes&nbsp;: nous esp&eacute;rons que vous y
                                trouverez le cours qui r&eacute;pond &agrave;
                                vos envies, et restons attentifs &agrave; tous
                                vos souhaits pour enrichir encore notre offre de
                                cours.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="qui-sommes-nous-informations-wrapper">
                    <div className="qui-sommes-nous-image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/photo5.png"
                            }
                            alt="équipe édito"
                        />{" "}
                    </div>
                    <div className="qui-sommes-nous-text-information">
                        <h2>
                            Apprenez avec les meilleurs la couture, la cuisine
                            et le dessin
                        </h2>
                        <div className="qui-sommes-nous-description">
                            <p>
                                Ici, nous vous proposons des{" "}
                                <strong>cours en ligne</strong> d&rsquo;un genre
                                nouveau. Quel que soit votre domaine
                                cr&eacute;atif et artistique de
                                pr&eacute;dilection &ndash;{" "}
                                <strong>arts du fil</strong>,{" "}
                                <strong>arts culinaires</strong> ou{" "}
                                <strong>arts plastiques</strong> &ndash;,
                                retrouvez sur Artesane des{" "}
                                <strong>professeurs sp&eacute;cialistes</strong>{" "}
                                dans leur domaine, issus des plus grandes
                                &eacute;coles, maisons et restaurants,
                                pr&ecirc;ts &agrave; transmettre leurs
                                ann&eacute;es d&rsquo;exp&eacute;rience avec
                                passion et curiosit&eacute;.
                            </p>
                            <p>
                                A leurs c&ocirc;t&eacute;s, nous sommes
                                convaincus que nous pouvons ensemble vous
                                apprendre les gestes et les techniques avec
                                autant de pr&eacute;cision, de rigueur et
                                d&rsquo;&eacute;change qu&rsquo;un cours
                                pr&eacute;sentiel. Qu'il s'agisse&nbsp;
                                <strong>de cours en ligne</strong>&nbsp;
                                <strong>de couture,</strong>&nbsp;
                                <strong>de broderie</strong>&nbsp;
                                <strong>ou de tricot,</strong>&nbsp;
                                <strong>de cuisine,</strong>&nbsp;
                                <strong>de p&acirc;tisserie</strong>&nbsp;
                                <strong>ou de mixologie</strong>,&nbsp;
                                <strong>de dessin,</strong>&nbsp;
                                <strong>de peinture&nbsp;ou de photo,</strong>
                                &nbsp;nous poursuivons toujours avec
                                assiduit&eacute; le m&ecirc;me objectif&nbsp;:
                                vous transmettre avec la m&ecirc;me exigence que
                                nos professeurs les gestes de l&rsquo;expert.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="qui-sommes-nous-informations-wrapper">
                    <div className="qui-sommes-nous-image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/photo3.jpg"
                            }
                            alt="équipe de tournage"
                        />{" "}
                    </div>
                    <div className="qui-sommes-nous-text-information">
                        <h2>
                            Les meilleures technologies au service de nos cours
                            vid&eacute;o
                        </h2>
                        <div className="qui-sommes-nous-description">
                            <p>
                                Au travers des yeux vifs et curieux de nos huit
                                cam&eacute;ras, vous d&eacute;couvrez les
                                techniques artistiques et manuelles les plus
                                pointues. Que vous soyez{" "}
                                <strong>d&eacute;butant ou expert</strong>,
                                amateur passionn&eacute; ou en qu&ecirc;te de{" "}
                                <strong>reconversion professionnelle</strong>,
                                nos cours con&ccedil;us pour tous les publics
                                r&eacute;pondent &agrave; votre besoin de
                                p&eacute;dagogie{" "}
                                <strong>&agrave; distance</strong>.
                            </p>
                            <p>
                                Nous avons rassembl&eacute; les meilleures
                                technologies, les plus adapt&eacute;es au format
                                du <strong>cours vid&eacute;o en ligne</strong>,
                                pour vous permettre de suivre avec confort et
                                sous tous les angles nos enseignements dans des
                                th&eacute;matiques aussi vari&eacute;es que les
                                <strong>
                                    {" "}
                                    finitions haute couture, la broderie or, les
                                    techniques de cuisson ou les bases du
                                    paysage &agrave; l'aquarelle
                                </strong>
                                . Chez Artesane, vous pouvez apprendre{" "}
                                <strong>
                                    &agrave; coudre, &agrave; broder, &agrave;
                                    tricoter, &agrave; cuisiner, &agrave;
                                    dessiner ou &agrave; peindre et &agrave;
                                    photographier
                                </strong>{" "}
                                dans les r&egrave;gles de l'art.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="qui-sommes-nous-informations-wrapper">
                    <div className="qui-sommes-nous-image-wrapper">
                        <img
                            src={
                                "https://static.artesane.com/images_react/photo1.jpg"
                            }
                            alt="cuisine"
                        />{" "}
                    </div>
                    <div className="qui-sommes-nous-text-information">
                        <h2>
                            Un format vid&eacute;o et p&eacute;dagogique
                            in&eacute;dit
                        </h2>
                        <div className="qui-sommes-nous-description">
                            <p>
                                <strong>&nbsp; </strong>Au-del&agrave; de la
                                technique, nous avons pens&eacute; un format
                                vid&eacute;o qui satisfasse notre
                                obsession&nbsp;: vous apporter plus, beaucoup
                                plus, que de simples tutoriels. Nos cours sont
                                fournis, vivants, interactifs, fouill&eacute;s,
                                et vous projettent aux c&ocirc;t&eacute;s de nos
                                professeurs dans un atelier ou une cuisine pour
                                r&eacute;aliser avec eux des{" "}
                                <strong>projets cr&eacute;atifs</strong>{" "}
                                ambitieux.
                            </p>
                            <p>
                                Que vous souhaitiez&nbsp;
                                <strong>apprendre la couture,</strong>&nbsp;
                                <strong>la cuisine</strong>&nbsp;
                                <strong>ou le dessin en ligne,</strong>
                                &nbsp;vous pouvez voir et revoir chaque geste
                                sous tous les angles et les professeurs
                                d&eacute;cortiquent pour vous la th&eacute;orie
                                et la pratique des plus belles techniques des{" "}
                                <strong>m&eacute;tiers de l'artisanat</strong>.
                                Nos cours en ligne sont accessibles &agrave;
                                vie&nbsp;: vous pouvez les voir, les revoir et
                                petit &agrave; petit reproduire les gestes de
                                vos professeurs &agrave; la perfection.
                            </p>
                            <p>
                                Forts de centaines de tournages dans des
                                dizaines de disciplines cr&eacute;atives, nous
                                avons fait de la transmission des savoir-faire
                                notre m&eacute;tier, et sommes fiers et
                                reconnaissants de voir nos &eacute;l&egrave;ves
                                prouver chaque jour qu'il est possible
                                d'apprendre, o&ugrave; que l'on soit et quel que
                                soit son niveau initial, l'art et la
                                mani&egrave;re.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuiSommesNous
