import { useEffect } from "react"
import "./RedirectProduct.scss"
import { useParams, Navigate, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchProductData,
    isLoadingProduct,
} from "../../../actions/productsActions"
import Spinner from "../../Commons/Spinner/Spinner"

const RedirectProduct = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { slug } = useParams()
    const notFoundProduct = useSelector(
        (state) => state.products.notFoundProduct
    )
    const isLoadingProductData = useSelector(
        (state) => state.products.isLoadingProduct
    )
    const productData = useSelector(
        (state) => state.products.productData
    )

    useEffect(() => {
        dispatch(isLoadingProduct(true))
        dispatch(fetchProductData(slug))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug])

    useEffect(() => {
        if (notFoundProduct) {
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notFoundProduct])
    return (
        <div className="redirect-product-wrapper">
            {isLoadingProductData && <Spinner />}
            {!isLoadingProductData && !notFoundProduct && (
                <Navigate
                    to={`/${productData.universes[0].code}/produit/${slug}`}
                    replace={true}
                />
            )}
        </div>
    )
}

export default RedirectProduct
