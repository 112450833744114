//Styles
import "./Partenaires.scss"

//Hooks
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import Header from "../../Home/Header/Header"
import NotFound from "../NotFound/NotFound"
import Banner from "../../Banner/Banner"

const Partenaires = () => {
    const { univers } = useParams()

    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | Nos partenaires  " +
            (univers === "arts-du-fil"
                ? "Arts du fil "
                : univers === "arts-culinaires"
                  ? "Arts culinaires"
                  : "Arts plastiques")
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Nos partenaires" +
                (univers === "arts-du-fil"
                    ? "Arts du fil "
                    : univers === "arts-culinaires"
                      ? "Arts culinaires"
                      : "Arts plastiques")
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [univers])

    return (
        <div>
            {(univers === "arts-du-fil" ||
                univers === "arts-culinaires" ||
                univers === "arts-plastiques") && (
                <>
                    <Banner univers={univers} />
                    <Header />
                    <div className="partenaires-wrapper">
                        <h1> Nos partenaires {univers.split("-").join(" ")}</h1>
                        <div className="partenaire-top-description">
                            <p>
                                Si Artesane est aujourd&rsquo;hui ce
                                qu&rsquo;elle est, c&rsquo;est aussi gr&acirc;ce
                                au soutien sans faille de partenaires que nous
                                tenons &agrave; remercier chaleureusement pour
                                leur soutien et leur confiance. Nous sommes
                                tr&egrave;s fiers d&rsquo;avoir pu nouer de
                                solides partenariats avec de tr&egrave;s belles
                                marques&nbsp;: nous vous les recommandons sans
                                h&eacute;sitation, pour la qualit&eacute; de
                                leurs produits avec lesquels nous travaillons
                                r&eacute;guli&egrave;rement, professionnellement
                                comme personnellement. C&rsquo;est donc avec
                                plaisir que nous vous les pr&eacute;sentons ici.
                            </p>
                        </div>
                        {univers === "arts-du-fil" && (
                            <>
                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/pfaff.jpg"
                                            }
                                            alt="logo de pfaff"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Pfaff
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Depuis plus de 150 ans,
                                                PFAFF&reg; a
                                                d&eacute;velopp&eacute; des
                                                machines &agrave; coudre haut de
                                                gamme, se diff&eacute;renciant
                                                par leur apparence
                                                sophistiqu&eacute;e, une
                                                performance sup&eacute;rieure,
                                                et offrant une libert&eacute; de
                                                conception ultime
                                            </p>
                                            <p>
                                                Une surveillance &eacute;troite
                                                sur les besoins et les tendances
                                                du secteur est devenue
                                                importante car la couture a
                                                &eacute;volu&eacute;, partant
                                                d'une n&eacute;cessit&eacute; et
                                                devenant une forme d'expression
                                                cr&eacute;ative : c&rsquo;est de
                                                ce constat que PFAFF tire ses
                                                inspirations pour penser le
                                                d&eacute;veloppement de ses
                                                produits. Par cons&eacute;quent,
                                                les produits PFAFF incarnent la
                                                technologie innovante attendue
                                                par les cr&eacute;ateurs les
                                                plus passionn&eacute;s
                                                d'aujourd'hui et par ceux qui
                                                veulent se d&eacute;marquer.
                                            </p>
                                            <p>
                                                Peu importe ce que sera
                                                l&rsquo;avenir, chaque produit
                                                PFAFF continuera &agrave;
                                                &ecirc;tre
                                                caract&eacute;ris&eacute; par la
                                                fiabilit&eacute; et la totale
                                                libert&eacute; de conception.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Singer.png"
                                            }
                                            alt="logo de singer"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Singer
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                C&rsquo;est l&rsquo;histoire
                                                d&rsquo;une marque de machines
                                                &agrave; coudre devenue vite
                                                indispensable &agrave; un grand
                                                nombre de foyers en France et
                                                dans le monde. Succ&egrave;s
                                                d&rsquo;hier et de demain,
                                                SINGER parcourt les
                                                si&egrave;cles en renouvelant
                                                ses{" "}
                                                <strong>
                                                    trois engagements
                                                </strong>
                                                .
                                            </p>
                                            <p>
                                                Le premier est{" "}
                                                <strong>
                                                    l&rsquo;expertise
                                                </strong>
                                                &nbsp;: depuis 1851, SINGER
                                                allie{" "}
                                                <strong>
                                                    esprit de conception
                                                    pratique
                                                </strong>{" "}
                                                et{" "}
                                                <strong>
                                                    innovation cr&eacute;ative{" "}
                                                </strong>
                                                pour proposer la machine qui
                                                correspond aux envies et besoins
                                                de chacun. Qualit&eacute;,
                                                fiabilit&eacute;, innovation et
                                                service sont au rendez-vous. Le
                                                second est le{" "}
                                                <strong>service</strong>&nbsp;:
                                                l&rsquo;atmosph&egrave;re
                                                conviviale de ses points de
                                                vente est coupl&eacute;e
                                                &agrave; des{" "}
                                                <strong>
                                                    conseils et
                                                    d&eacute;monstrations
                                                    d&rsquo;expert
                                                </strong>{" "}
                                                pour trouver la machine &agrave;
                                                coudre qui vous convient. Le
                                                troisi&egrave;me, enfin, est le{" "}
                                                <strong>
                                                    service apr&egrave;s-vente
                                                </strong>
                                                &nbsp;:{" "}
                                                <strong>
                                                    160 ann&eacute;es
                                                    d&rsquo;exp&eacute;rience
                                                </strong>
                                                , une tr&egrave;s grande
                                                sp&eacute;cialisation de ses
                                                produits et de leur technologie
                                                conf&egrave;rent &agrave; la
                                                marque SINGER l&rsquo;image
                                                rassurante d&rsquo;une
                                                organisation constitu&eacute;e
                                                de professionnels au service de
                                                leurs utilisatrices.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/rascol.jpg"
                                            }
                                            alt="logo de rascol"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            rascol
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                <strong>
                                                    Pionnier de la vente en
                                                    ligne{" "}
                                                </strong>
                                                depuis 2000, la Mercerie Rascol
                                                a &eacute;t&eacute;
                                                cr&eacute;&eacute;e en 1974
                                                &agrave; Montpellier. Son
                                                objectif&nbsp;: permettre
                                                &agrave; ses clients de
                                                cr&eacute;er et
                                                d&eacute;velopper leur
                                                cr&eacute;ativit&eacute;
                                                gr&acirc;ce &agrave; leur{" "}
                                                <strong>
                                                    catalogue de 40 000
                                                    r&eacute;f&eacute;rences
                                                </strong>{" "}
                                                en couture et mercerie. Vous
                                                trouverez chez Rascol des{" "}
                                                <strong>
                                                    produits
                                                    s&eacute;lectionn&eacute;s
                                                    avec soin
                                                </strong>{" "}
                                                en privil&eacute;giant au mieux
                                                les articles de qualit&eacute;,
                                                &eacute;co-responsables et
                                                durables que ce soit pour les
                                                tissus, patrons de couture,
                                                boutons, mat&eacute;riel de
                                                mercerie, ciseaux, aiguilles,
                                                ruban, etc. Outil classique ou
                                                derni&egrave;re tendance&nbsp;:
                                                une{" "}
                                                <strong>
                                                    &eacute;quipe de
                                                    passionn&eacute;s
                                                </strong>{" "}
                                                r&eacute;pond &agrave; tous vos
                                                besoins et n&rsquo;h&eacute;site
                                                pas &agrave; vous conseiller sur
                                                le choix et l&rsquo;utilisation
                                                de leurs produits.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Bohin.png"
                                            }
                                            alt="logo de bohin"
                                        />{" "}
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            bohin
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                H&eacute;riti&egrave;re d'un
                                                pass&eacute; industriel glorieux
                                                fond&eacute; sur l'apprentissage
                                                d'un savoir-faire
                                                perp&eacute;tr&eacute; depuis le
                                                XVIe si&egrave;cle,
                                                l&rsquo;entreprise BOHIN
                                                distribue, con&ccedil;oit et
                                                produit dans sa manufacture
                                                pr&egrave;s de 2300
                                                r&eacute;f&eacute;rences parmi
                                                lesquelles les
                                                c&eacute;l&egrave;bres aiguilles
                                                &agrave; coudre, aiguilles
                                                artisanales, &eacute;pingles,
                                                &eacute;pingles &agrave;
                                                t&ecirc;te de verre de Murano,
                                                &eacute;pingles fourche,
                                                &eacute;pingles de suret&eacute;
                                                &agrave; boules, bracelets
                                                pelote, agrafes de confection.
                                            </p>
                                            <p>
                                                Depuis 1833, BOHIN France
                                                conserve ce patrimoine unique
                                                dans un souci de
                                                pr&eacute;server l'extr&ecirc;me
                                                qualit&eacute; de ses produits
                                                et sa position de
                                                sp&eacute;cialiste du
                                                mat&eacute;riel de couture et
                                                des loisirs cr&eacute;atifs.
                                                Entreprise fran&ccedil;aise,
                                                &agrave; taille humaine et
                                                responsable, BOHIN a
                                                &eacute;t&eacute;
                                                labellis&eacute;e entreprise du
                                                patrimoine vivant : son
                                                excellence artisanale se lit
                                                dans la fiabilit&eacute; sans
                                                faille de ses produits.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/stragier.png"
                                            }
                                            alt="logo de stragier"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Stragier
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Stragier est une entreprise
                                                familiale qui a fait ses
                                                premiers pas en 1935.
                                                Install&eacute;e aujourd'hui
                                                &agrave; Nil-Saint-Vincent, dans
                                                une &eacute;co-construction en
                                                plein c&oelig;ur de la nature,
                                                elle compte de nombreux{" "}
                                                <strong>
                                                    d&eacute;partements
                                                    sp&eacute;cialis&eacute;s
                                                    dans le domaine textile
                                                </strong>
                                                .
                                            </p>
                                            <p>
                                                Depuis les plus belles dentelles
                                                pour la mari&eacute;e jusqu'au
                                                coton fin et doux pour les
                                                v&ecirc;tements de
                                                b&eacute;b&eacute;, Stragier
                                                offre &agrave; ses clients{" "}
                                                <strong>
                                                    le meilleur des
                                                    mati&egrave;res textiles
                                                </strong>
                                                . Du tissu &agrave; la mercerie,
                                                Stragier propose gr&acirc;ce
                                                &agrave; son regard expert des
                                                produits de{" "}
                                                <strong>
                                                    tr&egrave;s haute
                                                    qualit&eacute;
                                                </strong>{" "}
                                                dans des{" "}
                                                <strong>
                                                    mati&egrave;res et coloris
                                                </strong>{" "}
                                                <strong>vari&eacute;s</strong>.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/dmc.png"
                                            }
                                            alt="logo de dmc"
                                        />{" "}
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            dmc
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Cr&eacute;&eacute; en 1746 par
                                                Jean Henri Dollfus &agrave;
                                                Mulhouse, le groupe DMC cultive
                                                la{" "}
                                                <strong>
                                                    richesse de son patrimoine
                                                </strong>{" "}
                                                et met l&rsquo;accent depuis le
                                                d&eacute;but de son histoire sur
                                                la qualit&eacute; en apportant
                                                un soin tout particulier
                                                &agrave; la cr&eacute;ation de
                                                ses produits.
                                            </p>
                                            <p>
                                                Aujourd&rsquo;hui,{" "}
                                                <strong>
                                                    DMC continue de produire en
                                                    France
                                                </strong>{" "}
                                                dans son usine &agrave;
                                                Mulhouse, le plus
                                                c&eacute;l&egrave;bre de ses
                                                fils : le{" "}
                                                <strong>
                                                    Moulin&eacute;
                                                    Sp&eacute;cial
                                                </strong>{" "}
                                                (pr&egrave;s d&rsquo;un million
                                                d&rsquo;&eacute;chevettes
                                                vendues par jour).{" "}
                                                <strong>
                                                    Leader dans la fabrication
                                                    de fils &agrave; broder
                                                </strong>
                                                , DMC est &eacute;galement
                                                pr&eacute;sent sur le
                                                march&eacute; de la laine et
                                                renouvelle chaque ann&eacute;e
                                                ses collections vendues aux
                                                quatre coins du monde.
                                                Aujourd&rsquo;hui, DMC est un{" "}
                                                <strong>
                                                    groupe international
                                                </strong>{" "}
                                                exportant dans plus de 60 pays
                                                sur les 5 continents.
                                            </p>
                                            <p>
                                                &Agrave; tout moment, la{" "}
                                                <strong>
                                                    qualit&eacute;
                                                    sup&eacute;rieure
                                                </strong>{" "}
                                                des produits, la{" "}
                                                <strong>satisfaction</strong>{" "}
                                                client, la{" "}
                                                <strong>
                                                    responsabilit&eacute;{" "}
                                                </strong>
                                                sociale, l&rsquo;
                                                <strong>innovation</strong> et
                                                la{" "}
                                                <strong>
                                                    cr&eacute;ativit&eacute;
                                                </strong>{" "}
                                                ainsi que la prise en compte des
                                                contraintes environnementales
                                                doivent demeurer les valeurs
                                                fondamentales partag&eacute;es
                                                qui guident DMC dans son
                                                &eacute;volution, au service des
                                                consommateurs.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Fiskars.png"
                                            }
                                            alt="logo de fiskars"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            fiskars
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Fiskars est une marque
                                                Finlandaise qui prend part aux
                                                &eacute;volutions des outils
                                                depuis 1649&nbsp;:{" "}
                                                <strong>
                                                    365 ans d&rsquo;histoire
                                                </strong>
                                                , c&rsquo;est la preuve de leur
                                                engagement en faveur de la
                                                qualit&eacute;. Les produits
                                                Fiskars sont{" "}
                                                <strong>
                                                    ing&eacute;nieux et
                                                    efficaces
                                                </strong>
                                                ,d&rsquo;une convivialit&eacute;
                                                unique, d&rsquo;une{" "}
                                                <strong>
                                                    long&eacute;vit&eacute;
                                                </strong>{" "}
                                                remarquable, des ic&ocirc;nes{" "}
                                                <strong>
                                                    esth&eacute;tiques
                                                </strong>
                                                &nbsp;: ils ont ainsi
                                                remport&eacute; les prix Red Dot
                                                Awards, Good Design Awards,
                                                Popular Mechanics Green Design
                                                Awards, Ease-of-Use
                                                Commendations et bien d'autres.
                                            </p>
                                            <p>
                                                Avec plus d'un milliard de
                                                ciseaux vendus depuis leur
                                                cr&eacute;ation en 1967, le
                                                design remarquable de leurs{" "}
                                                <strong>
                                                    ciseaux &agrave; anneaux
                                                    orange
                                                </strong>{" "}
                                                les rend irrempla&ccedil;ables
                                                dans le monde entier.
                                            </p>
                                            <p>
                                                Fiskars s&rsquo;engage
                                                &eacute;galement pour des{" "}
                                                <strong>
                                                    produits durables
                                                </strong>{" "}
                                                en privil&eacute;giant la
                                                long&eacute;vit&eacute; du
                                                produit, des mat&eacute;riaux
                                                innovants, et en facilitant
                                                l&rsquo;&eacute;conomie
                                                circulaire.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/lafayette_saltiel_drapiers.jpeg"
                                            }
                                            alt="logo de lafayette saltier drapiers"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Lafayette Saltiel Drapiers
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Lafayette Saltiel Drapiers est
                                                une{" "}
                                                <strong>
                                                    entreprise familiale
                                                </strong>{" "}
                                                qui existe depuis quatre
                                                g&eacute;n&eacute;rations.
                                            </p>
                                            <p>
                                                Ils sont actuellement cinq
                                                collaborateurs dans leur
                                                boutique situ&eacute;e dans le 2
                                                <sup>&egrave;me</sup>{" "}
                                                arrondissement de Paris, et
                                                proposent plus de{" "}
                                                <strong>
                                                    3500 articles de mercerie
                                                    professionnelle
                                                </strong>{" "}
                                                suivis et en stock dans leurs
                                                locaux parisiens. Ils sont
                                                &eacute;galement{" "}
                                                <strong>
                                                    repr&eacute;sentants
                                                    exclusifs
                                                </strong>{" "}
                                                de plusieurs maisons de tissus
                                                italiennes et anglaises.
                                            </p>
                                            <p>
                                                Leur m&eacute;tier est le{" "}
                                                <strong>conseil</strong> et
                                                l&rsquo;
                                                <strong>accompagnement</strong>,
                                                c&rsquo;est ainsi que leur
                                                exp&eacute;rience et leur offre
                                                de produits prennent tous leurs
                                                sens. Ils sont fiers de proposer
                                                des produits de qualit&eacute;
                                                et des conseils
                                                personnalis&eacute;s, en accord
                                                avec leur vision et devise :
                                                &laquo;{" "}
                                                <strong>
                                                    La qualit&eacute; ne doit
                                                    pas &ecirc;tre un luxe
                                                </strong>{" "}
                                                &raquo;.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/1083.jpeg"
                                            }
                                            alt="logo de 1083"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            1083
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Savez-vous qu&rsquo;un jeans
                                                conventionnel parcourt
                                                jusqu&rsquo;&agrave; 65 000 km
                                                lors de sa fabrication alors que
                                                1083 km seulement
                                                s&eacute;parent les 2 villes les
                                                plus &eacute;loign&eacute;es de
                                                l&rsquo;hexagone ? En 2013, la
                                                marque 1083 s&rsquo;est
                                                lanc&eacute; comme d&eacute;fi
                                                de{" "}
                                                <strong>
                                                    relocaliser &agrave; moins
                                                    de 1083 km de chez vous la
                                                    fabrication de jeans
                                                </strong>{" "}
                                                et de chaussures pour
                                                cr&eacute;er un maximum
                                                d&rsquo;emplois et de plaisir !
                                            </p>
                                            <p>
                                                Chercher, apprendre, essayer,
                                                corriger, &eacute;chouer,
                                                inventer, c&rsquo;est le
                                                quotidien de la{" "}
                                                <strong>
                                                    reconstruction d&rsquo;une
                                                    fili&egrave;re textile
                                                    fran&ccedil;aise
                                                    compl&egrave;te
                                                </strong>
                                                , de la filature du coton
                                                jusqu&rsquo;&agrave; la
                                                confection&nbsp;: en faisant le
                                                lien entre retours
                                                d&rsquo;exp&eacute;rience et
                                                recherches, la{" "}
                                                <strong>qualit&eacute;</strong>{" "}
                                                et l&rsquo;
                                                <strong>
                                                    &eacute;co-conception{" "}
                                                </strong>
                                                des jeans 1083 ne font que
                                                progresser.
                                            </p>
                                            <p>
                                                Entre cr&eacute;ation
                                                d&rsquo;emplois et
                                                r&eacute;flexion autour de la
                                                production et des
                                                mati&egrave;res, 1803 a su
                                                r&eacute;inventer le jeans pour
                                                proposer un{" "}
                                                <strong>
                                                    produit aussi
                                                    esth&eacute;tique que
                                                    respectueux de
                                                    l&rsquo;&eacute;cosyst&egrave;me
                                                </strong>{" "}
                                                dans lequel il s&rsquo;inscrit.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/neelam.png"
                                            }
                                            alt="logo de neelam"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Neelam
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Jeune marque de textile
                                                responsable, NEELAM
                                                d&eacute;sire{" "}
                                                <strong>
                                                    promouvoir les richesses
                                                    artisanales
                                                </strong>{" "}
                                                cach&eacute;es aux quatre coins
                                                de la plan&egrave;te. Dans notre
                                                soci&eacute;t&eacute; qui tend
                                                &agrave; s&rsquo;uniformiser,
                                                les cr&eacute;ateurs de NEELAM
                                                font le choix de travailler en{" "}
                                                <strong>
                                                    partenariat durable avec des
                                                    artisans
                                                </strong>{" "}
                                                pour valoriser leurs
                                                savoir-faire et prot&eacute;ger
                                                leur diversit&eacute;
                                                culturelle. De leurs voyages
                                                autour du globe, les deux
                                                fondateurs de NEELAM rapportent
                                                avec eux des{" "}
                                                <strong>
                                                    mati&egrave;res
                                                    exceptionnelles
                                                </strong>{" "}
                                                et des{" "}
                                                <strong>
                                                    savoir-faire uniques
                                                </strong>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/mapetitemercerie.jpg"
                                            }
                                            alt="logo de ma petite mercerie"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            ma petite mercerie
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                <strong>
                                                    Aventure humaine et
                                                    entrepreneuriale
                                                </strong>
                                                , Ma Petite Mercerie a
                                                d&eacute;velopp&eacute; une
                                                activit&eacute;{" "}
                                                <strong>100% en ligne</strong>{" "}
                                                mais bien ancr&eacute;e dans son
                                                territoire, &agrave; Gaillac
                                                dans le Tarn. L&rsquo;entreprise{" "}
                                                <strong>
                                                    dynamise
                                                    l&rsquo;&eacute;cosyst&egrave;me
                                                    local
                                                </strong>{" "}
                                                tout en proposant &agrave; des
                                                clients de partout un choix de{" "}
                                                <strong>
                                                    plus de 80&nbsp;000 produits
                                                </strong>{" "}
                                                s&eacute;lectionn&eacute;s pour
                                                leur{" "}
                                                <strong>qualit&eacute;</strong>,{" "}
                                                <strong>
                                                    respect de
                                                    l&rsquo;environnement
                                                </strong>{" "}
                                                et{" "}
                                                <strong>
                                                    originalit&eacute;
                                                </strong>
                                                , privil&eacute;giant le Made in
                                                France et les cr&eacute;ateurs.
                                            </p>
                                            <p>
                                                En s&rsquo;inspirant des
                                                mouvements artistiques et des
                                                derniers courants de mode, Ma
                                                Petite Mercerie anime une
                                                communaut&eacute; de{" "}
                                                <strong>
                                                    700&nbsp;000 membres
                                                </strong>{" "}
                                                <strong>
                                                    passionn&eacute;s
                                                </strong>{" "}
                                                et cultive ainsi avec eux son
                                                potager artistique au
                                                quotidien&nbsp;!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {univers === "arts-plastiques" && (
                            <>
                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Faber-Castell.png"
                                            }
                                            alt="logo de faber castell"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            faber-castell
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Faber-Castell d&eacute;veloppe
                                                depuis 1761 un{" "}
                                                <strong>
                                                    savoir-faire unique dans la
                                                    production d&rsquo;outils de
                                                    dessin
                                                </strong>{" "}
                                                technique et artistique. Son
                                                produit-phare&nbsp;? Le{" "}
                                                <strong>crayon en bois</strong>,
                                                qu&rsquo;il produit en plus de 2
                                                milliards d&rsquo;exemplaires
                                                par an.
                                            </p>
                                            <p>
                                                Les valeurs essentielles de
                                                Faber-Castell pr&eacute;sident
                                                &agrave; toute leur
                                                activit&eacute;&nbsp;: la{" "}
                                                <strong>
                                                    comp&eacute;tence
                                                </strong>{" "}
                                                et la <strong>tradition</strong>
                                                , n&eacute;s d&rsquo;un
                                                savoir-faire ancr&eacute; dans
                                                une histoire longue&nbsp;; la{" "}
                                                <strong>qualit&eacute; </strong>
                                                constante de tous leurs
                                                produits&nbsp;; l&rsquo;
                                                <strong>innovation</strong> et
                                                la{" "}
                                                <strong>
                                                    cr&eacute;ativit&eacute;
                                                </strong>{" "}
                                                pour constamment
                                                s&rsquo;am&eacute;liorer&nbsp;;
                                                ainsi que la{" "}
                                                <strong>
                                                    responsabilit&eacute;
                                                    sociale et &eacute;cologique
                                                </strong>{" "}
                                                en assurant une production
                                                respectueuse de
                                                l&rsquo;environnement.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/rougieretple.png"
                                            }
                                            alt="logo de rougier et plé"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Rougier et plé
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Rougier et Pl&eacute; est une
                                                entreprise fran&ccedil;aise
                                                existant depuis{" "}
                                                <strong>165 ans</strong>.
                                            </p>
                                            <p>
                                                Fort de son r&eacute;seau de 50
                                                magasins en France, leur
                                                objectif est la{" "}
                                                <strong>
                                                    satisfaction totale
                                                </strong>{" "}
                                                de ses clients. En profonde
                                                &eacute;volution depuis quelques
                                                ann&eacute;es, ils ont vocation
                                                &agrave; devenir un des{" "}
                                                <strong>
                                                    tout premiers distributeurs
                                                    fran&ccedil;ais de
                                                    mat&eacute;riel de
                                                    beaux-arts et de loisirs
                                                    cr&eacute;atifs
                                                </strong>{" "}
                                                gr&acirc;ce &agrave; la
                                                satisfaction de leurs clients et
                                                donc, &agrave; leur
                                                fid&eacute;lit&eacute;.
                                            </p>
                                            <p>
                                                En choisissant Rougier &amp;
                                                Pl&eacute;, vous avez la
                                                garantie d&rsquo;une{" "}
                                                <strong>
                                                    entreprise fran&ccedil;aise
                                                </strong>{" "}
                                                ayant pignon sur rue, de la
                                                s&eacute;lection des{" "}
                                                <strong>
                                                    meilleurs produits
                                                </strong>{" "}
                                                aux meilleurs prix, d&rsquo;un{" "}
                                                <strong>
                                                    service professionnel{" "}
                                                </strong>
                                                et engag&eacute;, et d&rsquo;une{" "}
                                                <strong>
                                                    garantie de satisfaction
                                                </strong>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/colart.png"
                                            }
                                            alt="logo de colart"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            colart
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Le groupe Colart a pour objectif
                                                d&rsquo;
                                                <strong>
                                                    inspirer chaque artiste dans
                                                    le monde
                                                </strong>
                                                . Possesseurs d&rsquo;une{" "}
                                                <strong>
                                                    mine de connaissances
                                                </strong>{" "}
                                                sur la mani&egrave;re dont leur
                                                outils et services sont
                                                utilis&eacute;s dans le monde
                                                entier, Colart s&rsquo;efforce
                                                constamment de toucher tous ceux
                                                qui souhaitent &ecirc;tre
                                                cr&eacute;atifs.
                                            </p>
                                            <p>
                                                Leurs marques, qui{" "}
                                                <strong>
                                                    vont des beaux-arts
                                                    professionnels &agrave;
                                                    l&rsquo;artisanat
                                                </strong>
                                                , proposent une{" "}
                                                <strong>
                                                    vaste offre pour les
                                                    industries cr&eacute;atives
                                                </strong>
                                                , ce qui permet &agrave; leurs
                                                clients d&rsquo;exprimer leur
                                                cr&eacute;ativit&eacute; dans le
                                                monde entier. L'
                                                <strong>
                                                    histoire collective{" "}
                                                </strong>
                                                de toutes leurs marques explique
                                                comment ils continuent &agrave;
                                                produire des{" "}
                                                <strong>
                                                    outils de la plus haute
                                                    qualit&eacute;
                                                </strong>
                                                . Et tirer parti de leur
                                                histoire leur permet de
                                                d&eacute;velopper des outils
                                                innovants qui r&eacute;pondent
                                                aux besoins de chaque type de
                                                cr&eacute;ativit&eacute;.
                                            </p>
                                            <p>
                                                Du pinceau &agrave;
                                                l&rsquo;imprimerie,{" "}
                                                <strong>
                                                    les solutions
                                                    cr&eacute;atives commencent
                                                    avec les outils
                                                </strong>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/hahnemuhle.png"
                                            }
                                            alt="logo de hahnemühle"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Hahnemühle
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Hahnem&uuml;hle fabrique du
                                                papier avec passion, et
                                                privil&eacute;gie la{" "}
                                                <strong>qualit&eacute;</strong>{" "}
                                                &agrave; la production de
                                                masse&nbsp;: ils se sont
                                                d&rsquo;avantage une{" "}
                                                <strong>manufacture</strong>{" "}
                                                qu&rsquo;une importante usine.
                                                Les{" "}
                                                <strong>
                                                    meilleures mati&egrave;res
                                                    premi&egrave;res
                                                </strong>
                                                , un{" "}
                                                <strong>
                                                    savoir-faire artisanal
                                                </strong>
                                                , beaucoup de travail manuel et{" "}
                                                <strong>
                                                    une relation client de
                                                    qualit&eacute;
                                                </strong>{" "}
                                                veillent &agrave; la
                                                qualit&eacute; exceptionnelle,
                                                reconnue au niveau{" "}
                                                <strong>international</strong>,
                                                de leurs produits.
                                            </p>
                                            <p>
                                                Les papiers, sous le signe du
                                                coq, servent l&rsquo;art et les
                                                artistes depuis des
                                                si&egrave;cles et sont des
                                                supports fiables pour les
                                                applications techniques.
                                                L&rsquo;invention du &laquo;
                                                papier FineArt &raquo; illustre
                                                leur capacit&eacute;
                                                d&rsquo;innovation.
                                                Hahnem&uuml;hle est le pionnier
                                                et le{" "}
                                                <strong>
                                                    principal fournisseur de
                                                    papiers &agrave; jet
                                                    d&rsquo;encre FineArt
                                                </strong>{" "}
                                                &agrave; ce jour.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {univers === "arts-culinaires" && (
                            <>
                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Cristel.png"
                                            }
                                            alt="logo de cristel"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            cristel
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                CRISTEL, vendu dans une
                                                quarantaine de pays dans le
                                                monde, est une&nbsp;
                                                <strong>
                                                    r&eacute;f&eacute;rence de
                                                    qualit&eacute; dans le monde
                                                    du culinaire
                                                </strong>
                                                , &agrave; laquelle&nbsp;
                                                <strong>
                                                    Artesane est fi&egrave;re de
                                                    s&rsquo;associer
                                                </strong>{" "}
                                                pour proposer ses cours
                                                d&rsquo;arts culinaires.Leurs
                                                produits de cuisson en inox sont
                                                d&rsquo;ailleurs garantis
                                                &agrave; vie, gr&acirc;ce
                                                &agrave; leur rev&ecirc;tement
                                                r&eacute;novable :&nbsp;
                                                <strong>
                                                    on ne jette pas un produit
                                                    CRISTEL
                                                </strong>
                                                .
                                            </p>
                                            <p>
                                                CRISTEL est fi&egrave;re de
                                                d&eacute;clarer une&nbsp;
                                                <strong>
                                                    int&eacute;gration
                                                    industrielle de plus de 92%
                                                </strong>{" "}
                                                sur production propre, unique
                                                dans leur secteur
                                                d&rsquo;activit&eacute;.
                                            </p>
                                            <p>
                                                CRISTEL, c&rsquo;est aussi
                                                une&nbsp;
                                                <strong>
                                                    politique de
                                                    d&eacute;veloppement et
                                                    d&rsquo;innovation : l
                                                </strong>
                                                a Maison CRISTEL place au
                                                c&oelig;ur de ses valeurs&nbsp;
                                                <strong>
                                                    la recherche de
                                                    l&rsquo;excellence
                                                </strong>{" "}
                                                et d&rsquo;une &eacute;thique de
                                                qualit&eacute;, tant au niveau
                                                de sa fabrication de ses
                                                produits, qu&rsquo;au niveau
                                                social, soci&eacute;tal&nbsp;
                                                <strong>
                                                    et environnemental.
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Plum.png"
                                            }
                                            alt="logo de plum"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            plum
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Plum est n&eacute;e &agrave;
                                                Paris en 2020, &agrave;
                                                l&rsquo;initiative
                                                d&rsquo;Amandine et Marion, deux
                                                soeurs passionn&eacute;es
                                                d&rsquo;architecture
                                                int&eacute;rieure et
                                                port&eacute;es par&nbsp;
                                                <strong>
                                                    l&rsquo;envie de permettre
                                                    &agrave; chacun de vivre
                                                    dans un lieu qu&rsquo;il a
                                                    cr&eacute;&eacute;
                                                </strong>
                                                .
                                            </p>
                                            <p>
                                                Aujourd&rsquo;hui , se cache
                                                derri&egrave;re Plum une
                                                &eacute;quipe
                                                profond&eacute;ment
                                                anim&eacute;e par
                                                l&rsquo;id&eacute;e d&rsquo;
                                                <strong>
                                                    apporter les cl&eacute;s
                                                    pour cr&eacute;er cet
                                                    int&eacute;rieur unique
                                                </strong>
                                                , celui dont vous r&ecirc;vez.
                                            </p>
                                            <p>
                                                Notre mission est&nbsp;
                                                <strong>
                                                    d&rsquo;inspirer
                                                </strong>
                                                ,&nbsp;
                                                <strong>
                                                    proposer les meilleurs
                                                    produits
                                                </strong>
                                                , et donner les conseils pour
                                                mettre en oeuvre nos projets.
                                                Une cuisine, un dressing, une
                                                enfilade&hellip; une salle de
                                                bain ? Seule l&rsquo;imagination
                                                pose les limites de ce
                                                qu&rsquo;il est possible de
                                                cr&eacute;er avec Plum.
                                            </p>
                                            <p>
                                                C&rsquo;est donc naturellement
                                                qu&rsquo;Artesane s&rsquo;est
                                                tourn&eacute; vers Plum
                                                pour&nbsp;
                                                <strong>
                                                    construire la cuisine
                                                    Artesane
                                                </strong>{" "}
                                                !
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Kenwood.png"
                                            }
                                            alt="logo de kenwood"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            kenwood
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                En 1947, Kenneth Wood a eu une
                                                vision :&nbsp;
                                                <strong>
                                                    un avenir o&ugrave; les gens
                                                    seraient inspir&eacute;s par
                                                    une ing&eacute;nierie
                                                    brillante pour cuisiner de
                                                    mani&egrave;re
                                                    cr&eacute;ative au quotidien
                                                </strong>
                                                . Cette vision est maintenant
                                                l&rsquo;h&eacute;ritage de
                                                Kenwood, et continue
                                                d&rsquo;&eacute;voluer.
                                            </p>
                                            <p>
                                                <strong>
                                                    Artesane s&rsquo;est ainsi
                                                    associ&eacute; &agrave;
                                                    Kenwood
                                                </strong>{" "}
                                                pour la&nbsp;
                                                <strong>
                                                    qualit&eacute;
                                                </strong>{" "}
                                                et l&rsquo;
                                                <strong>excellence</strong> de
                                                ses produits,
                                                appr&eacute;ci&eacute;s par les
                                                cuisiniers et p&acirc;tissiers
                                                du monde entier :
                                                imagin&eacute;s et con&ccedil;us
                                                au&nbsp;
                                                <strong>Royaume-Uni</strong>,
                                                les produits Kenwood utilisent
                                                les meilleurs mat&eacute;riaux
                                                et sons test&eacute;s afin de
                                                garantir leur&nbsp;
                                                <strong>
                                                    fiabilit&eacute;
                                                </strong>{" "}
                                                pour les ann&eacute;es &agrave;
                                                venir.
                                            </p>
                                            <p>
                                                C&rsquo;est la passion qui guide
                                                Kenwood :&nbsp;
                                                <strong>
                                                    aimer cuisiner autant que
                                                    concevoir des produits
                                                </strong>{" "}
                                                est la ligne directrice des
                                                designers, ing&eacute;nieurs et
                                                experts techniques, qui sont
                                                fiers de voir leurs innovations
                                                r&eacute;compens&eacute;es
                                                ann&eacute;e apr&egrave;s
                                                ann&eacute;e dans les plus
                                                grands concours internationaux.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="partenaire-informations-wrapper">
                                    <div className="partenaire-image-wrapper">
                                        <img
                                            src={
                                                "https://static.artesane.com/images_react/Terroirs-du-liban.png"
                                            }
                                            alt="logo de terroirs du liban"
                                        />
                                    </div>
                                    <div className="partanaire-text-information">
                                        <div className="partenaire-name">
                                            Terroirs du liban
                                        </div>
                                        <div className="partenaire-description">
                                            <p>
                                                Terroirs du Liban{" "}
                                                <strong>
                                                    c&eacute;l&egrave;bre la
                                                    cuisine levantine
                                                </strong>{" "}
                                                et perp&eacute;tue des centaines
                                                d&rsquo;ann&eacute;es de
                                                traditions culinaires &agrave;
                                                travers une&nbsp;
                                                <strong>
                                                    s&eacute;lection unique de
                                                    sp&eacute;cialit&eacute;s
                                                    libanaises saines et
                                                    gourmandes
                                                </strong>
                                                . Issus directement de
                                                coop&eacute;ratives rurales,
                                                leurs produits sont
                                                confectionn&eacute;s sans le
                                                moindre ajout chimique ni
                                                artificiel, pr&eacute;servant
                                                ainsi toute leur
                                                authenticit&eacute; et leur
                                                naturalit&eacute;.
                                                C&rsquo;&eacute;tait donc
                                                le&nbsp;
                                                <strong>
                                                    partenaire id&eacute;al pour
                                                    les cours de cuisine
                                                    libanaise
                                                </strong>{" "}
                                                sur Artesane.
                                            </p>
                                            <p>
                                                Gourmets&hellip; et
                                                engag&eacute;s !&nbsp;
                                                <strong>
                                                    D&eacute;velopp&eacute;e par
                                                    l&rsquo;ONG&nbsp;Fair Trade
                                                    Lebanon
                                                </strong>
                                                , Terroirs du Liban soutient les
                                                communaut&eacute;s rurales
                                                situ&eacute;es &agrave; travers
                                                le pays en promouvant leurs mets
                                                d&rsquo;exception. Acteurs du
                                                commerce &eacute;quitable, ils
                                                agissent au quotidien pour un
                                                monde plus &eacute;thique et
                                                plus solidaire.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            {univers !== "arts-du-fil" &&
                univers !== "arts-culinaires" &&
                univers !== "arts-plastiques" && <NotFound />}
        </div>
    )
}

export default Partenaires
