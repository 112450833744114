//Styles
import "./Logo.scss"

//Hooks
import { useSelector } from "react-redux"

const Logo = () => {
    const isScrolling = useSelector((state) => state.home.scroll)
    const isStickiheader = useSelector((state) => state.common.isStickiheader)

    return (
        <div
            style={{
                transform: isStickiheader ? " translateY(-205px)" : "none",
                position: isStickiheader ? "fixed" : "relative",
                transition: "transform 0.3s ease-in-out",
                opacity: isStickiheader ? "0" : "1",
                WebkitTransition: "transform 0.3s ease-in-out",
                WebkitTransform: isStickiheader ? "translateY(-205px)" : "none",
                willChange: "transform, opacity",
            }}
            className={isScrolling ? "logo-scroll" : "logo"}>
            <img
                src={"https://static.artesane.com/images_react/logo.png"}
                alt="Logo du site Artesane"
            />
        </div>
    )
}

export default Logo
