//Styles
import "./ProfesseurCard.scss"

//Components
import { NavLink } from "react-router-dom"

const ProfesseurCard = ({ name, subjects, urlImage, bio, url }) => {
    return (
        <NavLink className="professeur-card-link" to={url}>
            <div className="professeur-card-wrapper">
                <div className="professeur-image-wrapper">
                    <img src={urlImage} alt={name} />
                </div>
                <div className="professeur-name">{name}</div>
                <div className="professeur-subjects">
                    {subjects.length === 1 && (
                        <span className="professor-home-card-subject-intitule">
                            {" "}
                            {subjects[0].taxon.name}{" "}
                        </span>
                    )}
                    {subjects.length > 1 && subjects.length < 3 && (
                        <span className="professor-home-card-subject-intitule">
                            {" "}
                            {subjects[0].taxon.name}, {subjects[1].taxon.name}
                        </span>
                    )}
                    {subjects.length > 2 && subjects.length < 10 && (
                        <span className="professor-home-card-subject-intitule">
                            {" "}
                            {subjects[0].taxon.name}, {subjects[1].taxon.name}{" "}
                            et {subjects[2].taxon.name}
                        </span>
                    )}
                    <span className="professeur-card-catalogue-line"></span>
                </div>
                <div
                    className="professeur-bio"
                    dangerouslySetInnerHTML={{ __html: bio }}></div>
                <div className="professeur-card-button-wrapper">
                    <div className="button">Voir tous ses cours {">"}</div>
                </div>
            </div>
        </NavLink>
    )
}

export default ProfesseurCard
