// replace createStore, it combines automatically many reducers and many middlewares : https://redux-toolkit.js.org/api/configureStore
import { configureStore } from "@reduxjs/toolkit"

import userReducer from "../reducers/userReducer"
import commonReducer from "../reducers/commonReducer"
import prepageReducer from "../reducers/prepageReducer"
import homeReducer from "../reducers/homeReducer"
import catalogueReducer from "../reducers/catalogueReducer"
import productsReducer from "../reducers/productsReducer"
import professorsReducer from "../reducers/professorsReducer"
import authReducer from "../reducers/authReducer"

import apiMiddleware from "../middlewares/apiMiddleware"
import mediaReducer from "../reducers/mediaReducer"
import cartReducer from "../reducers/cartReducer"

const reducer = {
    user: userReducer,
    common: commonReducer,
    prepage: prepageReducer,
    home: homeReducer,
    catalogue: catalogueReducer,
    products: productsReducer,
    professors: professorsReducer,
    auth: authReducer,
    media: mediaReducer,
    cart: cartReducer,
}

const middleware = [apiMiddleware]
const store = configureStore({ reducer, middleware })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
export default store
