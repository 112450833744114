import "./Title.scss"

const Title = ({ upperText, downText }) => {
    return (
        <div>
            <div className="professeur-catalogue-uppertext">
                {upperText}

                {downText !== undefined && (
                    <div className="professeur-catalogue-downtext">
                        {downText.length === 1 && (
                            <span className="professor-home-card-subject-intitule">
                                {" "}
                                {downText[0].taxon.name}{" "}
                            </span>
                        )}
                        {downText.length > 1 && downText.length < 3 && (
                            <span className="professor-home-card-subject-intitule">
                                {" "}
                                {downText[0].taxon.name} et{" "}
                                {downText[1].taxon.name}
                            </span>
                        )}
                        {downText.length > 2 && downText.length < 10 && (
                            <span className="professor-home-card-subject-intitule">
                                {" "}
                                {downText[0].taxon.name},{" "}
                                {downText[1].taxon.name} et{" "}
                                {downText[2].taxon.name}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Title
