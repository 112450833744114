import './AddressInsert.scss';
import { useDispatch, useSelector } from "react-redux"
import TitleCart from "../TittleCart/TitleCart"
import InputSimple from '../../../UserAccount/InputSimple/inputSimple';
import { changeInputOrder, putAddAddressOrder, toggleBillingAddress } from '../../../../actions/cartAction';
import ModifyAddress from '../../../UserAccount/Addresses/ModifyAddress/ModifyAddress';
import { isAddressesSame } from '../../../../selectors/selectors';
import { useEffect } from 'react';
import { fetchAddresses } from '../../../../actions/userAction';

const AddressInsert = () => {


    const dispatch = useDispatch();
    const lastNameShipping = useSelector((state) => state.cart.lastNameShipping)
    const firstNameShipping = useSelector(
        (state) => state.cart.firstNameShipping
    )
    const societyShipping = useSelector((state) => state.cart.societyShipping)
    const streetShipping = useSelector((state) => state.cart.streetShipping)
    const postCodeShipping = useSelector((state) => state.cart.postCodeShipping)
    const cityShipping = useSelector((state) => state.cart.cityShipping)
    const countryShipping = useSelector((state) => state.cart.countryShipping)
    const telephoneShipping = useSelector(
        (state) => state.cart.telephoneShipping
    )
    const errorTelephoneShipping = useSelector(
        (state) => state.cart.errorTelephoneShipping
    )

    const lastNameBilling = useSelector((state) => state.cart.lastNameBilling)
    const firstNameBilling = useSelector(
        (state) => state.cart.firstNameBilling
    )
    const societyBilling = useSelector((state) => state.cart.societyBilling)
    const streetBilling = useSelector((state) => state.cart.streetBilling)
    const postCodeBilling = useSelector((state) => state.cart.postCodeBilling)
    const cityBilling = useSelector((state) => state.cart.cityBilling)
    const countryBilling = useSelector((state) => state.cart.countryBilling)
    const telephoneBilling = useSelector(
        (state) => state.cart.telephoneBilling
    )
    const errorTelephoneBilling = useSelector(
        (state) => state.cart.errorTelephoneBilling
    )
    const isBillingAddressSame = useSelector(
        (state) => state.cart.sameAddressAsShippingAddress
    )
    const modifyAddressShipping = useSelector(
        (state) => state.cart.modifyAddressShipping
    )
    const modifyAddressBilling = useSelector(
        (state) => state.cart.modifyAddressBilling
    )
    const cartData = useSelector((state) => state.cart.cartData)




    return (
        <div className="adresseinsert-wrapper">
            {modifyAddressShipping && (
                                <ModifyAddress 
                                title={"Modifier l'adresse de livraison"}
                                toggleWindow={(param) => {
                                    dispatch(changeInputOrder(param, "modifyAddressShipping"))

                                    }
                                } 
                                errorPatternTelephone={(param) => {
                                    dispatch(
                                        changeInputOrder(
                                            param,
                                            "errorTelephoneShipping"
                                        )
                                    )
                                }}
                                errorTelephone={errorTelephoneShipping}
                                error = {(param) => {
                                    
            
                                }}
                                loading = {(param) => {
                                    dispatch(
                                        changeInputOrder(
                                            true,
                                            "loadingCart"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            true,
                                            "loadingValidateAddress"
                                        )
                                    )
                                }}
                                put={()=> {
                                    dispatch(
                                        putAddAddressOrder(
                                            cartData.tokenValue
                                        )
                                    )
                                }}
                                lastName={lastNameShipping}
                                firstName={firstNameShipping}
                                society={societyShipping}
                                street={streetShipping}
                                postCode={postCodeShipping}
                                city={cityShipping}
                                telephone={telephoneShipping}
                                country={countryShipping}
                                firstNameChangeInput = {
                                    (event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "firstNameShipping"
                                            )
                                        )
                                    }
                                   
                                }
            
                                lastNameChangeInput = {
                                    (event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "lastNameShipping"
                                            )
                                        )
                                    }
                                   
                                }
            
                                societyChangeInput = {
                                    (event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "societyShipping"
                                            )
                                        )
                                    }
                                   
                                }
                                streetChangeInput = {
                                    (event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "streetShipping"
                                            )
                                        )
                                    }
                                   
                                }
                                postCodeChangeInput = {
                                    (event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "postCodeShipping"
                                            )
                                        )
                                    }
                                }
            
                                cityChangeInput = {
                                    (event) => {
                                        dispatch(
                                            changeInputOrder(
                                                event.target
                                                    .value,
                                                "cityShipping"
                                            )
                                        )
                                    }
            
                                }
                                telephoneChangeInput = {(event) => {
                                    dispatch(
                                        changeInputOrder(
                                            event.target
                                                .value,
                                            "telephoneShipping"
                                        )
                                    )
                                }}
                                countryChangeInput = {(event) => {
                                    dispatch(
                                        changeInputOrder(
                                            event.target.value,
                                            "countryShipping"
                                        )
                                    )
                                }}
                            />
            )}

            {!isBillingAddressSame && modifyAddressBilling && (
                                                <ModifyAddress 
                                                title={"Modifier l'adresse de facturation"}
                                                toggleWindow={(param) => {
                                                    dispatch(changeInputOrder(param, "modifyAddressBilling"))
                                                    if(isAddressesSame(cartData.shippingAddress, cartData.billingAddress)) {
                                                        dispatch(changeInputOrder(true, "sameAddressAsShippingAddress"))
                                                    }
                                                }} 
                                                errorPatternTelephone={(param) => {
                                                    dispatch(
                                                        changeInputOrder(
                                                            param,
                                                            "errorTelephoneBilling"
                                                        )
                                                    )
                                                }}
                                                errorTelephone={errorTelephoneBilling}
                                                error = {(param) => {
                                                    
                            
                                                }}
                                                loading = {(param) => {
                                                    dispatch(
                                                        changeInputOrder(
                                                            true,
                                                            "loadingCart"
                                                        )
                                                    )
                                                    dispatch(
                                                        changeInputOrder(
                                                            true,
                                                            "loadingValidateAddress"
                                                        )
                                                    )
                                                }}
                                                put={()=> {
                                                    dispatch(
                                                        putAddAddressOrder(
                                                            cartData.tokenValue
                                                        )
                                                    )
                                                }}
                                                lastName={lastNameBilling}
                                                firstName={firstNameBilling}
                                                society={societyBilling}
                                                street={streetBilling}
                                                postCode={postCodeBilling}
                                                city={cityBilling}
                                                telephone={telephoneBilling}
                                                country={countryBilling}
                                                firstNameChangeInput = {
                                                    (event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "firstNameBilling"
                                                            )
                                                        )
                                                    }
                                                   
                                                }
                            
                                                lastNameChangeInput = {
                                                    (event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "lastNameBilling"
                                                            )
                                                        )
                                                    }
                                                   
                                                }
                            
                                                societyChangeInput = {
                                                    (event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "societyBilling"
                                                            )
                                                        )
                                                    }
                                                   
                                                }
                                                streetChangeInput = {
                                                    (event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "streetBilling"
                                                            )
                                                        )
                                                    }
                                                   
                                                }
                                                postCodeChangeInput = {
                                                    (event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "postCodeBilling"
                                                            )
                                                        )
                                                    }
                                                }
                            
                                                cityChangeInput = {
                                                    (event) => {
                                                        dispatch(
                                                            changeInputOrder(
                                                                event.target
                                                                    .value,
                                                                "cityBilling"
                                                            )
                                                        )
                                                    }
                            
                                                }
                                                telephoneChangeInput = {(event) => {
                                                    dispatch(
                                                        changeInputOrder(
                                                            event.target
                                                                .value,
                                                            "telephoneBilling"
                                                        )
                                                    )
                                                }}
                                                countryChangeInput = {(event) => {
                                                    dispatch(
                                                        changeInputOrder(
                                                            event.target.value,
                                                            "countryBilling"
                                                        )
                                                    )
                                                }}
                                            />
            )}
            <TitleCart title="Adresse"/>
            <div className='address-wrapper'>
            <div className='adress-wrapper_title'>Adresse de livraison :</div>
                <div className='address-wrapper-side'>
                    <div className='address-side left'>
                        <div className='adress-detail'> {cartData.shippingAddress.lastName} {cartData.shippingAddress.firstName}</div>
                        {cartData.shippingAddress.company !== undefined && (
                            <div className='adress-detail'> {cartData.shippingAddress.company}</div>
                        )}

                        <div className='adress-detail'>{cartData.shippingAddress.street}</div>
                        <div className='adress-detail'>{cartData.shippingAddress.postcode} {cartData.shippingAddress.city} </div>
                    </div>
                    <div className='address-side'>
                        <div className='adress-detail'> {cartData.shippingAddress.countryCode}</div>
                        <div className='adress-detail'> {cartData.shippingAddress.phoneNumber}</div>
                        <div 
                            className='button-transparent'
                            onClick={() => {
                                dispatch(changeInputOrder(!modifyAddressShipping, "modifyAddressShipping"));
                            }}
                        >
                            Modifier
                        </div>
                    </div>
                </div>
            {isBillingAddressSame && (
                    <InputSimple
                    className={"checkbox"}
                    // inputName={"defaultAddressAdd"}
                    label={
                        "Utiliser la même adresse pour la facturation"
                    }
                    value={isBillingAddressSame}
                    checked={isBillingAddressSame}
                    type={"checkbox"}
                    required={false}
                    onChange={(event) => {
                        if(!!isBillingAddressSame) {
                            dispatch(changeInputOrder(true, "modifyAddressBilling"));

                        }
                        dispatch(
                            toggleBillingAddress(
                                !isBillingAddressSame
                            )
                        )

                    }}
                />
            )}


            </div>

            {!isAddressesSame(cartData.shippingAddress, cartData.billingAddress) && !isBillingAddressSame && (
                            <div className='address-wrapper'>
                                <div className='adress-wrapper_title'>Adresse de facturation :</div>
                            <div className='address-wrapper-side'>
                                <div className='address-side left'>
                                    <div className='adress-detail'> {cartData.billingAddress.lastName} {cartData.billingAddress.firstName}</div>
                                    {cartData.billingAddress.company !== "" && (
                                        <div className='adress-detail'> {cartData.billingAddress.company}</div>
                                    )}
            
                                    <div className='adress-detail'>{cartData.billingAddress.street}</div>
                                    <div className='adress-detail'>{cartData.billingAddress.postcode} {cartData.billingAddress.city} </div>
                                </div>
                                <div className='address-side'>
                                    <div className='adress-detail'> {cartData.billingAddress.countryCode}</div>
                                    <div className='adress-detail'> {cartData.billingAddress.phoneNumber}</div>
                                    <div 
                                        className='button-transparent'
                                        onClick={() => {
                                            dispatch(changeInputOrder(!modifyAddressBilling, "modifyAddressBilling"));
                                        }}
                                    >
                                        Modifier
                                    </div>
                                </div>
                            </div>
                        </div>
            )}
           
        </div>

    )
}

export default AddressInsert;