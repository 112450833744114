export const CHANGE_CURRENT_PROFESSORS_PAGE = "CHANGE_CURRENT_PROFESSORS_PAGE"
export const FETCH_PROFESSEUR_CATALOGUE_DATA = "FETCH_PROFESSEUR_CATALOGUE_DATA"
export const SAVE_PROFESSEUR_CATALOGUE_DATA = "SAVE_PROFESSEUR_CATALOGUE_DATA"
export const SAVE_PROFESSOR_DATA_IN_STATE = "SAVE_PROFESSOR_DATA_IN_STATE"
export const FETCH_PROFESSEUR_PUBLIC_DATA = "FETCH_PROFESSEUR_PUBLIC_DATA"
export const SAVE_PROFESSEUR_PUBLIC_DATA = "SAVE_PROFESSEUR_PUBLIC_DATA"
export const FETCH_ALL_PROFESSORS = "FETCH_ALL_PROFESSORS"
export const SAVE_ALL_PROFESSORS = "SAVE_ALL_PROFESSORS"
export const SAVE_PROFESSORS_HIGHLIGHT = "SAVE_PROFESSORS_HIGHLIGHT"

export const saveProfessorDataInState = (property, newValue) => ({
    type: SAVE_PROFESSOR_DATA_IN_STATE,
    value: newValue,
    property: property,
})

export const saveAllProfessors = (newValue) => ({
    type: SAVE_ALL_PROFESSORS,
    value: newValue,
})

export const fetchAllProfessors = () => ({
    type: FETCH_ALL_PROFESSORS,
})

export const fetchProfesseurPublicData = (slug) => ({
    type: FETCH_PROFESSEUR_PUBLIC_DATA,
    value: slug,
})

export const saveProfesseurPublicData = (newValue, products) => ({
    type: SAVE_PROFESSEUR_PUBLIC_DATA,
    value: newValue,
    isLoading: false,
    products: products,
})

export const saveProfesseurCatalogueData = (newValue) => ({
    type: SAVE_PROFESSEUR_CATALOGUE_DATA,
    value: newValue,
    loading: false,
})

export const fetchProfesseurCatalogueData = (universId) => ({
    type: FETCH_PROFESSEUR_CATALOGUE_DATA,
    value: universId,
})

export const changeCurrentProfessorsPage = (newValue) => ({
    type: CHANGE_CURRENT_PROFESSORS_PAGE,
    value: newValue,
})

export const saveProfessorsHighlight = (newValue) => ({
    type: SAVE_PROFESSORS_HIGHLIGHT,
    value: newValue,
})
