//Styles
import "./Soul.scss"

//Hooks
import { useDispatch, useSelector } from "react-redux"
//Actions
import { toggleSoulButtonVoirPlus } from "../../../actions/homeActions"

const HomeSoul = () => {
    const dispatch = useDispatch()
    const isOpenButton = useSelector(
        (state) => state.home.isOpenToggleSoulButton
    )
    return (
        <div className="home-soul-wrapper">
            <div className="home-soul-title-first-part">l'âme</div>
            <div className="home-soul-title-second-part">d'artesane</div>

            <div className="home-soul-wrapper-sides">
                <div className="home-soul-wrapper-left">
                    <img
                        className="home-soul-wrapper-left-image desktop"
                        src={
                            "https://static.artesane.com/images_react/ame-artesane-desktop.png"
                        }
                        alt="l'âme d'artesane"
                    />
                    <img
                        className="home-soul-wrapper-left-image mobile"
                        src={
                            "https://static.artesane.com/images_react/ame-artesane-mobile.png"
                        }
                        alt="l'âme d'artesane"
                    />

                    {/* <p className='home-soul-wrapper-left-paragraph'>
                        Anne-Sarah Ballu-Samuel, fondatrice
                    </p> */}
                </div>

                <div className="home-soul-wrapper-right">
                    <div className="home-soul-title-second-part">
                        d'artesane
                    </div>

                    <section className="home-soul-wrapper-right-text">
                        <div className="home-soul-first-part">
                            <h2 className="home-soul-wrapper-right-text-title">
                                Artesane, notre école en ligne d'arts et
                                d'artisanat
                            </h2>
                            <div className="home-soul-wrapper-right-text-paragraph">
                                {" "}
                                Écrire ces quelques lignes sur l'âme d'Artesane,
                                c'est d'abord s'adresser à vous, nos élèves, qui
                                avez bâti au fil des années cette communauté
                                vivante, chaleureuse et passionnée. La passion
                                du geste, du savoir-faire et du travail bien
                                fait : voilà l'indicible ciment qui nous lie.
                                C'est sur les fondations de votre insatiable
                                désir d'apprendre et de progresser que nous
                                avons construit cette école virtuelle et
                                pourtant si vivante, si tangible, qu'est devenue
                                Artesane.{" "}
                            </div>

                            <h2 className="home-soul-wrapper-right-text-title">
                                Suivez nos cours en ligne de couture, de cuisine
                                et de dessin
                            </h2>
                            <div className="home-soul-wrapper-right-text-paragraph">
                                Vous qui entrez ici pour la première fois, vous
                                trouverez au sein de ces murs, des cours en
                                ligne d'un genre nouveau. Sur Artesane, les
                                professeurs enseignent en vidéo les{" "}
                                <strong>arts du fil,</strong> les{" "}
                                <strong>arts culinaires</strong> ou encore les{" "}
                                <strong>arts plastiques</strong>. À leurs côtés,
                                nous faisons le pari de parvenir à vous
                                apprendre le geste avec précision, rigueur et
                                chaleur. Qu'il s'agisse{" "}
                                <strong>de cours en ligne</strong>{" "}
                                <strong>de couture,</strong>{" "}
                                <strong>de broderie</strong>{" "}
                                <strong>ou de tricot,</strong>{" "}
                                <strong>de cuisine,</strong>{" "}
                                <strong>de pâtisserie</strong>{" "}
                                <strong>ou de mixologie </strong>,{" "}
                                <strong>de dessin,</strong>{" "}
                                <strong>de peinture </strong>{" "}
                                <strong>ou de photo,</strong> nos formations en
                                vidéo portent toujours la même intention :
                                l'exigence de nos professeurs avec eux-mêmes
                                pour vous transmettre leur savoir-faire au
                                travers des yeux vifs et curieux de nos huit
                                caméras.{" "}
                            </div>
                        </div>
                        <div
                            className={
                                isOpenButton
                                    ? "home-soul-second-part-open"
                                    : "home-soul-second-part"
                            }>
                            <div className="home-soul-wrapper-right-text-paragraph">
                                Rarement <strong>cours vidéo</strong> et
                                masterclasses n'auront été édités et filmés avec
                                autant de dynamisme, de justesse et de précision
                                que les <strong>cours en ligne Artesane</strong>
                                . Que vous soyez débutant ou expert, que vous
                                soyez amateur passionné ou en quête de
                                reconversion professionnelle, nous voulons vous
                                offrir un catalogue de cours en ligne dans les
                                arts et loisirs créatifs à la profondeur unique
                                au monde.{" "}
                            </div>

                            <div className="home-soul-wrapper-right-text-paragraph">
                                Nous avons osé produire des cours en ligne dans
                                chacune de nos trois branches principales -arts
                                du fil, arts culinaires et arts plastiques -
                                abordant des thématiques aussi variées que les
                                finitions haute couture, la broderie or, les
                                techniques de cuisson ou les bases du paysage à
                                l'aquarelle. À chaque fois, nous nous sommes
                                adressés{" "}
                                <strong>
                                    aux plus grands experts dans leur domaine,
                                </strong>{" "}
                                issus des grandes maisons, des meilleures écoles
                                et des restaurants les plus prestigieux, pour
                                vous permettre d'apprendre à votre rythme et
                                sous tous les angles, la technique appropriée,
                                le bon tour de main ou le secret d'atelier. Chez
                                Artesane, vous pouvez apprendre à coudre, à
                                broder, à tricoter, à cuisiner, à dessiner ou à
                                peindre et à photographier dans les règles de
                                l'art.
                            </div>

                            <h2 className="home-soul-wrapper-right-text-title">
                                Apprenez des meilleurs, en vidéo et à votre
                                rythme
                            </h2>
                            <div className="home-soul-wrapper-right-text-paragraph">
                                C'est donc cela la mission d'Artesane :{" "}
                                <strong>
                                    vous apprendre à créer auprès des meilleurs.
                                </strong>{" "}
                                Que vous souhaitiez{" "}
                                <strong>apprendre la couture,</strong>{" "}
                                <strong>la cuisine</strong>{" "}
                                <strong>ou le dessin en ligne,</strong> nous
                                avons pour obsession de vous apporter plus que
                                de simples tutoriels : nos cours sont riches,
                                vivants et interactifs. Vous pouvez voir et
                                revoir chaque geste sous tous les angles et les
                                professeurs décortiquent pour vous la théorie et
                                la pratique des plus belles techniques des
                                métiers de l'artisanat.{" "}
                                <strong>De la broderie traditionnelle</strong>{" "}
                                <strong>au patchwork,</strong>{" "}
                                <strong> du CAP couture flou </strong>{" "}
                                <strong>
                                    {" "}
                                    aux techniques de couture tailleur,
                                </strong>{" "}
                                <strong>du paysage à l'aquarelle </strong>{" "}
                                <strong>aux secrets de la céramique,</strong> en
                                passant par l'art de{" "}
                                <strong>faire des macarons</strong>{" "}
                                <strong>ou de réussir des pâtes,</strong> nous
                                abordons en profondeur chaque sujet et vous
                                accompagnons dans votre apprentissage. Nos cours
                                en ligne sont accessibles à vie, vous pouvez les
                                voir, les revoir et petit à petit reproduire les
                                gestes de vos professeurs à la perfection.
                            </div>

                            <div className="home-soul-wrapper-right-text-paragraph">
                                L'âme d'Artesane vibre à travers ses professeurs
                                et ses élèves, unis par la passion du geste et
                                du savoir-faire. Puissions nous ensemble prouver
                                qu'il est possible d'apprendre, où que l'on soit
                                et quel que soit son niveau initial, l'art et la
                                manière.
                            </div>

                            <div className="home-soul-wrapper-right-text-paragraph">
                                Anne-Sarah
                            </div>
                        </div>

                        <button
                            className="home-soul-button"
                            onClick={() => {
                                dispatch(
                                    toggleSoulButtonVoirPlus(!isOpenButton)
                                )

                                window.scrollTo({
                                    top: 4600,
                                    left: 0,
                                    behavior: "smooth",
                                })
                            }}>
                            Voir {isOpenButton ? "moins" : "plus"}
                            {isOpenButton ? " ↑" : " ↓"}
                        </button>
                    </section>
                </div>
            </div>
            {/* <div className='home-soul-wrapper-image'>
                <img className='home-soul-image' src={tournage} alt="" />
            </div> */}
        </div>
    )
}

export default HomeSoul
