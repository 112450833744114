import ReactPlayer from "react-player"

//Styles
import "./NewsLetter.scss"
import { NavLink } from "react-router-dom"

const NewsLetter = ({ previewImgUrl, videoUrl }) => {
    return (
        <div className="newsletter-home-wrapper">
            <div className="newsletter-home-left-side-wrapper">
                <div className="newsletter-home-titles">
                    <div className="newsletter-home-title">Un cours vidéo</div>
                    <div className="newsletter-home-title">offert</div>
                </div>
                <div className="newsletter-home-subtitles">
                    <div className="newsletter-home-subtitle">
                        en vous abonnant
                    </div>
                    <div className="newsletter-home-subtitle">
                        à la newsletter Artesane
                    </div>
                </div>
                <div className="newsletter-home-form-wrapper">
                    <div className="newsletter-home-input-wrapper">
                        {/* <form
                            name="mc-embedded-subscibe-form"
                            rel={"noopener"}
                            // noValidate
                            action="https://artesane.us13.list-manage.com/subscribe/post"
                            target="_blank"
                            method="post"
                            className="newsletter-home-form"
                            // onSubmit={(event) => (
                            //     // event.preventDefault()
                            // )}
                        >
                            <input type="hidden" name="u" value={"fed3adae0edf9e9d89854e25d"} />
                            <input type="hidden" name="id" value={"698c1acbba"} />
                            <input
                                className="newsletter-home-input"
                                placeholder="Mon e-mail"
                                type="email" 
                                id="email"
                                size="30"
                                required
                                name="EMAIL"
                                value={inputValue}
                                onChange={(event) => {
                                    dispatch(changeInputNewsletter(event.target.value))
                                }}
                            />
                            <button 
                                className="newsletter-home-button"
                                type="submit"
                            >   
                                je m'inscris
                            </button>
                        </form> */}

                        <form
                            name="mc-embedded-subscibe-form"
                            rel={"noopener"}
                            // noValidate
                            action="https://artesane.us13.list-manage.com/subscribe/post?u=fed3adae0edf9e9d89854e25d&amp;id=698c1acbba&amp;f_id=00f3c2e1f0"
                            target="_blank"
                            method="post"
                            className="newsletter-home-form"
                            id="mc-embedded-subscribe-form">
                            <div className="input-top-wrapper">
                                <input
                                    className="newsletter-home-input"
                                    placeholder="Mon e-mail"
                                    type="email"
                                    id="mce-EMAIL"
                                    size="30"
                                    name={"EMAIL"}
                                    required
                                />
                                <div>
                                    <ul>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="2"
                                                name="group[15][2]"
                                                id="mce-group[15]-15-0"
                                            />
                                            <label htmlFor="mce-group[15]-15-0">
                                                Arts du fil
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="4"
                                                name="group[15][4]"
                                                id="mce-group[15]-15-1"
                                            />
                                            <label htmlFor="mce-group[15]-15-1">
                                                Arts plastiques
                                            </label>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                value="8"
                                                name="group[15][8]"
                                                id="mce-group[15]-15-2"
                                            />
                                            <label htmlFor="mce-group[15]-15-2">
                                                Arts culinaires
                                            </label>
                                        </li>
                                    </ul>
                                </div>

                                <button
                                    className="newsletter-home-button"
                                    type="submit">
                                    je m'inscris
                                </button>
                            </div>
                        </form>
                        <div className="newsletter-home-confidential">
                            Artesane respecte vos données personnelles. Vous
                            pouvez vous désinscrire à tout moment et retrouver
                            notre{" "}
                            <NavLink to="/politique-de-confidentialite">
                                {" "}
                                politique de confidentialité sur le site.
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="newsletter-home-player">
                <ReactPlayer
                    url={
                        "https://static.artesane.com/images_react/newsletter.mp4"
                    }
                    muted={false}
                    playing
                    loop={false}
                    light={
                        "https://static.artesane.com/images_react/newsletter.png"
                    }
                    controls={true}
                    wrapper={"div"}
                    width={"100%"}
                    height={"100%"}
                    playIcon={
                        <img
                            className="player-logo"
                            src={
                                "https://static.artesane.com/images_react/white_player.png"
                            }
                            alt="player"
                        />
                    }
                />
            </div>
        </div>
    )
}

export default NewsLetter
