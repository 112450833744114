//Components
import ProfessorHomeCard from "../../Commons/Cards/ProfessorHomeCard/ProfessorHomeCard"
import LinkButton from "../../Commons/Buttons/LinkButton/LinkButton"
//Hooks
import { useSelector } from "react-redux"

//Libraries
import AliceCarousel from "react-alice-carousel"

//Styles
import "./ProfessorSlider.scss"
import { NavLink } from "react-router-dom"

const ProfessorSlider = ({ univers }) => {
    const professors = useSelector(
        (state) => state.professors.professorsHighlight
    )
    const loading = useSelector(
        (state) => state.professors.loadingProfessorsHighlight
    )
    return (
        <div className="home-professor-slider-wrapper">
            <div className="home-professor-slider-title">Nos professeurs</div>
            {!loading && professors.images.length > 0 && (
                <AliceCarousel
                    responsive={{
                        0: {
                            items: 1,
                        },
                        1024: {
                            items: 4,
                        },
                    }}
                    items={professors.images.map(({ teacher }) => {
                        return(
                        <>
                            {teacher !== null && (
                                <NavLink
                                    className="home-professor-slider-link"
                                    to={`/${univers}/professeur/${teacher.code}`}>
                                    <ProfessorHomeCard
                                        name={teacher.name}
                                        teacherTaxons={teacher.taxons}
                                        imgUrl={
                                            teacher.images.length > 0
                                                ? teacher.images[0].thumbnails
                                                      .carousel
                                                : "https://images.pexels.com/photos/11960772/pexels-photo-11960772.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                                        }
                                    />
                                </NavLink>
                            )}
                        </>
                    )})}
                />
            )}

            <div className="home-professor-slider-button">
                <LinkButton
                    texte={"tous les professeurs"}
                    url={"professeurs"}
                />
            </div>
        </div>
    )
}

export default ProfessorSlider
