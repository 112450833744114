import React from "react"

//Components
import UnderTaxonsItems from "./UnderTaxonsItems/UnderTaxonsItems"

//Hooks
import { useDispatch, useSelector } from "react-redux"

//Actions
import { toggleUnderTaxonsActif } from "../../../../../../actions/homeActions"

//Styles
import "./UnderTaxons.scss"

const UnderTaxons = ({ underTaxons }) => {
    const currentUnderTaxonActif = useSelector(
        (state) => state.home.toggleUnderTaxonsActif
    )
    const dispatch = useDispatch()
    return (
        <div key={underTaxons} className="under-taxons">
            {underTaxons.map(({ name, children }, index) => (
                <React.Fragment key={index}>
                    <div
                        id={name === "Offre" ? "offre" : ""}
                        key={index}
                        className="under-taxons-title"
                        onClick={() => {
                            if (currentUnderTaxonActif === name) {
                                dispatch(toggleUnderTaxonsActif(""))
                            } else {
                                dispatch(toggleUnderTaxonsActif(name))
                            }
                        }}>
                        {name}
                    </div>
                    <div
                        className={
                            currentUnderTaxonActif === name
                                ? " "
                                : "under-taxons-actif-closed"
                        }>
                        <UnderTaxonsItems
                            key={children}
                            title={name}
                            elements={children}
                        />
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

export default UnderTaxons
