import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import store from "./store/store"
import "./styles/index.scss"
import App from "./components/App/App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <Provider store={store}>
        {/* <React.StrictMode> */}
        <BrowserRouter>
            <App />
        </BrowserRouter>
        {/* </React.StrictMode> */}
    </Provider>
)

reportWebVitals()
