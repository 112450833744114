import { useSelector } from "react-redux"
import MediaFilter from "./MediaFilter/MediaFilter"
import MediaProductItem from "./MediaProductItem/MediaProductItem"
import "./MediaProducts.scss"
import Spinner from "../../Commons/Spinner/Spinner"
//Libraries
import MediaQuery from "react-responsive"

import "./MediaProducts.scss"
import MediaFilterDesktop from "./MediaFilterDesktop/MediaFilterDesktop"
import EmptyPage from "../../Commons/EmptyPage/EmptyPage"

const MediaProducts = ({ data, lastAssociation, lastWatched }) => {
    const isLoadingMedia = useSelector((state) => state.media.isloadingMedia)
    const mediaCulinaires = useSelector(
        (state) => state.media.mediaArtsCulinaires
    )
    const mediaFil = useSelector((state) => state.media.mediaArtsDuFil)
    const mediaPlastiques = useSelector(
        (state) => state.media.mediaArtsPlastiques
    )

    const packsEtParcours = useSelector((state) => state.media.packsEtParcours)
    const documentsCours = useSelector((state) => state.media.documentsCours)
    const videosCours = useSelector((state) => state.media.videosCours)

    return (
        <div className="mediaproducts-wrapper">
            {isLoadingMedia && (
                <div className="media-products-spinner">
                    <Spinner />
                </div>
            )}
            {!isLoadingMedia && (
                <>
                    {(mediaCulinaires.length !== 0 ||
                        mediaFil.length !== 0 ||
                        mediaPlastiques.length !== 0) && (
                        <>
                            <MediaQuery maxWidth={1000}>
                                <MediaFilter />
                            </MediaQuery>
                            <MediaQuery minWidth={1000}>
                                <MediaFilterDesktop />
                            </MediaQuery>
                        </>
                    )}

                    {/* <div className="media-searchBar">
                    <SearchBarMutual
                     onSubmit={(event) => {event.preventDefault();}}
                     placeHolder={"Rechercher dans cet univers"}
                     inputValue={""}
                     onChange={(event) => {} }
                    />
                </div> */}

                    {/* {(data.length === 0) && (
                     
                        <div className="no-result">
                            Vous n'avez pas encore de cours dans cet univers. 
                        </div>
                
                )} */}
                    {mediaCulinaires.length === 0 &&
                        mediaFil.length === 0 &&
                        mediaPlastiques.length === 0 && (
                            <EmptyPage
                                firstPart={
                                    "Vous n'avez pas encore de cours vidéo dans votre médiathèque."
                                }
                                secondPartOne={"En panne d’inspiration ?"}
                                secondPartTwo={
                                    " Retrouvez nos meilleurs cours ici ! >"
                                }
                                urlSecondPart={"/arts-du-fil/catalogue/tout/1"}
                            />
                        )}

                    {!packsEtParcours &&
                        !documentsCours &&
                        !videosCours &&
                        lastAssociation.length > 0 && (
                            <MediaProductItem
                                title="Mes derniers cours achetés"
                                collections={lastAssociation}
                            />
                        )}
                    {!packsEtParcours &&
                        !documentsCours &&
                        !videosCours &&
                        lastWatched.length > 0 && (
                            <MediaProductItem
                                title="Mes derniers cours visionnés"
                                collections={lastWatched}
                            />
                        )}

                    {data.map(({ matiere, collections }, index) => (
                        <MediaProductItem
                            key={index}
                            title={matiere.name}
                            collections={collections}
                        />
                    ))}
                </>
            )}
        </div>
    )
}
export default MediaProducts
