import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { changeInputAuthValue } from "../../../actions/authAction"

import "./ConfirmRequest.scss"

const ConfirmRequest = ({ message }) => {
    const redirectAfterChangePassword = useSelector(
        (state) => state.auth.redirectAfterChangePassword
    )

    const dispatch = useDispatch()
    return (
        <div className="confirm-popup-wrapper">
            <div className="popup-wrapper">
                <span
                    className="popup-cross"
                    onClick={() => {
                        dispatch(
                            changeInputAuthValue(false, "popUpPasswordConfirm")
                        )
                    }}>
                    ╳
                </span>
                <div className="confirm-message">{message}</div>
                {redirectAfterChangePassword && (
                    <NavLink to={"/connexion"}>
                        <div
                            onClick={() => {
                                dispatch(
                                    changeInputAuthValue(
                                        false,
                                        "popUpPasswordConfirm"
                                    )
                                )
                            }}
                            className="confirm-button">
                            Connexion {">"}
                        </div>
                    </NavLink>
                )}
                <div
                    onClick={() => {
                        dispatch(
                            changeInputAuthValue(false, "popUpPasswordConfirm")
                        )
                    }}
                    className="close-button">
                    Fermer {">"}
                </div>
            </div>
        </div>
    )
}

export default ConfirmRequest
