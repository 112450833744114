//Hooks
import { useEffect } from "react"
import { Outlet, useLocation, Navigate } from "react-router-dom"

//Styles
import "./UserAccount.scss"

//Components
import Header from "../Prepage/Header/Header"
import AccountMenu from "./AccountMenu/AccountMenu"
import { useDispatch, useSelector } from "react-redux"
import { fetchCountries } from "../../actions/userAction"
import ErrorPopup from "./ErrorPopup/ErrorPopup"
import Banner from "../Banner/Banner"

const UserAccount = () => {
    const dispatch = useDispatch()
    const isErrorPopup = useSelector((state) => state.user.errorRequest)
    useEffect(() => {
        dispatch(fetchCountries())
    }, [dispatch])

    const { pathname } = useLocation()
    return (
        <div>
            <Banner />
            <Header />
            <div className="user-account-wrapper">
                {isErrorPopup && <ErrorPopup />}
                <AccountMenu />
                {(pathname === "/mon-compte" ||
                    pathname === "/mon-compte/") && (
                    <Navigate to={"mes-infos-personnelles"} replace />
                )}

                <Outlet />
            </div>
        </div>
    )
}

export default UserAccount
