import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userConnexion } from "../../actions/authAction"
import "./MaintenanceRoute.scss"

const MaintenanceRoute = () => {
    const isUserConnected = useSelector((state) => state.auth.userConnected)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isUserConnected) {
            dispatch(userConnexion(false))
            localStorage.clear()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserConnected])

    return (
        <div>
            <div>
                <img
                    className="logo-maintenance"
                    src={"https://static.artesane.com/images_react/logo.png"}
                    alt=" logo du site Artesane"
                />{" "}
            </div>
            <div className="notfound-wrapper">
                <div className="bloc-wrapper">
                    <img
                        className="reverse"
                        src={
                            "https://static.artesane.com/images_react/favicon.png"
                        }
                        alt="artesane"
                    />{" "}
                    <div className="text-wrapper">
                        <div className="not-found-title">
                            Notre site est en maintenance
                        </div>
                        <div className="not-found">
                            <div>Nous revenons très vite !</div>
                        </div>
                    </div>
                    <img
                        src={
                            "https://static.artesane.com/images_react/favicon.png"
                        }
                        alt="artesane"
                    />
                </div>
            </div>
        </div>
    )
}

export default MaintenanceRoute
