import Header from "../../Prepage/Header/Header"
//Libraries
import ReactPlayer from "react-player"

import "./Player.scss"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchCourseItem,
    loadingCourseItem,
    postNewTimeInVideo,
    toggleChapterOpen,
} from "../../../actions/mediaAction"
import { useParams } from "react-router-dom"
import Spinner from "../../Commons/Spinner/Spinner"
import ChapterMenu from "./ChapterMenu/ChapterMenu"
import ChatButton from "./ChatButton/ChatButton"
import Banner from "../../Banner/Banner"

const Player = () => {
    const dispatch = useDispatch()
    const { videoSlug } = useParams()
    const isLoading = useSelector((state) => state.media.loadingCourse)
    const coursData = useSelector((state) => state.media.singleCourse)
    const light = useSelector((state) => state.media.light)
    const userId = useSelector((state) => state.user.userId)
    const isOpenChapters = useSelector((state) => state.media.chapterOpen)
    const ref = useRef(null)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    useEffect(() => {
        dispatch(loadingCourseItem(true))
        dispatch(fetchCourseItem(videoSlug))
        
    }, [dispatch, videoSlug, userId])

    useEffect(() => {
        if (window.matchMedia("(min-width: 1000px)").matches) {
            dispatch(toggleChapterOpen(true))
        }
    }, [dispatch])
    useEffect(() => {
        if (window.matchMedia("(max-width: 998px)").matches) {
            dispatch(toggleChapterOpen(false))
        }
    }, [dispatch])

    return (
        <div>
            <Banner />
            <Header />
            {!isLoading && (
                <div className="player-and-menu-wrapper">
                    <div
                        onClick={() => {
                            dispatch(toggleChapterOpen(true))
                        }}
                        className={`to-open-chapter${!isOpenChapters ? " display" : " displayNone"}`}>
                        Plan du cours{" "}
                        <img
                            src={
                                "https://static.artesane.com/images_react/fleche.png"
                            }
                            alt=""
                        />
                    </div>
                    <div
                        className={`chapter-menu-wrapper${isOpenChapters ? " open" : " closed"}`}>
                        <ChapterMenu
                            playerRef={ref}
                            courseTitle={coursData.name}
                            chapters={coursData.chapters}
                        />
                    </div>
                    <div className="player-wrapper">
                        <ChatButton />
                        <ReactPlayer
                            onProgress={(event) => {
                                const time = Math.ceil(event.playedSeconds)

                                if (time % 15 === 0 && time > 0) {
                                    dispatch(
                                        postNewTimeInVideo(time, coursData.id)
                                    )
                                }
                            }}
                            ref={ref}
                            controls={true}
                            onStart={() => {
                                const savedTimes = coursData.savedTimes
                                ref.current.seekTo(
                                    Object.keys(savedTimes).length > 0
                                        ? savedTimes.timeInVideo
                                        : 0,
                                    "seconds"
                                )
                            }}
                            light={
                                light
                                    ? coursData.artesane_sellable_item_image_url_original
                                    : false
                            }
                            config={{
                                forceHLS: !isSafari,
                                hlsOptions: {
                                    xhrSetup: function (xhr, url) {
                                        xhr.withCredentials = true // send cookies
                                    },
                                },
                            }}
                            url={coursData.access.URL.replace(
                                "*",
                                "index.m3u8"
                            )}
                            playing
                            playIcon={
                                <img
                                    className="player-media-logo"
                                    src={
                                        "https://static.artesane.com/images_react/white_player.png"
                                    }
                                    alt="player"
                                />
                            }
                            width={"100%"}
                            height={"100%"}
                        />
                    </div>
                </div>
            )}
            {isLoading && (
                <div className="spinner-wrapper">
                    {" "}
                    <Spinner />
                </div>
            )}
        </div>
    )
}
export default Player
