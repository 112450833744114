import Banner from "../../Banner/Banner"
import Header from "../../Prepage/Header/Header"
import "./DeliveryAndReturn.scss"
import { useEffect } from "react"

const DeliveryAndReturn = () => {
    useEffect(() => {
        document.title = "Artesane - Apprenez à créer | Livraisons et retours"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Livraison et retours"
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="livraison-et-retours-wrapper">
                <h1>Livraisons, retours et remboursements</h1>

                <h2>Quels sont les moyens de livraison ?</h2>
                <p>
                    Les livraisons se font &agrave; votre domicile et
                    s&rsquo;effectuent par La Poste Colissimo ou La Poste Lettre
                    verte selon le(s) produit(s) command&eacute;(s).
                </p>
                <p>Nous exp&eacute;dions en France et dans le monde entier.</p>

                <h2>Quels sont les d&eacute;lais de livraison ?</h2>
                <p>
                    Pour les commandes de produits physiques (coffrets, Cahiers,
                    affiches, cartes postales &hellip;) &agrave; destination de
                    la France m&eacute;tropolitaine, il faut compter un
                    d&eacute;lai moyen de 4 &agrave; 5 jours ouvr&eacute;s pour
                    la r&eacute;ception de votre colis.
                </p>
                <p>
                    Pour les commandes hors France m&eacute;tropolitaine, il
                    faut compter un d&eacute;lai moyen de 8 jours ouvr&eacute;s,
                    selon la destination.
                </p>

                <h2>
                    Quelles informations sont n&eacute;cessaires pour recevoir
                    votre commande ?
                </h2>
                <p>
                    En passant commande chez Artesane, vous vous engagez
                    &agrave; :
                </p>
                <ul>
                    <li>
                        Nous fournir un nom, un pr&eacute;nom, une adresse
                        &eacute;lectronique, une adresse postale et/ou
                        d&rsquo;autres coordonn&eacute;es correctes et
                        exactes.&nbsp;
                    </li>
                    <li>
                        De plus, vous nous autorisez &agrave; utiliser ces
                        donn&eacute;es pour que nous puissions vous contacter le
                        cas &eacute;ch&eacute;ant. Si vous ne nous communiquez
                        pas toutes les informations dont nous avons besoin, nous
                        risquons de ne pas &ecirc;tre en mesure de
                        compl&eacute;ter le processus de votre commande.
                    </li>
                </ul>
                <p>
                    Artesane se r&eacute;serve le droit de modifier, retirer des
                    produits pr&eacute;sents sur le site internet, et ainsi,
                    d&rsquo;annuler une commande si nous faisons face &agrave;
                    une situation exceptionnelle. Dans ce cas, vous serez
                    pr&eacute;venu par mail ou t&eacute;l&eacute;phone, nous
                    vous proposerons une solution alternative ou vous pourrez
                    demander un remboursement de votre commande.
                </p>

                <h2>Comment puis-je retourner ma commande Artesane ?</h2>
                <p>
                    Vous disposez d&rsquo;un d&eacute;lai de 14 jours, &agrave;
                    compter de la date de r&eacute;ception des produits
                    physiques, hors cours vid&eacute;o, pour retourner votre
                    commande. Pour ce faire, le ou les produits rendus doivent
                    &ecirc;tre neufs et complets.&nbsp;
                </p>
                <p>En fonction du type de produit command&eacute; :</p>
                <ul>
                    <li>
                        Soit nous vous transmettrons une &eacute;tiquette de
                        retour &agrave; coller sur votre colis qui doit ensuite
                        &ecirc;tre d&eacute;pos&eacute; en bureau de poste.
                    </li>
                    <li>
                        Soit vous devrez prendre &agrave; votre charge les frais
                        que nous vous rembourserons ensuite sur
                        pr&eacute;sentation d&apos;un justificatif.
                    </li>
                </ul>
                <p>
                    Vous recevrez par la suite le remboursement du montant
                    pay&eacute; pour ce(s) produit(s) dans un d&eacute;lai
                    maximal de 12 jours &agrave; compter de la date o&ugrave;
                    vous avez fait valoir ce droit, via le moyen de paiement
                    utilis&eacute; lors de l&rsquo;achat de ce(s) produit(s).
                </p>

                <h2>
                    Qu&rsquo;en est-il des produits d&eacute;fectueux /
                    manquants ?
                </h2>
                <p>
                    Artesane se porte garant de la qualit&eacute; de ses
                    produits. Cependant, il peut arriver qu&rsquo;un article
                    d&eacute;fectueux se glisse dans nos livraisons. Si vous
                    recevez un produit d&eacute;fectueux, ou auquel il manque
                    des composants, merci de nous envoyer un mail &agrave; :{" "}
                    <a href="mailto:contact@artesane.com">
                        <strong>contact@artesane.com</strong>
                    </a>
                </p>
                <p>
                    Dans le mail, expliquez-nous le probl&egrave;me
                    rencontr&eacute; avec notre produit et ajoutez-y une photo
                    comme preuve.
                </p>
                <p>
                    Nous reviendrons vers vous pour proc&eacute;der &agrave;
                    l&rsquo;envoi d&rsquo;un nouveau produit ou du composant
                    manquant le plus rapidement possible, &agrave; notre charge.
                    Vous pouvez &eacute;galement nous retourner le produit et en
                    &ecirc;tre rembours&eacute;(e).
                </p>

                <h2>O&ugrave; trouver mon num&eacute;ro de commande ?</h2>
                <p>
                    Pour les produits qui font l&rsquo;objet d&rsquo;un suivi
                    lors de leur exp&eacute;dition, vous recevrez le
                    num&eacute;ro de suivi dans un mail qui vous sera
                    envoy&eacute; &agrave; l&rsquo;adresse mail
                    renseign&eacute;e lors de votre commande.
                </p>
                <p>
                    En cas de question concernant votre livraison, nous vous
                    invitons &agrave; nous contacter sur{" "}
                    <a href="mailto:contact@artesane.com">
                        <strong>contact@artesane.com</strong>
                    </a>
                    .&nbsp;
                </p>

                <h2>
                    Comment j&rsquo;acc&egrave;de &agrave; mon cours
                    vid&eacute;o ?
                </h2>
                <p>
                    Le cours vid&eacute;o n&rsquo;est pas automatiquement
                    ajout&eacute; &agrave; votre compte client : les
                    instructions pour acc&eacute;der au cours sont contenues
                    dans le coffret/Cahier.
                </p>
                <p>
                    Voici les &eacute;tapes &agrave; suivre pour acc&eacute;der
                    &agrave; votre cours vid&eacute;o :
                </p>
                <p>&nbsp;</p>
                <ul>
                    <li>
                        Rendez-vous sur{" "}
                        <a href="mailto:contact@artesane.com">
                            <strong>contact@artesane.com</strong>
                        </a>
                        .
                    </li>
                    <li>
                        Connectez-vous &agrave; votre compte, ou cr&eacute;ez-en
                        un si vous n&rsquo;en avez pas encore.
                    </li>
                    <li>
                        Cherchez le cours correspondant &agrave; votre coffret
                    </li>
                    <li>
                        Ajoutez-le &agrave; votre panier puis rendez-vous sur
                        votre panier.
                    </li>
                    <li>
                        Dans l&rsquo;espace &ldquo;Coupon de
                        r&eacute;duction&rdquo;, entrez le code inscrit dans le
                        livret pr&eacute;sent dans votre coffret.
                    </li>
                    <li>
                        Cliquez sur &ldquo;Mettre &agrave; jour&rdquo; : le
                        montant du panier s&rsquo;actualise et passe &agrave;
                        0&euro;.
                    </li>
                    <li>Terminez votre commande.</li>
                    <li>
                        Vous pouvez d&egrave;s &agrave; pr&eacute;sent retrouver
                        votre cours dans l&rsquo;onglet &ldquo;Mes cours&quot;
                    </li>
                </ul>

                <h2>Fonctionnement des produits en pr&eacute;commande</h2>
                <p>
                    Certains de nos produits sont disponibles en
                    pr&eacute;commande. La date estim&eacute;e de livraison du
                    produit en pr&eacute;commande est indiqu&eacute;e dans la
                    fiche produit.
                </p>
                <p>
                    L&rsquo;achat doit &ecirc;tre r&eacute;gl&eacute; en amont,
                    puis vous recevez votre commande dans les jours qui suivent
                    la date de livraison estim&eacute;e (avec un d&eacute;lai
                    moyen de 2 &agrave; 10 jours ouvr&eacute;s selon votre
                    adresse).
                </p>

                <p>
                    Si vous rencontrez des difficult&eacute;s, vous pouvez
                    contacter l&rsquo;&eacute;quipe Artesane &agrave;
                    l&rsquo;adresse{" "}
                    <a href="mailto:contact@artesane.com">
                        <strong>contact@artesane.com</strong>
                    </a>
                    , qui s&rsquo;engage &agrave; r&eacute;pondre &agrave; vos
                    questions dans les plus brefs d&eacute;lais.
                </p>
            </div>
        </div>
    )
}

export default DeliveryAndReturn
