//Libraries
import Switch from "@mui/material/Switch"
//Hooks
import { useSelector, useDispatch } from "react-redux/es/exports"

//Components
import Spinner from "../../Commons/Spinner/Spinner"
//action
import {
    changeInputValue,
    loadingUserRequest,
    patchSubscriptions,
} from "../../../actions/userAction"
//Styles
import "./Subscriptions.scss"
import { setCookie } from "../../../selectors/selectors"

const Subscriptions = () => {
    const dispatch = useDispatch()
    const isNewsletterEmailChecked = useSelector(
        (state) => state.user.newsletterEmail
    )
    const isNewsletterSMSChecked = useSelector(
        (state) => state.user.newsletterSMS
    )
    const isNewsletterPaperChecked = useSelector(
        (state) => state.user.newsletterPaper
    )
    const isCookiesChecked = useSelector((state) => state.user.cookies)
    const customerId = useSelector((state) => state.user.customerId)
    const isLoadingUserRequest = useSelector(
        (state) => state.user.loadingUserRequest
    )
    return (
        <div className="subscriptions-wrapper">
            {isLoadingUserRequest && (
                <div className="spinner-subscriptions-spinner">
                    <Spinner />
                </div>
            )}

            <div className="subscriptions-items-wrapper">
                <div className="subscriptions-item">
                    <div className="subsription-item-title">
                        Je m'abonne à la Newsletter par e-mail
                    </div>
                    <div className="switch-button-wrapper">
                        <Switch
                            checked={isNewsletterEmailChecked}
                            onChange={() => {
                                dispatch(
                                    changeInputValue(
                                        !isNewsletterEmailChecked,
                                        "newsletterEmail"
                                    )
                                )
                                dispatch(loadingUserRequest(true))
                                dispatch(
                                    patchSubscriptions(
                                        customerId,
                                        "newsletterEmail"
                                    )
                                )
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </div>
                </div>
                <div className="subscriptions-item">
                    <div className="subsription-item-title">
                        Je m'abonne à la Newsletter par SMS
                    </div>
                    <div className="switch-button-wrapper">
                        <Switch
                            checked={isNewsletterSMSChecked}
                            onChange={() => {
                                dispatch(
                                    changeInputValue(
                                        !isNewsletterSMSChecked,
                                        "newsletterSMS"
                                    )
                                )
                                dispatch(loadingUserRequest(true))
                                dispatch(
                                    patchSubscriptions(
                                        customerId,
                                        "newsletterSms"
                                    )
                                )
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </div>
                </div>
                <div className="subscriptions-item">
                    <div className="subsription-item-title">
                        Je m'abonne à la Newsletter par papier
                    </div>
                    <div className="switch-button-wrapper">
                        <Switch
                            checked={isNewsletterPaperChecked}
                            onChange={() => {
                                dispatch(
                                    changeInputValue(
                                        !isNewsletterPaperChecked,
                                        "newsletterPaper"
                                    )
                                )
                                dispatch(loadingUserRequest(true))
                                dispatch(
                                    patchSubscriptions(
                                        customerId,
                                        "newsletterPaper"
                                    )
                                )
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </div>
                </div>
                <div className="subscriptions-item">
                    <div className="subsription-item-title">
                        J'accepte les cookies
                    </div>
                    <div className="switch-button-wrapper">
                        <Switch
                            checked={
                                isCookiesChecked.indexOf("true") > 0
                                    ? true
                                    : false
                            }
                            onChange={() => {
                                let cookies = ""
                                if (isCookiesChecked.indexOf("true") > 0) {
                                    cookies = isCookiesChecked.replace(
                                        "true",
                                        false
                                    )
                                } else {
                                    cookies = isCookiesChecked.replace(
                                        "false",
                                        true
                                    )
                                }

                                setCookie("tarteaucitron", cookies, 365)
                                dispatch(changeInputValue(cookies, "cookies"))
                                dispatch(loadingUserRequest(true))
                                dispatch(
                                    patchSubscriptions(customerId, "cookies")
                                )
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscriptions
