//Styles
import "./SelectFilters.scss"
//Hooks
import { useSelector, useDispatch } from "react-redux"
import {
    saveCurrentPageCatalogue,
    saveCatalogueLessonsInState,
    addFiltersCheckbox,
} from "../../../../actions/catalogueAction"
import { useNavigate, useParams } from "react-router-dom"
import {
    checkFiltersName,
    checkFiltersOrNot,
    filterProduct,
} from "../../../../selectors/selectors"

const SelectFilters = () => {
    const dispatch = useDispatch()
    const filtersIdCheckedName = useSelector(
        (state) => state.catalogue.filterCheckBoxInputName
    )
    const filtersChecked = useSelector(
        (state) => state.catalogue.filtersCheckboxInput
    )
    const nameFiltersChecked = useSelector(
        (state) => state.catalogue.filterCheckBoxInputName
    )
    const isFilterActif = useSelector((state) => state.catalogue.isFilterActif)
    const currentUnivers = useParams().univers
    const currentFilters = useParams().currentFilters
    const allProduct = useSelector((state) => state.catalogue.allProduct)
    const priceValues = useSelector((state) => state.catalogue.priceValue)
    const sortActifValue = useSelector(
        (state) => state.catalogue.sortActifValue
    )
    let navigate = useNavigate()

    return (
        <div
            className={
                isFilterActif
                    ? "select-filters-wrapper"
                    : "select-filters-wrapper-closed"
            }>
            {filtersIdCheckedName.map((item, index) => (
                <div
                    onClick={() => {
                        navigate(
                            `/${currentUnivers}/catalogue/${currentFilters}/1`,
                            { replace: true }
                        )

                        dispatch(saveCurrentPageCatalogue(1))

                        let newfilters = checkFiltersOrNot(
                            filtersChecked,
                            item.parent,
                            item.id,
                            item.parentName,
                            item.name
                        )
                        let newNameFilters = checkFiltersName(
                            nameFiltersChecked,
                            item.id,
                            item.parent,
                            item.parentName,
                            item.name
                        )

                        dispatch(
                            saveCatalogueLessonsInState(
                                filterProduct(
                                    newfilters,
                                    allProduct,
                                    priceValues,
                                    sortActifValue,
                                    []
                                ),
                                filterProduct(
                                    newfilters,
                                    allProduct,
                                    priceValues,
                                    sortActifValue,
                                    []
                                ).length,
                                allProduct
                            )
                        )
                        dispatch(addFiltersCheckbox(newfilters, newNameFilters))
                    }}
                    key={index}
                    className="select-filters-items">
                    {item.name}
                    <span key={index} className="select-filters-cross">
                        {" "}
                        x{" "}
                    </span>
                </div>
            ))}
        </div>
    )
}

export default SelectFilters
