import { useDispatch, useSelector } from "react-redux"
import "./ChoosePayment.scss"
import {
    changeInputOrder,
    patchPaymentMethod,
} from "../../../../actions/cartAction"
import OneyChoose from "./OneyChoose/OneyChoose"
import TitleCart from "../TittleCart/TitleCart"
import PaymentAccordion from "./PaymentAccordion/PaymentAccordion"

const ChoosePayment = () => {
    const payments = useSelector((state) => state.cart.cartData.payments)
    const selectPaymentCode = useSelector(
        (state) => state.cart.selectPaymentCode
    )
    const validatePayment = useSelector((state) => state.cart.validatePayment)
    const cartData = useSelector((state) => state.cart.cartData)
    const oneyActive = useSelector((state) => state.cart.oneyActive)

    const dispatch = useDispatch()
    return (
        <div className="shipment-address-wrapper">
        <TitleCart title={"Paiement"}/>
            <form
                onSubmit={(event) => {
                    event.preventDefault()
                    dispatch(changeInputOrder(true, "loadingAddPayment"))
                    dispatch(changeInputOrder(true, "loadingCart"))
                    dispatch(
                        patchPaymentMethod(
                            cartData.tokenValue,
                            cartData.payments[cartData.payments.length - 1].id,
                            selectPaymentCode,
                            oneyActive
                        )
                    )
                }}>
                {payments[cartData.payments.length - 1].availableMethods.map(
                    ({ id, description, name, code }) => (
                        <PaymentAccordion id={id} name={name} code={code} description={description}
                        />
                    )
                )}

                {/* )} */}
            </form>
        </div>
    )
}

export default ChoosePayment
