import { useDispatch, useSelector } from "react-redux"
import { changeInputOrder, patchShippingMethod } from "../../../../actions/cartAction";
import TitleCart from "../TittleCart/TitleCart";
import './DeliveryForm.scss';
import MondialRelaySelect from "../MondialRelaySelect/MondialRelaySelect";
import { useEffect } from "react";

const DeliveryForm = () => {
    const dispatch = useDispatch();

    const redirectPaiementWindow = useSelector(
        (state) => state.cart.redirectPaiementWindow
    )
    const shipmentsSelected = useSelector(
        (state) => state.cart.shipmentSelected
    )
    const cartData = useSelector((state) => state.cart.cartData)
    const mondialRelayId = useSelector(
        (state) => state.cart.mondialRelayId
      )

      useEffect(() => {
        if(cartData.checkoutState === "addressed") {
            dispatch(
                changeInputOrder(
                    true,
                    "loadingValidateAddress"
                )
            )

            dispatch(
                changeInputOrder(
                    true,
                    "loadingCart"
                )
            )

            dispatch(
                patchShippingMethod(
                    cartData.shipments[0].method.id
                )
            )
        }
     
                 
      }, [ cartData.checkoutState])

    return (
        <div>
        <div
            className="return"
            onClick={() => {
                dispatch(
                    changeInputOrder(
                        false,
                        "addressValidate"
                    )
                )
                dispatch(
                    changeInputOrder(
                        false,
                        "chooseDelivery"
                    )
                )
            }}>
            {"< "}Retour aux adresses
        </div>

        <div className="shipment-address-wrapper">
            <TitleCart title={"Choix de livraison"}/>
            <div
                className="shipment-wrapper"
                onSubmit={(event) => {
                    event.preventDefault()
                    dispatch(
                        changeInputOrder(
                            true,
                            "loadingValidateAddress"
                        )
                    )

                    dispatch(
                        changeInputOrder(
                            true,
                            "loadingCart"
                        )
                    )

                    dispatch(
                        patchShippingMethod(
                            shipmentsSelected
                        )
                    )
                }}
                onChange={() => {
                    dispatch(
                        changeInputOrder(
                            false,
                            "redirectPaiementWindow"
                        )
                    )
                }}>
                {cartData.shipments[0].availableMethods.map(
                    ({ id, configuration, name, code, cost }) => (
                        <>
                        <div
                            key={id}
                            className="shipping-item-wrapper">
                                                            <input
                                value={id}
                                className="radio-input"
                                checked={
                                    shipmentsSelected === id
                                            ? true
                                            : false
                                }
                                name={id}
                                type={"radio"}
                            />
                            <label
                             className="payment-item"
                                htmlFor={id}
                                onClick={() => {
                                    if(code.includes("mondial-relay") && mondialRelayId === null) {
                                        dispatch(changeInputOrder(true, "isMondialRelayWidgetLoading"))
                                        dispatch(changeInputOrder(true, "openMondialRelayWidget"));
                                    } else {
                                        dispatch(changeInputOrder(false, "openMondialRelayWidget"));
                                        dispatch(
                                            changeInputOrder(
                                                true,
                                                "loadingValidateAddress"
                                            )
                                        )
                    
                                        dispatch(
                                            changeInputOrder(
                                                true,
                                                "loadingCart"
                                            )
                                        )
                    
                                        dispatch(
                                            patchShippingMethod(
                                                id
                                            )
                                        )
                                    }

                                    dispatch(
                                        changeInputOrder(
                                            id,
                                            "shipmentSelected"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "addressValidate"
                                        )
                                    )
                                    dispatch(
                                        changeInputOrder(
                                            false,
                                            "redirectPaiementWindow"
                                        )
                                    )
                                }}>
                                <div className="delivery-item-and-logo">            
                                    <span>{name}{" - "}{cost === 0 ? "Gratuit" : (cost/100).toFixed(2).toString().replace(".",",") + "€"}</span>
                                    <img 
                                        src={ code.includes("mondial-relay") ? "https://static.artesane.com/images_react/Logo_Mondial_Relay_-_2022.svg" : 
                                              code.toLowerCase().includes("colis") ? "https://static.artesane.com/images_react/colissimo.png" : 
                                              code.includes("click") ? "https://static.artesane.com/images_react/click.png" :
                                              code.includes('lettre') ? "https://static.artesane.com/images_react/lettre_suivie.png" :
                                               ""


                                        } 
                                        alt={ code.includes("mondial-relay") ? "Mondial relay" :
                                            code.includes("colis") ? "colissimo" :
                                            code.includes("click") ? "click and collect" :
                                            code.includes("lettre") ? "lettre suivie":
                                            ""

                                        } />
                                </div>
                            </label>

                        </div>
                        {mondialRelayId !== null && code.includes("mondial-relay") && id === shipmentsSelected && (
                            <MondialRelaySelect/>
                        )}

                        </>
                        
                    )
                )}
            </div>
        </div>
    </div>
    )
}

export default DeliveryForm;