import type { AppStore } from "../store/store"
import {
    countCart,
    saveCartInState,
    changeInputOrder,
    putAddAddressOrder,
    fetchCartAssociation,
} from "../actions/cartAction"
import { Address, CartAddresses } from "../types/address"
import { changeInputValue } from "../actions/userAction"
import { isAddressesSame } from "../selectors/selectors"

export const cartProcessor = (store: AppStore, cartData: any, context : any, addresses: any) => {
    changeInputOrder(cartData.tokenValue, "cartTokenUser")
    store.dispatch(saveCartInState(cartData))

    if(context === "cart") {
        if(cartData.payments.length > 0  ) {
            store.dispatch(changeInputOrder( cartData.payments[0].method.code, "selectPaymentCode"))
        }
        if (cartData.promotionCoupon !== undefined) {
            if (Object.keys(cartData.promotionCoupon).length > 0) {
                store.dispatch(
                    changeInputOrder(
                        cartData.promotionCoupon.code,
                        "couponCode"
                    )
                )
            }
        }
        if (cartData.items.length === 0) {
            store.dispatch(countCart(0))
        }
        if (cartData.items.length === 1) {
            store.dispatch(countCart(cartData.items[0].quantity))
            store.dispatch(fetchCartAssociation(cartData.items))
        } else if (cartData.items.length > 1) {
            store.dispatch(fetchCartAssociation(cartData.items))
            const totalItemsCart = Object.keys(cartData.items)
                .map((variants) => cartData.items[variants].quantity)
                .reduce((previous, current) => previous + current)
            store.dispatch(countCart(totalItemsCart))
        }
    }
    if(context === "delivery") {

        if(cartData.payments.length > 0  ) {
            store.dispatch(changeInputOrder( cartData.payments[0].method.code, "selectPaymentCode"))
        }
        if(cartData.shipments.length > 0  ) {
            store.dispatch(changeInputOrder( cartData.shipments[0].method.id, "shipmentSelected"))
        }

        if (
            cartData.shippingAddress !== undefined &&
            cartData.billingAddress !== undefined
        ) {
            store.dispatch(changeInputOrder(cartData.shipments, "shipments"))
            store.dispatch(
            changeInputOrder(
                cartData.shippingAddress.firstName,
                "firstNameShipping"
            )
            )
            store.dispatch(
                changeInputOrder(
                    cartData.shippingAddress.lastName,
                    "lastNameShipping"
                )
            )
            store.dispatch(
                changeInputOrder(
                    cartData.shippingAddress.company,
                    "societyShipping"
                )
            )
            store.dispatch(
                changeInputOrder(cartData.shippingAddress.city, "cityShipping")
            )
            store.dispatch(
                changeInputOrder(cartData.shippingAddress.street, "streetShipping")
            )
            store.dispatch(
                changeInputOrder(
                    cartData.shippingAddress.postcode,
                    "postCodeShipping"
                )
            )
            store.dispatch(
                changeInputOrder(
                    cartData.shippingAddress.countryCode,
                    "countryShipping"
                )
            )
            store.dispatch(
                changeInputOrder(
                    cartData.shippingAddress.phoneNumber,
                    "telephoneShipping"
                )
            )




            store.dispatch(
                changeInputOrder(
                    cartData.billingAddress.firstName,
                    "firstNameBilling"
                )
                )
                store.dispatch(
                    changeInputOrder(
                        cartData.billingAddress.lastName,
                        "lastNameBilling"
                    )
                )
                store.dispatch(
                    changeInputOrder(
                        cartData.billingAddress.company,
                        "societyBilling"
                    )
                )
                store.dispatch(
                    changeInputOrder(cartData.billingAddress.city, "cityBilling")
                )
                store.dispatch(
                    changeInputOrder(cartData.billingAddress.street, "streetBilling")
                )
                store.dispatch(
                    changeInputOrder(
                        cartData.billingAddress.postcode,
                        "postCodeBilling"
                    )
                )
                store.dispatch(
                    changeInputOrder(
                        cartData.billingAddress.countryCode,
                        "countryBilling"
                    )
                )
                store.dispatch(
                    changeInputOrder(
                        cartData.billingAddress.phoneNumber,
                        "telephoneBilling"
                    )
                )

            if(!isAddressesSame(cartData.billingAddress, cartData.shippingAddress)) {
                store.dispatch(changeInputOrder(false, "sameAddressAsShippingAddress"))
            }
            store.dispatch(
                putAddAddressOrder(
                    cartData.tokenValue
                )
            )

        } else {

            if(addresses !== undefined) {
                if(addresses.length  === 0) {
                    store.dispatch(changeInputOrder(false, "addressValidate"))
                    store.dispatch(
                        changeInputOrder(
                            false,
                            "loadingValidateAddress"
                        )
                    )
                } else {
                    const defaultAddress = store.getState().user.defaultAddress;
                    if(defaultAddress !== "") {
                        const findDefaultAddress = addresses.find((item : {id : number}) => item.id === defaultAddress);

                        if(findDefaultAddress !== undefined) {

                            if(findDefaultAddress.phoneNumber !== null ) {
                                store.dispatch(
                                    changeInputOrder(
                                        findDefaultAddress.firstName,
                                        "firstNameShipping"
                                    )
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.lastName,
                                            "lastNameShipping"
                                        )
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.company,
                                            "societyShipping"
                                        )
                                    )
                                    store.dispatch(
                                        changeInputOrder(findDefaultAddress.city, "cityShipping")
                                    )
                                    store.dispatch(
                                        changeInputOrder(findDefaultAddress.street, "streetShipping")
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.postcode,
                                            "postCodeShipping"
                                        )
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.countryCode,
                                            "countryShipping"
                                        )
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.phoneNumber,
                                            "telephoneShipping"
                                        )
                                    )
                                    store.dispatch(
                                        putAddAddressOrder(
                                            cartData.tokenValue
                                        )
                                    )
                            } else {
                                store.dispatch(
                                    changeInputOrder(
                                        findDefaultAddress.firstName,
                                        "firstNameShipping"
                                    )
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.lastName,
                                            "lastNameShipping"
                                        )
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.company,
                                            "societyShipping"
                                        )
                                    )
                                    store.dispatch(
                                        changeInputOrder(findDefaultAddress.city, "cityShipping")
                                    )
                                    store.dispatch(
                                        changeInputOrder(findDefaultAddress.street, "streetShipping")
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.postcode,
                                            "postCodeShipping"
                                        )
                                    )
                                    store.dispatch(
                                        changeInputOrder(
                                            findDefaultAddress.countryCode,
                                            "countryShipping"
                                        )
                                    )
                                store.dispatch(changeInputOrder(false, "addressValidate"))
                                store.dispatch(
                                    changeInputOrder(
                                        false,
                                        "loadingValidateAddress"
                                    )
                                )
                            }
  
                        }
  
                        
                    } else {
                        store.dispatch(
                            changeInputOrder(
                                addresses[0].firstName,
                                "firstNameShipping"
                            )
                            )
                            store.dispatch(
                                changeInputOrder(
                                    addresses[0].lastName,
                                    "lastNameShipping"
                                )
                            )
                            store.dispatch(
                                changeInputOrder(
                                    addresses[0].company,
                                    "societyShipping"
                                )
                            )
                            store.dispatch(
                                changeInputOrder(addresses[0].city, "cityShipping")
                            )
                            store.dispatch(
                                changeInputOrder(addresses[0].street, "streetShipping")
                            )
                            store.dispatch(
                                changeInputOrder(
                                    addresses[0].postcode,
                                    "postCodeShipping"
                                )
                            )
                            store.dispatch(
                                changeInputOrder(
                                    addresses[0].countryCode,
                                    "countryShipping"
                                )
                            )
                            store.dispatch(
                                changeInputOrder(
                                    addresses[0].phoneNumber,
                                    "telephoneShipping"
                                )
                            )


                        // store.dispatch(changeInputValue(true, "loadingAddAddress"))

                        store.dispatch(
                            putAddAddressOrder(
                                cartData.tokenValue
                            )
                        )

                      
                        
                    }
                }
            }
        }
    }

    if (cartData.items.length === 0) {
        store.dispatch(countCart(0))
    }

    if (cartData.items.length === 1) {
        store.dispatch(countCart(cartData.items[0].quantity))
    } else if (cartData.items.length > 1) {
        const totalItemsCart = Object.keys(cartData.items)
            .map((variants) => cartData.items[variants].quantity)
            .reduce((previous, current) => previous + current)
        store.dispatch(countCart(totalItemsCart))
    }

    if(cartData.shipments.length > 0 ){
        if(cartData.shipments[0].pickupPointId !== undefined) {
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupPointId, "mondialRelayId"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupPostCode, "mondialRelayPostCode"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupCountryCode, "mondialRelayCountryCode"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupName, "mondialRelayName"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupStreet, "mondialRelayStreet"))
            store.dispatch(changeInputOrder(cartData.shipments[0].pickupCity, "mondialRelayCity"))

        }
    }


    store.dispatch(changeInputOrder(false, "loadingCart"))
}

export const cartAddressProcessor = (store: AppStore): CartAddresses => {
    const cart = store.getState().cart
    const isSameAddress = cart.sameAddressAsShippingAddress

    let shipping: Address = {
        firstName: cart.firstNameShipping,
        lastName: cart.lastNameShipping,
        street: cart.streetShipping,
        city: cart.cityShipping,
        postcode: cart.postCodeShipping,
        countryCode: cart.countryShipping,
        phoneNumber: cart.telephoneShipping,
    }
    if (cart.societyShipping) {
        shipping.company = cart.societyShipping
    }
    var billing: Address = shipping

    if (!isSameAddress) {
        billing = {
            firstName: cart.firstNameBilling,
            lastName: cart.lastNameBilling,
            street: cart.streetBilling,
            city: cart.cityBilling,
            postcode: cart.postCodeBilling,
            countryCode: cart.countryBilling,
            phoneNumber: cart.telephoneBilling,
        }
        if (cart.societyBilling) {
            billing.company = cart.societyBilling
        }
    }

    let result: CartAddresses = {
        shippingAddress: shipping,
        billingAddress: billing,
    }
    
    if (cart.couponCode !== "") {
        result.couponCode = cart.couponCode
    }


    return result
}
