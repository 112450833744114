// Components
import Header from "./Header/Header"
import NavCard from "./NavCard/NavCard"
import Reinsurance from "./Reinsurance/Reinsurance"
import VideoCard from "./VideoCard/VideoCard"
import Banner from "../Banner/Banner"

import "./Prepage.scss"

import SeoText from "./SeoText/SeoText"
import { useEffect } from "react"
import {
    isLoadingProduct,
    saveProductDataState,
} from "../../actions/productsActions"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../Commons/Spinner/Spinner"

const Prepage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(isLoadingProduct(true))
        dispatch(saveProductDataState("notFoundProduct", false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadingAllUniverses = useSelector((state) => state.common.loadingAllUniverses);

    return (
        <>
            <Banner />
            <Header />
            <h1 className="seo-prepage">
                Artesane, les cours en ligne d'arts et de loisirs créatifs
            </h1>
            {!loadingAllUniverses && (
                <NavCard />
            )}
            {loadingAllUniverses && (
                <Spinner/>
            )}
      
            <div className="reinssurance-wrapper">
                <Reinsurance
                    logo={
                        "https://static.artesane.com/images_react/picto-prepage1.png"
                    }
                    altImg={"bobine de fil"}
                    firstPartOfPhrase={"L'école d'arts et"}
                    secondPartOfPhrase={"d'artisanat créatifs"}
                />
                <Reinsurance
                    logo={
                        "https://static.artesane.com/images_react/picto-prepage2.png"
                    }
                    altImg={"bobine de fil"}
                    firstPartOfPhrase={"avec les plus"}
                    secondPartOfPhrase={"grands experts"}
                />
                <Reinsurance
                    logo={
                        "https://static.artesane.com/images_react/picto-prepage3.png"
                    }
                    altImg={"bobine de fil"}
                    firstPartOfPhrase={"en ligne, à votre rythme"}
                    secondPartOfPhrase={"et en illimité"}
                />
            </div>
            <VideoCard />
            <SeoText />
        </>
    )
}

export default Prepage
