import { useSelector } from "react-redux";
import TitleCart from "../Livraison/TittleCart/TitleCart";
import './OrderSummary.scss';

const OrderSummary = () => {
    const cartData = useSelector((state) => state.cart.cartData);
    return (
        <div className="order-summary-wrapper">
            <TitleCart title={"Récapitulatif de la commande"}/>
            <div className="order-summary-cards-wrapper">
                {cartData.items.map(
                            ({ productName, unitPrice, product, quantity }) => (
                                <div key={productName} className="order-summary-card">
                                    <img src={product.mainImage.url} alt={productName} />
                                    <span className="name-product"> 
                                        {productName}  
                                    </span>
                                    <span>
                                        x{quantity}
                                    </span>
                                    <span>
                                  
                                        {" "}
                                        <span className="num-font">
                                            {(unitPrice / 100)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </span>{" "}
                                        €
                                        
                                    </span>
                                </div>
                            )
                        )}
            </div>
        </div>
    );
}

export default OrderSummary;