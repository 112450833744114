//Styles
import "./ProfesseurProfilPublic.scss"
import {
    fetchProfesseurPublicData,
    saveProfessorDataInState,
} from "../../actions/professorsAction"

//Component
import Header from "../Home/Header/Header"
import Title from "../ProfesseurCatalogue/Title/Title"
import LessonCardSelection from "../Commons/Cards/LessonCardSelection/LessonCardSelection"

//hooks
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import ProfesseurPublicCard from "./ProfessorPublicCard/ProfessorPublicCard"
import Spinner from "../Commons/Spinner/Spinner"
import Banner from "../Banner/Banner"

const ProfesseurProfilPublic = () => {
    const dispatch = useDispatch()
    const { currentProfesseur } = useParams()
    const isLoading = useSelector(
        (state) => state.professors.isLoadingProfesseurPublicData
    )
    const professordata = useSelector(
        (state) => state.professors.professeurPublicData
    )
    const professorProducts = useSelector(
        (state) => state.professors.productsOneTeacher
    )

    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | " +
            currentProfesseur.replaceAll("-", " ").toUpperCase()
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | " +
                currentProfesseur.replaceAll("-", " ").toUpperCase()
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        dispatch(
            saveProfessorDataInState("isLoadingProfesseurPublicData", true)
        )
        dispatch(fetchProfesseurPublicData(currentProfesseur))
    }, [dispatch, currentProfesseur])

    return (
        <div>
            <Banner />
            <Header />

            {isLoading && <Spinner />}
            {!isLoading && (
                <>
                    <Title
                        upperText={professordata.name}
                        downText={professordata.teacherTaxons}
                    />
                    <ProfesseurPublicCard
                        name={professordata.name}
                        urlImg={professordata.images[0].thumbnails.carousel}
                        resume={professordata.bio}
                        facebook={professordata.facebookLink}
                        instagram={professordata.instagramLink}
                        pinterest={professordata.pinterestLink}
                        website={professordata.websiteLink}
                    />
                    {professorProducts.length > 0 && (
                        <>
                            {/* eslint-disable-next-line */}
                            <a id="topAnchor"></a>
                            <Title upperText={"Tous ses cours"} />

                            <div
                                id="professeur-profil-publique-product-wrapper"
                                className="professeur-profil-publique-product-wrapper">
                                {professorProducts.map(
                                    ({
                                        mainImage,
                                        name,
                                        id,
                                        author,
                                        variants,
                                        slug,
                                        pack,
                                        parcours,
                                        isNew,
                                        coffret,
                                        buy_once,
                                        attributes,
                                        universes,
                                        giftCard,
                                        giftCardAmountConfigurable,
                                        duree,
                                        niveau,
                                    }) => (
                                        <div
                                            key={name}
                                            className="professeur-profil-publique-product">
                                            <LessonCardSelection
                                                productId={id}
                                                urlImg={
                                                    mainImage.thumbnails
                                                        .carousel
                                                }
                                                title={name}
                                                professor={
                                                    author ? author.name : ""
                                                }
                                                originalPrice={(
                                                    variants[0].originalPrice /
                                                    100
                                                )
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",")}
                                                price={(variants[0].price / 100)
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(".", ",")}
                                                pack={pack}
                                                parcours={parcours}
                                                coffret={coffret}
                                                reduction={
                                                    variants[0]
                                                        .originalPrice !==
                                                    variants[0].price
                                                        ? Math.floor(
                                                              ((variants[0]
                                                                  .originalPrice -
                                                                  variants[0]
                                                                      .price) /
                                                                  variants[0]
                                                                      .originalPrice) *
                                                              100
                                                          )
                                                        : 0
                                                }
                                                newer={isNew}
                                                time={duree}
                                                level={niveau}
                                                key={id}
                                                currentUnivers={
                                                    universes[0].code
                                                }
                                                productSlug={slug}
                                                // shipedRequired={variants[0].shipping_required}
                                                variants={variants}
                                                buyOnce={buy_once}
                                                giftCard={giftCard}
                                                giftCardAmountConfigurable={
                                                    giftCardAmountConfigurable
                                                }
                                                alt={
                                                    mainImage !== null &&
                                                    mainImage !== undefined
                                                        ? mainImage.alt
                                                        : ""
                                                }
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default ProfesseurProfilPublic
