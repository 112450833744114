export const homeCatalogueTaxons = [
    "Couture",
    "Tricot",
    "Nouveautés",
    "Projets",
    "Broderie",
    "Lingerie",
    "Petits prix",
    "Packs & Parcours",
    // "Projets",
    // "Broderie",
    // "Lingerie",
    // "Petits prix",
    // "Packs & Parcours",
]
