//Hooks
import { useSelector, useDispatch } from "react-redux"
import { useRef } from "react"
import OutsideClickHandler from "react-outside-click-handler"
import {
    changeSortActifValue,
    toggleIsSortActif,
    saveCatalogueLessonsInState,
} from "../../../../actions/catalogueAction"
import { sort } from "../../../../selectors/selectors"

import "./SortItems.scss"

const SortItems = () => {
    const dispatch = useDispatch()
    const ref = useRef(null)
    const isSortActif = useSelector((state) => state.catalogue.isSortActif)
    const lessonsData = useSelector((state) => state.catalogue.catalogueData)
    const sortActifValue = useSelector(
        (state) => state.catalogue.sortActifValue
    )
    const allProduct = useSelector((state) => state.catalogue.allProduct)

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                dispatch(toggleIsSortActif(false))
            }}>
            <div
                ref={ref}
                className={
                    isSortActif
                        ? "sort-items-wrapper"
                        : "sort-items-wrapper-closed"
                }>
                <div className="filters-sort-under-items-wrapper">
                    <input
                        onChange={(event) => {
                            dispatch(changeSortActifValue(event.target.value))

                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, event.target.value),
                                    sort(lessonsData, event.target.value)
                                        .length,
                                    allProduct
                                )
                            )
                        }}
                        className="filters-sort-under-items-input"
                        name="sort"
                        value="PlusRecent"
                        checked={sortActifValue === "PlusRecent" ? true : false}
                        type="radio"
                    />
                    <label
                        onClick={() => {
                            dispatch(changeSortActifValue("PlusRecent"))
                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, "PlusRecent"),
                                    sort(lessonsData, "PlusRecent").length,
                                    allProduct
                                )
                            )
                        }}>
                        Du + récent au + ancien
                    </label>
                </div>
                <div className="filters-sort-under-items-wrapper">
                    <input
                        onChange={(event) => {
                            dispatch(changeSortActifValue(event.target.value))
                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, event.target.value),
                                    sort(lessonsData, event.target.value)
                                        .length,
                                    allProduct
                                )
                            )
                        }}
                        checked={
                            sortActifValue === "MoinsRecent" ? true : false
                        }
                        className="filters-sort-under-items-input"
                        name="sort"
                        type="radio"
                        value="MoinsRecent"
                    />
                    <label
                        onClick={() => {
                            dispatch(changeSortActifValue("MoinsRecent"))
                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, "MoinsRecent"),
                                    sort(lessonsData, "MoinsRecent").length,
                                    allProduct
                                )
                            )
                        }}>
                        Du + ancien au + récent
                    </label>
                </div>
                <div className="filters-sort-under-items-wrapper">
                    <input
                        onChange={(event) => {
                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, event.target.value),
                                    sort(lessonsData, event.target.value)
                                        .length,
                                    allProduct
                                )
                            )
                            dispatch(changeSortActifValue(event.target.value))
                        }}
                        checked={sortActifValue === "PlusCher" ? true : false}
                        className="filters-sort-under-items-input"
                        name="sort"
                        type="radio"
                        value="PlusCher"
                    />
                    <label
                        onClick={() => {
                            dispatch(changeSortActifValue("PlusCher"))
                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, "PlusCher"),
                                    sort(lessonsData, "PlusCher").length,
                                    allProduct
                                )
                            )
                        }}>
                        Du + cher au - cher
                    </label>
                </div>
                <div className="filters-sort-under-items-wrapper">
                    <input
                        onChange={(event) => {
                            dispatch(changeSortActifValue(event.target.value))
                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, event.target.value),
                                    sort(lessonsData, event.target.value)
                                        .length,
                                    allProduct
                                )
                            )
                        }}
                        checked={sortActifValue === "MoinsCher" ? true : false}
                        className="filters-sort-under-items-input"
                        name="sort"
                        type="radio"
                        value="MoinsCher"
                    />
                    <label
                        onClick={() => {
                            dispatch(changeSortActifValue("MoinsCher"))
                            dispatch(
                                saveCatalogueLessonsInState(
                                    sort(lessonsData, "MoinsCher"),
                                    sort(lessonsData, "MoinsCher").length,
                                    allProduct
                                )
                            )
                        }}>
                        Du - cher au + cher
                    </label>
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default SortItems
