import "./NetworksInput.scss"

const NetworksInput = ({
    iconImg,
    inputName,
    type,
    required,
    value,
    onChange,
    placeholder,
}) => {
    return (
        <div className="networks-input-wrapper">
            <img
                className="networks-image"
                src={iconImg}
                alt={`logo ${inputName}`}
            />
            <input
                placeholder={placeholder}
                className="networks-input"
                required={required}
                name={inputName}
                type={type}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default NetworksInput
