//Hooks
import { useLocation } from "react-router-dom"

const CatalogueImage = () => {
    const currentLocation = useLocation().pathname
    if (currentLocation === "/arts-culinaires") {
        return (
            <img
                className="home-catalogue-image"
                src={
                    "https://static.artesane.com/images_react/mosaique-art-culinaire.png"
                }
                alt="mosaïque d'images représentant les cours présents sur Artesane"
            />
        )
    }
    if (currentLocation === "/arts-du-fil") {
        return (
            <img
                className="home-catalogue-image"
                src={
                    "https://static.artesane.com/images_react/mosaique-art-fil.png"
                }
                alt="mosaïque d'images représentant les cours présents sur Artesane"
            />
        )
    }
    if (currentLocation === "/arts-plastiques") {
        return (
            <img
                className="home-catalogue-image"
                src={
                    "https://static.artesane.com/images_react/mosaique-art-graphique.png"
                }
                alt="mosaïque d'images représentant les cours présents sur Artesane"
            />
        )
    }
}

export default CatalogueImage
