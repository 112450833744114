//API for get country's name from ISO code Alpha2
import countries from "i18n-iso-countries"

//Style
import "./Addresses.scss"
//Hooks
import { useSelector, useDispatch } from "react-redux"
//Action
import {
    changeInputValue,
    fetchAddresses,
    loadingAddAddress,
    postNewAddress,
    toggleModifyAddress,
    errorModifyAddress,
    loadingModifyAddress,
    putModifyAddress,
} from "../../../actions/userAction"
//Components
import AddressCard from "./AddressCard/AddressCard"
import InputSimple from "../InputSimple/inputSimple"
import ModifyAddress from "./ModifyAddress/ModifyAddress"
import Spinner from "../../Commons/Spinner/Spinner"
import { useEffect } from "react"

//API for get country's name from ISO code Alpha2
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"))

const Addresses = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAddresses())
    }, [dispatch])

    const firstName = useSelector((state) => state.user.firstNameAddressAdd)
    const firstNameModify = useSelector((state) => state.user.firstNameAddressModify)
    const lastName = useSelector((state) => state.user.lastNameAddressAdd)
    const lastNameModify = useSelector((state) => state.user.lastNameAddressModify)
    const society = useSelector((state) => state.user.societyAddressAdd)
    const societyModify = useSelector((state) => state.user.societyAddressModify)
    const street = useSelector((state) => state.user.streetAddressAdd)
    const streetModify = useSelector((state) => state.user.streetAddressModify)
    const postCode = useSelector((state) => state.user.postCodeAddressAdd)
    const postCodeModify = useSelector((state) => state.user.postCodeAddressModify)
    const city = useSelector((state) => state.user.cityAddressAdd)
    const cityModify = useSelector((state) => state.user.cityAddressModify)
    const telephoneModify = useSelector((state) => state.user.telephoneAddressModify)
    const telephone = useSelector((state) => state.user.telephoneAddressAdd)
    const errorTelephone = useSelector(
        (state) => state.user.errorPatternTelephoneAddressAdd
    )
    const errorTelephoneModify = useSelector(
        (state) => state.user.errorPatternTelephoneAddressModify
    )
    const country = useSelector((state) => state.user.countryAddressAdd)
    const countryModify = useSelector((state) => state.user.countryAddressModify)

    const defaultAddressAdd = useSelector(
        (state) => state.user.defaultAdressAdd
    )
    const addresses = useSelector((state) => state.user.addresses)
    const defaultAddress = useSelector((state) => state.user.defaultAddress)
    const isOpenPopupAdress = useSelector(
        (state) => state.user.isPopupModifyAddressOpen
    )
    const countries = useSelector((state) => state.user.countries)
    const loadingAddAddresses = useSelector(
        (state) => state.user.loadingAddAddress
    )
    const loadingDeleteAddress = useSelector(
        (state) => state.user.loadingDeleteAddress
    )
    const id = useSelector((state) => state.user.adressIdModify)


    return (
        <div className="addresses-wrapper">
            {isOpenPopupAdress && 
                <ModifyAddress 
                    title={"Modifier l'adresse"}
                    toggleWindow={(param) => {dispatch(toggleModifyAddress(param))}} 
                    errorPatternTelephone={(param) => {
                        dispatch(changeInputValue(
                        param,
                        "errorPatternTelephoneAddressModify"
                        ))
                    }}
                    errorTelephone={errorTelephoneModify}
                    error = {(param) => {
                        dispatch(errorModifyAddress(param))

                    }}
                    loading = {(param) => {
                        dispatch(loadingModifyAddress(param))
                    }}
                    put={()=> {
                        dispatch(putModifyAddress(id))
                    }}
                    lastName={lastNameModify}
                    firstName={firstNameModify}
                    society={societyModify}
                    street={streetModify}
                    postCode={postCodeModify}
                    city={cityModify}
                    telephone={telephoneModify}
                    country={countryModify}
                    firstNameChangeInput = {
                        (event) => {
                            dispatch( changeInputValue(
                                    event.target.value,
                                    "firstNameAddressModify"
                                ))
                        }
                       
                    }

                    lastNameChangeInput = {
                        (event) => {
                            dispatch( changeInputValue(
                                    event.target.value,
                                    "lastNameAddressModify"
                                ))
                        }
                       
                    }

                    societyChangeInput = {
                        (event) => {
                            dispatch( changeInputValue(
                                    event.target.value,
                                    "societyAddressModify"
                                ))
                        }
                       
                    }
                    streetChangeInput = {
                        (event) => {
                            dispatch( changeInputValue(
                                    event.target.value,
                                    "streetAddressModify"
                                ))
                        }
                       
                    }
                    postCodeChangeInput = {
                        (event) => {
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "postCodeAddressModify"
                                )
                            )
                        }
                    }

                    cityChangeInput = {
                        (event) => {
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "cityAddressModify"
                                )
                            )
                        }

                    }
                    telephoneChangeInput = {(event) => {
                        dispatch(
                            changeInputValue(
                                event.target.value,
                                "telephoneAddressModify"
                            )
                        )
                    }}
                    countryChangeInput = {(event) => {
                        dispatch(
                            changeInputValue(
                                event.target.value,
                                "countryAddressModify"
                            )
                        )
                    }}
                />
            }
            {(loadingAddAddresses || loadingDeleteAddress) && (
                <div className="spinner-add-address-spinner">
                    <Spinner />
                </div>
            )}

            <div className="addresses-wrapper-top">
                <div className="addresses-wrapper-default">
                    {addresses.length > 0 &&
                        addresses.map(
                            ({
                                id,
                                lastName,
                                firstName,
                                company,
                                street,
                                city,
                                postcode,
                                countryCode,
                                phoneNumber,
                            }) => {
                                if (id === defaultAddress) {
                                    return (
                                        <AddressCard
                                            key={id}
                                            id={id}
                                            title={"Adresse par défaut"}
                                            lastName={lastName}
                                            firstName={firstName}
                                            society={company}
                                            street={street}
                                            city={city}
                                            postCode={postcode}
                                            country={countryCode}
                                            phoneNumber={phoneNumber}
                                        />
                                    )
                                }
                                return null
                            }
                        )}
                    {addresses.length < 1 && !loadingAddAddress && (
                        <div className="address-null">
                            {" "}
                            Vous n'avez pas encore d'adresses enregistrées.
                        </div>
                    )}
                </div>
                <div className="addresses-form-add">
                    <div className="address-form-title">
                        Ajouter une adresse
                    </div>
                    <form
                        action="#"
                        onSubmit={(event) => {
                            event.preventDefault()
                            if (
                                (telephone.match(/[0-9]/g) ||
                                    telephone.match(/[-+!?*$@%_]/g)) &&
                                !telephone.match(/[A-Z]/g) &&
                                !telephone.match(/[a-z]/g)
                            ) {
                                dispatch(
                                    changeInputValue(
                                        false,
                                        "errorPatternTelephoneAddressAdd"
                                    )
                                )
                                dispatch(postNewAddress())
                                dispatch(loadingAddAddress(true))
                            } else {
                                dispatch(
                                    changeInputValue(
                                        true,
                                        "errorPatternTelephoneAddressAdd"
                                    )
                                )
                            }
                        }}>
                        <div className="form-address-left">
                            <InputSimple
                                inputName={"lastNameAdd"}
                                label={"Nom *"}
                                value={lastName}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "lastNameAddressAdd"
                                        )
                                    )
                                }}
                            />

                            <InputSimple
                                inputName={"firstNameAdd"}
                                label={"Prénom *"}
                                value={firstName}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "firstNameAddressAdd"
                                        )
                                    )
                                }}
                            />
                            <InputSimple
                                inputName={"societyAdd"}
                                label={"Société"}
                                value={society}
                                type={"text"}
                                required={false}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "societyAddressAdd"
                                        )
                                    )
                                }}
                            />

                            <InputSimple
                                inputName={"streetAdd"}
                                label={"Voie et numéro *"}
                                maxLength={35}
                                value={street}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "streetAddressAdd"
                                        )
                                    )
                                }}
                            />
                        </div>
                        <div className="form-address-right">
                            <InputSimple
                                inputName={"postCodeAdd"}
                                label={"Code postal *"}
                                value={postCode}
                                type={country === "FR" ? "number" : "text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "postCodeAddressAdd"
                                        )
                                    )
                                }}
                            />

                            <InputSimple
                                inputName={"cityAdd"}
                                label={"Ville *"}
                                value={city}
                                type={"text"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "cityAddressAdd"
                                        )
                                    )
                                }}
                            />
                            <div className="input-wrapper">
                                <label
                                    className="personal-label"
                                    htmlFor="first-name">
                                    Pays *
                                </label>
                                <select
                                    required
                                    value={country}
                                    name="country"
                                    onChange={(event) => {
                                        dispatch(
                                            changeInputValue(
                                                event.target.value,
                                                "countryAddressAdd"
                                            )
                                        )
                                    }}>
                                    {countries.map(({ name, code }) => {
                                        if (code !== "EZ" && code !== "AC") {
                                            return (
                                                <option key={code} value={code}>
                                                    {name}
                                                </option>
                                            )
                                        }
                                        return null
                                    })}
                                </select>
                            </div>
                            <InputSimple
                                error={errorTelephone}
                                inputName={"telephoneAdd"}
                                label={"Téléphone *"}
                                value={telephone}
                                type={"tel"}
                                required={true}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            false,
                                            "errorPatternTelephoneAddressAdd"
                                        )
                                    )
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "telephoneAddressAdd"
                                        )
                                    )
                                }}
                            />
                            <div
                                className={`input-information ${errorTelephone ? "error" : ""}`}>
                                {" "}
                                Les lettres ne sont pas autorisées.
                            </div>
                            <InputSimple
                                checked={defaultAddressAdd}
                                className={"checkbox"}
                                inputName={"defaultAdressAdd"}
                                label={"Définir cette adresse par défaut"}
                                value={defaultAddressAdd}
                                type={"checkbox"}
                                required={false}
                                onChange={(event) => {
                                    dispatch(
                                        changeInputValue(
                                            !defaultAddressAdd,
                                            "defaultAdressAdd"
                                        )
                                    )
                                }}
                            />
                        </div>
                        <button className="submit-button" type="submit">
                            Enregistrer
                        </button>
                    </form>
                </div>
            </div>

            <div className="addresses-wrapper-other">
                {addresses.length > 0 &&
                    addresses.map(
                        ({
                            id,
                            lastName,
                            firstName,
                            company,
                            street,
                            city,
                            postcode,
                            countryCode,
                            phoneNumber,
                        }) => {
                            if (id !== defaultAddress) {
                                return (
                                    <AddressCard
                                        key={id}
                                        id={id}
                                        title={"autre adresse"}
                                        lastName={lastName}
                                        firstName={firstName}
                                        society={company}
                                        street={street}
                                        city={city}
                                        postCode={postcode}
                                        country={countryCode}
                                        phoneNumber={phoneNumber}
                                    />
                                )
                            }
                            return null
                        }
                    )}
            </div>
        </div>
    )
}

export default Addresses
