import { useDispatch } from "react-redux"
import "./ConfirmPopup.scss"
import { saveCommonDataInState } from "../../../actions/commonAction"

const ConfirmPopup = () => {
    const dispatch = useDispatch()

    return (
        <div className="confirm-popup-wrapper">
            <div className="popup-wrapper">
                <span
                    className="popup-cross"
                    onClick={() => {
                        dispatch(
                            saveCommonDataInState("confirmPopupOpen", false)
                        )
                    }}>
                    ╳
                </span>
                <div className="confirm-message">
                    Vos modifications ont bien été enregistrées
                </div>
                <div
                    onClick={() => {
                        dispatch(
                            saveCommonDataInState("confirmPopupOpen", false)
                        )
                    }}
                    className="close-button">
                    Fermer {">"}
                </div>
            </div>
        </div>
    )
}

export default ConfirmPopup
