import Header from "../../Prepage/Header/Header"
import Title from "../../ProfesseurCatalogue/Title/Title"
import ProfesseurCard from "../../ProfesseurCatalogue/ProfesseurCard/ProfesseurCard"
import Spinner from "../Spinner/Spinner"
import Banner from "../../Banner/Banner"

//Styles
import "./AllProfessors.scss"

//Actions
import { fetchAllProfessors } from "../../../actions/professorsAction"

//Hooks
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

const AllProfessors = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAllProfessors())
    }, [dispatch])

    useEffect(() => {
        document.title = " Nos professeurs - Artesane "
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Les professeurs"
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])

    const allProfessors = useSelector((state) => state.professors.allProfessors)
    const isLoading = useSelector(
        (state) => state.professors.isLoadingAllProfessors
    )
    return (
        <div>
            <Banner />
            <Header />
            <div className="all-professors-wrapper">
                <Title upperText={"Tous nos professeurs"} />
                {isLoading && <Spinner />}

                {!isLoading && (
                    <div className="all-professors-cards-wrapper">
                        {allProfessors.map(
                            ({
                                name,
                                code,
                                teacherTaxons,
                                images,
                                resume,
                                univers,
                            }) => (
                                <ProfesseurCard
                                    key={code}
                                    name={name}
                                    subjects={teacherTaxons}
                                    urlImage={images[0].url}
                                    bio={
                                        resume !== null && resume !== undefined
                                            ? `${resume.slice(0, 220)}...`
                                            : ""
                                    }
                                    url={`/${univers}/professeur/${code}`}
                                />
                            )
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default AllProfessors
