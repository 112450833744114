import { useDispatch, useSelector } from "react-redux"
import {
    changeInputValue,
    togglePopUpPassword,
    checkSave,
    isErrorPatternEmail,
    loadingUserRequest,
    patchMyPersonalInfos,
} from "../../../actions/userAction"

import InputSimple from "../InputSimple/inputSimple"
import Spinner from "../../Commons/Spinner/Spinner"
import PasswordConfirm from "./PasswordConfirm/PasswordConfirm"
import "./PersonalInfos.scss"
import ConfirmPopup from "../../Commons/ConfirmPopup/ConfirmPopup"

const PersonalInfos = () => {
    const dispatch = useDispatch()
    const firstNameInput = useSelector((state) => state.user.firstName)
    const LastNameInput = useSelector((state) => state.user.lastName)
    const genderInput = useSelector((state) => state.user.gender)
    const birthDateInput = useSelector((state) => state.user.birthDate)
    const emailInput = useSelector((state) => state.user.email)
    const telephoneInput = useSelector((state) => state.user.telephone)
    const isOpenPopUpPassword = useSelector(
        (state) => state.user.isPopUpPasswordOpen
    )
    const isSave = useSelector((state) => state.user.isSave)
    const isEmailValid = useSelector((state) => state.user.errorPatternEmail)
    const errorTelephone = useSelector(
        (state) => state.user.errorPatternTelephone
    )
    const errorBirthdate = useSelector((state) => state.user.errorBirthdate)
    const customerId = useSelector((state) => state.user.customerId)
    const isLoadingUserRequest = useSelector(
        (state) => state.user.loadingUserRequest
    )
    const openConfirmPopup = useSelector(
        (state) => state.common.confirmPopupOpen
    )

    return (
        <div className="personal-infos-wrapper">
            {openConfirmPopup && <ConfirmPopup />}
            {isLoadingUserRequest && (
                <div className="spinner-personnalinfos-spinner">
                    <Spinner />
                </div>
            )}

            {isOpenPopUpPassword && <PasswordConfirm />}

            <form
                className="personal-form"
                action="#"
                onSubmit={(event) => {
                    event.preventDefault()
                    if (
                        emailInput.match(
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/
                        ) &&
                        (telephoneInput.match(/[0-9]/g) ||
                            telephoneInput.match(/[-+!?*$@%_]/g) ||
                            telephoneInput === "") &&
                        !telephoneInput.match(/[A-Z]/g) &&
                        !telephoneInput.match(/[a-z]/g) &&
                        birthDateInput.length > 1
                    ) {
                        dispatch(loadingUserRequest(true))
                        dispatch(patchMyPersonalInfos(customerId))
                    } else if (
                        !emailInput.match(
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/
                        )
                    ) {
                        dispatch(isErrorPatternEmail(true))
                    } else if (
                        telephoneInput.match(/[A-Z]/g) ||
                        telephoneInput.match(/[a-z]/g)
                    ) {
                        dispatch(
                            changeInputValue(true, "errorPatternTelephone")
                        )
                    } else if (birthDateInput.length < 1) {
                        dispatch(changeInputValue(true, "errorBirthdate"))
                    }
                }}>
                <div className="personal-infos-wrapper-side">
                    <InputSimple
                        inputName={"firstName"}
                        label={"Prénom"}
                        value={firstNameInput}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            if (isSave) {
                                dispatch(checkSave(false))
                            }
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "firstName"
                                )
                            )
                        }}
                    />

                    <InputSimple
                        inputName={"lastName"}
                        label={"Nom"}
                        value={LastNameInput}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            if (isSave) {
                                dispatch(checkSave(false))
                            }
                            dispatch(
                                changeInputValue(event.target.value, "lastName")
                            )
                        }}
                    />

                    <div className="input-wrapper">
                        <label className="personal-label" htmlFor="first-name">
                            Genre
                        </label>
                        <select
                            value={genderInput}
                            name="gender"
                            onChange={(event) => {
                                if (isSave) {
                                    dispatch(checkSave(false))
                                }
                                dispatch(
                                    changeInputValue(
                                        event.target.value,
                                        "gender"
                                    )
                                )
                            }}>
                            <option value="">
                                Veuillez sélectionner un genre
                            </option>
                            <option value="m">Homme</option>
                            <option value="f">Femme</option>
                            <option value="o">Autre</option>
                        </select>
                    </div>
                </div>
                <div className="personal-infos-wrapper-side">
                    <InputSimple
                        error={errorBirthdate}
                        inputName={"birthDate"}
                        label={"Date de naissance *"}
                        value={birthDateInput}
                        type={"date"}
                        required={false}
                        onChange={(event) => {
                            if (isSave) {
                                dispatch(checkSave(false))
                            }
                            dispatch(changeInputValue(false, "errorBirthdate"))
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "birthDate"
                                )
                            )
                        }}
                    />
                    <div
                        className={`input-information ${errorBirthdate ? "error" : ""}`}>
                        * Champ obligatoire
                    </div>

                    <InputSimple
                        error={isEmailValid}
                        inputName={"email"}
                        label={"Adresse mail"}
                        value={emailInput}
                        type={"email"}
                        // pattern={}
                        required={true}
                        onChange={(event) => {
                            if (isSave) {
                                dispatch(checkSave(false))
                            }
                            dispatch(isErrorPatternEmail(false))
                            dispatch(
                                changeInputValue(event.target.value, "email")
                            )
                        }}
                    />

                    <InputSimple
                        error={errorTelephone}
                        inputName={"telephone"}
                        label={"Téléphone *"}
                        value={telephoneInput}
                        type={"tel"}
                        required={false}
                        onChange={(event) => {
                            if (isSave) {
                                dispatch(checkSave(false))
                            }
                            dispatch(
                                changeInputValue(false, "errorPatternTelephone")
                            )
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "telephone"
                                )
                            )
                        }}
                    />
                    <div
                        className={`input-information ${errorTelephone ? "error" : ""}`}>
                        * Les lettres ne sont pas autorisées{" "}
                    </div>

                    <div className="password-wrapper">
                        {/* <div>
                            <span>Mot de passe</span>
                            <span>XXXXXXXX </span>
                        </div> */}
                        <div>
                            <span
                                onClick={() => {
                                    dispatch(togglePopUpPassword(true))
                                }}
                                className="button-password">
                                {" "}
                                Changer mon mot de passe {">"}
                            </span>
                        </div>
                    </div>
                    <div className="submit-button-wrapper">
                        <div className="confirmation-message">
                            {/* <span>
                                Vos modifications ont bien été enregistrées
                            </span> */}
                        </div>
                        <button className="submit-button" type="submit">
                            Enregistrer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default PersonalInfos
