// Components
import DropDownMenuItems from "./DropDownMenuItems/DropDownMenuItems"

//Styles
import "./DropDownMenu.scss"

//Hooks
import { useDispatch } from "react-redux"

const DropDownMenu = ({
    firstItem,
    secondItem,
    navItems,
    action,
    isOpen,
    keyId,
}) => {
    const dispatch = useDispatch()

    return (
        <div
            id={keyId}
            className="dropdownMenu"
            onMouseEnter={() => {
                dispatch(action(true))
            }}
            onClick={() => {
                dispatch(action(!isOpen))
            }}
            onMouseLeave={() => {
                dispatch(action(false))
            }}>
            {firstItem}
            <span>{secondItem}</span>

            <DropDownMenuItems
                keyId={keyId}
                items={navItems}
                isOpenDropdown={isOpen}
            />
        </div>
    )
}

export default DropDownMenu
