import "./SeoText.scss"

const SeoText = () => {
    return (
        <div className="seotext-wrapper">
            <div>
                <p>
                    Artesane est la{" "}
                    <strong>
                        première école de cours d’arts et de loisirs créatifs en
                        ligne
                    </strong>
                    . Nous vous apprenons les arts du fil, les arts culinaires
                    et les beaux-arts. Champions des{" "}
                    <strong>cours de couture</strong>, des{" "}
                    <strong>cours de cuisine</strong> et de{" "}
                    <strong>pâtisserie</strong> ou des{" "}
                    <strong>cours d’aquarelle en ligne</strong>, . nous vous
                    proposons de suivre nos cours vidéo pas-à-pas, à votre
                    rythme! Eh oui, nos <strong>cours vidéo</strong> sont
                    accessibles à vie. N'hésitez pas à essayer nos cours en
                    ligne dans les matières suivantes :{" "}
                    <strong>
                        couture, tricot, broderie, crochet, dessin ou
                        photographie, cuisine ou pâtisserie
                    </strong>
                    .
                </p>
                <p>
                    Dans votre espace élève, vous pouvez consulter votre cours
                    chapitre après chapitre, prendre des notes, visionner les
                    séquences techniques autant de fois que vous le souhaitez !
                    Découvrez tous nos cours avec des professeurs d'excellence.
                    Avec Artesane, suivez des cours de couture en ligne, des
                    cours de broderie en ligne, des cours de photographie en
                    ligne, des cours de tricot en ligne, des cours de dessin en
                    ligne et des cours de cuisine en ligne. Nous proposons des{" "}
                    <strong>cours de couture débutant et expert</strong> qui
                    sont bien plus que de simples tuto couture, ce sont des
                    cours en ligne pour apprendre à coudre le jersey, des cours
                    vidéo pour démarrer la broderie. Avec nous, vous pourrez
                    apprendre à coudre une chemise, un jean en denim, apprendre
                    à coudre un manteau, apprendre à coudre une robe, ou même un
                    pantalon. Nos cours de couture vidéo vous permettent aussi
                    d'apprendre à coudre un soutien-gorge avec ou sans armature
                    ou un maillot de bain.
                </p>

                <p>
                    Nos <strong>cours vidéo de tricot</strong> vous permettront
                    de maîtriser le tricot avec des aiguilles circulaires, la
                    technique du top-down pour tricoter un pull à manches
                    raglan. Vous découvrirez aussi le tricot jacquard, le tricot
                    islandais, les mailles endroit, envers et le jersey. Vous
                    apprendrez en vidéo à réaliser des torsades, des mailles
                    glissées, des jetés et de la dentelle.
                </p>
                <p>
                    Nos <strong>cours de broderie en vidéo</strong> vous
                    apprendront la broderie traditionnelle, les smocks, et même
                    la broderie or ou les jours à fils tirés. Vous saurez broder
                    des fleurs et broder du texte.
                </p>
                <p>
                    Grâce à nos professeurs artistes, vous pouvez apprendre le
                    dessin, la gouache, l'aquarelle, la calligraphie ou le brush
                    lettering. Nos cours en ligne s'adressent aux débutants
                    comme aux confirmés. Vous rêvez d'apprendre le croquis sur
                    le vif et de réaliser de magnifiques carnets de voyage,
                    suivez notre cours vidéo sur le sujet. Le dessin urbain
                    n'aura plus de secrets pour vous et vous deviendrez un
                    incroyable urban sketcher. Vous préférez apprendre l'art des
                    lettres et réaliser des faire-parts maisons avec une
                    écriture magnifique, vous trouverez à votre disposition des
                    vidéos de calligraphie classique à la plume et à l'encre de
                    Chine et des cours de brush lettering. Vous apprendrez tout
                    sur le dessin, la peinture à l’huile, la gouache, le coup de
                    crayon, la perspective, les couleurs, la création d’une
                    bande-dessinée grâce à nos cours en ligne. Vous pourrez
                    également vous former à l’illustration numérique sur
                    Procreate ou Photoshop. Nos professeurs sont de véritables
                    experts diplômés des meilleures écoles de beaux-arts. Nos{" "}
                    <strong>cours en ligne de dessin</strong> sont accessibles à
                    vie, vous pouvez apprendre à votre rythme sans risque et
                    prendre le temps de trouver l'inspiration.
                </p>
                <p>
                    Enfin, vous avez envie d’apprendre à maîtriser les arts
                    culinaires : osez prendre des{" "}
                    <strong>cours en ligne de cuisine</strong>, des{" "}
                    <strong>cours vidéo de pâtisserie</strong> pour vous lancer
                    dans la préparation d’un{" "}
                    <strong>CAP cuisine à distance</strong> par exemple. Bien
                    plus que de simples tuto cuisine, nos cours en ligne vous
                    permettent de maîtriser la cuisine du sans-gluten, les
                    techniques de pâtisserie classique ou les secrets des
                    différentes cuisines du monde.
                </p>
                <p>
                    Bienvenue sur Artesane, la première plateforme de{" "}
                    <strong>cours de dessin en ligne</strong>, découvrez sans
                    plus attendre les beaux arts en vidéo! Quel que soit le
                    loisir créatif que vous souhaitez pratiquer, nous vous
                    proposons régulièrement coffrets et box comme notre coffret
                    lingerie, notre coffret broderie ou nos box dessin enfants.
                    Elles incluent tout le matériel et les matériaux nécessaires
                    pour vous lancer. Grâce à nos partenaires comme Fiskars,
                    Rascol, Sennelier ou Faber-Castell, nous vous fournissons un
                    matériel de très belle qualité.
                </p>
                <p>
                    Découvrez aussi nos <strong>cours de photographie</strong>{" "}
                    pour apprendre en vidéo à réaliser de belles photographies
                    avec votre smartphone. Nous proposons aussi un cours en
                    ligne pour apprendre à se servir de son appareil photo
                    réflex. Artesane vous permettra également de vous former au{" "}
                    <strong>CAP couture à distance</strong> et/ou en présentiel.
                </p>
            </div>
        </div>
    )
}

export default SeoText
