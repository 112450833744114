//Styles
import "./ProfessorPublicCard.scss"

const ProfesseurPublicCard = ({
    urlImg,
    name,
    resume,
    facebook,
    instagram,
    pinterest,
    website,
}) => {
    return (
        <div className="professor-public-card-wrapper">
            <div className="professeur-public-card-image-wrapper">
                <img
                    src={
                        urlImg === null
                            ? "https://static.artesane.com/default/0001/12/thumb_11364_default_big.png"
                            : urlImg
                    }
                    alt={` ${name}`}
                />
            </div>
            <div className="professeur-public-card-description">
                <div className="professeur-public-card-title">À propos</div>
                <div
                    className="professeur-public-card-texte"
                    dangerouslySetInnerHTML={{ __html: resume }}></div>
                <div
                    onClick={() => {
                        document.getElementById("topAnchor").scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        })
                    }}
                    className="button-anchor">
                    Voir tous ses cours
                </div>
            </div>
            <div className="professeur-public-card-networks">
                {instagram !== null && (
                    <div className="professeur-public-card-network">
                        <div className="professeur-public-card-logo-wrapper">
                            <a
                                href={instagram}
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={
                                        "https://static.artesane.com/images_react/logo_instagram.png"
                                    }
                                    alt="logo instagram"
                                />{" "}
                            </a>
                        </div>
                        <div className="professeur-public-card-network-texte">
                            <a
                                href={instagram}
                                target="_blank"
                                rel="noreferrer">
                                {instagram.split("/")[3]}
                            </a>
                        </div>
                    </div>
                )}

                {facebook !== null && (
                    <div className="professeur-public-card-network">
                        <div className="professeur-public-card-logo-wrapper">
                            <a href={facebook} target="_blank" rel="noreferrer">
                                <img
                                    src={
                                        "https://static.artesane.com/images_react/logo_facebook.png"
                                    }
                                    alt="logo facebook"
                                />{" "}
                            </a>
                        </div>
                        <div className="professeur-public-card-network-texte">
                            <a href={facebook} target="_blank" rel="noreferrer">
                                {facebook.split("/")[3]}
                            </a>
                        </div>
                    </div>
                )}
                {pinterest !== null && (
                    <div className="professeur-public-card-network">
                        <div className="professeur-public-card-logo-wrapper">
                            <a
                                href={pinterest}
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={
                                        "https://static.artesane.com/images_react/logo_pinterest.png"
                                    }
                                    alt="logo pinterest"
                                />{" "}
                            </a>
                        </div>
                        <div className="professeur-public-card-network-texte">
                            <a
                                href={pinterest}
                                target="_blank"
                                rel="noreferrer">
                                {pinterest.split("/")[3]}
                            </a>
                        </div>
                    </div>
                )}
                {website !== null && (
                    <div className="professeur-public-card-network">
                        <div className="professeur-public-card-logo-wrapper">
                            <a
                                href={
                                    website.includes("http")
                                        ? website
                                        : "https://" + website
                                }
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    src={
                                        "https://static.artesane.com/images_react/logo_website.png"
                                    }
                                    alt="logo pinterest"
                                />{" "}
                            </a>
                        </div>

                        <div className="professeur-public-card-network-texte">
                            <a
                                href={
                                    website.includes("http")
                                        ? website
                                        : "https://" + website
                                }
                                target="_blank"
                                rel="noreferrer">
                                {website.includes("http")
                                    ? website.split("/")[2]
                                    : website}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProfesseurPublicCard
