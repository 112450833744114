import {
    USER_CONNECTION,
    TOGGLE_POPUP_VERIFIED_EMAIL,
    CHANGE_INPUT_AUTH_VALUE,
    IS_ERROR_PATTERN_EMAIL_VERIFICATION,
    IS_EMAIL_EXIST,
    VISIBILITY_AUTH_PASSWORD,
    LOADING_CONNEXION,
    ERROR_CONNEXION,
    LOADING_REGISTRATION,
    REDIRECT_TO_ORDER,
} from "../actions/authAction"

const initialState = {
    userConnected: false,
    emailVerified: false,
    password: "",
    emailPatternError: false,
    email: "",
    emailExist: false,
    popupEmailVerifiedOpen: false,
    visibilityPasswordConnexion: false,
    stayConnected: true,
    loadingConnexion: false,
    errorConnexion: false,
    //Registration
    newsletterArtsDuFil: false,
    newsletterArtsCulinaires: false,
    newsletterArtsPlastiques: false,
    firstNameRegistration: "",
    lastNameRegistration: "",
    birthDateRegistration: "",
    emailRegistration: "",
    pseudoRegistration: "",
    telephoneRegistration: "",
    errorPatternTelephoneRegistration: false,
    passwordRegistration: "",
    confirmPasswordRegistration: "",
    errorPatternPasswordRegistration: false,
    errorConfirmPasswordRegistration: false,
    errorPatternEmailRegistration: false,
    visibilityPasswordRegistration: false,
    visibilityConfirmPasswordRegistration: false,
    newsletterEmailRegistration: false,
    loadingRegistration: false,
    capchaRegistrationChecked: false,
    errorCapchaRegistration: false,
    capchaConnexionChecked: false,
    errorCapchaConnexion: false,
    isRefreshing: false,
    //Order
    redirectToOrder: false,

    isDeconnect: false,
    emailForgottenPassword: "",
    popUpPasswordConfirm: false,
    loadingChangePassword: false,
    forgetNewPassword: "",
    forgetNewPasswordVisibility: false,
    forgetNewPasswordConfirmVisibility: false,
    forgetConfirmNewPassword: "",
    forgetErrorPatternPassword: false,
    forgetErrorConfirmPassword: false,
    redirectAfterChangePassword: false,
}

const authReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case REDIRECT_TO_ORDER:
            return {
                ...state,
                redirectToOrder: action.value,
            }

        case LOADING_REGISTRATION:
            return {
                ...state,
                loadingRegistration: action.value,
            }

        case ERROR_CONNEXION:
            return {
                ...state,
                errorConnexion: action.value,
            }

        case LOADING_CONNEXION:
            return {
                ...state,
                loadingConnexion: action.value,
            }

        case VISIBILITY_AUTH_PASSWORD:
            return {
                ...state,
                visibilityPasswordConnexion: action.value,
            }

        case IS_EMAIL_EXIST:
            return {
                ...state,
                emailVerified: true,
                emailExist: action.value,
            }

        case IS_ERROR_PATTERN_EMAIL_VERIFICATION:
            return {
                ...state,
                emailPatternError: action.value,
            }

        case CHANGE_INPUT_AUTH_VALUE:
            return {
                ...state,
                [action.name]: action.value,
            }

        case TOGGLE_POPUP_VERIFIED_EMAIL:
            return {
                ...state,
                popupEmailVerifiedOpen: action.value,
            }

        case USER_CONNECTION:
            return {
                ...state,
                userConnected: action.value,
            }

        default:
            return state
    }
}
export default authReducer
