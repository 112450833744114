import { useDispatch, useSelector } from "react-redux"
import InputSimple from "../../InputSimple/inputSimple"
import "./PasswordConfirm.scss"
import {
    togglePopUpPassword,
    changeInputValue,
    isErrorPatternPassword,
    isErrorConfirmPassword,
    visibilityPassword,
    loadingModifyPassword,
    putModifyPassword,
} from "../../../../actions/userAction"
import Spinner from "../../../Commons/Spinner/Spinner"

const PasswordConfirm = () => {
    const dispatch = useDispatch()
    const currentPassword = useSelector((state) => state.user.currentPassword)
    const customerId = useSelector((state) => state.user.customerId)
    const newPassword = useSelector((state) => state.user.newPassword)
    const confirmPassword = useSelector((state) => state.user.confirmPassword)
    const errorPatternPassword = useSelector(
        (state) => state.user.errorPatternPassword
    )
    const errorConfirmPassword = useSelector(
        (state) => state.user.errorConfirmPassword
    )
    const isVisibleOldPassword = useSelector(
        (state) => state.user.visibilityOldPassword
    )
    const isVisibleNewPassword = useSelector(
        (state) => state.user.visibilityNewPassword
    )
    const isVisibleConfirmPassword = useSelector(
        (state) => state.user.visibilityConfirmPassword
    )
    const isLoadingModifyPassword = useSelector(
        (state) => state.user.loadingModifyPassword
    )
    const passwordIsChange = useSelector(
        (state) => state.user.changePasswordConfirm
    )
    const badCurrentPassword = useSelector(
        (state) => state.user.badCurrentPassword
    )
    const errorModifyPassword = useSelector(
        (state) => state.user.errorModifyPassword
    )

    return (
        <div className="password-confirm-wrapper">
            <div className="password-form-wrapper">
                {isLoadingModifyPassword && (
                    <div className="password-spinner">
                        <Spinner />
                    </div>
                )}
                <div
                    onClick={() => {
                        dispatch(togglePopUpPassword(false))
                        dispatch(changeInputValue("", "currentPassword"))
                        dispatch(changeInputValue("", "newPassword"))
                        dispatch(changeInputValue("", "confirmPassword"))
                        dispatch(
                            changeInputValue(false, "changePasswordConfirm")
                        )
                        dispatch(isErrorConfirmPassword(false))
                        dispatch(isErrorPatternPassword(false))
                        dispatch(
                            visibilityPassword(false, "visibilityOldPassword")
                        )
                        dispatch(
                            visibilityPassword(false, "visibilityNewPassword")
                        )
                        dispatch(
                            visibilityPassword(
                                false,
                                "visibilityConfirmPassword"
                            )
                        )
                    }}
                    className="password-to-closed">
                    ✕
                </div>
                <h1>Modifier votre mot de passe</h1>
                {passwordIsChange && (
                    <div className="validate">
                        Votre mot de passe a bien été changé
                    </div>
                )}
                {badCurrentPassword && (
                    <div className="error">Mot de passe actuel incorrect</div>
                )}
                {errorModifyPassword && (
                    <div className="error">
                        Une erreur s'est produite. Veuillez réessayer
                    </div>
                )}

                <form
                    className="password-form"
                    action="#"
                    onSubmit={(event) => {
                        event.preventDefault()
                        if (
                            newPassword.match(/[A-Z]/g) &&
                            newPassword.match(/[0-9]/g) &&
                            newPassword.match(/[a-z]/g) &&
                            newPassword.match(/[-+!?*$@%_]/g) &&
                            newPassword.match(/[-+!?*$@%_\w]/g) &&
                            newPassword.length > 7 &&
                            newPassword.length <= 15
                        ) {
                            dispatch(isErrorPatternPassword(false))
                        } else {
                            dispatch(isErrorPatternPassword(true))
                        }

                        if (newPassword === confirmPassword) {
                            dispatch(isErrorConfirmPassword(false))
                        } else {
                            dispatch(isErrorConfirmPassword(true))
                        }
                        dispatch(loadingModifyPassword(true))
                        dispatch(putModifyPassword(customerId))
                    }}>
                    <InputSimple
                        iconVisibility={
                            <img
                                src={
                                    isVisibleOldPassword
                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                        : "https://static.artesane.com/images_react/oeil.png"
                                }
                                alt="oeil"
                                onClick={() => {
                                    dispatch(
                                        visibilityPassword(
                                            !isVisibleOldPassword,
                                            "visibilityOldPassword"
                                        )
                                    )
                                }}
                            />
                        }
                        minLength={8}
                        placeholder={"Mot de passe actuel"}
                        inputName={"currentPassword"}
                        value={currentPassword}
                        type={isVisibleOldPassword ? "text" : "password"}
                        required={true}
                        onChange={(event) => {
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "currentPassword"
                                )
                            )
                            dispatch(
                                changeInputValue(false, "changePasswordConfirm")
                            )
                            dispatch(
                                changeInputValue(false, "badCurrentPassword")
                            )
                            dispatch(
                                changeInputValue(false, "errorModifyPassword")
                            )
                        }}
                    />
                    <InputSimple
                        iconVisibility={
                            <img
                                src={
                                    isVisibleNewPassword
                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                        : "https://static.artesane.com/images_react/oeil.png"
                                }
                                alt="oeil"
                                onClick={() => {
                                    dispatch(
                                        visibilityPassword(
                                            !isVisibleNewPassword,
                                            "visibilityNewPassword"
                                        )
                                    )
                                }}
                            />
                        }
                        error={errorPatternPassword}
                        minLength={8}
                        maxLength={15}
                        placeholder={"Nouveau mot de passe"}
                        inputName={"newPassword"}
                        value={newPassword}
                        type={isVisibleNewPassword ? "text" : "password"}
                        required={true}
                        onChange={(event) => {
                            dispatch(
                                changeInputValue(false, "changePasswordConfirm")
                            )
                            dispatch(
                                changeInputValue(false, "badCurrentPassword")
                            )
                            dispatch(
                                changeInputValue(false, "errorModifyPassword")
                            )
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "newPassword"
                                )
                            )
                        }}
                    />
                    <div
                        className={`input-information ${errorPatternPassword ? "error" : ""}`}>
                        {" "}
                        8 à 15 caractères requis. Au moins une majuscule, une
                        minuscule, un chiffre, un caractère spécial ($ @ % * + -
                        _ ! ?){" "}
                    </div>
                    <InputSimple
                        iconVisibility={
                            <img
                                src={
                                    isVisibleConfirmPassword
                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                        : "https://static.artesane.com/images_react/oeil.png"
                                }
                                alt="oeil"
                                onClick={() => {
                                    dispatch(
                                        visibilityPassword(
                                            !isVisibleConfirmPassword,
                                            "visibilityConfirmPassword"
                                        )
                                    )
                                }}
                            />
                        }
                        error={errorConfirmPassword}
                        minLength={8}
                        maxLength={15}
                        placeholder={"Confirmer mon nouveau mot de passe"}
                        inputName={"confirmNewPassword"}
                        value={confirmPassword}
                        type={isVisibleConfirmPassword ? "text" : "password"}
                        required={true}
                        onChange={(event) => {
                            dispatch(
                                changeInputValue(false, "changePasswordConfirm")
                            )
                            dispatch(
                                changeInputValue(false, "badCurrentPassword")
                            )
                            dispatch(
                                changeInputValue(false, "errorModifyPassword")
                            )
                            dispatch(
                                changeInputValue(
                                    event.target.value,
                                    "confirmPassword"
                                )
                            )
                        }}
                    />
                    <div
                        className={`input-information ${errorConfirmPassword ? "error" : ""}`}>
                        Correspondance des mots de passe
                    </div>

                    <button className="submit-button" type="submit">
                        Enregistrer {">"}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default PasswordConfirm
