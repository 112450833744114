import { useSelector } from "react-redux"
import MediaProductCard from "./MediaProductCard/MediaProductCard"
import "./MediaProductItem.scss"

const MediaProductItem = ({ collections, title }) => {
    const packsEtParcours = useSelector((state) => state.media.packsEtParcours)
    const documentsCours = useSelector((state) => state.media.documentsCours)
    const videosCours = useSelector((state) => state.media.videosCours)

    let countProductFind = 0

    return (
        collections.length > 0 && (
            <div className="mediaproduct-item-wrapper">
                <div className="mediaproduct-subject">{title}</div>

                <div className="media-product-items">
                    <div className="media-product-item-cards">
                        {collections.map(({ id, collection }) => {
                            var teacher = ""
                            for (var product of collection.products) {
                                if (product.product.author_name !== null) {
                                    teacher = product.product.author_name
                                    break
                                }
                            }

                            if (
                                packsEtParcours &&
                                collection.courses_count > 1 &&
                                !documentsCours &&
                                !videosCours
                            ) {
                                countProductFind++

                                return (
                                    <MediaProductCard
                                        key={id}
                                        teacher={teacher}
                                        urlImg={
                                            collection.products.length > 0
                                                ? collection.products[0].product
                                                      .mainImage.thumbnails
                                                      .carousel
                                                : ""
                                        }
                                        title={collection.name}
                                        id={id}
                                    />
                                )
                            } else if (
                                documentsCours &&
                                collection.courses_count < 1 &&
                                !packsEtParcours &&
                                !videosCours
                            ) {
                                countProductFind++

                                return (
                                    <MediaProductCard
                                        key={id}
                                        teacher={teacher}
                                        urlImg={
                                            collection.products.length > 0
                                                ? collection.products[0].product
                                                      .mainImage !== null ? collection.products[0].product
                                                      .mainImage.thumbnails
                                                      .carousel : ""
                                                : ""
                                        }
                                        title={collection.name}
                                        id={id}
                                    />
                                )
                            } else if (
                                videosCours &&
                                collection.courses_count === 1 &&
                                !packsEtParcours &&
                                !documentsCours
                            ) {
                                countProductFind++

                                return (
                                    <MediaProductCard
                                        key={id}
                                        teacher={teacher}
                                        urlImg={
                                            collection.products.length > 0
                                                ? collection.products[0].product
                                                      .mainImage.thumbnails
                                                      .carousel
                                                : ""
                                        }
                                        title={collection.name}
                                        id={id}
                                    />
                                )
                            } else if (
                                videosCours &&
                                packsEtParcours &&
                                collection.courses_count >= 1 &&
                                !documentsCours
                            ) {
                                countProductFind++

                                return (
                                    <MediaProductCard
                                        key={id}
                                        teacher={teacher}
                                        urlImg={
                                            collection.products.length > 0
                                                ? collection.products[0].product
                                                      .mainImage.thumbnails
                                                      .carousel
                                                : ""
                                        }
                                        title={collection.name}
                                        id={id}
                                    />
                                )
                            } else if (
                                videosCours &&
                                documentsCours &&
                                (collection.courses_count === 1 ||
                                    collection.courses_count < 1) &&
                                !packsEtParcours
                            ) {
                                countProductFind++

                                return (
                                    <MediaProductCard
                                        key={id}
                                        teacher={teacher}
                                        urlImg={
                                            collection.products.length > 0
                                                ? collection.products[0].product
                                                      .mainImage.thumbnails
                                                      .carousel
                                                : ""
                                        }
                                        title={collection.name}
                                        id={id}
                                    />
                                )
                            } else if (
                                packsEtParcours &&
                                documentsCours &&
                                (collection.courses_count > 1 ||
                                    collection.courses_count < 1) &&
                                !videosCours
                            ) {
                                countProductFind++

                                return (
                                    <MediaProductCard
                                        key={id}
                                        teacher={teacher}
                                        urlImg={
                                            collection.products.length > 0
                                                ? collection.products[0].product
                                                      .mainImage.thumbnails
                                                      .carousel
                                                : ""
                                        }
                                        title={collection.name}
                                        id={id}
                                    />
                                )
                            } else if (
                                (videosCours &&
                                    packsEtParcours &&
                                    documentsCours) ||
                                (!packsEtParcours &&
                                    !documentsCours &&
                                    !videosCours)
                            ) {
                                countProductFind++

                                return (
                                    <MediaProductCard
                                        key={id}
                                        teacher={teacher}
                                        urlImg={
                                            collection.products.length > 0
                                                ? collection.products[0].product
                                                      .mainImage.thumbnails
                                                      .carousel
                                                : ""
                                        }
                                        title={collection.name}
                                        id={id}
                                    />
                                )
                            }
                            return null
                        })}
                    </div>
                    {countProductFind < 1 && (
                        <div className="no-result">
                            {" "}
                            Aucun résultat dans cette matière
                        </div>
                    )}
                </div>
            </div>
        )
    )
}
export default MediaProductItem
