import "./Header.scss"

import { NavLink, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const Header = () => {
    const { pathname } = useLocation()
    const lastUnivers = useSelector((state) => state.home.homeUniversActif)
    return (
        <div className="header-wrapper">
            <div className="header-logo">
                <NavLink to={lastUnivers !== "" ? `/${lastUnivers}` : "/"}>
                    <img
                        src={
                            "https://static.artesane.com/images_react/logo.png"
                        }
                        alt="logo du site Artesane"
                    />{" "}
                </NavLink>
            </div>
            <div className="header-progression">
                <div className="progression-item ">
                    <span title="Connexion">1</span>
                </div>
                <div
                    className={`progression-item ${pathname.includes("livraison") ? "active" : ""} `}>
                    <span title="Livraison">2</span>
                </div>
                <div
                    className={`progression-item ${pathname.includes("paiement") ? "active" : ""} `}>
                    <span title="Paiement">3</span>
                </div>
                <div
                    className={`progression-item ${pathname.includes("reussite") ? "active" : ""} `}>
                    <span title="Confirmation">4</span>
                </div>
            </div>
        </div>
    )
}

export default Header
