//Styles
import { useEffect, useRef } from "react"
import "./Summary.scss"

const Summary = ({ productData }) => {
    const contentRef = useRef(null)

    useEffect(() => {
        if (contentRef.current) {
            const links = contentRef.current.querySelectorAll("a")
            links.forEach((link) => {
                link.setAttribute("target", "_blank")
                link.setAttribute("rel", "noopener noreferrer")
            })
        }
    }, [])
    function createMarkup() {
        return {
            __html:
                productData.attributes.length > 0
                    ? productData.attributes.find(
                          (item) => item.code === "resume"
                      ).value
                    : "",
        }
    }

    return (
        <div
            className="summary-wrapper"
            ref={contentRef}
            dangerouslySetInnerHTML={createMarkup()}></div>
    )
}
export default Summary
