import {
    changeInputOrder,
    changeQuantity,
    deleteOrderItemCart,
    loadingDeleteModifyCart,
} from "../../../actions/cartAction"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../../Commons/Spinner/Spinner"
import "./ItemCard.scss"
import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import OutsideClickHandler from "react-outside-click-handler"

const ItemCard = ({
    gift,
    subtotal,
    unitPrice,
    originalUnitPrice,
    universeCode,
    productSlug,
    index,
    dateUpdate,
    id,
    productId,
    shippingRequired,
    productName,
    variant,
    buyOnce,
    quantity,
    urlImg,
}) => {
    const dispatch = useDispatch()
    const userConnected = useSelector((state) => state.auth.userConnected)
    const orderItemAdjustments = useSelector(
        (state) => state.cart.orderItemAdjustments
    )
    const itemModify = useSelector(
        (state) => state.cart.loadingDeleteOrModifyItemCart
    )
    const cartTokenUser = useSelector((state) => state.cart.cartData)

    const giftInformation = useSelector((state) => state.cart.giftInformation)

    //Permet d'obtenir un objet type Date et d'utiliser la fonction getTimezoneOffset
    const serverTimeCartUpdate = new Date(dateUpdate)

    //Retourne le décalage horaire par rapport à l'heure local du client et l'heure du server ( en nombre positif ou négatif)
    const timeZoneOffset = serverTimeCartUpdate.getTimezoneOffset()

    //Conversion de la date server en milliseconds pour ajouter les 15 min de réservation
    const serverTimeCartUpdateMilliseconds = Date.parse(dateUpdate) + 15 * 60000

    //Récupération de la date locale en miliseconds, ajustée au décalage horraire avec le server pour être sur le même fuseau horaire
    const localTimeCartUpdateMilliseconds = Date.now() + timeZoneOffset * 60000

    useEffect(() => {
        if (shippingRequired) {
            if (
                localTimeCartUpdateMilliseconds >=
                serverTimeCartUpdateMilliseconds
            ) {
                dispatch(loadingDeleteModifyCart(id))
                if (!userConnected) {
                    dispatch(
                        deleteOrderItemCart(
                            productId,
                            id,
                            localStorage.getItem("cart"),
                            userConnected
                        )
                    )
                } else {
                    dispatch(
                        deleteOrderItemCart(
                            productId,
                            id,
                            cartTokenUser.token_value,
                            userConnected
                        )
                    )
                }
            }
        }
    }, [
        shippingRequired,
        cartTokenUser.token_value,
        dispatch,
        id,
        localTimeCartUpdateMilliseconds,
        serverTimeCartUpdateMilliseconds,
        productId,
        userConnected,
    ])

    return (
        <div className="item-card-wrapper">
            <div className="item-bottom-wrapper">
                {itemModify === id && (
                    <div className="spinner-cart">
                        <Spinner />
                    </div>
                )}

                <div className="image-wrapper">
                    {originalUnitPrice !== unitPrice && (
                        <span className="solde">
                            -{" "}
                            {Math.floor(((originalUnitPrice - unitPrice) /originalUnitPrice) * 100)}{" "} %
                        </span>
                    )}
                    <NavLink to={`/${universeCode}/produit/${productSlug}`}>
                        <img src={urlImg} alt="" />
                    </NavLink>
                </div>
                <div className="name-wrapper">
                    <NavLink to={`/${universeCode}/produit/${productSlug}`}>
                        <div className="product-title">{productName}</div>
                    </NavLink>
                    {variant.shippingRequired && (
                        <div className="product-variant">{variant.name}</div>
                    )}
                </div>
                <div className="gift-wrapper">
                    {gift && (
                        <>
                            <img
                                src="https://static.artesane.com/images_react/cadeau-jaune-38.png"
                                alt="offrir"
                            />
                            <span>Offrir</span>

                            <OutsideClickHandler
                                onOutsideClick={() => {
                                    dispatch(
                                        changeInputOrder(0, "giftInformation")
                                    )
                                }}>
                                <span
                                    onClick={() => {
                                        dispatch(
                                            changeInputOrder(
                                                id,
                                                "giftInformation"
                                            )
                                        )
                                    }}
                                    className="information-gift">
                                    i
                                </span>
                            </OutsideClickHandler>
                            {giftInformation === id && (
                                <div className="gift-information-text">
                                    Rien de plus simple qu'offrir un cours
                                    Artesane en cadeau! Une fois le panier
                                    validé, vous recevrez un email contenant un
                                    lien d'activation du cours. C'est ce lien
                                    qu'il vous faudra communiquer à l'heureux-se
                                    destinataire de votre cadeau pour qu'il/elle
                                    puisse ajouter le cours à son espace élève.
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="quantity-wrapper">
                    {!buyOnce && (
                        <select
                            onChange={(event) => {
                                dispatch(loadingDeleteModifyCart(id))
                                if (!userConnected) {
                                    dispatch(
                                        changeQuantity(
                                            Number(event.target.value),
                                            localStorage.getItem("cart"),
                                            id
                                        )
                                    )
                                } else {
                                    dispatch(
                                        changeQuantity(
                                            Number(event.target.value),
                                            cartTokenUser.tokenValue,
                                            id
                                        )
                                    )
                                }
                            }}
                            value={quantity}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </select>
                    )}
                    <div
                        className="delete"
                        onClick={() => {
                            dispatch(loadingDeleteModifyCart(id))
                            if (!userConnected) {
                                dispatch(
                                    deleteOrderItemCart(
                                        id,
                                        localStorage.getItem("cart"),
                                        userConnected
                                    )
                                )
                            } else {
                                dispatch(
                                    deleteOrderItemCart(
                                        id,
                                        cartTokenUser.tokenValue,
                                        userConnected
                                    )
                                )
                            }
                        }}>
                        <span className="desktop"> Supprimer</span>
                        <img
                            className="mobile"
                            src={
                                "https://static.artesane.com/images_react/delete.png"
                            }
                            alt="supprimer"
                        />
                    </div>
                </div>
                <div className="price-wrapper">
                    <div className="after-price">
                        {" "}
                        <span className="num-font">
                            {(subtotal / 100).toFixed(2).replace(".", ",")}
                        </span>{" "}
                        €
                    </div>
                    {originalUnitPrice !== unitPrice && (
                        <div className="before-price">
                            {(originalUnitPrice * quantity) / 100} €
                        </div>
                    )}
                </div>
            </div>
            {orderItemAdjustments.length > 0 && (
                <>
                    {orderItemAdjustments[index].adjustments_total > 0 && (
                        <div>
                            Coupon réduction appliqué{" "}
                            {(
                                (orderItemAdjustments[index].units[0]
                                    .adjustments_total *
                                    orderItemAdjustments[index].units.length) /
                                100
                            ).toFixed(2)}
                            €
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ItemCard
