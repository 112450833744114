import Header from "../../Prepage/Header/Header"
import { useEffect } from "react"

//Styles
import "./ConditionsOffres.scss"
import Banner from "../../Banner/Banner"

const ConditionsOffres = () => {
    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | Conditions des offres en cours"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Conditions des offres en cours"
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [])
    return (
        <div>
            <Banner />
            <Header />
            <div className="conditions-offres-wrapper">
                <h1>Conditions des offres en cours</h1>
                <p>
                    D&eacute;couvrez dans cet article les conditions des offres
                    promotionnelles d&rsquo;Artesane : offres de bienvenue,
                    offre de lancement, offre de parrainage.
                </p>
                <p>
                    <strong>Offres de bienvenue</strong>
                </p>
                <p>
                    Offre de bienvenue valable (du 25 novembre au 30 avril 2022)
                    lors de l&rsquo;inscription &agrave; la newsletter
                    d&rsquo;Artesane. Cette offre vous permet de
                    b&eacute;n&eacute;ficier d&rsquo;un cours gratuit parmi les
                    trois cours suivants : &ldquo;Dessiner des paysages aux
                    feutres&rdquo;, &ldquo;Je couds ma blouse
                    nou&eacute;e&rdquo;, &ldquo;20 techniques pour
                    d&eacute;buter l&rsquo;aquarelle&rdquo;. Cette offre est non
                    cumulable avec une autre offre promotionnelle en cours. Pour
                    b&eacute;n&eacute;ficier de cette offre, il faut
                    s&rsquo;inscrire &agrave; la newsletter d&rsquo;Artesane en
                    rentrant son e-mail en bas du site{" "}
                    <a href="http://www.artesane.com">www.artesane.com</a>.
                </p>
                <p>
                    Offre de bienvenue valable sur le site d&rsquo;Artesane
                    jusqu&rsquo;au 31 d&eacute;cembre 2022. Cette offre vous
                    permet de b&eacute;n&eacute;ficier gratuitement d&rsquo;un
                    cours et d&rsquo;un patron &ldquo;Je couds ma robe
                    parfaite&rdquo; en vous inscrivant &agrave; la newsletter :
                    cette offre est non cumulable avec une autre offre
                    promotionnelle en cours.
                </p>
                <p>
                    <strong>Offres de lancement&nbsp;</strong>
                </p>
                <p>
                    &Agrave; l&rsquo;occasion du lancement de nouveaux cours,
                    Artesane peut offrir une r&eacute;duction de 5&euro;, ou
                    d&rsquo;un autre montant, lors de la semaine de mise en
                    ligne du ou des nouveaux cours. Cette offre est non
                    cumulable avec une autre offre promotionnelle en cours. Les
                    prix affich&eacute;s ne tiennent pas en compte de la
                    r&eacute;duction. Ces offres sont sujettes &agrave;
                    modification sans pr&eacute;avis de la part
                    d&rsquo;Artesane.
                </p>
                <p>
                    <strong>Offre de parrainage</strong>
                </p>
                <p>
                    Offre de parrainage, valable lorsque le parrain partage son
                    code disponible sur le site. Bon de 5&euro; offert pour
                    chaque personne parrain&eacute;e. Le nombre maximum de
                    filleuls est de 10.
                </p>
                <p>
                    Le filleul b&eacute;n&eacute;ficie de 5&euro; de
                    r&eacute;duction sur sa premi&egrave;re commande.
                </p>
                <p>
                    Syst&egrave;me de parrainage avec obligation minimum
                    d&rsquo;achat : l&rsquo;utilisation des r&eacute;ductions de
                    5&euro; &agrave; 15&euro; est possible pour toute commande
                    de 25&euro; minimum ; l&rsquo;utilisation des
                    r&eacute;ductions de 20&euro; &agrave; 30&euro; est possible
                    pour toute commande de 50&euro; minimum ;
                    l&rsquo;utilisation des r&eacute;ductions de 35&euro;
                    &agrave; 45&euro; est possible pour toute commande de
                    100&euro; minimum ; enfin l&rsquo;utilisation des
                    r&eacute;ductions de 50&euro; n&rsquo;est possible que pour
                    les commandes de 150&euro; et plus.
                </p>
                <p>
                    Les bons de parrainage sont cumulables avec toutes les
                    op&eacute;rations commerciales en cours.
                </p>
                <p>
                    L&rsquo;offre est accessible sur notre site :{" "}
                    <a href="http://www.artesane.com">www.artesane.com</a>
                </p>
            </div>
        </div>
    )
}
export default ConditionsOffres
