import OutsideClickHandler from "react-outside-click-handler"

//Hooks
import { useDispatch, useSelector } from "react-redux"

//Action
import { toggleFilterNameActif } from "../../../../../actions/catalogueAction"

//Components
import FilterChoiceInput from "./FilterChoiceInput/FilterChoiceInput"
import FilterChoiceSlider from "./FilterChoiceSlider/FilterChoiceSlider"

//Style
import "./FiltersUnderItems.scss"
import MediaQuery from "react-responsive"

const FiltersUnderItems = ({
    productNumber,
    teachers,
    filters,
    title,
    parentFilterId,
}) => {
    const dispatch = useDispatch()
    const currentFilterActif = useSelector(
        (state) => state.catalogue.filterNameActif
    )
    const filtersToMap = title === "Professeur" ? teachers : filters

    if (title !== "Prix" && currentFilterActif === title) {
        return (
            <>
                <MediaQuery minWidth={1000}>
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            dispatch(toggleFilterNameActif(""))
                        }}>
                        <div className="filters-sort-under-items-open <1000">
                            {filtersToMap.filter(item => item.enabled !== false).map(({ name, id }) => {
                                return (
                                    <FilterChoiceInput
                                        parentFilterId={parentFilterId}
                                        parentFilterName={title}
                                        nombreProduits={productNumber}
                                        key={id}
                                        nom={name}
                                        id={id}
                                    />
                                )
                            })}
                        </div>
                    </OutsideClickHandler>
                </MediaQuery>
                <MediaQuery maxWidth={1000}>
                    <div className="filters-sort-under-items-open 1000">
                        {filtersToMap.filter(item => item.enabled !== false).map(({ name, id }) => {
                            return (
                                <FilterChoiceInput
                                    parentFilterId={parentFilterId}
                                    parentFilterName={title}
                                    nombreProduits={productNumber}
                                    key={id}
                                    nom={name}
                                    id={id}
                                />
                            )
                        })}
                    </div>
                </MediaQuery>
            </>
        )
    } else if (title === "Prix" && currentFilterActif === "Prix") {
        return (
            <>
                <MediaQuery minWidth={1000}>
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            dispatch(toggleFilterNameActif(""))
                        }}>
                        <div className={"filters-sort-under-items-open"}>
                            <FilterChoiceSlider />
                        </div>
                    </OutsideClickHandler>
                </MediaQuery>
                <MediaQuery maxWidth={1000}>
                    <div className={"filters-sort-under-items-open"}>
                        <FilterChoiceSlider />
                    </div>
                </MediaQuery>
            </>
        )
    }
}

export default FiltersUnderItems
