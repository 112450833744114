import {
    SAVE_PROFESSORS_HIGHLIGHT,
    CHANGE_CURRENT_PROFESSORS_PAGE,
    SAVE_PROFESSEUR_CATALOGUE_DATA,
    SAVE_PROFESSEUR_PUBLIC_DATA,
    SAVE_ALL_PROFESSORS,
    SAVE_PROFESSOR_DATA_IN_STATE,
} from "../actions/professorsAction"

const initialState = {
    catalogueCurrentPage: 1,
    professeurCatalogueData: [],
    isLoadingProfesseurCatalogue: true,
    professeurPublicData: [],
    productsOneTeacher: [],
    isLoadingProfesseurPublicData: true,
    isLoadingAllProfessors: true,
    allProfessors: [],
    professorsHighlight: [],
    loadingProfessorsHighlight: true,
}

const professorsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_PROFESSOR_DATA_IN_STATE:
            return {
                ...state,
                [action.property]: action.value,
            }

        case SAVE_PROFESSORS_HIGHLIGHT:
            return {
                ...state,
                loadingProfessorsHighlight: false,
                professorsHighlight: action.value,
            }

        case SAVE_ALL_PROFESSORS:
            return {
                ...state,
                isLoadingAllProfessors: false,
                allProfessors: action.value,
            }

        case SAVE_PROFESSEUR_PUBLIC_DATA:
            return {
                ...state,
                professeurPublicData: action.value,
                productsOneTeacher: action.products,
                isLoadingProfesseurPublicData: action.isLoading,
            }

        case SAVE_PROFESSEUR_CATALOGUE_DATA:
            return {
                ...state,
                professeurCatalogueData: action.value,
                isLoadingProfesseurCatalogue: action.loading,
            }

        case CHANGE_CURRENT_PROFESSORS_PAGE:
            return {
                ...state,
                catalogueCurrentPage: action.value,
            }

        default:
            return state
    }
}
export default professorsReducer
