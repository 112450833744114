//Style
import "./SearchBarMutual.scss"

import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
    getGeneralSearchbar,
    saveCommonDataInState,
} from "../../../../../actions/commonAction"

const SearchBarMutual = ({ onSubmit, placeHolder, inputValue, onChange }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loadingSearchbar = useSelector(
        (state) => state.common.loadingGeneralSearchbar
    )

    return (
        <div className="searchBar">
            <form
                className="searchBar-form"
                htmlFor="search"
                onSubmit={(event) => {
                    event.preventDefault()
                    if (!loadingSearchbar) {
                        dispatch(
                            saveCommonDataInState(
                                "loadingGeneralSearchbar",
                                true
                            )
                        )
                    }
                    dispatch(getGeneralSearchbar(inputValue))
                    dispatch(
                        saveCommonDataInState(
                            "searchBarGeneralWords",
                            inputValue
                        )
                    )
                    navigate(`/recherche/${inputValue}`)
                }}>
                <input
                    id="search"
                    name="search-bar"
                    className="searchBar-input"
                    type="text"
                    placeholder={placeHolder}
                    value={inputValue}
                    onChange={onChange}
                />
                <button className="search-bar-button" type="submit">
                    <img
                        type="submit"
                        className="searchBar-icon"
                        src={
                            "https://static.artesane.com/images_react/loupe.png"
                        }
                        alt="icon de recherche"
                    />
                </button>
            </form>
        </div>
    )
}

export default SearchBarMutual
