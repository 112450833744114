import "./ChatButton.scss"
import { useDispatch, useSelector } from "react-redux"
import { toggleChatButton } from "../../../../actions/mediaAction"
import { useEffect } from "react"

const ChatButton = () => {
    const dispatch = useDispatch()
    const isChatButtonOpen = useSelector((state) => state.media.chatButton)

    useEffect(() => {
        if (isChatButtonOpen) {
            setTimeout(() => {
                dispatch(toggleChatButton(false))
            }, 10000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChatButtonOpen])

    return (
        <div className="chatbutton-wrapper">
            <div
                onClick={() => {
                    dispatch(toggleChatButton(!isChatButtonOpen))
                }}
                className="chat-img-wrapper">
                <img
                    src={
                        "https://static.artesane.com/images_react/chatpicto.png"
                    }
                    alt="chat"
                />
            </div>
            {isChatButtonOpen && (
                <div className="chat-message">
                    Le fil de discussion est actuellement inactif pour cause de
                    refonte : vous retrouverez bientôt un nouvel espace de
                    discussion entièrement repensé pour votre confort ici !
                </div>
            )}
        </div>
    )
}

export default ChatButton
