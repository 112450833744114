//Hooks
import { NavLink, useParams } from "react-router-dom"

//Styles
import "./UnderTaxonsItems.scss"

const UnderTaxonsItems = ({ elements }) => {
    const { univers } = useParams()
    return (
        <div className="under-taxons-items" key={elements}>
            {elements.map(({ name, code }) => (
                <div className="under-taxons-item" key={code}>
                    <NavLink
                        key={code}
                        to={`/${univers}/catalogue/${code.toLowerCase()}/1`}>
                        {name}
                    </NavLink>
                </div>
            ))}
        </div>
    )
}

export default UnderTaxonsItems
