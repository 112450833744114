//Action

import {
    CHANGE_INPUT_VALUE,
    TOGGLE_POP_UP_PASSWORD,
    IS_ERROR_PATTERN_PASSWORD,
    IS_ERROR_CONFIRM_PASSWORD,
    VISIBILITY_PASSWORD,
    CHANGE_IMAGE_FILE,
    CHANGE_IMAGE_URL,
    CHECK_SAVE,
    SAVE_ORDERS,
    TOGGLE_ITEMS_ORDER,
    SAVE_ORDER_ITEMS,
    LOADING_ORDER_ITEMS,
    TOGGLE_POPUP_MODIFY_ADDRESS,
    IS_ERROR_PATTERN_EMAIL,
    DEFAULT_ADDRESS_ACTIF,
    SAVE_WISHLIST,
    LOADING_MODIFY_ADDRESS,
    SAVE_COUNTRIES,
    ERROR_MODIFY_ADDRESS,
    LOADING_ADD_ADDRESS,
    TOGGLE_POPUP_ERROR,
    LOADING_DELETE_ADDRESS,
    LOADING_USER_REQUEST,
    LOADING_MODIFY_PASSWORD,
    WISHLIST_ITEM,
} from "../actions/userAction"

const initialState = {
    customerId: 0,
    userId: 0,
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    email: "",
    pseudo: "",
    website: "",
    description: "",
    instagram: "",
    facebook: "",
    pinterest: "",
    twitch: "",
    youtube: "",
    telephone: "",
    isPopUpPasswordOpen: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    errorPatternPassword: false,
    errorPatternTelephone: false,
    errorBirthdate: false,
    errorConfirmPassword: false,
    errorPatternEmail: false,
    visibilityOldPassword: false,
    visibilityNewPassword: false,
    visibilityConfirmPassword: false,
    userImageUrl: "",
    userImageFile: null,
    isSave: true,
    orders: [],
    orderItems: [],
    loadingOrders: true,
    loadingOrdersItems: true,
    openItemsOrders: false,
    loadingWishlist: true,
    wishList: {},
    wishlistItemNumber: 0,
    //Adresses
    loadingModifyAddress: false,
    loadingAddAddress: true,
    loadingUserRequest: false,
    errorModifyAddress: false,
    addresses: [],
    defaultAddress: "",
    isPopupModifyAddressOpen: false,
    adressIdModify: 0,
    lastNameAddressModify: "",
    firstNameAddressModify: "",
    streetAddressModify: "",
    societyAddressModify: "",
    postCodeAddressModify: "",
    cityAddressModify: "",
    countryAddressModify: "",
    telephoneAddressModify: "",
    errorPatternTelephoneAddressModify: false,
    lastNameAddressAdd: "",
    firstNameAddressAdd: "",
    streetAddressAdd: "",
    societyAddressAdd: "",
    postCodeAddressAdd: "",
    cityAddressAdd: "",
    countryAddressAdd: "FR",
    telephoneAddressAdd: "",
    errorPatternTelephoneAddressAdd: false,
    defaultAdressAdd: false,
    errorRequest: false,
    errorMessage: "",
    loadingDeleteAddress: false,
    //Subsciptions
    newsletterEmail: false,
    newsletterSMS: false,
    newsletterPaper: false,
    cookies: "",
    //    Auth and connexion
    verificationEmailPopUp: false,
    //Password
    loadingModifyPassword: false,
    changePasswordConfirm: false,
    badCurrentPassword: false,
    errorModifyPassword: false,
    //Countries
    countries: [],
    wishlistItemSaved: [],
    giftcards: [],
    loadingGiftcards: true,
    loadingGiftcardsPdf: "",
    giftcardCode: "",
    loadingActivateCourse: false,
    courseActivate: false,
    loadingInvoice: "",
    urlInvoice: { orderToken: "", url: "" },
}

const userReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case WISHLIST_ITEM: {
            return {
                ...state,
                wishlistItemSaved: action.productId,
            }
        }
        case LOADING_MODIFY_PASSWORD:
            return {
                ...state,
                loadingModifyPassword: action.value,
            }

        case LOADING_USER_REQUEST:
            return {
                ...state,
                loadingUserRequest: action.value,
            }

        case LOADING_DELETE_ADDRESS:
            return {
                ...state,
                loadingDeleteAddress: action.value,
            }

        case TOGGLE_POPUP_ERROR:
            return {
                ...state,
                errorRequest: action.value,
                errorMessage: action.message,
            }

        case LOADING_ADD_ADDRESS:
            return {
                ...state,
                loadingAddAddress: action.value,
            }

        case ERROR_MODIFY_ADDRESS:
            return {
                ...state,
                errorModifyAddress: action.value,
            }

        case SAVE_COUNTRIES:
            return {
                ...state,
                countries: action.value,
            }

        case LOADING_MODIFY_ADDRESS:
            return {
                ...state,
                loadingModifyAddress: action.value,
            }

        case SAVE_WISHLIST:
            return {
                ...state,
                wishList: action.value,
            }
        case DEFAULT_ADDRESS_ACTIF:
            return {
                ...state,
                defaultAddress: action.value,
            }

        case IS_ERROR_PATTERN_EMAIL:
            return {
                ...state,
                errorPatternEmail: action.value,
            }

        case TOGGLE_POPUP_MODIFY_ADDRESS:
            return {
                ...state,
                isPopupModifyAddressOpen: action.value,
            }

        case LOADING_ORDER_ITEMS:
            return {
                ...state,
                loadingOrdersItems: action.value,
            }

        case SAVE_ORDER_ITEMS:
            return {
                ...state,
                orderItems: action.value,
            }

        case TOGGLE_ITEMS_ORDER:
            return {
                ...state,
                openItemsOrders: action.value,
            }

        case SAVE_ORDERS:
            return {
                ...state,
                orders: action.value,
                loadingOrders: false,
            }

        case CHECK_SAVE:
            return {
                ...state,
                isSave: action.value,
            }

        case CHANGE_IMAGE_URL:
            return {
                ...state,
                userImageUrl: action.value,
            }

        case CHANGE_IMAGE_FILE:
            return {
                ...state,
                userImageFile: action.value,
            }

        case VISIBILITY_PASSWORD:
            return {
                ...state,
                [action.stateName]: action.value,
            }

        case IS_ERROR_CONFIRM_PASSWORD:
            return {
                ...state,
                errorConfirmPassword: action.value,
            }

        case IS_ERROR_PATTERN_PASSWORD:
            return {
                ...state,
                errorPatternPassword: action.value,
            }

        case TOGGLE_POP_UP_PASSWORD:
            return {
                ...state,
                isPopUpPasswordOpen: action.value,
            }

        case CHANGE_INPUT_VALUE:
            return {
                ...state,
                [action.name]: action.value,
            }
        default:
            return state
    }
}
export default userReducer
