import "./OrdersCard.scss"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchOrderItems,
    saveOrderItems,
    toggleItemsOrder,
    loadingOrderItems,
    changeInputValue,
    fetchInvoice,
} from "../../../../actions/userAction"
import Spinner from "../../../Commons/Spinner/Spinner"

const OrdersCard = ({
    id,
    orderNumber,
    totalPrice,
    date,
    orderState,
    paymentState,
    shippingFirstName,
    shippingLastName,
    shippingStreet,
    shippingCity,
    shippingPostcode,
    shippingCompany,
    shippingCountrycode,
    billingFirstName,
    billingLastName,
    billingStreet,
    billingCity,
    billingPostcode,
    billingCompany,
    billingCountrycode,
    tokenValue,
    shipments,
}) => {
    const dispatch = useDispatch()
    const isOpenItemsOrder = useSelector((state) => state.user.openItemsOrders)
    const orderItems = useSelector((state) => state.user.orderItems)
    const loadingItems = useSelector((state) => state.user.loadingOrdersItems)
    const loadingInvoice = useSelector((state) => state.user.loadingInvoice)
    const urlInvoice = useSelector((state) => state.user.urlInvoice)

    return (
        <div className="orders-card-wrapper">
            <div className="orders-card-wrapper-left">
                {loadingInvoice === tokenValue && <Spinner />}
                <div className="orders-card-number">
                    <span>N° de commande</span>
                    <span className="bold">{orderNumber}</span>
                </div>
                <div className="orders-card-total-price">
                    <span>Montant</span>
                    <span className="bold">
                        {totalPrice === 0
                            ? "Gratuit"
                            : (totalPrice / 100).toFixed(2) + " € TTC"}
                    </span>
                </div>
                <div className="orders-card-date">
                    <span>commande effectuée le</span>
                    {date !== undefined && date !== null && (
                        <span className="bold">
                            {date.slice(8, 10)}/{date.slice(5, 7)}/
                            {date.slice(0, 4)}
                        </span>
                    )}
                </div>
                <div
                    className="orders-card-invoice"
                    onClick={() => {
                        dispatch(changeInputValue(tokenValue, "loadingInvoice"))
                        dispatch(fetchInvoice(tokenValue))
                    }}>
                    {!(urlInvoice.orderToken === tokenValue) && !(orderNumber.includes("N")) && (
                        <span className="bold">Générer la facture {">"}</span>
                    )}

                    {urlInvoice.orderToken === tokenValue && (
                        <a
                            className="bold green"
                            href={urlInvoice.url}
                            download={`${orderNumber}.pdf`}>
                            Cliquez ici pour télécharger {">"}
                        </a>
                    )}
                </div>
            </div>
            <div className="orders-card-wrapper-right">
                {shipments.length > 0 && (
                    <>
                        {shipments[0].tracking !== undefined &&
                            shipments[0].tracking !== null && (
                                <div className="orders-card-state">
                                    <span> Numéro de suivi : </span>
                                    <span className="bold url">
                                        <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${shipments[0].tracking}`}>
                                            {shipments[0].tracking}
                                        </a>
                                    </span>
                                </div>
                            )}
                    </>
                )}

                <div className="orders-card-state">
                    <span> Etat de la commande : </span>
                    {orderState === "ready" && (
                        <span className="bold">Prête à être envoyée</span>
                    )}
                    {orderState === "shipped" && (
                        <span className="bold">Envoyée</span>
                    )}
                    {orderState === "cart" && (
                        <span className="bold">Dans le panier</span>
                    )}
                    {orderState === "cancelled" && (
                        <span className="bold">Annulée</span>
                    )}
                </div>
                <div className="orders-card-state">
                    <span> Etat du paiement : </span>

                    {paymentState === "paid" && (
                        <span className="bold"> Payée</span>
                    )}
                    {paymentState === "cancelled" && (
                        <span className="bold"> Annulé</span>
                    )}

                    {paymentState === "cart" && (
                        <span className="bold">Dans le panier</span>
                    )}

                    {paymentState === "partially_refunded" && (
                        <span className="bold">Partiellement remboursé</span>
                    )}

                    {paymentState === "awaiting_payment" && (
                        <span className="bold">Paiement en cours</span>
                    )}

                    {paymentState === "authorized" && (
                        <span className="bold">Paiement autorisé</span>
                    )}
                    {/* <span className='bold'> {paymentState}</span> */}
                </div>
                <div className="orders-card-article-wrapper">
                    <div
                        className="orders-card-article-button"
                        onClick={() => {
                            dispatch(loadingOrderItems(true))
                            dispatch(saveOrderItems([]))
                            dispatch(fetchOrderItems(tokenValue))
                            dispatch(toggleItemsOrder(id))
                        }}>
                        Voir tous les articles
                    </div>
                    {isOpenItemsOrder === id && (
                        <div className="orders-card-articles-wrapper">
                            <div className="order-card-article-title">
                                Articles
                            </div>
                            <span
                                className="cross"
                                onClick={() => {
                                    dispatch(toggleItemsOrder(""))
                                    dispatch(saveOrderItems([]))
                                }}>
                                ✖
                            </span>
                            {loadingItems && (
                                <div className="card-spinner-wrapper">
                                    <Spinner />
                                </div>
                            )}
                            {!loadingItems &&
                                orderItems.map(
                                    ({
                                        id,
                                        productName,
                                        unitPrice,
                                        quantity,
                                    }) => (
                                        <div
                                            key={id}
                                            className="orders-card-articles-items">
                                            <span className="orders-card-article-name">
                                                {productName}
                                            </span>
                                            <span className="orders-card-article-price">
                                                {" "}
                                                {unitPrice === 0
                                                    ? "Gratuit"
                                                    : (unitPrice / 100).toFixed(
                                                          2
                                                      ) + " € TTC"}
                                            </span>
                                            <span className="orders-card-article-quantity">
                                                {" "}
                                                x {quantity}
                                            </span>
                                        </div>
                                    )
                                )}
                        </div>
                    )}
                </div>
                <div className="orders-card-delivery-information-wrapper">
                    <div>
                        <span className="bold">Adresse de facturation</span>
                        <span>
                            {billingFirstName} {billingLastName}
                            {billingCompany
                                ? `, Société ${billingCompany}`
                                : ""}
                            , {billingStreet}, {billingPostcode} {billingCity}
                            {billingCountrycode === "FR"
                                ? ""
                                : `, ${billingCountrycode}`}
                        </span>
                    </div>
                    <div>
                        <span className="bold">Adresse de livraison</span>
                        <span>
                            {shippingFirstName} {shippingLastName}
                            {shippingCompany
                                ? `, Société ${shippingCompany}`
                                : ""}
                            , {shippingStreet}, {shippingPostcode}{" "}
                            {shippingCity}
                            {shippingCountrycode === "FR"
                                ? ""
                                : `, ${shippingCountrycode}`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OrdersCard
