//Components
import Prepage from "../Prepage/Prepage"
import Home from "../Home/Home"
import Catalogue from "../Catalogue/Catalogue"
import Footer from "../Commons/Footer/Footer"
import FooterResponsive from "../Commons/FooterResponsive/FooterResponsive"
import Product from "../Product/Product"
import DeliveryAndReturn from "../Commons/DeliveryAndReturn/DeliveryAndReturn"
import QuiSommesNous from "../Commons/QuiSommesNous/QuiSommesNous"
import ProfesseurCatalogue from "../ProfesseurCatalogue/ProfesseurCatalogue"
import ProfesseurProfilPublic from "../ProfesseurProfilPublic/ProfesseurProfilPublic"
import UserAccount from "../UserAccount/UserAccount"
import PersonalInfos from "../UserAccount/PersonalInfos/PersonalInfos"
import Faq from "../Commons/FAQ/Faq"
import Contact from "../Commons/Contact/Contact"
import SecurePayment from "../Commons/SecurePayment/SecurePayment"
import ConditionsOffres from "../Commons/ConditionsOffres/ConditionsOffres"
import Partenaires from "../Commons/Partenaires/Partenaires"
import Subscriptions from "../UserAccount/Subscriptions/Subsciptions"
import Connexion from "../Auth/Connexion/Connexion"
import AllProfessors from "../Commons/AllProfessors/AllProfessors"
import Cgv from "../Commons/CGV/Cgv"
import PrivacyPolicy from "../Commons/PrivacyPolicy/PrivacyPolicy"
import LegalNotices from "../Commons/LegalNotices/LegalNotices"
import MyProfil from "../UserAccount/MyProfil/MyProfil"
import Addresses from "../UserAccount/Addresses/Addresses"
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute"
import Wishlist from "../UserAccount/Wishlist/Wishlist"
import Inscription from "../Auth/Inscription/Inscription"
import MediaLibrary from "../MediaLibrary/MediaLibrary"
import MediaProducts from "../MediaLibrary/MediaProducts/MediaProducts"
import MediaDashboard from "../MediaLibrary/MediaDashboard/MediaDashboard"
import Player from "../MediaLibrary/Player/Player"
import Cart from "../Cart/Cart"
import Order from "../Order/Order"
import Orders from "../UserAccount/Orders/Orders"
import Livraison from "../Order/Livraison/Livraison"
import PaymentValidate from "../Order/PaymentValidate/PaymentValidate"
import DeconnectPopin from "../Auth/DeconnectPopIn/DeconnectPopin"
import ForgottenPassword from "../ForgottenPassword/ForgottenPassword"
import NewPassword from "../NewPassword/NewPassword"
import ResearchGeneral from "../ResearchGeneral/ResearchGeneral"
import MaintenanceRoute from "../MaintenanceRoute/MaintenanceRoute"
import TopArrow from "../TopArrow/TopArrow"
import CoursIdeal from "../CoursIdeal/CoursIdeal"
import RedirectProduct from "../Seo/RedirectProduct/RedirectProduct"

//Action
import { toggleMenuBurger } from "../../actions/homeActions"
import { refreshToken} from "../../actions/authAction"
import {
    fetchUserData,
    changeInputValue,
    fetchWishlist,
} from "../../actions/userAction"
import { changeInputAuthValue, userConnexion } from "../../actions/authAction"
import {
    changeInputOrder,
    getCartConnected,
    getCartDisconnected
} from "../../actions/cartAction"
import { fetchChannel } from "../../actions/commonAction"

//Libraries
import MediaQuery from "react-responsive"
import jwt_decode from "jwt-decode"

// Style
import "./App.scss"

//Hooks
import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

//GOOGLE ANALYTICS
import ReactGA from "react-ga"
import Version from "../Versions/Version"
import { getCookie } from "../../selectors/selectors"
import GiftCard from "../UserAccount/GiftCard/GiftCard"
import Paiement from "../Order/Paiement/Paiement"
import { ActivateCourse } from "../UserAccount/ActivateCourse/ActivateCourse"
import MondialRelayWidget from "../Order/Livraison/MondialRelayWidget/MondialRelayWidget"
const TRACKING_ID = "UA-78290342-1" // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID)
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
    const dispatch = useDispatch()
    const currentLocation = useLocation().pathname
    const isUserConnected = useSelector((state) => state.auth.userConnected)
    const customerId = useSelector((state) => state.user.customerId)
    const mediaCulinaires = useSelector(
        (state) => state.media.mediaArtsCulinaires
    )
    const mediaFil = useSelector((state) => state.media.mediaArtsDuFil)
    const lastAssociationmediaArtsDuFil = useSelector(
        (state) => state.media.lastAssociationmediaArtsDuFil
    )
    const lastAssociationmediaArtPlastiques = useSelector(
        (state) => state.media.lastAssociationmediaArtPlastiques
    )
    const lastAssociationmediaArtsCulinaires = useSelector(
        (state) => state.media.lastAssociationmediaArtsCulinaires
    )

    const lastAssociationWatchedmediaArtsDuFil = useSelector(
        (state) => state.media.lastAssociationWatchedmediaArtsDuFil
    )
    const lastAssociationWatchedmediaArtPlastiques = useSelector(
        (state) => state.media.lastAssociationWatchedmediaArtPlastiques
    )
    const lastAssociationWatchedmediaArtsCulinaires = useSelector(
        (state) => state.media.lastAssociationWatchedmediaArtsCulinaires
    )

    const mediaPlastiques = useSelector(
        (state) => state.media.mediaArtsPlastiques
    )
    const isDeconnect = useSelector((state) => state.auth.isDeconnect)

    useEffect(() => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [currentLocation])

    const token = localStorage.getItem("token")
    const customerURI = localStorage.getItem("customer")
    const cartToken = localStorage.getItem("cart")
    const maintenance = useSelector((state) => state.common.modeMaintenance)

    //Infos du channel artesane
    useEffect(() => {
        dispatch(fetchChannel("all"))
        const interval = setInterval(() => {
            dispatch(fetchChannel("maintenance"))
        }, 900000)

        return () => clearInterval(interval)
    }, [dispatch])

    useEffect(() => {
        if (token) {
            const decoded = jwt_decode(token)
            const email = decoded.username
            const exp = decoded.exp * 1000

            if (exp < Date.now()) {
                dispatch(refreshToken())
            } else {
                dispatch(changeInputAuthValue(email, "email"))
                dispatch(fetchUserData(customerURI, token))
                dispatch(userConnexion(true))
                dispatch(fetchWishlist(token))
                dispatch(changeInputOrder(true, "loadingCart"))
                dispatch(getCartConnected("cart"))
                
  
            }
        } else if (cartToken && !token) {
            dispatch(changeInputOrder(true, "loadingCart"))
            dispatch(getCartDisconnected(cartToken))
        } else {

            dispatch(changeInputOrder(false, "loadingCart"))
            dispatch(changeInputValue(false, "loadingGiftcards"))
        }
    }, [dispatch, cartToken, customerURI, token ])

    useEffect(() => {
        const cookies = getCookie("tarteaucitron")
            ? getCookie("tarteaucitron")
            : ""
        dispatch(changeInputValue(cookies, "cookies"))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo({ top: 0 })
        dispatch(toggleMenuBurger(false))
    }, [dispatch, currentLocation])

    return (
        <div className="App">
            {!maintenance && (
                <MediaQuery maxWidth={1000}>
                    <TopArrow />
                </MediaQuery>
            )}

            {isDeconnect && !maintenance && <DeconnectPopin />}
            <Routes>
                {maintenance && (
                    <Route path="/maintenance" element={<MaintenanceRoute />} />
                )}

                <Route
                    element={
                        <ProtectedRoute
                            redirectPath={"/maintenance"}
                            isAllowed={!maintenance}
                        />
                    }>
                    <Route
                        path="/version"
                        element={
                            <Version date={process.env.REACT_APP_VERSION} />
                        }
                    />
                    <Route
                        path="/fr"
                        element={
                            <div>
                                {" "}
                                <Navigate to={"/"} replace={true} />{" "}
                            </div>
                        }
                    />
                    <Route path="/" element={<Prepage />} />
                    <Route path="/:univers" element={<Home />} />
                    <Route
                        path="/recherche/:words"
                        element={<ResearchGeneral />}
                    />
                    <Route path="/recherche" element={<ResearchGeneral />} />
                    {/* <Route path="/:univers/recherche/:words" element={<ResearchUnivers/>}/> */}
                    <Route
                        path="/:univers/catalogue/:currentFilters/:currentPage"
                        element={<Catalogue />}
                    />
                    <Route
                        path="/:univers/produit/:currentSlugProduct"
                        element={<Product />}
                    />
                    <Route
                        path="/:univers/professeurs"
                        element={<ProfesseurCatalogue />}
                    />
                    <Route
                        path="/:univers/professeur/:currentProfesseur"
                        element={<ProfesseurProfilPublic />}
                    />
                    <Route
                        path="/livraison-et-retours"
                        element={<DeliveryAndReturn />}
                    />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route
                        path="/paiement-securise"
                        element={<SecurePayment />}
                    />
                    <Route
                        path="/qui-sommes-nous"
                        element={<QuiSommesNous />}
                    />
                    <Route
                        path="/mondialrelay"
                        element={<MondialRelayWidget/>}
                    />
                    <Route path="/cgv" element={<Cgv />} />
                    <Route
                        path="/politique-de-confidentialite"
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path="/mentions-legales"
                        element={<LegalNotices />}
                    />
                    <Route
                        path="/conditions-offres"
                        element={<ConditionsOffres />}
                    />
                    <Route
                        path="/:univers/nos-partenaires"
                        element={<Partenaires />}
                    />
                    <Route
                        path="/les-professeurs"
                        element={<AllProfessors />}
                    />
                    <Route path="/connexion" element={<Connexion />} />
                    <Route
                        path="/nouveau-mot-de-passe/:token"
                        element={<NewPassword />}
                    />
                    <Route
                        path="/mot-de-passe-oublie"
                        element={<ForgottenPassword />}
                    />
                    <Route path="/inscription" element={<Inscription />} />
                    <Route path="/mon-panier" element={<Cart />} />
                    <Route path="/cours-ideal" element={<CoursIdeal />} />
                    <Route
                        element={
                            <ProtectedRoute
                                redirectPath={"/connexion"}
                                isAllowed={!!(token || isUserConnected)}
                            />
                        }>
                        <Route path={"/commande"} element={<Order />}>
                            <Route path={"livraison"} element={<Livraison />} />
                            <Route path={"paiement"} element={<Paiement />} />
                        </Route>
                        <Route
                            path="/paiement/:orderId/annulation"
                            element={
                                <div>
                                    <Navigate
                                        to={"/mon-panier"}
                                        replace={true}
                                    />
                                </div>
                            }
                        />
                        <Route
                            path={"/paiement/:orderId/reussite"}
                            element={<PaymentValidate />}
                        />
                        <Route
                            path={"/mes-cours-et-documents/:collectionId/"}
                            element={<MediaDashboard />}
                        />

                        <Route
                            path={"/activation-cours/:token/"}
                            element={<ActivateCourse />}
                        />

                        <Route
                            path={"/player/:videoSlug/:collectionId"}
                            element={<Player />}
                        />
                        <Route
                            path={"/mes-cours-et-documents"}
                            element={<MediaLibrary />}>
                            <Route
                                path={"arts-culinaires"}
                                element={
                                    <MediaProducts
                                        data={mediaCulinaires}
                                        lastAssociation={
                                            lastAssociationmediaArtsCulinaires
                                        }
                                        lastWatched={
                                            lastAssociationWatchedmediaArtsCulinaires
                                        }
                                    />
                                }
                            />
                            <Route
                                path={"arts-du-fil"}
                                element={
                                    <MediaProducts
                                        data={mediaFil}
                                        lastAssociation={
                                            lastAssociationmediaArtsDuFil
                                        }
                                        lastWatched={
                                            lastAssociationWatchedmediaArtsDuFil
                                        }
                                    />
                                }
                            />
                            <Route
                                path={"arts-plastiques"}
                                element={
                                    <MediaProducts
                                        data={mediaPlastiques}
                                        lastAssociation={
                                            lastAssociationmediaArtPlastiques
                                        }
                                        lastWatched={
                                            lastAssociationWatchedmediaArtPlastiques
                                        }
                                    />
                                }
                            />
                        </Route>
                        <Route path="/mon-compte" element={<UserAccount />}>
                            <Route
                                path={"mes-commandes"}
                                element={<Orders />}
                            />
                            <Route
                                path={"mes-cheques-cadeaux"}
                                element={<GiftCard />}
                            />
                            <Route
                                path={"mes-infos-personnelles"}
                                element={<PersonalInfos />}
                            />
                            <Route path={"mon-profil"} element={<MyProfil />} />
                            <Route
                                path={"mes-adresses"}
                                element={<Addresses />}
                            />
                            <Route
                                path={"mes-abonnements"}
                                element={<Subscriptions />}
                            />
                            <Route
                                path={"ma-liste-de-souhaits"}
                                element={<Wishlist />}
                            />
                        </Route>
                    </Route>

                    <Route
                        path="/fr/products/:slug"
                        element={<RedirectProduct />}
                    />
                    <Route path="*" element={<Prepage />} />
                </Route>
            </Routes>
            {!maintenance && (
                <>
                    <MediaQuery minWidth={1000}>
                        <Footer />
                    </MediaQuery>
                    <MediaQuery maxWidth={1000}>
                        <FooterResponsive />
                    </MediaQuery>
                </>
            )}
        </div>
    )
}

export default App
