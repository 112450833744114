import { useEffect, useRef } from "react"
const TrustBox = () => {
    // Crée une référence à l'élément <div> qui représentera la TrustBox
    const ref = useRef(null)

    useEffect(() => {
        // Si window.Trustpilot est disponible, cela signifie que nous devons charger la TrustBox depuis notre réf.
        // Si ce n'est pas le cas, cela signifie que le script que vous avez collé dans <head /> n'est pas encore chargé.
        // Quand c'est le cas, il chargera automatiquement la TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true)
        }
    }, [])
    return (
        <div
            ref={ref} // Nous avons besoin d'une référence à cet élément pour charger la TrustBox dans l'effet.
            className="trustpilot-widget" // Renommé ceci en className.
            data-locale="fr-FR"
            data-template-id="56278e9abfbbba0bdcd568bc"
            data-businessunit-id="61378e2d0775cb001d7e40db"
            data-style-height="52px"
            data-style-width="100%">
            <a
                href="https://www.trustpilot.com/review/artesane.com"
                target="_blank"
                rel="noopener noreferrer">
                {" "}
                Trustpilot
            </a>
        </div>
    )
}

export default TrustBox
