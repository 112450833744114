import React from "react"

//Styles
import "./AutresUnivers.scss"
//Hooks
import { useSelector, useDispatch } from "react-redux"
import { NavLink, useParams } from "react-router-dom"

//Actions
import { toggleTaxonsActif } from "../../../../../actions/homeActions"

const AutresUnivers = () => {
    const currentTaxonActif = useSelector(
        (state) => state.home.toggleTaxonsActif
    )
    const dispatch = useDispatch()
    const { univers } = useParams()
    const isLoadingAllUniverse = useSelector(
        (state) => state.common.loadingAllUniverses
    )
    const allUniverses = useSelector((state) => state.common.allUniverses)
    return (
        <React.Fragment>
            <div
                className="other-univers"
                onClick={() => {
                    if (currentTaxonActif === "les autres univers") {
                        dispatch(toggleTaxonsActif(""))
                    } else {
                        dispatch(toggleTaxonsActif("les autres univers"))
                    }
                }}>
                les autres univers
            </div>
            <div
                className={
                    currentTaxonActif === "les autres univers"
                        ? "other-actif "
                        : "other-actif-closed"
                }>
                {!isLoadingAllUniverse && (
                    <>
                        {allUniverses
                            .filter((item) => item.code !== univers)
                            .map((item) => (
                                <div
                                    className="other-univers-item"
                                    key={item.name}>
                                    <NavLink to={`/${item.code}`}>
                                        {item.name} {" >"}
                                    </NavLink>
                                </div>
                            ))}
                    </>
                )}

                <div className="other-univers-item">
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.artesane.com/le-journal/artesane-formation-pro">
                        Espace formation pro {" >"}
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AutresUnivers
