import { useDispatch, useSelector } from "react-redux"
import InputSimple from "../InputSimple/inputSimple"
import {
    changeInputValue,
    checkSave,
    loadingUserRequest,
    patchMyProfilData,
} from "../../../actions/userAction"
import "./MyProfil.scss"
import NetworksInput from "../NetworksInput/NetworksInput"
import Spinner from "../../Commons/Spinner/Spinner"
import ConfirmPopup from "../../Commons/ConfirmPopup/ConfirmPopup"

const MyProfil = () => {
    const dispatch = useDispatch()
    const pseudo = useSelector((state) => state.user.pseudo)
    const website = useSelector((state) => state.user.website)
    const description = useSelector((state) => state.user.description)
    const instagramPseudo = useSelector((state) => state.user.instagram)
    const facebookPseudo = useSelector((state) => state.user.facebook)
    const pinterestPseudo = useSelector((state) => state.user.pinterest)
    const twitchPseudo = useSelector((state) => state.user.twitch)
    const youtubePseudo = useSelector((state) => state.user.youtube)
    const isLoadingUserRequest = useSelector(
        (state) => state.user.loadingUserRequest
    )

    const openConfirmPopup = useSelector(
        (state) => state.common.confirmPopupOpen
    )
    const customerId = useSelector((state) => state.user.customerId)
    return (
        <div className="my-profil-wrapper">
            {openConfirmPopup && <ConfirmPopup />}
            {isLoadingUserRequest && (
                <div className="spinner-myprofil-spinner">
                    <Spinner />
                </div>
            )}
            <form
                className="my-profil-form"
                action="#"
                onSubmit={(event) => {
                    event.preventDefault()
                    // dispatch(checkSave(true))
                    dispatch(loadingUserRequest(true))
                    dispatch(patchMyProfilData(customerId))
                }}>
                <div className="my-profil-wrapper-left">
                    {/* <Avatar /> */}

                    <InputSimple
                        inputName={"pseudo"}
                        label={"Pseudo"}
                        value={pseudo}
                        type={"text"}
                        required={true}
                        onChange={(event) => {
                            dispatch(checkSave(false))
                            dispatch(
                                changeInputValue(event.target.value, "pseudo")
                            )
                        }}
                    />

                    <InputSimple
                        inputName={"website"}
                        label={"Mon site personnel"}
                        value={website}
                        type={"text"}
                        onChange={(event) => {
                            dispatch(checkSave(false))
                            dispatch(
                                changeInputValue(event.target.value, "website")
                            )
                        }}
                    />
                </div>
                <div className="my-profil-wrapper-right">
                    <div className="textarea-wrapper">
                        <label htmlFor="description">À propos</label>
                        <textarea
                            value={description}
                            name="description"
                            cols="30"
                            rows="8"
                            onChange={(event) => {
                                dispatch(checkSave(false))
                                dispatch(
                                    changeInputValue(
                                        event.target.value,
                                        "description"
                                    )
                                )
                            }}></textarea>
                    </div>
                    <div className="my-profil-bottom-right">
                        <div className="my-profil-bottom-inputs">
                            <NetworksInput
                                placeholder={"Votre pseudo Instagram"}
                                iconImg={
                                    "https://static.artesane.com/images_react/logo_instagram.png"
                                }
                                inputName={"instagram"}
                                type={"text"}
                                value={instagramPseudo}
                                onChange={(event) => {
                                    dispatch(checkSave(false))
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "instagram"
                                        )
                                    )
                                }}
                            />
                            <NetworksInput
                                placeholder={"Votre pseudo Facebook"}
                                iconImg={
                                    "https://static.artesane.com/images_react/logo_facebook.png"
                                }
                                inputName={"facebook"}
                                type={"text"}
                                value={facebookPseudo}
                                onChange={(event) => {
                                    dispatch(checkSave(false))
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "facebook"
                                        )
                                    )
                                }}
                            />
                            <NetworksInput
                                placeholder={"Votre pseudo Pinterest"}
                                iconImg={
                                    "https://static.artesane.com/images_react/logo_pinterest.png"
                                }
                                inputName={"pinterest"}
                                type={"text"}
                                value={pinterestPseudo}
                                onChange={(event) => {
                                    dispatch(checkSave(false))
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "pinterest"
                                        )
                                    )
                                }}
                            />
                            <NetworksInput
                                placeholder={"Votre pseudo Twitch"}
                                iconImg={
                                    "https://static.artesane.com/images_react/logo_twitch.png"
                                }
                                inputName={"twitch"}
                                type={"text"}
                                value={twitchPseudo}
                                onChange={(event) => {
                                    dispatch(checkSave(false))
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "twitch"
                                        )
                                    )
                                }}
                            />
                            <NetworksInput
                                placeholder={"Votre pseudo Youtube"}
                                iconImg={
                                    "https://static.artesane.com/images_react/logo_youtube.png"
                                }
                                inputName={"youtube"}
                                type={"text"}
                                value={youtubePseudo}
                                onChange={(event) => {
                                    dispatch(checkSave(false))
                                    dispatch(
                                        changeInputValue(
                                            event.target.value,
                                            "youtube"
                                        )
                                    )
                                }}
                            />
                        </div>

                        <button className="submit-button" type="submit">
                            Enregistrer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default MyProfil
