import { useDispatch, useSelector } from "react-redux"
import { togglePopupError } from "../../../actions/userAction"
import "./ErrorPopup.scss"

const ErrorPopup = () => {
    const dispatch = useDispatch()
    const errorMessage = useSelector((state) => state.user.errorMessage)
    return (
        <div className="error-popup-wrapper">
            <div className="popup-wrapper">
                <span
                    className="popup-cross"
                    onClick={() => {
                        dispatch(togglePopupError(false, ""))
                    }}>
                    ╳
                </span>
                <div className="error-message">
                    Oups ! Une erreur s'est produite.
                </div>
                {errorMessage !== "" && (
                    <div className="error-message">{errorMessage}</div>
                )}
            </div>
        </div>
    )
}

export default ErrorPopup
