//Libraries
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

//Hooks
import { useSelector } from "react-redux"
import { useRef } from "react"

// //Components
import ToggleButton from "./ToggleButton/ToggleButton"

//Styles
import "./Faq.scss"

const Faq = () => {
    const isOpenAccordion = useSelector((state) => state.home.accordionExpended)
    const numberOfItems = useSelector((state) => state.home.accordionNumberItem)
    const faqsData = useSelector((state) => state.home.faqsData)
    const myRef = useRef()

    function createMarkup(item) {
        return {
            __html: item,
        }
    }

    return (
        <div ref={myRef} className="home-faq-wrapper" id="home-faq-wrapper">
            <div className="home-faq-title">FAQ</div>
            <div className="home-faq-accordion-wrapper">
                {/* Afficher un nombre d'items variable en fonction de l'accordéon ouvert ou non, action dispatchée au click sur le bouton  */}
                {faqsData
                    .slice(0, numberOfItems)
                    .map(({ question, answer }) => (
                        <Accordion
                            sx={{
                                boxShadow: "none",
                                marginBottom: "1%",
                                backgroundColor: "#FFFCF8",
                                "& .MuiAccordionSummary-root": {
                                    padding: "0 5%",
                                    fontWeight: "600",
                                    "& .MuiAccordionSummary-content": {
                                        paddingRight: "5%",
                                    },
                                },
                                "&::before": {
                                    height: "0px",
                                },
                            }}
                            key={question}>
                            <AccordionSummary
                                sx={{
                                    fontSize: "16px",
                                    padding: "0 20%",
                                    fontWeight: 600,
                                    "& .MuiAccordionDetails-root": {
                                        padding: "0 5%",
                                        fontWeight: "600",
                                    },
                                }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <span
                                    dangerouslySetInnerHTML={createMarkup(
                                        question
                                    )}></span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <span
                                    dangerouslySetInnerHTML={createMarkup(
                                        answer
                                    )}></span>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </div>
            <div className="home-faq-button-wrapper">
                <ToggleButton
                    myRef={myRef}
                    texte={isOpenAccordion ? "Voir moins" : "Voir plus"}
                    itemsLength={faqsData.length}
                />
            </div>
        </div>
    )
}

export default Faq
