//Styles
import "./LevelAndTime.scss"
const LevelAndTime = ({ texte, logo }) => {
    if (texte !== undefined) {
        return (
            <div className="level-wrapper">
                <div className="level-image-wrapper">
                    <img src={logo} alt="pictogramme de niveau" />
                </div>
                <div className="level-texte">{texte}</div>
            </div>
        )
    }
}

export default LevelAndTime
