//Libraries
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
//Hooks
import { useDispatch, useSelector } from "react-redux/es/exports"

//Action
import {
    changePriceSliderValue,
    saveCatalogueLessonsInState,
} from "../../../../../../actions/catalogueAction"
import { filterProduct } from "../../../../../../selectors/selectors"

//Styles
import "./FilterChoiceSlider.scss"

function valuetext(value) {
    return `${value}€`
}

const FilterChoiceSlider = () => {
    const dispatch = useDispatch()
    const filtersChecked = useSelector(
        (state) => state.catalogue.filtersCheckboxInput
    )
    const allProduct = useSelector((state) => state.catalogue.allProduct)
    const priceValues = useSelector((state) => state.catalogue.priceValue)
    const sortActifValue = useSelector(
        (state) => state.catalogue.sortActifValue
    )

    return (
        <div className="filter-choice-slider-wrapper">
            <Box sx={{ width: 300 }}>
                <Slider
                    getAriaLabel={() => "Temperature range"}
                    value={priceValues}
                    onChange={(event) => {
                        dispatch(changePriceSliderValue(event.target.value))

                        dispatch(
                            saveCatalogueLessonsInState(
                                filterProduct(
                                    filtersChecked,
                                    allProduct,
                                    event.target.value,
                                    sortActifValue,
                                    []
                                ),
                                filterProduct(
                                    filtersChecked,
                                    allProduct,
                                    event.target.value,
                                    sortActifValue,
                                    []
                                ).length,
                                allProduct
                            )
                        )
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    min={0}
                    max={600}
                />
                <span className="filter-choice-slider-min-price">0 €</span>
                <span className="filter-choice-slider-max-price">600 €</span>
            </Box>
        </div>
    )
}

export default FilterChoiceSlider
