import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getGeneralSearchbar } from "../../actions/commonAction"
import Spinner from "../Commons/Spinner/Spinner"
import Header from "../Prepage/Header/Header"
import LessonCardSelection from "../Commons/Cards/LessonCardSelection/LessonCardSelection"
import "./ResearchGeneral.scss"
import Banner from "../Banner/Banner"

const ResearchGeneral = () => {
    const loading = useSelector((state) => state.common.loadingGeneralSearchbar)
    const { words } = useParams()
    const searchData = useSelector((state) => state.common.searchGeneral)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getGeneralSearchbar(words))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [words])

    useEffect(() => {
        document.title = "Artesane - Apprenez à créer | Recherche"
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | Inscription "
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com/recherche/"
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "noindex")
    }, [])

    return (
        <div>
            <Banner />
            <Header />
            <div className="research-wrapper">
                {loading && <Spinner />}
                {!loading && (
                    <>
                        <div className="research-title">
                            Résultat de la recherche dans tout notre catalogue
                            pour :{" "}
                        </div>
                        <div className="research-words">{words} </div>
                        <div className="number-result">
                            {" "}
                            {searchData.length} produits trouvés
                        </div>
                        <div className="research-products">
                            {searchData.map(
                                ({
                                    mainImage,
                                    name,
                                    id,
                                    author,
                                    variants,
                                    slug,
                                    pack,
                                    parcours,
                                    isNew,
                                    coffret,
                                    buy_once,
                                    attributes,
                                    universes,
                                    giftCard,
                                    giftCardAmountConfigurable,
                                }) => (
                                    <div
                                        key={id}
                                        className="research-product-card-wrapper">
                                        <LessonCardSelection
                                            productId={id}
                                            urlImg={
                                                mainImage !== null
                                                    ? mainImage.thumbnails
                                                          .carousel
                                                    : ""
                                            }
                                            title={name}
                                            professor={
                                                author ? author.name : ""
                                            }
                                            originalPrice={(
                                                variants[0].originalPrice / 100
                                            )
                                                .toFixed(2)
                                                .toString()
                                                .replace(".", ",")}
                                            price={(variants[0].price / 100)
                                                .toFixed(2)
                                                .toString()
                                                .replace(".", ",")}
                                            pack={pack}
                                            parcours={parcours}
                                            coffret={coffret}
                                            reduction={
                                                variants[0].originalPrice !==
                                                variants[0].price
                                                    ? Math.floor(
                                                          ((variants[0]
                                                              .originalPrice -
                                                              variants[0]
                                                                  .price) /
                                                              variants[0]
                                                                  .originalPrice) *
                                                          100
                                                      )
                                                    : 0
                                            }
                                            newer={isNew}
                                            time={
                                                attributes.find(
                                                    (item) =>
                                                        item.code === "duree"
                                                )
                                                    ? attributes.find(
                                                          (item) =>
                                                              item.code ===
                                                              "duree"
                                                      ).value
                                                    : ""
                                            }
                                            level={
                                                attributes.find(
                                                    (item) =>
                                                        item.code === "niveau"
                                                )
                                                    ? attributes.find(
                                                          (item) =>
                                                              item.code ===
                                                              "niveau"
                                                      ).value
                                                    : ""
                                            }
                                            key={id}
                                            currentUnivers={
                                                universes.length > 0
                                                    ? universes[0].code
                                                    : "arts-du-fil"
                                            }
                                            productSlug={slug}
                                            // shipedRequired={variants[0].shipping_required}
                                            variants={variants}
                                            buyOnce={buy_once}
                                            giftCard={giftCard}
                                            giftCardAmountConfigurable={
                                                giftCardAmountConfigurable
                                            }
                                            alt={
                                                mainImage !== null &&
                                                mainImage !== undefined
                                                    ? mainImage.alt
                                                    : ""
                                            }
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default ResearchGeneral
