//Styles
import "./Infos.scss"

const Infos = ({ time, level }) => (
    <div className="infos-wrapper">
        {time !== undefined && (
            <div className="infos">
                <img
                    className="infos-icon"
                    src={"https://static.artesane.com/images_react/time.png"}
                    alt=""
                />
                <span className="infos-text">{time}</span>
            </div>
        )}

        {level !== undefined && (
            <div className="infos">
                <img
                    className="infos-icon"
                    src={"https://static.artesane.com/images_react/level.png"}
                    alt=""
                />
                <span className="infos-text">{level}</span>
            </div>
        )}
    </div>
)

export default Infos
