//Component
import Header from "../Home/Header/Header"
import ProductSlider from "./ProductSlider/ProductSlider"
import TopInformations from "./TopInformations/TopInformations"
import Highlights from "./TopInformations/Highlights/Highlights"
import AccordionsProducts from "./AccordionProducts/AccordionsProducts"
import NouveauteEtPack from "./AccordionProducts/NouveauteEtPack/NouveauteEtPack"
import Summary from "./AccordionProducts/Summary/Summary"
import Objectifs from "./AccordionProducts/Objectifs/Objectifs"
import Materiel from "./AccordionProducts/Materiel/Materiel"
import Prerequis from "./AccordionProducts/Prerequis/Prerequis"
import OffresEleves from "./AccordionProducts/OffresEleves/OffresEleves"
import Programme from "./AccordionProducts/Programme/Programme"
import ExtraitCours from "./ExtraitCours/ExtraitCours"
import Professor from "./Professor/Professor"
import Recommandations from "./Recommandations/Recommandations"
import Reinsurance from "../Prepage/Reinsurance/Reinsurance"
import AddCardBar from "./AddCardBar/AddCardBar"
import ProductSliderDesktop from "./ProductSliderDesktop/ProductSliderDesktop"
import Banner from "../Banner/Banner"

//Libraries
import MediaQuery from "react-responsive"

//hooks
import { useSelector } from "react-redux"
import { useParams, NavLink, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect } from "react"

import {
    fetchProductData,
    toggleSliderZoomProduct,
    accordionMenuActif,
    isLoadingProduct,
    saveProductDataState,
} from "../../actions/productsActions"

//Styles
import "./Product.scss"
import ZoomCarousel from "./ZoomCarousel/ZoomCarousel"
import Spinner from "../Commons/Spinner/Spinner"

const Product = () => {
    const dispatch = useDispatch()
    const { univers } = useParams()
    const { currentSlugProduct } = useParams()
    const isHeaderScroll = useSelector((state) => state.home.scroll)
    const isLoadingProductData = useSelector(
        (state) => state.products.isLoadingProduct
    )
    const isOpenZoomCarousel = useSelector(
        (state) => state.products.zoomCarousel
    )
    const productData = useSelector((state) => state.products.productData)

    const notFoundProduct = useSelector(
        (state) => state.products.notFoundProduct
    )

    const associationsProduct = useSelector(
        (state) => state.products.associationsProduct
    )
    const loadingAssociationsProduct = useSelector(
        (state) => state.products.loadingAssociationsProduct
    )

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(isLoadingProduct(true))
        dispatch(saveProductDataState("loadingTrailers", true))
        dispatch(fetchProductData(currentSlugProduct, univers))
        dispatch(toggleSliderZoomProduct(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlugProduct, univers])

    useEffect(() => {
        if (notFoundProduct) {
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notFoundProduct])

    useEffect(() => {
        if (window.matchMedia("(max-width: 999px)").matches) {
            dispatch(accordionMenuActif(""))
        } else {
            dispatch(accordionMenuActif("Résumé"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentSlugProduct])

    return (
        <div>
            <Banner univers={univers} />
            {isOpenZoomCarousel && <ZoomCarousel productData={productData} />}
            <Header />
            {isLoadingProductData && <Spinner />}

            {!isLoadingProductData && (
                <div className="fiche-product-wrapper">
                    <MediaQuery maxWidth={1000}>
                        <ProductSlider productData={productData} />
                    </MediaQuery>
                    <MediaQuery minWidth={1000}>
                        <ProductSliderDesktop productData={productData} />
                    </MediaQuery>
                    <div
                        className={
                            isHeaderScroll
                                ? `barre-fixed-wrapper-desktop-scroll`
                                : `barre-fixed-wrapper-desktop`
                        }>
                        <TopInformations productData={productData} />
                        <Highlights productData={productData} />
                        <AddCardBar />
                    </div>

                    <AccordionsProducts
                        title={"Résumé"}
                        nouveuteEtPack={
                            <NouveauteEtPack productData={productData} />
                        }
                        summary={<Summary productData={productData} />}
                        objectifs={<Objectifs productData={productData} />}
                        anchor={"#resume"}
                        target={"resume"}
                    />
                    {(productData.attributes.find(
                        (item) =>
                            item.code === "il-faut-que-vous-vous-procuriez"
                    ) !== undefined ||
                        productData.attributes.find(
                            (item) =>
                                item.code === "ce-cours-s-adresse-a-moi-si"
                        ) !== undefined ||
                        productData.attributes.find(
                            (item) => item.code === "offres-eleves"
                        ) !== undefined) && (
                        <AccordionsProducts
                            title={"Matériel et prérequis"}
                            materiel={<Materiel productData={productData} />}
                            prerequis={<Prerequis productData={productData} />}
                            offresEleves={
                                <OffresEleves productData={productData} />
                            }
                            anchor={"#material"}
                            target={"material"}
                        />
                    )}

                    {productData.attributes.find(
                        (item) =>
                            item.code === "votre-programme-d-apprentissage"
                    ) !== undefined && (
                        <AccordionsProducts
                            title={"Programme"}
                            programme={<Programme productData={productData} />}
                            anchor={"#programme"}
                            target={"programme"}
                        />
                    )}
                    {productData.attributes.find(
                        (item) => item.code === "extrait"
                    ) && (
                        <ExtraitCours
                            extrait={
                                productData.attributes.find(
                                    (item) => item.code === "extrait"
                                ).value
                            }
                        />
                    )}

                    {productData.author !== undefined &&
                        productData.author !== null && (
                            <Professor productData={productData} />
                        )}

                    {associationsProduct.length > 0 &&
                        !loadingAssociationsProduct && (
                            <Recommandations
                                associations={associationsProduct}
                            />
                        )}

                    <div className="reinssurance-product-wrapper">
                        <NavLink to="/qui-sommes-nous">
                            <Reinsurance
                                logo={
                                    "https://static.artesane.com/images_react/reassurance-34.png"
                                }
                                altImg={"bobine de fil"}
                                firstPartOfPhrase={"L'école d'arts et"}
                                secondPartOfPhrase={"d'artisanat créatifs"}
                            />
                        </NavLink>
                        <NavLink to="/qui-sommes-nous">
                            <Reinsurance
                                logo={
                                    "https://static.artesane.com/images_react/reassurance-35.png"
                                }
                                altImg={"bobine de fil"}
                                firstPartOfPhrase={"avec les plus"}
                                secondPartOfPhrase={"grands experts"}
                            />
                        </NavLink>
                        <NavLink to="/qui-sommes-nous">
                            <Reinsurance
                                logo={
                                    "https://static.artesane.com/images_react/reassurance-36.png"
                                }
                                altImg={"bobine de fil"}
                                firstPartOfPhrase={"en ligne, à votre rythme"}
                                secondPartOfPhrase={"et en illimité"}
                            />
                        </NavLink>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Product
