//Components
import Taxons from "./Taxons/Taxons"
//Hooks
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

//Action
import {
    toggleMenuBurger,
    toggleTaxonsActif,
    toggleUnderTaxonsActif,
} from "../../../../actions/homeActions"
import { countCart } from "../../../../actions/cartAction"
//Styles
import "./MenuBurger.scss"
import AutresUnivers from "./AutresUnivers/AutresUnivers"
import { NavLink } from "react-router-dom"
import NavIcons from "../../../Commons/NavIcons/NavIcons"

const MenuBurger = () => {
    const dispatch = useDispatch()
    const navData = useSelector((state) => state.home.navData)
    const isOpen = useSelector((state) => state.home.isOpenBurgerMenu)
    const isConnected = useSelector((state) => state.user.userConnected)
    const numberProductCart = useSelector((state) => state.cart.cartDataNumber)
    const cartItems = JSON.parse(sessionStorage.getItem("cart"))

    useEffect(() => {
        if (cartItems !== null) {
            dispatch(countCart(cartItems.length))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems])

    return (
        <div
            id="menu-burger"
            className={
                isOpen ? "menu-burger-wrapper-open" : "menu-burger-wrapper"
            }>
            <div
                className={isOpen ? "burger-wrapper-open" : "burger-wrapper"}
                onClick={() => {
                    dispatch(toggleMenuBurger(!isOpen))
                    dispatch(toggleTaxonsActif(""))
                    dispatch(toggleUnderTaxonsActif(""))
                }}>
                <div
                    className={
                        isOpen
                            ? "burger-item-open item-one"
                            : "burger-item item-one"
                    }></div>
                <div
                    className={
                        isOpen
                            ? "burger-item-open item-two"
                            : "burger-item item-two"
                    }></div>
                <div
                    className={
                        isOpen
                            ? "burger-item-open item-three"
                            : "burger-item item-three"
                    }></div>
            </div>
            <div
                className={
                    isOpen
                        ? "menu-burger-items-wrapper-open"
                        : "menu-burger-items-wrapper"
                }>
                <div className="taxons-group">
                    {navData.map(({ name, children, code }) => (
                        <Taxons
                            taxon={children}
                            code={code}
                            nom={name}
                            key={name}
                        />
                    ))}
                </div>
                <AutresUnivers />
                <div className="menu-burger-navIcons">
                    <NavLink to="/mon-compte/mes-commandes">
                        <NavIcons
                            path={"#"}
                            logo={
                                "https://static.artesane.com/images_react/user.png"
                            }
                        />
                        Mon compte
                    </NavLink>
                    <NavLink to="/mes-favoris">
                        <NavIcons
                            numberOfProduct={0}
                            logo={
                                "https://static.artesane.com/images_react/empty_heart.png"
                            }
                        />{" "}
                        Mes favoris
                    </NavLink>
                    <NavLink to="/mon-panier">
                        <NavIcons
                            styleColor={"cart"}
                            numberOfProduct={numberProductCart}
                            path={"/mon-panier"}
                            logo={
                                "https://static.artesane.com/images_react/cart.png"
                            }
                        />
                        Mon panier
                    </NavLink>
                    {isConnected && (
                        <NavLink to="mon-compte//mes-cours-et-documents">
                            <NavIcons
                                path={"#"}
                                logo={
                                    "https://static.artesane.com/images_react/player.png"
                                }
                            />
                            Mes cours
                        </NavLink>
                    )}
                </div>
            </div>
            <div className="burger-background"></div>
        </div>
    )
}

export default MenuBurger
