//Components
import NavIcons from "../../../Commons/NavIcons/NavIcons"
import DropDownMenu from "../../../Commons/DropDownMenu/DropDownMenu"

//Hooks
import { useSelector } from "react-redux"

//Actions
import { toggleDropownUser } from "../../../../actions/homeActions"

//Style
import "./NavBarMutual.scss"

//Data
import { dropdownMutualHomeUserConnected } from "../../../../data/dropdownMutualHomeUser"
import { dropdownMutualHomeUserNoConnected } from "../../../../data/dropdownMutualHomeUser"

import { NavLink } from "react-router-dom"
import OrderPopup from "../../../Cart/OrderPopup/OrderPopup"

const NavBarMutual = () => {
    const isConnected = useSelector((state) => state.auth.userConnected)
    const wishListNum = useSelector((state) => state.user.wishlistItemNumber)
    const firstName = useSelector((state) => state.user.firstName)
    const numberProductCart = useSelector((state) => state.cart.cartDataNumber)
    const orderPopup = useSelector((state) => state.common.orderPopup)

    const isOpenDropdownUser = useSelector(
        (state) => state.home.dropDownMenuUser
    )

    return (
        <div className="navBar-mutual">
            {orderPopup && <OrderPopup />}
            {isConnected && (
                <NavLink to={"/mes-cours-et-documents"}>
                    <NavIcons
                        logo={
                            "https://static.artesane.com/images_react/player.png"
                        }
                        altPicture={"Logo d'un lecteur vidéo"}
                        title={"Mes cours"}
                    />
                </NavLink>
            )}

            <DropDownMenu
                firstItem={
                    <NavLink to={"/mon-compte/mes-commandes"}>
                        <NavIcons
                            logo={
                                "https://static.artesane.com/images_react/user.png"
                            }
                            path={"#"}
                            altPicture={"Logo d'un utilisateur"}
                            title={isConnected ? firstName : "Mon compte"}
                        />
                    </NavLink>
                }
                navItems={
                    isConnected
                        ? dropdownMutualHomeUserConnected
                        : dropdownMutualHomeUserNoConnected
                }
                action={toggleDropownUser}
                isOpen={isOpenDropdownUser}
                keyId={"user"}
            />
            <NavLink to={"/mon-compte/ma-liste-de-souhaits"}>
                <NavIcons
                    logo={
                        "https://static.artesane.com/images_react/empty_heart.png"
                    }
                    path={"#"}
                    numberOfProduct={wishListNum}
                    styleColor={"wishList"}
                    altPicture={"Logo liste d'envie"}
                    title={"Ma liste de souhaits"}
                />
            </NavLink>

            <NavLink to={"/mon-panier"}>
                <NavIcons
                    logo={"https://static.artesane.com/images_react/cart.png"}
                    path={"/"}
                    numberOfProduct={numberProductCart}
                    styleColor={"cart"}
                    altPicture={"Logo d'un pannier"}
                    title={"Panier"}
                />
            </NavLink>
        </div>
    )
}

export default NavBarMutual
