import { useDispatch, useSelector } from "react-redux"
import "./OneyChoose.scss"
import { changeInputOrder } from "../../../../../actions/cartAction"

const OneyChoose = () => {
    const oneyActive = useSelector((state) => state.cart.oneyActive)
    const dispatch = useDispatch()

    const oneySimulation = useSelector((state) => state.cart.oneySimulation)

    return (
        <div className="oney-choose-wrapper">
            <div className="oney-choose-tab">
                <div
                    onClick={() => {
                        dispatch(
                            changeInputOrder("oney_x3_with_fees", "oneyActive")
                        )
                    }}
                    className={`oney-choose-tab_choice ${oneyActive === "oney_x3_with_fees" ? "active" : ""}`}>
                    <div className="oney-choose-tab_choice_item">
                        <img
                            src="https://static.artesane.com/images_react/3x.svg"
                            alt=" paiement en 3x"
                        />
                        <img
                            src="https://static.artesane.com/images_react/logo.svg"
                            alt="Paiement Oney"
                        />
                        <p>Paiement en 3x</p>
                    </div>
                </div>
                <div
                    onClick={() => {
                        dispatch(
                            changeInputOrder("oney_x4_with_fees", "oneyActive")
                        )
                    }}
                    className={`oney-choose-tab_choice ${oneyActive === "oney_x4_with_fees" ? "active" : ""}`}>
                    <div className="oney-choose-tab_choice_item">
                        <img
                            src="https://static.artesane.com/images_react/4x.svg"
                            alt="paiement en 4x"
                        />
                        <img
                            src="https://static.artesane.com/images_react/logo.svg"
                            alt="Paiement Oney"
                        />
                        <p>Paiement en 4x</p>
                    </div>
                </div>
            </div>
            <div className="oney-choose-cards">
                {oneyActive === "oney_x3_with_fees" && (
                    <div
                        className="oney-choose-cards-item"
                        value="oney_x3_with_fees">
                        <div className="oney-choose-cards-item_content">
                            <span className="oney-choose-cards-item_header">
                                <img
                                    src="https://static.artesane.com/images_react/3x.svg"
                                    alt=" paiement en 3x"
                                />
                                <img
                                    src="https://static.artesane.com/images_react/logo.svg"
                                    alt="Paiement Oney"
                                />
                                <p>Paiement en 3x</p>
                            </span>
                            <span className="oney-choose-cards-item_choice_content">
                                <p>
                                    <span>Pour un montant de :</span>
                                    <span>
                                        {(oneySimulation.current_total / 100)
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                    </span>
                                </p>
                                <p>
                                    <span>Premier apport de :</span>
                                    <span>
                                        {(
                                            oneySimulation.x3_with_fees
                                                .down_payment_amount / 100
                                        )
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                    </span>
                                    <small>
                                        {"(Coût de financement  : "}
                                        <strong>
                                            {" "}
                                            {(
                                                oneySimulation.x3_with_fees
                                                    .total_cost / 100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                        TAEG :
                                        <strong>
                                            {
                                                oneySimulation.x3_with_fees
                                                    .effective_annual_percentage_rate
                                            }{" "}
                                            %
                                        </strong>
                                    </small>
                                </p>
                                <p>
                                    <span>Mensualité n° 1 : </span>
                                    <span>
                                        <strong>
                                            {(
                                                oneySimulation.x3_with_fees
                                                    .installments[0].amount /
                                                100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                    </span>
                                </p>
                                <p>
                                    <span>Mensualité n° 2 :</span>
                                    <span>
                                        <strong>
                                            {(
                                                oneySimulation.x3_with_fees
                                                    .installments[1].amount /
                                                100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <strong>Total : </strong>
                                        <strong>
                                            {(
                                                (oneySimulation.current_total +
                                                    oneySimulation.x3_with_fees
                                                        .total_cost) /
                                                100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                    </span>
                                </p>
                            </span>
                        </div>
                    </div>
                )}
                {oneyActive === "oney_x4_with_fees" && (
                    <div
                        className="oney-choose-cards-item"
                        value="oney_x4_with_fees">
                        <div className="oney-choose-cards-item_content">
                            <span className="oney-choose-cards-item_header">
                                <img
                                    src="https://static.artesane.com/images_react/4x.svg"
                                    alt=" paiement en 3x"
                                />
                                <img
                                    src="https://static.artesane.com/images_react/logo.svg"
                                    alt="Paiement Oney"
                                />
                                <p>Paiement en 4x</p>
                            </span>
                            <span className="oney-choose-cards-item_choice_content">
                                <p>
                                    <span>Pour un montant de :</span>
                                    <span>
                                        {(oneySimulation.current_total / 100)
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                    </span>
                                </p>
                                <p>
                                    <span>Premier apport de :</span>
                                    <span>
                                        {(
                                            oneySimulation.x4_with_fees
                                                .down_payment_amount / 100
                                        )
                                            .toString()
                                            .replace(".", ",")}{" "}
                                        €
                                    </span>
                                    <small>
                                        {"(Coût de financement  : "}
                                        <strong>
                                            {" "}
                                            {(
                                                oneySimulation.x4_with_fees
                                                    .total_cost / 100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                        TAEG :
                                        <strong>
                                            {
                                                oneySimulation.x4_with_fees
                                                    .effective_annual_percentage_rate
                                            }{" "}
                                            %
                                        </strong>
                                    </small>
                                </p>
                                <p>
                                    <span>Mensualité n° 1 : </span>
                                    <span>
                                        <strong>
                                            {(
                                                oneySimulation.x4_with_fees
                                                    .installments[0].amount /
                                                100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                    </span>
                                </p>
                                <p>
                                    <span>Mensualité n° 2 :</span>
                                    <span>
                                        <strong>
                                            {(
                                                oneySimulation.x4_with_fees
                                                    .installments[1].amount /
                                                100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                    </span>
                                </p>
                                <p>
                                    <span>Mensualité n° 3 :</span>
                                    <span>
                                        <strong>
                                            {(
                                                oneySimulation.x4_with_fees
                                                    .installments[2].amount /
                                                100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <strong>Total : </strong>
                                        <strong>
                                            {(
                                                (oneySimulation.current_total +
                                                    oneySimulation.x4_with_fees
                                                        .total_cost) /
                                                100
                                            )
                                                .toString()
                                                .replace(".", ",")}{" "}
                                            €{" "}
                                        </strong>
                                    </span>
                                </p>
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OneyChoose
