//Hooks
import { NavLink } from "react-router-dom"

//Hooks
import { useParams } from "react-router-dom"

//Styles
import "./MegaMenu.scss"

const MegaMenu = ({ navItems }) => {
    const { univers } = useParams()
    return navItems.map(({ children, name }) => (
        <div key={name} className="megamenu-items">
            <div key={name} className="megamenu-items-title">
                {" "}
                {name}
            </div>
            {children.map(({ name, code, categories }, index) => {
                return (
                    <div
                        key={name}
                        className="megamenu-items-links"
                        onClick={() => {}}>
                        <NavLink
                            to={`/${univers}/catalogue/${code.toLowerCase()}/1`}>
                            {" "}
                            {name}
                        </NavLink>
                    </div>
                )
            })}
        </div>
    ))
}

export default MegaMenu
