//Libraries
import AliceCarousel from "react-alice-carousel"

//COmponents
import LessonCardSelection from "../../Commons/Cards/LessonCardSelection/LessonCardSelection"

//Styles
import "./Recommandations.scss"

const Recommandations = ({ associations }) => {

    return (
        associations.length > 0 && (
            <div className="recommandations-wrapper">
                <div className="recommandations-title">
                    <span>Nos recommandations</span> <span>personnalisées</span>
                </div>

                <div
                    className={`recommandation-slider-wrapper${associations.length < 3 ? " flex" : ""}`}>
                    {associations.length < 3 &&
                        associations.map(
                            ({
                                id,
                                mainImage,
                                name,
                                author,
                                duree,
                                niveau,
                                universes,
                                isNew,
                                pack,
                                parcours,
                                coffret,
                                variants,
                                buy_once,
                                slug,
                                giftCard,
                                giftCardAmountConfigurable,
                            }) => (
                                <div className="recommendation-slider-card-wrapper">
                                    <LessonCardSelection
                                        key={id}
                                        productId={id}
                                        variants={variants}
                                        urlImg={
                                            mainImage !== null
                                                ? mainImage.thumbnails.carousel
                                                : ""
                                        }
                                        pack={pack}
                                        coffret={coffret}
                                        parcours={parcours}
                                        newer={isNew}
                                        title={name}
                                        professor={
                                            author !== null ? author.name : ""
                                        }
                                        time={duree}
                                        level={niveau}
                                        price={variants.length > 0 ? variants[0].price / 100 : null}
                                        originalPrice={
                                          variants.length > 0 ?  variants[0].originalPrice / 100 : null
                                        }
                                        reduction={
                                            variants.length > 0 ? 
                                            (variants[0].originalPrice !==
                                            variants[0].price
                                                ? Math.floor(
                                                      ((variants[0]
                                                          .originalPrice -
                                                          variants[0].price) /
                                                          variants[0]
                                                              .originalPrice) *
                                                      100
                                                  )
                                                : 0)
                                                : 
                                                0
                                        }
                                         currentUnivers={
                                                    universes.length > 0 ? universes[0].code : 'undefined'
                                                }
                                        productSlug={slug}
                                        buyOnce={buy_once}
                                        giftCard={giftCard}
                                        giftCardAmountConfigurable={
                                            giftCardAmountConfigurable
                                        }
                                        alt={
                                            mainImage !== null &&
                                            mainImage !== undefined
                                                ? mainImage.alt
                                                : ""
                                        }
                                    />
                                </div>
                            )
                        )}
                    {associations.length >= 3 && (
                        <AliceCarousel
                            items={associations.map(
                                ({
                                    id,
                                    mainImage,
                                    name,
                                    author,
                                    niveau,
                                    duree,
                                    universes,
                                    isNew,
                                    pack,
                                    parcours,
                                    coffret,
                                    variants,
                                    buy_once,
                                    slug,
                                    giftCard,
                                    giftCardAmountConfigurable,
                                }) => {
                                    return (
                                        <div className="recommendation-slider-card-wrapper">
                                            <LessonCardSelection
                                                key={id}
                                                productId={id}
                                                variants={variants}
                                                urlImg={
                                                    mainImage !== null &&
                                                    mainImage !== undefined
                                                        ? mainImage.url
                                                        : ""
                                                }
                                                pack={pack}
                                                coffret={coffret}
                                                parcours={parcours}
                                                newer={isNew}
                                                title={name}
                                                professor={
                                                    author !== null
                                                        ? author.name
                                                        : ""
                                                }
                                                time={duree !== undefined ? duree : ""}
                                                level={niveau !== undefined ? niveau : ""}
                                                price={variants.length > 0 ? variants[0].price / 100 : null}
                                                originalPrice={
                                                    variants.length > 0 ? 
                                                    (variants[0].originalPrice /
                                                    100)
                                                     : null
                                                }
                                                reduction={
                                                    variants.length > 0 ? 
                                                    (variants[0]
                                                        .originalPrice !==
                                                    variants[0].price
                                                        ? Math.floor(
                                                              ((variants[0]
                                                                  .originalPrice -
                                                                  variants[0]
                                                                      .price) /
                                                                  variants[0]
                                                                      .originalPrice) *
                                                              100
                                                          )
                                                        : 0)
                                                        : 0
                                                }
                                                currentUnivers={
                                                    universes.length > 0 ? universes[0].code : 'undefined'
                                                }
                                                productSlug={slug}
                                                buyOnce={buy_once}
                                                giftCard={giftCard}
                                                giftCardAmountConfigurable={
                                                    giftCardAmountConfigurable
                                                }
                                                alt={
                                                    mainImage !== null &&
                                                    mainImage !== undefined
                                                        ? mainImage.alt
                                                        : ""
                                                }
                                            />
                                        </div>
                                    )
                                }
                            )}
                            autoPlay
                            infinite
                            animationDuration={1000}
                            autoPlayInterval={1000}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                600: {
                                    items: 2,
                                },
                                1024: {
                                    items: 3,
                                },
                            }}
                        />
                    )}
                </div>
            </div>
        )
    )
}

export default Recommandations
