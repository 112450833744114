import Header from "../Prepage/Header/Header"
import InputSimple from "../UserAccount/InputSimple/inputSimple"
import "./NewPassword.scss"
import { useDispatch, useSelector } from "react-redux"
import {
    changeInputAuthValue,
    modifyResetPassword,
} from "../../actions/authAction"
import Spinner from "../Commons/Spinner/Spinner"
import ErrorPopup from "../UserAccount/ErrorPopup/ErrorPopup"
import ConfirmRequest from "../ForgottenPassword/ConfirmRequest/ConfirmRequest"
import { useParams } from "react-router-dom"
import Banner from "../Banner/Banner"

const NewPassword = () => {
    const dispatch = useDispatch()
    const newPassword = useSelector((state) => state.auth.forgetNewPassword)
    const confirmPassword = useSelector(
        (state) => state.auth.forgetConfirmNewPassword
    )
    const visibilityNewpassword = useSelector(
        (state) => state.auth.forgetNewPasswordVisibility
    )
    const visibilityConfirmNewpassword = useSelector(
        (state) => state.auth.forgetNewPasswordConfirmVisibility
    )
    const errorPatternPassword = useSelector(
        (state) => state.auth.forgetErrorPatternPassword
    )
    const errorPatternConfirmPassword = useSelector(
        (state) => state.auth.forgetErrorConfirmPassword
    )
    const loadingChangePassword = useSelector(
        (state) => state.auth.loadingChangePassword
    )
    const confirmPasswordPopup = useSelector(
        (state) => state.auth.popUpPasswordConfirm
    )
    const errorPassword = useSelector((state) => state.user.errorRequest)

    const { token } = useParams()

    return (
        <div>
            <Banner />
            <Header />
            <div className="new-password-wrapper">
                <div className="new-password-title">
                    Réinitialisation du mot de passe
                </div>
                {loadingChangePassword && <Spinner />}
                {errorPassword && <ErrorPopup />}
                {confirmPasswordPopup && (
                    <ConfirmRequest
                        message={
                            "Votre mot de passe a bien été changé. Vous pouvez dès à présent vous connecter."
                        }
                    />
                )}

                <form
                    className="password-form"
                    action="#"
                    onSubmit={(event) => {
                        event.preventDefault()
                        if (
                            newPassword.match(/[A-Z]/g) &&
                            newPassword.match(/[0-9]/g) &&
                            newPassword.match(/[a-z]/g) &&
                            newPassword.match(/[-+!?*$@%_]/g) &&
                            newPassword.match(/[-+!?*$@%_\w]/g) &&
                            newPassword.length > 7 &&
                            newPassword.length <= 15
                        ) {
                            dispatch(
                                changeInputAuthValue(
                                    false,
                                    "forgetErrorPatternPassword"
                                )
                            )
                        } else {
                            dispatch(
                                changeInputAuthValue(
                                    true,
                                    "forgetErrorPatternPassword"
                                )
                            )
                        }

                        if (newPassword === confirmPassword) {
                            dispatch(
                                changeInputAuthValue(
                                    false,
                                    "forgetErrorConfirmPassword"
                                )
                            )
                        } else {
                            dispatch(
                                changeInputAuthValue(
                                    true,
                                    "forgetErrorConfirmPassword"
                                )
                            )
                        }
                        dispatch(
                            changeInputAuthValue(true, "loadingChangePassword")
                        )
                        dispatch(
                            modifyResetPassword(
                                token,
                                newPassword,
                                confirmPassword
                            )
                        )
                    }}>
                    <InputSimple
                        label={"Nouveau mot de passe *"}
                        iconVisibility={
                            <img
                                src={
                                    visibilityNewpassword
                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                        : "https://static.artesane.com/images_react/oeil.png"
                                }
                                alt="oeil"
                                onClick={() => {
                                    dispatch(
                                        changeInputAuthValue(
                                            !visibilityNewpassword,
                                            "forgetNewPasswordVisibility"
                                        )
                                    )
                                }}
                            />
                        }
                        error={errorPatternPassword}
                        minLength={8}
                        maxLength={15}
                        inputName={"newPassword"}
                        value={newPassword}
                        type={visibilityNewpassword ? "text" : "password"}
                        required={true}
                        onChange={(event) => {
                            if (
                                event.target.value.match(/[A-Z]/g) &&
                                event.target.value.match(/[0-9]/g) &&
                                event.target.value.match(/[a-z]/g) &&
                                event.target.value.match(/[-+!?*$@%_]/g) &&
                                event.target.value.match(/[-+!?*$@%_\w]/g) &&
                                event.target.value.length > 8 &&
                                event.target.value.length <= 15
                            ) {
                            } else {
                            }
                            dispatch(
                                changeInputAuthValue(
                                    false,
                                    "forgetErrorPatternPassword"
                                )
                            )
                            dispatch(
                                changeInputAuthValue(
                                    event.target.value,
                                    "forgetNewPassword"
                                )
                            )
                        }}
                    />
                    <div
                        className={`input-information ${errorPatternPassword ? "error" : ""}`}>
                        {" "}
                        8 à 15 caractères requis. Au moins une majuscule, une
                        minuscule, un chiffre, un caractère spécial ($ @ % * + -
                        _ ! ?){" "}
                    </div>
                    <InputSimple
                        iconVisibility={
                            <img
                                src={
                                    visibilityConfirmNewpassword
                                        ? "https://static.artesane.com/images_react/oeil_barre.png"
                                        : "https://static.artesane.com/images_react/oeil.png"
                                }
                                alt="oeil"
                                onClick={() => {
                                    dispatch(
                                        changeInputAuthValue(
                                            !visibilityConfirmNewpassword,
                                            "forgetNewPasswordConfirmVisibility"
                                        )
                                    )
                                }}
                            />
                        }
                        error={errorPatternConfirmPassword}
                        minLength={8}
                        maxLength={15}
                        label={"Confirmation de votre nouveau mot de passe *"}
                        inputName={"confirmNewPassword"}
                        value={confirmPassword}
                        type={
                            visibilityConfirmNewpassword ? "text" : "password"
                        }
                        required={true}
                        onChange={(event) => {
                            dispatch(
                                changeInputAuthValue(
                                    false,
                                    "forgetErrorConfirmPassword"
                                )
                            )
                            dispatch(
                                changeInputAuthValue(
                                    event.target.value,
                                    "forgetConfirmNewPassword"
                                )
                            )
                        }}
                    />
                    <div
                        className={`input-information ${errorPatternConfirmPassword ? "error" : ""}`}>
                        Correspondance des mots de passe
                    </div>

                    <button className="submit-button" type="submit">
                        Enregistrer {">"}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default NewPassword
