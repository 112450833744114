export const homeSliderProduct = [
    {
        taxon: "cuisine",
        urlImage:
            "https://static.artesane.com/default/0001/11/1eeee88e9c3208d1d283438038d8b5efe6d7005c.jpeg",
        newer: true,
        pack: true,
        title: "Les petites salades",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "débutant",
        price: "100",
        reduction: 0,
    },
    {
        taxon: "cuisine",
        urlImage:
            "https://static.artesane.com/default/0001/11/3c34a4b163820e943840678e97558f62ac58889d.jpeg",
        newer: false,
        pack: false,
        title: "Les petites salades",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "débutant",
        price: "100",
        reduction: 0.3,
    },
    {
        taxon: "cuisine",
        urlImage:
            "https://static.artesane.com/default/0001/11/4711a511319088c707448ff1a8885cc32ba314ee.jpeg",
        newer: true,
        pack: true,
        title: "Les petites salades",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "intermédiaire",
        price: "100",
        reduction: 0,
    },
    {
        taxon: "cuisine",
        urlImage:
            "https://images.pexels.com/photos/1152237/pexels-photo-1152237.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        newer: true,
        pack: true,
        title: "Les petites salades",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "intermédiaire",
        price: "100",
        reduction: 0,
    },
    {
        taxon: "pâtisserie",
        urlImage:
            "https://images.pexels.com/photos/771454/pexels-photo-771454.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        newer: true,
        pack: false,
        title: "Les petits sablés",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "intermédiaire",
        price: "100",
        reduction: 0.2,
    },
    {
        taxon: "pâtisserie",
        urlImage:
            "https://images.pexels.com/photos/771454/pexels-photo-771454.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        newer: false,
        pack: false,
        title: "Les petits sablés",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "intermédiaire",
        price: "100",
        reduction: 0.1,
    },
    {
        taxon: "pâtisserie",
        urlImage:
            "https://images.pexels.com/photos/771454/pexels-photo-771454.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        newer: false,
        pack: true,
        title: "Les petits sablés",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "débutant",
        price: "100",
        reduction: 0,
    },
    {
        taxon: "boisson",
        urlImage:
            "https://images.pexels.com/photos/7376798/pexels-photo-7376798.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        newer: false,
        pack: true,
        title: "Les petits mojitos",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "intermédiaire",
        price: "100",
        reduction: 0.3,
    },
    {
        taxon: "boisson",
        urlImage:
            "https://images.pexels.com/photos/7376798/pexels-photo-7376798.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        newer: false,
        pack: false,
        title: "Les petits mojitos",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "débutant",
        price: "100",
        reduction: 0,
    },
    {
        taxon: "boisson",
        urlImage:
            "https://images.pexels.com/photos/7376798/pexels-photo-7376798.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        newer: false,
        pack: false,
        title: "Les petits mojitos",
        professor: "Marie-gabrielle Berland",
        time: "2h24",
        level: "débutant",
        price: "100",
        reduction: 0.2,
    },
]
