//Compoonents
import Infos from "./Infos/Infos"
import Price from "./Price/Price"
import AddCartAndWishlist from "./AddCartAndWishlist/AddCartAndWishlist"
import Spinner from "../../Spinner/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { toggleVariantsChoice } from "../../../../actions/cartAction"

//Styles
import "./LessonCardSelection.scss"
import { NavLink } from "react-router-dom"
import VariantsChoiceCart from "./AddCartAndWishlist/variantsChoiceCart/VariantsChoiceCart"
import { useEffect } from "react"

const LessonCardSelection = ({
    productId,
    shipedRequired,
    buyOnce,
    variants,
    urlImg,
    title,
    professor,
    time,
    level,
    price,
    pack,
    parcours,
    coffret,
    newer,
    originalPrice,
    reduction,
    productSlug,
    currentUnivers,
    giftCard,
    giftCardAmountConfigurable,
    alt,
}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(toggleVariantsChoice(0))
    }, [dispatch])

    const variantAddCart = useSelector((state) => state.cart.loadingAddCart)
    const isOpenVariantsChoice = useSelector(
        (state) => state.cart.popUpVariantsChoiceCatalogue
    )

    const wishlist = useSelector((state) => state.user.wishList)

    const productStillAddWishlist = (wishlist, productId) => {
        if (Object.keys(wishlist).length > 0) {
            if (wishlist.wishlistProducts.length > 0) {
                return wishlist.wishlistProducts.find(
                    (element) => element.product.id === productId
                )
            } else {
                return false
            }
        } else {
            return false
        }
    }

    let variantNotInStock = []

    variants.forEach((element) => {
        if (!element.inStock) {
            variantNotInStock.push(element)
        }
    })
    return (
        <div className="lesson-card-selection-wrapper">
            {variants.find((variant) => variant.code === variantAddCart) && (
                <div className="spinner-card-wrapper">
                    <Spinner />
                </div>
            )}
            <div className="lesson-card-selection-img-wrapper">
                {variantNotInStock.length === variants.length && (
                    <div className="no-stocks">
                        <span>
                            Malheureusement ce produit n'est actuellement plus
                            disponible
                        </span>
                    </div>
                )}

                <NavLink
                    className="lesson-card-selection-link"
                    to={`/${currentUnivers}/produit/${productSlug}`}>
                    <img
                        className="lesson-card-selection-img"
                        src={urlImg}
                        alt={alt === null ? title : alt}
                    />
                </NavLink>
                {reduction > 0 && (
                    <div className="lesson-card-selection-solde">
                        {" "}
                        - {reduction} %
                    </div>
                )}
            </div>
            <div className="lesson-card-selection-text-wrapper">
                {pack && (
                    <div className="lesson-card-selection-pack">
                        <span className="lesson-card-selection-pack-text">
                            pack
                        </span>
                    </div>
                )}
                {parcours && (
                    <div className="lesson-card-selection-pack">
                        <span className="lesson-card-selection-pack-text">
                            parcours
                        </span>
                    </div>
                )}
                {coffret && (
                    <div className="lesson-card-selection-pack">
                        <span className="lesson-card-selection-pack-text">
                            coffret
                        </span>
                    </div>
                )}

                <div className="lesson-card-selection-text">
                    {isOpenVariantsChoice === productId && (
                        <VariantsChoiceCart
                            variantsNotInStock={variantNotInStock}
                            variants={variants}
                            giftCard={giftCard}
                            giftCardAmountConfigurable={
                                giftCardAmountConfigurable
                            }
                        />
                    )}
                    <div
                        className={
                            newer
                                ? "lesson-card-selection-new-active"
                                : "lesson-card-selection-new"
                        }>
                        nouveauté
                    </div>
                    <div className="lesson-card-selection-title">
                        <NavLink
                            to={`/${currentUnivers}/produit/${productSlug}`}>
                            {title}
                        </NavLink>
                    </div>
                    {professor !== undefined && (
                        <div className="lesson-card-selection-professor">
                            par {professor}
                        </div>
                    )}

                    <Infos time={time} level={level} />

                    <div className="lesson-card-selection-buy-wrapper">
                        <Price
                            price={price}
                            reduction={reduction}
                            originalPrice={originalPrice}
                        />
                        <AddCartAndWishlist
                            buyOnce={buyOnce}
                            variants={variants}
                            productId={productId}
                            variantsNotInStock={variantNotInStock}
                            giftCardAmountConfigurable={
                                giftCardAmountConfigurable
                            }
                            wishlist={productStillAddWishlist(
                                wishlist,
                                productId
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonCardSelection
