import { NavLink } from "react-router-dom"

//Styles
import "./LinkButton.scss"

const LinkButton = ({ texte, url }) => (
    <NavLink to={url}>
        <div className="lesson-card-link">{texte}</div>
    </NavLink>
)

export default LinkButton
