import Header from "../Prepage/Header/Header"
import "./ForgottenPassword.scss"
import InputSimple from "../UserAccount/InputSimple/inputSimple"
import { useDispatch, useSelector } from "react-redux"
import { changeInputAuthValue, resetPassword } from "../../actions/authAction"
import ErrorPopup from "../UserAccount/ErrorPopup/ErrorPopup"
import ConfirmRequest from "./ConfirmRequest/ConfirmRequest"
import Spinner from "../Commons/Spinner/Spinner"
import Banner from "../Banner/Banner"

const ForgottenPassword = () => {
    const dispatch = useDispatch()
    const email = useSelector((state) => state.auth.emailForgottenPassword)
    const errorPassword = useSelector((state) => state.user.errorRequest)
    const confirmPassword = useSelector(
        (state) => state.auth.popUpPasswordConfirm
    )
    const loadingChangePassword = useSelector(
        (state) => state.auth.loadingChangePassword
    )
    return (
        <div>
            <Banner />
            <Header />
            <div className="forgotten-password-wrapper">
                {loadingChangePassword && <Spinner />}

                {errorPassword && <ErrorPopup />}
                {confirmPassword && (
                    <ConfirmRequest
                        message={
                            "Votre demande à bien été prise en compte. Rendez-vous dans votre boite mail pour retrouver votre lien de réinitialisation"
                        }
                    />
                )}
                <div className="forgotten-password-title">
                    Réinitialisation du mot de passe
                </div>
                <div className="forgotten-password-information">
                    Pour réinitialiser votre mot de passe, entrez l'adresse
                    email associée à votre compte dans la case ci-dessous. Un
                    lien vous sera envoyé, il vous permettra d'accéder à une
                    page vous permettant de définir un nouveau mot de passe.
                </div>
                <form
                    onSubmit={(event) => {
                        event.preventDefault()
                        dispatch(
                            changeInputAuthValue(true, "loadingChangePassword")
                        )
                        dispatch(resetPassword(email))
                    }}
                    action="#">
                    <InputSimple
                        inputName={"emailPassword"}
                        label={"Adresse mail *"}
                        type={"email"}
                        required={true}
                        value={email}
                        onChange={(event) => {
                            dispatch(
                                changeInputAuthValue(
                                    event.target.value,
                                    "emailForgottenPassword"
                                )
                            )
                        }}
                    />
                    <button type="submit">Réinitialiser {">"}</button>
                </form>
            </div>
        </div>
    )
}

export default ForgottenPassword
