import { NavLink } from "react-router-dom"
import Header from "../Header/Header"
import "./PaymentValidate.scss"

const PaymentValidate = () => {
    return (
        <div>
            <Header />
            <div className="payment-validate-wrapper">
                <div className="text">Merci pour votre commande</div>
                <div className="text-recap">Votre commande est validée !</div>
                <div className="text-recap">
                    Vous allez recevoir le récapitulatif de votre commande par
                    mail.
                </div>
                <div className="button-wrapper">
                    <NavLink to={"/"} className="button">
                        Retour au site {">"}
                    </NavLink>
                    <NavLink
                        to={"/mes-cours-et-documents/arts-du-fil"}
                        className="button">
                        Accès à mes cours {">"}
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default PaymentValidate
