//Components
import UnderTaxons from "./UnderTaxons/UnderTaxons"

//Styles
import "./Taxons.scss"
import { NavLink } from "react-router-dom"

//Actions
import { toggleTaxonsActif } from "../../../../../actions/homeActions"

//Hooks
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

const Taxons = ({ taxon, nom, code }) => {
    const { univers } = useParams()
    const currentTaxonActif = useSelector(
        (state) => state.home.toggleTaxonsActif
    )
    const dispatch = useDispatch()
    return (
        nom !== "Explorez notre catalogue" &&
        nom !== "explorez notre catalogue" &&
        nom !== "Notre sélection" && (
            <div className="taxons" key={code}>
                <div
                    key={nom}
                    className="taxons-name"
                    onClick={() => {
                        if (currentTaxonActif === nom) {
                            dispatch(toggleTaxonsActif(""))
                        } else {
                            dispatch(toggleTaxonsActif(nom))
                        }
                    }}>
                    {nom}
                </div>
                <div
                    className={
                        currentTaxonActif === nom ? " " : "taxons-actif-closed"
                    }>
                    <div key={code} className="taxons-all">
                        <NavLink
                            to={`/${univers}/catalogue/${code.toLowerCase()}/1`}>
                            Voir tous les cours &gt;
                        </NavLink>
                    </div>
                    <UnderTaxons key={nom} underTaxons={taxon} />
                </div>
            </div>
        )
    )
}

export default Taxons
