//Styles
import "./ClientsAdvicesCard.scss"

const ClientsAdvicesCard = ({ text, author, id }) => (
    <div className="clients-advices-card-wrapper" id={`card-${id}`}>
        <div className="clients-advices-card-text">{text}</div>
        <div className="clients-advices-card-author">{author}</div>
    </div>
)

export default ClientsAdvicesCard
