import "./ProfessorHomeCard.scss"

const ProfessorHomeCard = ({ imgUrl, name, teacherTaxons }) => {
    let subjects = teacherTaxons.filter((item) => item.name !== null)

    return (
        <div className="professor-home-card-wrapper">
            <div className="professor-home-card-image-wrapper">
                <img
                    className="professor-home-card-image"
                    src={imgUrl}
                    alt={name}
                />
            </div>
            <div className="professor-home-card-name">{name}</div>
            <div className="professor-home-card-subject">
                {subjects.length === 1 && (
                    <span className="professor-home-card-subject-intitule">
                        {" "}
                        {subjects[0].name}{" "}
                    </span>
                )}
                {subjects.length > 1 && subjects.length < 3 && (
                    <span className="professor-home-card-subject-intitule">
                        {" "}
                        {subjects[0].name} et {subjects[1].name}
                    </span>
                )}
                {subjects.length > 2 && subjects.length >= 3 && (
                    <span className="professor-home-card-subject-intitule">
                        {" "}
                        {subjects[0].name}, {subjects[1].name} et{" "}
                        {subjects[2].name}
                    </span>
                )}
            </div>
        </div>
    )
}

export default ProfessorHomeCard
