import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { fetchUserMedia, loadingMedia } from "../../actions/mediaAction"
import Header from "../Prepage/Header/Header"
import MediaMenu from "./MediaMenu/MediaMenu"
import Banner from "../Banner/Banner"

export const MediaLibrary = () => {
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadingMedia(true))
        dispatch(fetchUserMedia())
        
    }, [dispatch])
    return (
        <div>
            <Banner />
            <Header />
            <div className="media-library-wrapper">
                <MediaMenu />
            </div>

            {(pathname === "/mes-cours-et-documents" ||
                pathname === "/mes-cours-et-documents/") && (
                <Navigate to={"arts-du-fil"} replace />
            )}
            <Outlet />
        </div>
    )
}

export default MediaLibrary
