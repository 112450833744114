import { SAVE_COMMON_DATA_IN_STATE } from "../actions/commonAction"

const initialState = {
    cookiesPopup: false,
    cookiesParameterPopup: false,
    functionnalsCookies: true,
    statisticsCookies: true,
    marketingCookies: true,
    searchBarGeneralWords: "",
    searchBarUniversWords: "",
    allUniverses: [],
    loadingAllUniverses: true,
    loadingDataByUniverse: true,
    orderPopup: false,
    loadingGeneralSearchbar: true,
    loadingUniversSearchbar: true,
    searchGeneral: [],
    searchUnivers: [],
    modeMaintenance: false,
    loadingCoursIdeal: true,
    coursIdeal: [],
    coursIdealActual: [],
    coursIdealPrevious: [],
    coursIdealFind: [],
    loadingCoursIdealFind: true,
    loadingSeoItem: true,
    seoItems: [],
    confirmPopupOpen: false,
    isStickiheader: false,
}

const commonReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_COMMON_DATA_IN_STATE:
            return {
                ...state,
                [action.property]: action.value,
            }

        default:
            return state
    }
}
export default commonReducer
