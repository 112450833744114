import { useSelector } from "react-redux"
import LessonCardSelection from "../../Commons/Cards/LessonCardSelection/LessonCardSelection"
import "./Wishlist.scss"
import EmptyPage from "../../Commons/EmptyPage/EmptyPage"
import Spinner from "../../Commons/Spinner/Spinner"

const Wishlist = () => {
    const wishlistData = useSelector((state) => state.user.wishList)
    const wishlistLoading = useSelector((state) => state.user.loadingWishlist)

    if (wishlistLoading) {
        return <Spinner />
    } else {
        return (
            <div className="wishlist-wrapper">
                {Object.keys(wishlistData).length > 0 &&
                    wishlistData.wishlistProducts.map(({ product }) => (
                        <div
                            key={product.id}
                            className="wishlist-items-wrapper">
                            <LessonCardSelection
                                productId={product.id}
                                urlImg={
                                    product.mainImage !== null &&
                                    product.mainImage !== undefined
                                        ? product.mainImage.url
                                        : ""
                                }
                                title={product.name}
                                professor={
                                    product.author ? product.author.name : ""
                                }
                                originalPrice={ product.variants.length > 0 ? ((
                                    product.variants[0].originalPrice / 100
                                )
                                    .toFixed(2)
                                    .toString()
                                    .replace(".", ",")) : null}
                                price={ product.variants.length > 0 ? ((product.variants[0].price / 100)
                                    .toFixed(2)
                                    .toString()
                                    .replace(".", ",")) : null}
                                pack={product.pack}
                                parcours={product.parcours}
                                coffret={product.coffret}
                                reduction={
                                    product.variants.length > 0 ? 
                                    (product.variants[0].originalPrice !==
                                    product.variants[0].price
                                        ? Math.floor(
                                              ((product.variants[0]
                                                  .originalPrice -
                                                  product.variants[0].price) /
                                                  product.variants[0]
                                                      .originalPrice) *
                                              100
                                          )
                                        : 0)
                                        :0
                                }
                                newer={product.isNew}
                                time={
                                    product.attributes.find(
                                        (item) => item.code === "duree"
                                    )
                                        ? product.attributes.find(
                                              (item) => item.code === "duree"
                                          ).value
                                        : ""
                                }
                                level={
                                    product.attributes.find(
                                        (item) => item.code === "niveau"
                                    )
                                        ? product.attributes.find(
                                              (item) => item.code === "niveau"
                                          ).value
                                        : ""
                                }
                                key={product.id}
                                currentUnivers={
                                    product.universes.length > 0
                                        ? product.universes[0].code
                                        : "arts-du-fil"
                                }
                                productSlug={product.slug}
                                // shipedRequired={variants[0].shipping_required}
                                variants={product.variants}
                                buyOnce={product.buy_once}
                                giftCard={product.giftCard}
                                giftCardAmountConfigurable={
                                    product.giftCardAmountConfigurable
                                }
                                alt={
                                    product.mainImage !== null &&
                                    product.mainImage !== undefined
                                        ? product.mainImage.alt
                                        : ""
                                }
                            />
                        </div>
                    ))}

                {Object.keys(wishlistData).length === 0 && (
                    <>
                        <EmptyPage
                            firstPart={
                                "Vous n'avez pas encore ajouté de cours à votre liste de souhaits."
                            }
                            secondPartOne={"En panne d’inspiration ?"}
                            secondPartTwo={
                                " Retrouvez nos meilleurs cours ici ! >"
                            }
                            urlSecondPart={"/arts-du-fil/catalogue/tout/1"}
                        />
                    </>
                )}

                {Object.keys(wishlistData).length !== 0 && (
                    <>
                        {wishlistData.wishlistProducts.length === 0 && (
                            <>
                                <EmptyPage
                                    firstPart={
                                        "Vous n'avez pas encore ajouté de cours à votre liste de souhaits."
                                    }
                                    secondPartOne={"En panne d’inspiration ?"}
                                    secondPartTwo={
                                        " Retrouvez nos meilleurs cours ici ! >"
                                    }
                                    urlSecondPart={
                                        "/arts-du-fil/catalogue/tout/1"
                                    }
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        )
    }
}

export default Wishlist
