// Hooks
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux/es/exports"

//Action
import {
    toggleSelectProductChoice,
    selectVariants,
    saveProductDataState,
} from "../../../../actions/productsActions"

//Styles
import "./VariantsChoice.scss"

const VariantsChoice = ({ variants, productData, variantNotInStock }) => {
    const dispatch = useDispatch()
    const isOpenPopUp = useSelector(
        (state) => state.products.isOpenSelectChoice
    )
    const isVariantSelected = useSelector(
        (state) => state.products.variantSelected
    )

    useEffect(() => {
        dispatch(selectVariants(""))
    }, [dispatch])

    return (
        <div>
            <>
                <div
                    className="variants-choice-select"
                    data-label="coucou"
                    onClick={() => {
                        dispatch(toggleSelectProductChoice(true))
                    }}>
                    {isVariantSelected === "" && (
                        <span>
                            Choisissez une option{" "}
                            <span className="variants-choice-select-chevron">
                                {" "}
                                {"〈"}
                            </span>
                        </span>
                    )}
                    {isVariantSelected !== "" && (
                        <span className="select">
                            {isVariantSelected.name}
                            <span className="variants-choice-select-chevron">
                                {" "}
                                {"〈"}
                            </span>
                        </span>
                    )}
                </div>
                <div
                    className={
                        isOpenPopUp
                            ? "variants-choice-popup-background"
                            : "close"
                    }>
                    <div
                        className={
                            isOpenPopUp
                                ? "variants-choice-popup-wrapper"
                                : "close"
                        }>
                        <span
                            className="variants-choice-toclose"
                            onClick={() => {
                                dispatch(toggleSelectProductChoice(false))
                            }}>
                            {" "}
                            ✕
                        </span>

                        <div className="variants-choice-popup-choose">
                            Veuillez choisir une option :{" "}
                        </div>

                        {variants.map(
                            (
                                { name, code, id, inStock, product_id, price },
                                index
                            ) => {
                                if (inStock) {
                                    return (
                                        <div
                                            key={name}
                                            className={
                                                isVariantSelected.name === name
                                                    ? "variants-choices-item-select"
                                                    : "variants-choices-item"
                                            }
                                            onClick={() => {
                                                dispatch(
                                                    selectVariants({
                                                        name: name,
                                                        code: code,
                                                        id: id,
                                                        productId: product_id,
                                                        index: index,
                                                        price: price,
                                                    })
                                                )
                                                if (
                                                    productData.giftCardAmountConfigurable
                                                ) {
                                                    dispatch(
                                                        saveProductDataState(
                                                            "giftcardAmount",
                                                            price / 100
                                                        )
                                                    )
                                                }
                                                dispatch(
                                                    toggleSelectProductChoice(
                                                        false
                                                    )
                                                )
                                            }}>
                                            {name}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div
                                            key={name}
                                            className={
                                                "variants-choices-item not-in-stock"
                                            }>
                                            {name}
                                        </div>
                                    )
                                }
                            }
                        )}
                        {/* 'variants-choices-item' */}
                        {/* <div
                         className='variants-choices-item'
                         onClick={() => {
                             dispatch(selectVariants())
                         }}
                     >
                          option 1
                     </div>
                     <div  className='variants-choices-item'> option 2</div>
                     <div  className='variants-choices-item'> option 3</div>
                     <div className='variants-choices-item'> option 4</div>
                     <div  className='variants-choices-item'> option 5</div>
                     <div  className='variants-choices-item'> option 6</div> */}
                    </div>
                </div>
            </>

            {variantNotInStock.length === variants.length && (
                <div className="out-stocks">
                    Ce produit n'est malheureusement plus disponible{" "}
                </div>
            )}
        </div>
    )
}
export default VariantsChoice
