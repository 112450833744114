//Styles
import "./AddCardBar.scss"

// Hooks
import { useSelector, useDispatch } from "react-redux/es/exports"

//Action
import {
    saveProductDataState,
    toggleSelectProductChoice,
} from "../../../actions/productsActions.js"
import {
    createCartDisconnected,
    loadingAddCart,
    addOrderItemConnected,
} from "../../../actions/cartAction"
import {
    wishlistItem,
    putWishlistItem,
    deleteWishlistItem,
} from "../../../actions/userAction"

import { countQuantity } from "../../../selectors/selectors"

import Spinner from "../../Commons/Spinner/Spinner"
import { useNavigate } from "react-router-dom"

const AddCardBar = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const productData = useSelector((state) => state.products.productData)
    const variantAddCart = useSelector((state) => state.cart.loadingAddCart)
    const isVariantSelected = useSelector(
        (state) => state.products.variantSelected
    )
    const giftcardAmount = useSelector((state) => state.products.giftcardAmount)
    const userConnected = useSelector((state) => state.auth.userConnected)
    const cartData = useSelector((state) => state.cart.cartData)
    const customerId = useSelector((state) => state.user.customerId)
    const userId = useSelector((state) => state.user.userId)
    const wishlist = useSelector((state) => state.user.wishList)

    const productStillAdd = () => {
        if (productData.buy_once === true && cartData.items !== undefined) {
            if (cartData.items.length > 0) {
                return cartData.items.find(
                    (element) =>
                        element.ArtesaneProductVariant_code ===
                        productData.variants[0].code
                )
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const productStillAddWishlist = (wishlist, productId) => {
        if (Object.keys(wishlist).length > 0) {
            if (wishlist.wishlistProducts.length > 0) {
                return wishlist.wishlistProducts.find(
                    (element) => element.product.id === productId
                )
            } else {
                return false
            }
        } else {
            return false
        }
    }

    let variantNotInStock = []

    productData.variants.forEach((element) => {
        if (!element.inStock) {
            variantNotInStock.push(element)
        }
    })

    return (
        <div className="add-card-bar-wrapper">
            {variantNotInStock.length === productData.variants.length && (
                <div className="add-card-wrapper outStock">
                    <img
                        className="add-card-logo"
                        src={
                            "https://static.artesane.com/images_react/panier-blanc-37.png"
                        }
                        alt="panier"
                    />{" "}
                    <span>Ajouter au panier</span>
                </div>
            )}
            {productData.variants.find(
                (variant) => variant.code === variantAddCart
            ) && (
                <div className="spinner-product-wrapper">
                    <Spinner />
                </div>
            )}

            {productStillAdd() && (
                <div className="add-card-wrapper still">
                    <span>✓ Ajouté au panier</span>
                </div>
            )}

            {!productStillAdd() &&
                !(variantNotInStock.length === productData.variants.length) && (
                    <div
                        className="add-card-wrapper"
                        onClick={() => {
                            if (
                                productData.variants.length > 1 &&
                                isVariantSelected === ""
                            ) {
                                if (productData.giftCard) {
                                    if (giftcardAmount <= 0) {
                                        dispatch(
                                            saveProductDataState(
                                                "giftcardAmountError",
                                                true
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            loadingAddCart(
                                                productData.variants[0].code
                                            )
                                        )
                                        if (userConnected) {
                                            dispatch(
                                                addOrderItemConnected(
                                                    false,
                                                    productData.giftCardAmountConfigurable,
                                                    giftcardAmount,
                                                    customerId,
                                                    productData.variants[0].id,
                                                    productData.variants[0]
                                                        .code,
                                                    countQuantity(
                                                        productData.buy_once,
                                                        cartData,
                                                        isVariantSelected.code
                                                    )
                                                )
                                            )
                                        } else {
                                            dispatch(
                                                createCartDisconnected(
                                                    false,
                                                    productData.giftCardAmountConfigurable,
                                                    giftcardAmount,
                                                    productData.variants[0]
                                                        .product_id,
                                                    productData.variants[0].id,
                                                    productData.variants[0]
                                                        .code,
                                                    countQuantity(
                                                        productData.buy_once,
                                                        cartData,
                                                        isVariantSelected.code
                                                    )
                                                )
                                            )
                                        }
                                    }
                                } else {
                                    dispatch(toggleSelectProductChoice(true))
                                }
                            } else {
                                dispatch(
                                    loadingAddCart(productData.variants[0].code)
                                )
                                if (productData.variants.length > 1) {
                                    if (userConnected) {
                                        dispatch(
                                            addOrderItemConnected(
                                                false,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                customerId,
                                                isVariantSelected.id,
                                                isVariantSelected.code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    isVariantSelected.code
                                                )
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            createCartDisconnected(
                                                false,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                isVariantSelected.productId,
                                                isVariantSelected.id,
                                                isVariantSelected.code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    isVariantSelected.code
                                                )
                                            )
                                        )
                                    }
                                } else {
                                    if (userConnected) {
                                        dispatch(
                                            addOrderItemConnected(
                                                false,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                customerId,
                                                productData.variants[0].id,
                                                productData.variants[0].code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    productData.variants[0].code
                                                )
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            createCartDisconnected(
                                                false,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                productData.variants[0]
                                                    .product_id,
                                                productData.variants[0].id,
                                                productData.variants[0].code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    productData.variants[0].code
                                                )
                                            )
                                        )
                                    }
                                }
                            }
                        }}>
                        <img
                            className="add-card-logo"
                            src={
                                "https://static.artesane.com/images_react/panier-blanc-37.png"
                            }
                            alt=""
                        />{" "}
                        <span>Ajouter au panier</span>
                    </div>
                )}
            <div className="add-offer-and-wishlist-wrapper">
                {!(
                    variantNotInStock.length === productData.variants.length
                ) && (
                    <div
                        className="add-offer-wrapper"
                        onClick={() => {
                            if (
                                productData.variants.length > 1 &&
                                isVariantSelected === ""
                            ) {
                                if (productData.giftCard) {
                                    if (giftcardAmount <= 0) {
                                        dispatch(
                                            saveProductDataState(
                                                "giftcardAmountError",
                                                true
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            loadingAddCart(
                                                productData.variants[0].code
                                            )
                                        )
                                        if (userConnected) {
                                            dispatch(
                                                addOrderItemConnected(
                                                    true,
                                                    productData.giftCardAmountConfigurable,
                                                    giftcardAmount,
                                                    customerId,
                                                    productData.variants[0].id,
                                                    productData.variants[0]
                                                        .code,
                                                    countQuantity(
                                                        productData.buy_once,
                                                        cartData,
                                                        isVariantSelected.code
                                                    )
                                                )
                                            )
                                        } else {
                                            dispatch(
                                                createCartDisconnected(
                                                    true,
                                                    productData.giftCardAmountConfigurable,
                                                    giftcardAmount,
                                                    productData.variants[0]
                                                        .product_id,
                                                    productData.variants[0].id,
                                                    productData.variants[0]
                                                        .code,
                                                    countQuantity(
                                                        productData.buy_once,
                                                        cartData,
                                                        isVariantSelected.code
                                                    )
                                                )
                                            )
                                        }
                                    }
                                } else {
                                    dispatch(toggleSelectProductChoice(true))
                                }
                            } else {
                                dispatch(
                                    loadingAddCart(productData.variants[0].code)
                                )
                                if (productData.variants.length > 1) {
                                    if (userConnected) {
                                        dispatch(
                                            addOrderItemConnected(
                                                true,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                customerId,
                                                isVariantSelected.id,
                                                isVariantSelected.code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    isVariantSelected.code
                                                )
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            createCartDisconnected(
                                                true,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                isVariantSelected.productId,
                                                isVariantSelected.id,
                                                isVariantSelected.code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    isVariantSelected.code
                                                )
                                            )
                                        )
                                    }
                                } else {
                                    if (userConnected) {
                                        dispatch(
                                            addOrderItemConnected(
                                                true,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                customerId,
                                                productData.variants[0].id,
                                                productData.variants[0].code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    productData.variants[0].code
                                                )
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            createCartDisconnected(
                                                true,
                                                productData.giftCardAmountConfigurable,
                                                giftcardAmount,
                                                productData.variants[0]
                                                    .product_id,
                                                productData.variants[0].id,
                                                productData.variants[0].code,
                                                countQuantity(
                                                    productData.buy_once,
                                                    cartData,
                                                    productData.variants[0].code
                                                )
                                            )
                                        )
                                    }
                                }
                            }
                        }}>
                        <img
                            className="add-card-logo"
                            src={
                                "https://static.artesane.com/images_react/cadeau-jaune-38.png"
                            }
                            alt="offrir"
                        />
                        <span>Offrir</span>
                    </div>
                )}
                {!userConnected && (
                    <div
                        onClick={() => {
                            dispatch(wishlistItem([productData.id]))
                            dispatch(wishlistItem([productData.id]))
                            return navigate("/connexion")
                        }}
                        className="add-wishList-wrapper">
                        <img
                            className="add-card-logo"
                            src={
                                "https://static.artesane.com/images_react/coeur-vide-jaune-39.png"
                            }
                            alt="coeur vide jaune"
                        />
                    </div>
                )}
                {userConnected && (
                    <div
                        onClick={() => {
                            dispatch(
                                loadingAddCart(productData.variants[0].code)
                            )

                            if (
                                productStillAddWishlist(
                                    wishlist,
                                    productData.id
                                )
                            ) {
                                dispatch(
                                    loadingAddCart(productData.variants[0].code)
                                )
                                dispatch(
                                    deleteWishlistItem(
                                        wishlist.token,
                                        productData.id
                                    )
                                )
                            } else {
                                dispatch(
                                    loadingAddCart(productData.variants[0].code)
                                )
                                dispatch(
                                    putWishlistItem(userId, productData.id)
                                )
                            }
                        }}
                        className="add-wishList-wrapper">
                        <img
                            className="add-card-logo"
                            src={
                                productStillAddWishlist(
                                    wishlist,
                                    productData.id
                                )
                                    ? "https://static.artesane.com/images_react/coeur-plein-jaune.png"
                                    : "https://static.artesane.com/images_react/coeur-vide-jaune-39.png"
                            }
                            alt="coeur"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default AddCardBar
