//Components
import Header from "../Home/Header/Header"
import NavLogo from "./NavLogo/NavLogo"
import TopSlider from "./TopSlider/TopSlider"
import LessonSlider from "./LessonsSlider/LessonsSlider"
import NewsLetter from "./NewsLetter/NewsLetter"
import ProfessorSlider from "./ProfessorSlider/ProfessorSlider"
import ClientsAdvices from "./ClientsAdvices/ClientsAdvices"
import Catalogue from "./Catalogue/Catalogue"
import Faq from "./Faq/Faq"
import Soul from "./Soul/Soul"
import VideoCard from "../Prepage/VideoCard/VideoCard"
import Banner from "../Banner/Banner"

//Hooks
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

//Style
import "./Home.scss"

//Actions
import {
    fetchFaqs,
    saveHomeDataInState,
} from "../../actions/homeActions"
import NotFound from "../Commons/NotFound/NotFound"
import SeoText from "./SeoText/SeoText"
import {
    fetchDataByUniverseAndPage,
    saveCommonDataInState,
} from "../../actions/commonAction"
import Spinner from "../Commons/Spinner/Spinner"

const Home = () => {
    const { univers } = useParams()
    const dispatch = useDispatch()
    const isLoadingLessonsSlider = useSelector(
        (state) => state.home.isLoadingLessonsSlider
    )
    const isLoadingTopSlider = useSelector(
        (state) => state.home.isLoadingTopSlider
    )
    const allUniverses = useSelector((state) => state.common.allUniverses)
    const loadingAllUniverses = useSelector(
        (state) => state.common.loadingAllUniverses
    )
    const loadingDataByUniverse = useSelector(
        (state) => state.common.loadingDataByUniverse
    )

    const isLoadingMegaMenu = useSelector(
        (state) => state.home.isLoadingMegaMenu
    )

    const navData = useSelector((state) => state.home.navData)

    useEffect(() => {
        if (!loadingAllUniverses) {
            dispatch(fetchFaqs())
            dispatch(saveHomeDataInState("isLoadingMegaMenu", true))
            dispatch(saveCommonDataInState("loadingDataByUniverse", true))
            dispatch(fetchDataByUniverseAndPage(univers, "home"))
        }
    }, [dispatch, univers, loadingAllUniverses])

    useEffect(() => {
        document.title =
            "Artesane - Apprenez à créer | L'univers " +
            (univers === "arts-du-fil"
                ? "Arts du fil "
                : univers === "arts-culinaires"
                  ? "Arts culinaires"
                  : "Arts plastiques")
        const metaDescription = document.querySelector(
            "meta[name='description']"
        )
        metaDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaKeywords = document.querySelector("meta[name='keywords']")
        metaKeywords.setAttribute(
            "content",
            "cours de couture en ligne, cours de broderie en ligne, cours de tricot en ligne, couture, video couture online, cours de couture video, apprendre à coudre en ligne, modelisme, stylisme, CAP couture, cours de cuisine en ligne, arts culinaires, pâtisserie, macarons, macaron, tartes, tarte, cuisson, cuissons, cuisine végétale, vegan, végétarienne, végétalienne, cuisines asiatiques, cuisine japonnaise, cuisine chinoise, cuisine italienne, arts plastiques, céramique, dessin, dessins, aquarelle, apprendre à peindre, peinture, arts du fil, broderie or, haute couture, tricot, couture homme, tailleurs"
        )
        const metaOGDescription = document.querySelector(
            "meta[property='og:description']"
        )
        metaOGDescription.setAttribute(
            "content",
            "Prenez des cours d'arts culinaires, d'arts du fil ou bien d'arts plastiques en ligne! Avec notre panel de cours, nous sommes votre premier atelier de loisirs créatifs exclusivement sur internet."
        )
        const metaOGTitle = document.querySelector("meta[property='og:title']")
        metaOGTitle.setAttribute(
            "content",
            "Artesane - Apprenez à créer | L'univers " +
                (univers === "arts-du-fil"
                    ? "Arts du fil "
                    : univers === "arts-culinaires"
                      ? "Arts culinaires"
                      : "Arts plastiques")
        )
        const metaOGUrl = document.querySelector("meta[property='og:url']")
        metaOGUrl.setAttribute(
            "content",
            "https://www.artesane.com" + window.location.pathname
        )
        const linkCanonical = document.querySelector("link[rel='canonical']")
        linkCanonical.setAttribute(
            "href",
            "https://www.artesane.com" + window.location.pathname
        )
        const metaRobots = document.querySelector("meta[name='robots']")
        metaRobots.setAttribute("content", "index, follow")
    }, [univers])

    // Routage for 404 or not

    return (
        <div className="home-wrapper">
            <Banner univers={univers} />
            <Header />
            {!loadingAllUniverses && !loadingDataByUniverse && (
                <>
                    {allUniverses.find((item) => item.code === univers) && (
                        <>
                            {!isLoadingLessonsSlider &&
                                !isLoadingTopSlider &&
                                !isLoadingLessonsSlider &&
                                !isLoadingMegaMenu && (
                                    <>
                                        <TopSlider />

                                        <div className="navlogo-wrapper">
                                            {navData.map(({ name, slug }) => {
                                                if (
                                                    name !==
                                                        "Explorez notre catalogue" &&
                                                    name !==
                                                        "explorez notre catalogue" &&
                                                    name !== "Notre sélection"
                                                ) {
                                                    return (
                                                        <NavLogo
                                                            key={slug}
                                                            currentUnivers={
                                                                univers
                                                            }
                                                            logo={() => {
                                                                if (
                                                                    name ===
                                                                    "Dessin et peinture"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/peinture.png"
                                                                } else if (
                                                                    name ===
                                                                    "Céramique"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/poterie.png"
                                                                } else if (
                                                                    name ===
                                                                    "Photographie"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/photo.png"
                                                                } else if (
                                                                    name ===
                                                                    "Illustration numérique"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/dessin-num%C3%A9rique.png"
                                                                } else if (
                                                                    name ===
                                                                    "Couture"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/couture.png"
                                                                } else if (
                                                                    name ===
                                                                    "Broderie"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/broderie.png"
                                                                } else if (
                                                                    name ===
                                                                    "Arts de la laine"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/tricot.png"
                                                                } else if (
                                                                    name ===
                                                                    "Modélisme et stylisme"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/modelisme.png"
                                                                } else if (
                                                                    name ===
                                                                    "Cuisine"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/cuisine.png"
                                                                } else if (
                                                                    name ===
                                                                    "Pâtisserie et boulangerie"
                                                                ) {
                                                                    return "https://static.artesane.com/images_react/patisserie.png"
                                                                } else {
                                                                    return "https://static.artesane.com/images_react/couture.png"
                                                                }
                                                            }}
                                                            altImg={
                                                                "bobine de fil"
                                                            }
                                                            title={name}
                                                            filterAffectation={
                                                                slug
                                                            }
                                                        />
                                                    )
                                                }
                                                return null
                                            })}
                                        </div>
                                        <LessonSlider
                                            currentUnivers={univers}
                                        />
                                        <NewsLetter />
                                        <ProfessorSlider univers={univers} />
                                        <ClientsAdvices />
                                        {navData.length > 0 && <Catalogue />}

                                        <Faq />
                                        <Soul />
                                        <VideoCard />
                                        <SeoText />
                                    </>
                                )}
                        </>
                    )}

                    {!allUniverses.find((item) => item.code === univers) && (
                        <NotFound />
                    )}
                    {(isLoadingLessonsSlider ||
                        isLoadingTopSlider ||
                        isLoadingLessonsSlider ||
                        isLoadingMegaMenu) && (
                        <div className="home-wrapper">
                            <Spinner />
                        </div>
                    )}
                </>
            )}
            {(loadingAllUniverses || loadingDataByUniverse) && <Spinner />}
        </div>
    )
}

export default Home
