//Hooks
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

//Action
import {
    addFiltersCheckbox,
    saveCurrentPageCatalogue,
    saveNumberProduct,
    saveCatalogueLessonsInState,
} from "../../../../../../actions/catalogueAction"
import {
    filterProduct,
    checkFiltersOrNot,
    countOtherFiltersResult,
    checkFiltersName,
} from "../../../../../../selectors/selectors"

//Styles
import "./FilterChoiceInput.scss"

const FilterChoiceInput = ({
    nom,
    id,
    nombreProduits,
    parentFilterId,
    parentFilterName,
}) => {
    const dispatch = useDispatch()
    const filtersChecked = useSelector(
        (state) => state.catalogue.filtersCheckboxInput
    )
    const nameFiltersChecked = useSelector(
        (state) => state.catalogue.filterCheckBoxInputName
    )
    const allProduct = useSelector((state) => state.catalogue.allProduct)
    const priceValuesSlider = useSelector((state) => state.catalogue.priceValue)
    const sortActifValue = useSelector(
        (state) => state.catalogue.sortActifValue
    )
    const lessonsData = useSelector((state) => state.catalogue.catalogueData)
    const currentUnivers = useParams().univers
    const currentFilters = useParams().currentFilters
    let navigate = useNavigate()

    const isChecked = (filtersChecked, id) => {
        let isTrue = false

        filtersChecked.forEach((element) => {
            element.children.forEach((childId) => {
                if (childId === id) {
                    isTrue = true
                }
            })
        })

        return isTrue
    }

    return (
        <div key={id} className="filters-sort-under-items-wrapper">
            <input
                onChange={() => {
                    dispatch(saveNumberProduct(nombreProduits))
                    navigate(
                        `/${currentUnivers}/catalogue/${currentFilters}/1`,
                        { replace: true }
                    )
                    dispatch(saveCurrentPageCatalogue(1))

                    let newfilters = checkFiltersOrNot(
                        filtersChecked,
                        parentFilterId,
                        id,
                        parentFilterName,
                        nom
                    )
                    let newNameFilters = checkFiltersName(
                        nameFiltersChecked,
                        id,
                        parentFilterId,
                        parentFilterName,
                        nom
                    )
                    dispatch(
                        saveCatalogueLessonsInState(
                            filterProduct(
                                newfilters,
                                allProduct,
                                priceValuesSlider,
                                sortActifValue,
                                []
                            ),
                            filterProduct(
                                newfilters,
                                allProduct,
                                priceValuesSlider,
                                sortActifValue,
                                []
                            ).length,
                            allProduct
                        )
                    )
                    dispatch(addFiltersCheckbox(newfilters, newNameFilters))
                }}
                checked={isChecked(filtersChecked, id)}
                id={nom}
                key={nom}
                className="filters-sort-under-items-input"
                type="checkbox"
            />
            <label
                onClick={() => {
                    navigate(
                        `/${currentUnivers}/catalogue/${currentFilters}/1`,
                        { replace: true }
                    )

                    dispatch(saveCurrentPageCatalogue(1))

                    let newfilters = checkFiltersOrNot(
                        filtersChecked,
                        parentFilterId,
                        id,
                        parentFilterName,
                        nom
                    )
                    let newNameFilters = checkFiltersName(
                        nameFiltersChecked,
                        id,
                        parentFilterId,
                        parentFilterName,
                        nom
                    )

                    dispatch(
                        saveCatalogueLessonsInState(
                            filterProduct(
                                newfilters,
                                allProduct,
                                priceValuesSlider,
                                sortActifValue,
                                []
                            ),
                            filterProduct(
                                newfilters,
                                allProduct,
                                priceValuesSlider,
                                sortActifValue,
                                []
                            ).length,
                            allProduct
                        )
                    )
                    dispatch(addFiltersCheckbox(newfilters, newNameFilters))
                }}
                key={id}>
                {nom}{" "}
                <span className="choice-items-number">
                    {"(" +
                        countOtherFiltersResult(
                            filtersChecked,
                            {
                                parent: parentFilterId,
                                id: id,
                                parentName: parentFilterName,
                                name: nom,
                            },
                            allProduct,
                            lessonsData,
                            priceValuesSlider
                        ) +
                        ")"}
                </span>
            </label>
        </div>
    )
}

export default FilterChoiceInput
