//Actions
import {
    SAVE_CATALOGUE_LESSONS_IN_STATE,
    TOGGLE_IS_FILTER_ACTIF,
    TOGGLE_FILTER_NAME_ACTIF,
    TOGGLE_IS_SORT_ACTIF,
    SAVE_CURRENT_PAGE_CATALOGUE,
    SAVE_FILTERS,
    TOGGLE_NUMBER_FILTERS_VISIBLE,
    ADD_FILTERS_CHECKBOX,
    REMOVE_ALL_FILTERS,
    TOGGLE_LOADING,
    CHANGE_SORT_ACTIF_VALUE,
    CHANGE_PRICE_SLIDER_VALUE,
    TOGGLE_404,
    SAVE_PRODUCT_VARIANTS,
    SAVE_NUMBER_PRODUCT,
    SAVE_SEARCH_OBJECT,
    SAVE_TEACHERS_FILTERS,
    SAVE_CATEGORIES_MENU,
    SAVE_PAGELIST,
    SAVE_CATALOGUE_H1,
} from "../actions/catalogueAction"

const initialState = {
    catalogueData: [],
    currentFilterActif: " ",
    isFilterActif: false,
    isSortActif: false,
    isTriActif: false,
    filterNameActif: "",
    numberOfItems: 0,
    filters: [],
    teachersFilter: [],
    filterVisible: 5,
    filtersCheckboxInput: [],
    filterCheckBoxInputName: [],
    filterObject: {},
    loaderLessons: true,
    sortActifValue: "PlusRecent",
    currentPage: 1,
    indexDebutProduit: 0,
    indexFinProduit: 32,
    priceValue: [0, 600],
    pageNotFound: false,
    productVariants: [],
    numberProduct: [],
    searchObject: {},
    menuFind: [],
    allProduct: [],
    pagelists: [],
    catalogueDescription: "",
    catalogueTitle: "",
    catalogueName: "",
}

const catalogueReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_CATALOGUE_H1:
            return {
                ...state,
                catalogueTitle: action.value,
                catalogueName: action.name,
            }
        case SAVE_PAGELIST:
            return {
                ...state,
                pagelists: action.value,
            }

        case SAVE_CATEGORIES_MENU:
            return {
                ...state,
                menuFind: action.value,
                catalogueDescription: action.description,
            }

        case SAVE_SEARCH_OBJECT:
            return {
                ...state,
                searchObject: action.value,
            }

        case SAVE_NUMBER_PRODUCT:
            return {
                ...state,
                numberProduct: action.value,
            }

        case SAVE_PRODUCT_VARIANTS:
            return {
                ...state,
                productVariants: action.value,
            }

        case TOGGLE_404:
            return {
                ...state,
                pageNotFound: action.value,
            }

        case CHANGE_PRICE_SLIDER_VALUE:
            return {
                ...state,
                priceValue: action.value,
            }

        case CHANGE_SORT_ACTIF_VALUE:
            return {
                ...state,
                sortActifValue: action.value,
            }

        case TOGGLE_LOADING:
            return {
                ...state,
                loaderLessons: action.value,
            }

        case REMOVE_ALL_FILTERS:
            return {
                ...state,
                filtersCheckboxInput: [],
                filterCheckBoxInputName: [],
            }
        case ADD_FILTERS_CHECKBOX: {
            return {
                ...state,
                filtersCheckboxInput: action.value,
                filterCheckBoxInputName: action.names,
            }
        }

        case TOGGLE_NUMBER_FILTERS_VISIBLE:
            return {
                ...state,
                filterVisible: action.value,
            }
        case SAVE_FILTERS:
            return {
                ...state,
                filters: action.value,
            }

        case SAVE_TEACHERS_FILTERS:
            return {
                ...state,
                teachersFilter: action.value,
            }
        case SAVE_CATALOGUE_LESSONS_IN_STATE:
            return {
                ...state,
                catalogueData: [...action.value],
                numberOfItems: action.number,
                allProduct: [...action.allProduct],
            }
        case SAVE_CURRENT_PAGE_CATALOGUE:
            return {
                ...state,
                currentPage: action.value,
            }
        case TOGGLE_IS_FILTER_ACTIF:
            return {
                ...state,
                isFilterActif: action.value,
            }
        case TOGGLE_IS_SORT_ACTIF:
            return {
                ...state,
                isSortActif: action.value,
            }
        case TOGGLE_FILTER_NAME_ACTIF:
            return {
                ...state,
                filterNameActif: action.name,
            }

        default:
            return state
    }
}
export default catalogueReducer
