import {
    COUNT_CART,
    LOADING_ADD_CART,
    LOADING_DELETE_MODIFY_CART,
    SAVE_CART_IN_STATE,
    SELECT_VARIANTS_CATALOGUE,
    TOGGLE_VARIANTS_CHOICE,
    VALIDATE_VARIANTS_ADD,
    ASSOCIATIONS_SLIDER_CHANGE_INDEX,
    TOGGLE_BILLING_ADDRESS,
    CHANGE_INPUT_ORDER,
    SAVE_CART_ASSOCIATION,
} from "../actions/cartAction"

const initialState = {
    popUpVariantsChoiceCatalogue: 0,
    variantsSelectedCatalogue: "",
    addCarVariantsValidate: false,
    cartData: [],
    cartDataNumber: 0,
    loadingAddCart: "",
    loadingDeleteOrModifyItemCart: "",
    cartTokenUser: "",
    associationsSliderIndex: 0,
    sameAddressAsShippingAddress: true,
    addressSelectCarnet: 0,
    //ShippingAdress
    lastNameShipping: "",
    firstNameShipping: "",
    societyShipping: "",
    streetShipping: "",
    postCodeShipping: "",
    cityShipping: "",
    countryShipping: "FR",
    telephoneShipping: "",
    errorTelephoneShipping: false,
    //BillingAddress
    lastNameBilling: "",
    firstNameBilling: "",
    societyBilling: "",
    streetBilling: "",
    postCodeBilling: "",
    cityBilling: "",
    countryBilling: "FR",
    telephoneBilling: "",
    errorTelephoneBilling: false,
    loadingValidateAddress: true,
    addressValidate: false,
    cartAssociation: [],
    payplugUrl: "",
    chooseDelivery: false,
    shipments: [],
    shipmentSelected: 0,
    choosePayment: false,
    validatePayment: false,
    paymentSelected: 0,
    couponCode: "",
    orderAdjustments: [],
    orderItemAdjustmentsTotal: 0,
    orderItemAdjustments: [],
    loadingTotalOrder: false,
    loadingCart: true,
    loadingAdjustments: false,
    messageRetourCoupon: "",
    giftCardSelected: 0,
    oneySimulationLoading: true,
    oneySimulationPopup: false,
    oneySimulation: {},
    oneyChoosePayment: false,
    selectPaymentCode: "",
    oneyActive: "oney_x3_with_fees",
    redirectPaiement: false,
    validateChoosePayment: false,
    loadingAddPayment: false,
    redirectPaiementWindow: false,
    urlPayment: "",
    giftInformation: false,
    openMondialRelayWidget: false,
    isMondialRelayWidgetLoading : true,
    mondialRelayId : null,
    mondialRelayName : null,
    mondialRelayStreet: null,
    mondialRelayPostCode : null,
    mondialRelayCity : null,
    mondialRelayCountryCode: null,
    modifyAddressShipping: false,
    modiftAddressBilling : false,
    loadingAssociationProduct : true,
    loadingCartAfterConnexion : false,
}

const cartReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_CART_ASSOCIATION:
            return {
                ...state,
                cartAssociation: action.value,
            }

        case CHANGE_INPUT_ORDER:
            return {
                ...state,
                [action.name]: action.value,
            }

        case TOGGLE_BILLING_ADDRESS:
            return {
                ...state,
                sameAddressAsShippingAddress: action.value,
            }

        case ASSOCIATIONS_SLIDER_CHANGE_INDEX:
            return {
                ...state,
                associationsSliderIndex: action.value,
            }

        case LOADING_DELETE_MODIFY_CART:
            return {
                ...state,
                loadingDeleteOrModifyItemCart: action.value,
            }

        case LOADING_ADD_CART:
            return {
                ...state,
                loadingAddCart: action.value,
            }

        case SAVE_CART_IN_STATE:
            return {
                ...state,
                cartData: action.value,
            }

        case COUNT_CART:
            return {
                ...state,
                cartDataNumber: action.value,
            }

        case VALIDATE_VARIANTS_ADD:
            return {
                ...state,
                addCarVariantsValidate: action.value,
            }

        case SELECT_VARIANTS_CATALOGUE:
            return {
                ...state,
                variantsSelectedCatalogue: action.value,
            }

        case TOGGLE_VARIANTS_CHOICE:
            return {
                ...state,
                popUpVariantsChoiceCatalogue: action.value,
            }

        default:
            return state
    }
}
export default cartReducer
