//Libraries
import AliceCarousel from "react-alice-carousel"

//Action
import { productSliderChangeIndex } from "../../../actions/productsActions.js"

//Hooks
import { useSelector, useDispatch } from "react-redux/es/exports"

//Libraries
import ReactPlayer from "react-player"

//Styles
import "./ProductSlider.scss"
import { sortMediaProduct } from "../../../selectors/selectors.js"

const handleDragStart = (e) => e.preventDefault()

const ProductSlider = ({ productData }) => {
    const activeIndex = useSelector(
        (state) => state.products.productSliderIndex
    )
    const dispatch = useDispatch()
    const productMedia = sortMediaProduct(
        productData.images,
        productData.trailers,
        "default"
    )

    return (
        <div className="products-slider-wrapper">
            <div className="product-slider-miniature-wrapper">
                <AliceCarousel
                    activeIndex={activeIndex}
                    onSlideChange={({ item }) => {
                        dispatch(productSliderChangeIndex(item))
                    }}
                    items={productMedia.map((item, index) => {
                        if (item["@type"].includes("Image")) {
                            return (
                                <div
                                    onDragStart={handleDragStart}
                                    className="products-slider-image-wrapper">
                                    <img
                                        src={item.thumbnails.carousel}
                                        alt={item.alt}
                                    />
                                </div>
                            )
                        } else if (item["@type"].includes("Trailer")) {
                            return (
                                <>
                                    <div className="video-filter-drag-top">
                                        {" "}
                                    </div>
                                    <div
                                        onDragStart={handleDragStart}
                                        className="product-slider-video-wrapper">
                                        <ReactPlayer
                                            url={item.url}
                                            muted={false}
                                            loop={false}
                                            light={item.light}
                                            width={"100%"}
                                            height={"100%"}
                                            playIcon={
                                                <img
                                                    className="player-logo"
                                                    src={
                                                        "https://static.artesane.com/images_react/white_player.png"
                                                    }
                                                    alt="player"
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="video-filter-drag-bottom">
                                        {" "}
                                    </div>
                                </>
                            )
                        }
                        return null
                    })}
                    responsive={{
                        0: {
                            items: 1,
                        },

                        1024: {
                            items: 1,
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default ProductSlider
