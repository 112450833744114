//Components
import Logo from "../../Commons/Logo/Logo"
import NavBarMutual from "./NavBarMutual/NavBarMutual"
import UnderNavbar from "./UnderNavBar/UnderNavBar"
import MenuBurger from "./MenuBurger/MenuBurger"

//Hooks
import { NavLink, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

//Libraries
import MediaQuery from "react-responsive"

//Action
import { saveHomeUniversLocationActif } from "../../../actions/homeActions"

//Style
import "./Header.scss"
import { useEffect, useRef } from "react"
import {
    fetchDataByUniverseAndPage,
    saveCommonDataInState,
} from "../../../actions/commonAction"

const Header = () => {
    const dispatch = useDispatch()
    const homeUniversActif = useSelector((state) => state.home.homeUniversActif)
    const { univers } = useParams()

    const { currentFilters } = useParams()
    const isLoadingMegamenu = useSelector(
        (state) => state.home.isLoadingMegaMenu
    )
    const isLoadingAllUniverse = useSelector(
        (state) => state.common.loadingAllUniverses
    )
    const allUniverses = useSelector((state) => state.common.allUniverses)
    const isStickiheader = useSelector((state) => state.common.isStickiheader)

    useEffect(() => {
        dispatch(saveHomeUniversLocationActif(univers))
        dispatch(
            fetchDataByUniverseAndPage(univers, "mega-menu", currentFilters)
        )
    }, [dispatch, univers, currentFilters])

    const stickyElementRef = useRef(null)

    const handleScroll = () => {
        if (stickyElementRef.current) {
            const rect = stickyElementRef.current.getBoundingClientRect()
            dispatch(saveCommonDataInState("isStickiheader", rect.top === 0))
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        // Initial check
        handleScroll()

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll])

    return (
        <>
            <div className="header-mutual" ref={stickyElementRef}>
                {!isLoadingMegamenu && (
                    <MediaQuery maxWidth={1000}>
                        <MenuBurger />
                    </MediaQuery>
                )}
                <div
                    className={`header-mutual-wrapper-scroll ${isStickiheader ? "height" : ""}`}>
                    <div className="header-menu">
                        {!isLoadingAllUniverse && (
                            <>
                                {allUniverses.map((item) => (
                                    <div
                                        className="header-menu-item"
                                        key={item.name}>
                                        <NavLink to={"/" + item.code}>
                                            {" "}
                                            {item.name}{" "}
                                        </NavLink>
                                    </div>
                                ))}
                            </>
                        )}
                        <div className="header-menu-item">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.artesane.com/le-journal/artesane-formation-pro">
                                Espace formation pro
                            </a>
                        </div>
                    </div>
                    {/* {!isStickiheader && ( */}
                    <NavLink to={`/` + homeUniversActif}>
                        <Logo />
                    </NavLink>
                    {/* )} */}

                    <NavBarMutual />
                </div>

                {/* {!isStickiheader && ( */}
                <UnderNavbar />
                {/* )} */}
            </div>
        </>
    )
}

export default Header
