//Styles
import "./ToggleButton.scss"

import { useDispatch, useSelector } from "react-redux"

import { toggleAccordion } from "../../../../actions/homeActions"

const ToggleButton = ({ texte, myRef, itemsLength }) => {
    const isOpenAccordion = useSelector((state) => state.home.accordionExpended)
    const dispatch = useDispatch()
    return (
        <button
            className="simple-button"
            onClick={() => {
                if (isOpenAccordion) {
                    window.scrollTo({
                        top: myRef.current.offsetTop - 140,
                        behavior: "smooth",
                    })
                }
                dispatch(
                    toggleAccordion(
                        !isOpenAccordion,
                        isOpenAccordion ? 8 : itemsLength
                    )
                )
            }}>
            {texte}
        </button>
    )
}

export default ToggleButton
