import "./PaginationContent.scss"

//Hooks

import Pagination from "@mui/material/Pagination"
import PaginationItem from "@mui/material/PaginationItem"
import { NavLink } from "react-router-dom"
import { useDispatch } from "react-redux"

const PaginationContent = ({ currentPage, count, url, actionToDispatch }) => {
    const dispatch = useDispatch()

    return (
        <div className="pagination-wrapper">
            <Pagination
                page={Number(currentPage)}
                count={count}
                renderItem={(item) => {
                    // Vérifiez si la page actuelle est inférieure à 1 ou supérieure au nombre total de pages
                    if (item.page < 1 || item.page > count) {
                        return <PaginationItem component={"div"} {...item} />
                    }
                    return (
                        <PaginationItem
                            component={NavLink}
                            to={
                                item.page === 1
                                    ? `${url}/1`
                                    : `${url}/${item.page}`
                            }
                            disabled={
                                item.type === "previous"
                                    ? currentPage === 1
                                    : item.type === "next"
                                      ? currentPage === count
                                      : false
                            }
                            {...item}
                        />
                    )
                }}
                onChange={(event, value) => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    })
                    dispatch(actionToDispatch(value))
                }}
            />
        </div>
    )
}

export default PaginationContent
