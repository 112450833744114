export const TOGGLE_DROPDOWN = "TOGGLE_DROPDOWN"
export const TOGGLE_SEARCHBAR = "TOGGLE_SEARCHBAR"
export const CHANGE_SEARCH_BAR = "CHANGE_SEARCH_BAR"
export const TOGGLE_BURGER = "TOGGLE_BURGER"

export const toggleBurger = (newValue) => ({
    type: TOGGLE_BURGER,
    value: newValue,
})

export const toggleDropown = (newValue) => ({
    type: TOGGLE_DROPDOWN,
    value: newValue,
})

export const toggleSearchbar = (newValue) => ({
    type: TOGGLE_SEARCHBAR,
    value: newValue,
})

export const changeSearchbar = (newValue) => ({
    type: CHANGE_SEARCH_BAR,
    value: newValue,
})
