//Styles
import "./Button.scss"

//Hooks
import { useSelector } from "react-redux"

const Button = ({ onClick, title, classname }) => {
    const isFilterActif = useSelector((state) => state.catalogue.isFilterActif)

    return (
        <div
            className={
                isFilterActif
                    ? `filters-button-wrapper ${classname}`
                    : "filters-button-wrapper-closed"
            }>
            <button className="filters-button" onClick={onClick}>
                {title}
            </button>
        </div>
    )
}

export default Button
