//Hooks
import { NavLink } from "react-router-dom"

//Components
import FooterResponsiveItems from "./FooterResponsiveItems/FooterResponsiveItems"

//Styles
import "./FooterResponsive.scss"

//Libraries
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const FooterResponsive = () => {
    let date = new Date()
    return (
        <div className="footer-wrapper-responsive">
            <div className="footer-newsletter-icon-wrapper-responsive">
                <div className="footer-newsletter-wrapper-responsive">
                    <div className="footer-newsletter-title-responsive">
                        Abonnez-vous à la newsletter
                    </div>
                    {/* <form
                            name="mc-embedded-subscibe-form"
                            rel={"noopener"}
                            // noValidate
                            action="https://artesane.us13.list-manage.com/subscribe/post"
                            target="_blank"
                            method="post"
                            className="newsletter-footer-form-responsive"
                            // onSubmit={(event) => {
                            //     event.preventDefault();
                            // }}
                        >
                            <input type="hidden" name="u" value={"fed3adae0edf9e9d89854e25d"} />
                            <input type="hidden" name="id" value={"698c1acbba"} />
                            <input
                                className="newsletter-footer-input-responsive"
                                placeholder="Mon e-mail"
                                type="email" 
                                id="email"
                                size="30"
                                name={"EMAIL"}
                                required
                            />
                            <div>Choisissez vos univers préférés</div>
                            <button 
                                className="newsletter-footer-button-responsive"
                                type="submit"
                            >   
                                je m'inscris
                            </button>
                        </form> */}

                    <form
                        name="mc-embedded-subscibe-form"
                        rel={"noopener"}
                        // noValidate
                        action="https://artesane.us13.list-manage.com/subscribe/post?u=fed3adae0edf9e9d89854e25d&amp;id=698c1acbba&amp;f_id=00f3c2e1f0"
                        target="_blank"
                        method="post"
                        className="newsletter-footer-form-responsive"
                        id="mc-embedded-subscribe-form">
                        {/* <input type="hidden" name="u" value={"fed3adae0edf9e9d89854e25d"} />
                            <input type="hidden" name="id" value={"698c1acbba"} /> */}
                        <input
                            className="newsletter-footer-input-responsive"
                            placeholder="Mon e-mail"
                            type="email"
                            id="mce-EMAIL"
                            size="30"
                            name={"EMAIL"}
                            required
                        />
                        <div>Choisissez vos univers préférés :</div>
                        <ul>
                            <li>
                                <input
                                    type="checkbox"
                                    value="2"
                                    name="group[15][2]"
                                    id="mce-group[15]-15-0"
                                />
                                <label htmlFor="mce-group[15]-15-0">
                                    Arts du fil
                                </label>
                            </li>
                            <li>
                                <input
                                    type="checkbox"
                                    value="4"
                                    name="group[15][4]"
                                    id="mce-group[15]-15-1"
                                />
                                <label htmlFor="mce-group[15]-15-1">
                                    Arts plastiques
                                </label>
                            </li>
                            <li>
                                <input
                                    type="checkbox"
                                    value="8"
                                    name="group[15][8]"
                                    id="mce-group[15]-15-2"
                                />
                                <label htmlFor="mce-group[15]-15-2">
                                    Arts culinaires
                                </label>
                            </li>
                        </ul>
                        <button
                            className="newsletter-footer-button-responsive"
                            type="submit">
                            je m'inscris
                        </button>
                    </form>
                </div>
                <div className="footer-icon-wrapper-responsive">
                    <div className="footer-icon-responsive">
                        <a
                            target="_blank"
                            href="https://www.instagram.com/artesane_france/?hl=fr"
                            rel="noreferrer">
                            <img
                                className="footer-logo"
                                src={
                                    "https://static.artesane.com/images_react/footer-insta.png"
                                }
                                alt="instagram"
                            />
                        </a>
                    </div>
                    <div className="footer-icon-responsive">
                        <a
                            target="_blank"
                            href="https://www.facebook.com/artesanefrance/"
                            rel="noreferrer">
                            <img
                                className="footer-logo"
                                src={
                                    "https://static.artesane.com/images_react/footer-facebook.png"
                                }
                                alt="facebook"
                            />
                        </a>
                    </div>
                    <div className="footer-icon-responsive">
                        <a
                            target="_blank"
                            href="https://www.youtube.com/c/Artesane_lesvideos/videos"
                            rel="noreferrer">
                            <img
                                id="youtube"
                                className="footer-logo"
                                src={
                                    "https://static.artesane.com/images_react/footer-youtube.png"
                                }
                                alt="youtube"
                            />
                        </a>
                    </div>
                    <div className="footer-icon-responsive">
                        <a
                            target="_blank"
                            href="https://www.pinterest.fr/ArtesaneFrance/"
                            rel="noreferrer">
                            <img
                                className="footer-logo"
                                src={
                                    "https://static.artesane.com/images_react/footer-pinterest.png"
                                }
                                alt="pinterest"
                            />
                        </a>
                    </div>
                    <div className="footer-icon-responsive">
                        <a
                            target="_blank"
                            href="https://www.twitch.tv/artesane?lang=fr"
                            rel="noreferrer">
                            <img
                                className="footer-logo"
                                src={
                                    "https://static.artesane.com/images_react/footer-twitch.png"
                                }
                                alt="twitch"
                            />
                        </a>
                    </div>
                    <div className="footer-icon-responsive">
                        <a
                            target="_blank"
                            href="https://www.artesane.com/le-journal/artesane-formation-pro/"
                            rel="noreferrer">
                            <img
                                className="footer-logo qualiopi"
                                src={
                                    "https://static.artesane.com/images_react/AF.png"
                                }
                                alt="logo de qualiopi"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div className="footer-accordion-wrapper-responsive">
                <Accordion
                    sx={{
                        boxShadow: "none",
                        padding: "0 10%",
                        color: "black",
                        backgroundColor: "#e7dacb",
                        "& .MuiAccordionSummary-root": {
                            padding: "0 20%",
                        },
                        "&::before": {
                            height: "0px",
                        },
                    }}>
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                sx={{
                                    color: "black",
                                }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        A propos
                    </AccordionSummary>
                    <AccordionDetails>
                        <FooterResponsiveItems
                            title={"Qui sommes-nous?"}
                            url={"/qui-sommes-nous"}
                        />
                        <FooterResponsiveItems
                            title={"Les professeurs"}
                            url={"/les-professeurs"}
                        />
                        {/* <FooterResponsiveItems
                            target={"_blank"}
                            title={"Rejoignez-nous"}
                            url={
                                "//welcometothejungle.com/fr/companies/artesane/jobs"
                            }
                        /> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        boxShadow: "none",
                        padding: "0 10%",
                        color: "black",
                        backgroundColor: "#e7dacb",
                        "& .MuiAccordionSummary-root": {
                            padding: "0 20%",
                            minHeight: "0px",
                        },
                        "&::before": {
                            height: "0px",
                        },
                    }}>
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                sx={{
                                    color: "black",
                                }}
                            />
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        Aide
                    </AccordionSummary>
                    <AccordionDetails>
                        <FooterResponsiveItems
                            title={"Livraison et retours"}
                            url={"/livraison-et-retours"}
                        />
                        <FooterResponsiveItems title={"FAQ"} url={"/faq"} />
                        {/* <FooterResponsiveItems title={'Presse et partenaires'} url={'/presse-et-partenaires'}/> */}
                        <FooterResponsiveItems
                            title={"Nos partenaires Arts du fil"}
                            url={"/arts-du-fil/nos-partenaires"}
                        />
                        <FooterResponsiveItems
                            title={"Nos partenaires Arts plastiques"}
                            url={"/arts-plastiques/nos-partenaires"}
                        />
                        <FooterResponsiveItems
                            title={"Nos partenaires Arts culinaires"}
                            url={"/arts-culinaires/nos-partenaires"}
                        />
                        <FooterResponsiveItems
                            title={"Nous contacter"}
                            url={"/contact"}
                        />
                        <FooterResponsiveItems
                            title={"Paiement sécurisé"}
                            url={"/paiement-securise"}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{
                        boxShadow: "none",
                        padding: "0 10%",
                        color: "black",
                        backgroundColor: "#e7dacb",
                        "& .MuiAccordionSummary-root": {
                            padding: "0 20%",
                        },
                        "&::before": {
                            height: "0px",
                        },
                    }}>
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon
                                sx={{
                                    color: "black",
                                }}
                            />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        Infos légales
                    </AccordionSummary>
                    <AccordionDetails>
                        <FooterResponsiveItems title={"CGV"} url={"/cgv"} />
                        <FooterResponsiveItems
                            title={"Mentions légales"}
                            url={"/mentions-legales"}
                        />
                        <FooterResponsiveItems
                            title={"Politique de confidentialité"}
                            url={"/politique-de-confidentialite"}
                        />
                        <FooterResponsiveItems
                            title={"Conditions des offres en cours"}
                            url={"/conditions-offres"}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="footer-univers-responsive">
                <div className="footer-univers-link">
                    {" "}
                    <NavLink to="/arts-culinaires">
                        Arts culinaires
                    </NavLink>{" "}
                </div>
                <div className="footer-univers-link">
                    {" "}
                    <NavLink to="/arts-du-fil">Arts du fil</NavLink>
                </div>
                <div className="footer-univers-link">
                    {" "}
                    <NavLink to="/arts-plastiques">Arts plastiques</NavLink>
                </div>
                {/* <div className='footer-univers-link'> <NavLink to="/formation-pro">Formation pro</NavLink> </div> */}
                <div className="footer-univers-link">
                    {" "}
                    <a
                        target="_blank"
                        href="https://www.artesane.com/le-journal/"
                        rel="noreferrer">
                        Le journal
                    </a>{" "}
                </div>
            </div>

            <div className="footer-logo-wrapper">
                <img
                    className="footer-logo-img"
                    src={
                        "https://static.artesane.com/images_react/logo.png"
                    }
                    alt="logo d'Artesane"
                />
            </div>

            <div className="footer-copyright">
                © Artesane {date.getFullYear()}
            </div>
        </div>
    )
}

export default FooterResponsive
