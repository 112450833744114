import { NavLink } from "react-router-dom"
import "./MediaProductCard.scss"

const MediaProductCard = ({ urlImg, title, teacher, id }) => {
    return (
        <div className="mediaproduct-card-wrapper">
            <NavLink to={`/mes-cours-et-documents/${id}`}>
                <div className="mediaproduct-card-image-wrapper">
                    <img src={urlImg} alt="" />
                </div>
                <div className="mediaproduct-card-infos">
                    <div className="mediaproduct-card-title">{title}</div>
                    <div className="mediaproduct-card-teacher">{teacher}</div>
                    <div className="mediaproduct-card-button-wrapper">
                        <div className="mediaproduct-card-button">
                            vidéos et documents
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}
export default MediaProductCard
