//Styles
import "./FiltersItems.scss"
//Hooks
import { useDispatch, useSelector } from "react-redux"
//Actions
import {
    saveNumberProduct,
    toggleFilterNameActif,
} from "../../../../actions/catalogueAction"

//Components
import FiltersUnderItems from "./FiltersUnderItems/FiltersUnderItems"

const FiltersItems = ({ title, filters, indexCriteres, parentFilterId }) => {
    const currentFilterActif = useSelector(
        (state) => state.catalogue.filterNameActif
    )
    const numberOfFiltersVisible = useSelector(
        (state) => state.catalogue.filterVisible
    )
    const isFilterActif = useSelector((state) => state.catalogue.isFilterActif)
    const teachersFilter = useSelector(
        (state) => state.catalogue.teachersFilter
    )

    const filtersChecked = useSelector(
        (state) => state.catalogue.filtersCheckboxInput
    )
    const dispatch = useDispatch()
    const productNumber = useSelector((state) => state.catalogue.numberProduct)

    return (
        <div
            className={
                isFilterActif && indexCriteres < numberOfFiltersVisible
                    ? "filtersItems-and-filterUnderItems"
                    : "filtersItems-and-filterUnderItems-closed"
            }>
            <div
                className="filters-sort-items-wrapper"
                onClick={() => {
                    if (currentFilterActif === title) {
                        dispatch(toggleFilterNameActif(""))
                    } else {
                        dispatch(toggleFilterNameActif(title))

                        dispatch(saveNumberProduct(0))
                    }
                }}>
                <div className="filters-sort-items-title">{title}</div>
                {filtersChecked.find(
                    (element) => element.parent === parentFilterId
                ) && (
                    <div className="filter-sort-items-number-choice-click">
                        {
                            filtersChecked.find(
                                (element) => element.parent === parentFilterId
                            ).children.length
                        }
                    </div>
                )}
                <div className="filters-sort-items-title-chevron">{">"}</div>
            </div>
            <FiltersUnderItems
                parentFilterId={parentFilterId}
                productNumber={productNumber}
                indexCriteres={indexCriteres}
                title={title}
                filters={filters}
                teachers={teachersFilter}
            />
        </div>
    )
}

export default FiltersItems
