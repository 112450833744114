import "./OffresEleves.scss"

const OffresEleves = ({ productData }) => {
    if (
        productData.attributes.find((item) => item.code === "offres-eleves") !==
        undefined
    ) {
        return (
            <div className="offres-eleves-wrapper">
                <div className="accordion-under-title">
                    Les offres réservées à nos élèves
                </div>
                <div>
                    {
                        productData.attributes.find(
                            (item) => item.code === "offres-eleves"
                        ).value
                    }
                </div>
            </div>
        )
    }
}

export default OffresEleves
