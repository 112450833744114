import "./Banner.scss"

const Banner = ({ univers }) => {
    return (
        <div className="banner-wrapper">
            <div className="banner-text">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://mailchi.mp/artesane/inscription-newsletter-artesane-jupe">
                    <span>
                        Votre patron et cours offerts en vous inscrivant à la
                        newsletter !
                    </span>
                </a>
            </div>
        </div>
    )
}

export default Banner
