//Actions
import { accordionMenuActif } from "../../../actions/productsActions"

//Hooks
import { useDispatch, useSelector } from "react-redux"

//Styles
import "./AccordionsProducts.scss"

const AccordionsProducts = ({
    nouveuteEtPack,
    title,
    summary,
    objectifs,
    materiel,
    prerequis,
    offresEleves,
    programme,
    anchor,
    target,
}) => {
    const currentActifMenu = useSelector(
        (state) => state.products.accordionMenuActif
    )
    const dispatch = useDispatch()

    const scrollToAnchor = (event, anchorId) => {
        event.preventDefault()
        const element = document.getElementById(anchorId)
        const offset = 200 // Ajustez la valeur selon le décalage souhaité

        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })

        // Ajuster après un petit délai pour permettre le scrollIntoView
        setTimeout(() => {
            const yOffset = -offset
            const y =
                element.getBoundingClientRect().top + window.scrollY + yOffset
            window.scrollTo({ top: y, behavior: "smooth" })
        }) // Ajustez le délai selon vos besoins
    }

    return (
        <div className="accordions-products-wrapper">
            <a
                href={anchor}
                onClick={(event) => {
                    scrollToAnchor(event, target)
                }}>
                <div
                    id={target}
                    onClick={() => {
                        if (currentActifMenu === title) {
                            dispatch(accordionMenuActif(""))
                        } else {
                            dispatch(accordionMenuActif(""))
                            setTimeout(() => {
                                dispatch(accordionMenuActif(title))
                            })
                        }
                    }}
                    className="accordions-products-title-wrappper">
                    <h2 className="accordions-products-title">{title}</h2>
                    <div
                        className={
                            currentActifMenu === title
                                ? "accordions-products-chevron-open"
                                : "accordions-products-chevron-closed"
                        }>
                        {"〈"}
                    </div>
                </div>
            </a>
            <div
                className={
                    currentActifMenu === title
                        ? "accordions-products-informations-wrapper"
                        : "closed"
                }>
                {/* 'Résumé' */}
                {nouveuteEtPack}
                {summary}
                {objectifs}
                {/* 'Matériel et Prérequis' */}
                <div className="accordion-materiel-prerequis-wrapper">
                    {materiel}
                    <div className="accordion-offre-prerequis-wrapper">
                        {prerequis}
                        {offresEleves}
                    </div>
                </div>

                {/* 'Programme' */}
                {programme}
            </div>
        </div>
    )
}

export default AccordionsProducts
