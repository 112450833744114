//Styles
import "./FiltersAndSort.scss"

//Components
import FiltersItems from "./FiltersItems/FiltersItems"
import SortItems from "./SortItems/SortItems"

//Hooks
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import MediaQuery from "react-responsive"

//Actions
import {
    toggleIsFilterActif,
    toggleIsSortActif,
    toggleNumberFiltersVisible,
} from "../../../actions/catalogueAction"

const FiltersAndSort = () => {
    const numberOfFiltersVisible = useSelector(
        (state) => state.catalogue.filterVisible
    )
    const isFilterActif = useSelector((state) => state.catalogue.isFilterActif)
    const isSortActif = useSelector((state) => state.catalogue.isSortActif)
    const filtersData = useSelector((state) => state.catalogue.filters)

    const dispatch = useDispatch()

    useEffect(() => {
        if (window.matchMedia("(min-width: 1000px)").matches) {
            dispatch(toggleIsFilterActif(true))
        }
    }, [dispatch])

    useEffect(() => {
        if (window.matchMedia("(max-width: 999px)").matches) {
            dispatch(toggleNumberFiltersVisible(filtersData.length))
        }
    }, [dispatch, filtersData])

    return (
        <>
            <div className="filters-and-sort-wrapper">
                <MediaQuery maxWidth={1000}>
                    <div
                        className="filters-and-sort-button"
                        onClick={() => {
                            dispatch(toggleIsSortActif(false))
                            dispatch(toggleIsFilterActif(!isFilterActif))
                        }}>
                        {isFilterActif
                            ? "Masquer tous les filtres"
                            : " Voir tous les filtres"}
                    </div>
                </MediaQuery>
                <div
                    className="filters-and-sort-button"
                    onClick={() => {
                        if (window.matchMedia("(min-width: 1000px)").matches) {
                            dispatch(toggleIsSortActif(!isSortActif))
                        } else {
                            dispatch(toggleIsFilterActif(false))
                            dispatch(toggleIsSortActif(!isSortActif))
                        }
                    }}>
                    Trier par
                </div>
            </div>
            <div
                className="more-filters-button"
                onClick={() => {
                    if (numberOfFiltersVisible > 5) {
                        dispatch(toggleNumberFiltersVisible(5))
                    } else {
                        dispatch(toggleNumberFiltersVisible(filtersData.length))
                    }
                }}>
                <div>
                    {" "}
                    Voir {numberOfFiltersVisible > 5 ? "moins" : "plus"} de
                    filtres
                </div>
            </div>
            <div className="filters-or-sort-items">
                {filtersData.filter(item => item.enabled !== false).map(({ name, children, id }, indexCriteres) => (
                    <FiltersItems
                        parentFilterId={id}
                        indexCriteres={indexCriteres}
                        key={name}
                        title={name}
                        filters={children}
                    />
                ))}

                <SortItems />
            </div>
        </>
    )
}

export default FiltersAndSort
