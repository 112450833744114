//Libraries
import AliceCarousel from "react-alice-carousel"

//Actions
import {
    productSliderChangeIndex,
    productSliderIndexToShow,
    toggleThumbnailProductHover,
    toggleSliderZoomProduct,
    sliderZoomIndexToSHow,
    saveProductDataState,
} from "../../../actions/productsActions"

// Hooks
import { useDispatch, useSelector } from "react-redux/es/exports"

//Styles
import "./ProductSliderDesktop.scss"
import { getYoutubeThumbnailUrl, sortMediaProduct } from "../../../selectors/selectors"
import { useEffect } from "react"
import Spinner from "../../Commons/Spinner/Spinner"

const ProductSliderDesktop = ({ productData }) => {
    const dispatch = useDispatch()
    const activeIndex = useSelector(
        (state) => state.products.productSliderIndex
    )
    const activeIndexToShow = useSelector(
        (state) => state.products.productSliderIndexToShow
    )
    const isHover = useSelector((state) => state.products.toggleThumbnailHover)
    const loadingTrailers = useSelector((state) => state.products.loadingTrailers)
    const productMedia = sortMediaProduct(
        productData.images,
        productData.trailers,
        "big"
    )
    useEffect(() => {
        const fetchThumbnails = async () => {
            const thumbnailPromises = productData.trailers.map(async (url) => {
                try {
                    const thumbnailUrl = await getYoutubeThumbnailUrl(url.url);
                    return url["light"] = thumbnailUrl ;
                } catch (error) {
                    return url["light"] = null;
                }
            });

            const resolvedTrailers = await Promise.all(thumbnailPromises);
            dispatch(saveProductDataState("trailers", resolvedTrailers)); // Mettre à jour l'état avec les trailers
            dispatch(saveProductDataState("loadingTrailers", false)); // Mettre à jour l'état avec les trailers

        };


        fetchThumbnails();
    }, []);

    const trailers = useSelector((state) => state.products.trailers);

    return (
        !loadingTrailers ? (
            <div className="product-slider-desktop-wrapper">
            <div
                onClick={() => {
                    dispatch(sliderZoomIndexToSHow(activeIndexToShow))
                    dispatch(toggleSliderZoomProduct(true))
                }}
                className="product-slider-image-video-wrapper"
                onMouseEnter={() => {
                    dispatch(toggleThumbnailProductHover(true))
                }}
                onMouseLeave={() => {
                    dispatch(toggleThumbnailProductHover(false))
                }}>
                {isHover && (
                    <div className="product-slider-image-video-hover">
                        <div className="product-slider-image-video-hover-button">
                            Agrandir
                        </div>
                    </div>
                )}

                {productMedia.length > 0 && (
                    <>
                        {productMedia[activeIndexToShow]["@type"].includes(
                            "Image"
                        ) && (
                            <img
                                src={productMedia[activeIndexToShow].url}
                                alt={productMedia[activeIndexToShow].alt}
                            />
                        )}

                        {productMedia[activeIndexToShow]["@type"].includes(
                            "Trailer"
                        ) && (
                            <>
                                <img
                                    src={productMedia[activeIndexToShow].light}
                                    alt="vignette vidéo youtube"
                                />
                                <img
                                    className="product-slider-video-icon"
                                    src={
                                        "https://static.artesane.com/images_react/white_player.png"
                                    }
                                    alt="logo player"
                                />
                            </>
                        )}
                    </>
                )}
            </div>
            {productMedia.length > 1 && (
                <div className="product-slider-desktop-caroussel-miniature">
                    <AliceCarousel
                        touchTracking
                        activeIndex={activeIndex}
                        // infinite
                        onSlideChanged={({ item }) => {
                            dispatch(productSliderChangeIndex(item))
                        }}
                        disableDotsControls
                        items={productMedia.map((item, index) => {
                            return (
                                <div
                                    onClick={(event) => {
                                        dispatch(
                                            productSliderIndexToShow(index)
                                        )
                                    }}
                                    className="products-slider-image-wrapper">
                                    <img
                                        src={
                                            item["@type"].includes("Trailer")
                                                ? (item.light)
                                                : item.url
                                        }
                                        alt="trailer"
                                    />
                                </div>
                            )
                        })}
                        responsive={{
                            0: {
                                items: 5,
                            },

                            1024: {
                                items: 9,
                            },
                        }}
                    />
                </div>
            )}
        </div>
        ) : (
            <Spinner/>
        )

    )
}

export default ProductSliderDesktop
