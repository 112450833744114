//Styles
import "./LessonCard.scss"

const LessonCard = ({
    urlImg,
    title,
    subtitle,
    description,
    url,
    buttonText,
    fileType,
    urlVideo,
}) => {

    let titleTrim =( title !== undefined && title !== null) ? title.trim() : "";
    let subtitleTrim = (subtitle !== undefined && title !== null) ? subtitle.trim() : "";
    let descriptionTrim = (description !== undefined && description !== null)? description.trim() : "";
    let buttonTextTrim = (buttonText !== undefined && buttonText !== null)? buttonText.trim() : "";
    return (
        <div className="lesson-card-wrapper">
           <a href={url}><img className="lesson-card-img" src={urlImg} alt="" /></a>

        {(titleTrim.length > 0 || subtitleTrim.length > 0 || descriptionTrim.length > 0 || buttonTextTrim.length > 0 ) && (
                        <div className="lesson-card-text-wrapper">
                        {titleTrim.length > 0  && (
                            <div className="lesson-card-title">{title}</div>
                        )}
                        {subtitleTrim.length > 0  && (
                             <div className="lesson-card-level">{subtitle}</div>
                        )}
                       {descriptionTrim.length > 0  && (
                         <div className="lesson-card-description">{description}</div>
        
                        )}
                       {buttonTextTrim.length > 0  && (
                         <div className="lesson-card-button-wrapper">
                            <a href={url}>
                                <div className="lesson-card-link">{buttonText}</div>
                            </a>
                        </div>
                       )}                
                       
                    </div>
        )}

        </div>
    )
}

export default LessonCard
