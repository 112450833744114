import "./Livraison.scss"
import InputSimple from "../../UserAccount/InputSimple/inputSimple"
import { useSelector, useDispatch } from "react-redux"
import {
    changeInputOrder
} from "../../../actions/cartAction"
import { useEffect, useState } from "react"
import { fetchAddresses, fetchCountries } from "../../../actions/userAction"
import Spinner from "../../Commons/Spinner/Spinner"
import { useNavigate } from "react-router-dom"
import MondialRelayWidget from "./MondialRelayWidget/MondialRelayWidget"
import MondialRelaySelect from "./MondialRelaySelect/MondialRelaySelect"
import {useRef} from 'react';
import AddressForm from "./AddressForm/AddressForm"
import DeliveryForm from "./DeliveryForm/DeliveryForm"
import AddressInsert from "./AddressInsert/AddressInsert"
import OrderSummary from "../OrderSummary/OrderSummary"
import TotalOrder from "../../Cart/TotalOrder/TotalOrder"
import MediaQuery from "react-responsive"

const Livraison = () => {
    const dispatch = useDispatch()
    const payplugUrl = useSelector((state) => state.cart.payplugUrl)
    const navigate = useNavigate()
    const cartData = useSelector((state) => state.cart.cartData)
    const loadingCart = useSelector((state) => state.cart.loadingCart)
    const redirectPaiement = useSelector((state) => state.cart.redirectPaiement)
    const loadingValidateAddress = useSelector((state) => state.cart.loadingValidateAddress)
    const addresses = useSelector((state) => state.user.addresses)

    useEffect(() => {
        dispatch(fetchCountries())
        dispatch(changeInputOrder(true, "loadingValidateAddress"))
        dispatch(changeInputOrder(false, "adressValidate"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])


    useEffect(() => {
        if(!loadingCart && loadingValidateAddress) {

            dispatch(fetchAddresses("delivery"))
        }
    }, [dispatch, loadingCart, loadingValidateAddress])


    useEffect(() => {
        if (payplugUrl !== "") {
            window.location.replace(payplugUrl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payplugUrl])



    useEffect(() => {
        if (redirectPaiement) {
            navigate("/commande/paiement")
        }
    }, [redirectPaiement])
    const chooseDelivery = useSelector((state) => state.cart.chooseDelivery)

    const addressValidate = useSelector((state) => state.cart.addressValidate)
    const loadingAddresses = useSelector(
        (state) => state.user.loadingAddAddress
    )
    const loadingAddress = useSelector(
        (state) => state.cart.loadingValidateAddress
    )


    const redirectPaiementWindow = useSelector(
        (state) => state.cart.redirectPaiementWindow
    )
    const openMondialRelayWidget = useSelector(
        (state) => state.cart.openMondialRelayWidget
    )
    const ancreRef = useRef(null);

    
    return (
        <div className="livraison-wrapper">

            {openMondialRelayWidget && (
                <MondialRelayWidget/>
            )}
            {(loadingCart || loadingAddresses || loadingAddress) && <Spinner />}

            {!loadingCart && !loadingAddresses && !loadingAddress && (
                <>
                    {!chooseDelivery &&  cartData.shippingAddress === undefined && !addressValidate && (
                        <AddressForm/>
                    )}
                  
                    {!chooseDelivery && cartData.shippingAddress !== undefined &&(
                        <AddressInsert/>
                    )}
                      {chooseDelivery && cartData.shipments.length > 0 && (
                        <DeliveryForm/>
                    )}
                </>
            )}
            <MediaQuery maxWidth={1000}>
                {Object.keys(cartData).length > 0 && (
                    
                    <TotalOrder
                            link={"#"}
                            totalTTC={cartData.items_total}
                            adjustments={cartData.adjustments_total}
                            total={cartData.total}
                        />
                )}
            </MediaQuery>
           

            {!loadingCart && (
                 <OrderSummary/>
            )}
        </div>
    )
}

export default Livraison
